export const fetchISolvedEmployeeSchedule = async (employeeId) => {
  try {

    const url = 'https://vlcvyseryvyxyim3ngnbatxhma0ktols.lambda-url.us-east-1.on.aws/?employeeId=' + employeeId;

    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    console.warn('fetchISolvedEmployeeSchedule response:', response);

    // Check if the response was not successful and throw an error with detailed info
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error in fetchISolvedEmployeeSchedule:', response.status, errorText);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    // Parse the JSON data
    const dataJSON = await response.json();
    // console.warn('fetchISolvedEmployeeSchedule dataJSON:', dataJSON);

    return dataJSON;
  } catch (error) {
    console.error('fetchISolvedEmployeeSchedule failed:', error.message);
    return null;
  }
};