import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listPunchedLaborByDateDepartmentShort, getDepartmentCodeByDivisionId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchPunchedLaborByDateDepartment = async (params) => {
  if (!params) {
    // console.error('Missing params in fetchPunchedLaborByDateDepartment');
    return [];
  }

  try {
    const { divisionId, workDayHuman } = params;

    console.info('params: ', params);

    // Fetch department code
    const departmentResponse = await client.graphql({
      query: getDepartmentCodeByDivisionId,
      variables: {
        divisionId: divisionId,
        filter: { isPrimary: { eq: true } },
      },
    });

    const departmentCode = departmentResponse?.data?.iSolvedDepartmentByDivisionId?.items?.[0]?.departmentCode;
    if (!departmentCode) {
      console.warn('No departmentCode found for divisionId:', divisionId);
      return [];
    }

    const departmentGroup = departmentCode.substring(0, 2);
    const dateDepartmentGroup = `${workDayHuman}#${departmentGroup}`;

    // Fetch labor data
    const response = await client.graphql({
      query: listPunchedLaborByDateDepartmentShort,
      variables: { dateDepartment: dateDepartmentGroup }
    });

    const results = response?.data?.iSolvedPunchesByDateByDepartmentShort?.items;
    if (!Array.isArray(results)) {
      console.warn('No results found for dateDepartment:', dateDepartmentGroup);
      return [];
    }
    console.log('iSolvedPunchesByDateByDepartmentShort results: ', results);

    // Process results
    const seenTeamMembers = new Set(); // Set for uniqueness
    const uniqueArrayObjects = [];

    for (let result of results) {
      const teamMember = result?.teamMember?.teamMember; // Optional chaining for safety
      if (teamMember) {
        const teamMemberId = teamMember.id;

        // Assign default popularity if missing
        result.popularity = result.popularity?.popularity || 0;

        // Check if this teamMemberId is already seen
        if (!seenTeamMembers.has(teamMemberId)) {
          uniqueArrayObjects.push(result);
          seenTeamMembers.add(teamMemberId); // Add to Set to mark as seen
        }
      }
    }

    // Sort results by popularity
    uniqueArrayObjects.sort((a, b) =>
      (a.popularity || 0) < (b.popularity || 0) ? 1 : -1
    );

    return uniqueArrayObjects;
  } catch (error) {
    console.error('Error in fetchPunchedLaborByDateDepartment:', error);
    return [];
  }
};

// export const fetchPunchedLaborByDateDepartment = async (params) => {
//   if (params) {
//     try {
//       const { divisionId, workDayHuman } = params;

//       const departmentResponse = await client.graphql({
//         query: getDepartmentCodeByDivisionId,
//         variables: {
//           divisionId: divisionId,
//           filter: {
//             isPrimary: {
//               eq: true
//             }
//           }
//         }
//       });

//       const departmentCode = departmentResponse.data.iSolvedDepartmentByDivisionId.items[0].departmentCode;
//       const departmentGroup = departmentCode.substring(0, 2);
//       const dateDepartmentGroup = `${workDayHuman}#${departmentGroup}`;

//       const response = await client.graphql({
//         query: listPunchedLaborByDateDepartmentShort,
//         variables: {
//           dateDepartment: dateDepartmentGroup
//         }
//       });

//       const results = response.data.iSolvedPunchesByDateByDepartmentShort.items;

//       let teamMemberId = '';
//       let uniqueArrayObjects = [];
//       let seenTeamMembers = new Set(); // Set for uniqueness

//       for (let result of results) {
//         const teamMember = result.teamMember?.teamMember; // Optional chaining for safety
//         if (teamMember) {
//           teamMemberId = teamMember.id;

//           // Assign default popularity if it's missing
//           result.popularity = result.popularity?.popularity || 0;

//           // Check if this teamMemberId is already seen
//           if (!seenTeamMembers.has(teamMemberId)) {
//             uniqueArrayObjects.push(result);
//             seenTeamMembers.add(teamMemberId); // Add to Set to mark as seen
//           }
//         }
//       }

//       uniqueArrayObjects.sort((a, b) => a.popularity.popularity < b.popularity.popularity ? 1 : -1);

//       return uniqueArrayObjects;
//     } catch (err) {
//       return 'error fetchLaborByDivisionByDate: ' + err;
//     }
//   } else {
//     return 'Missing params';
//   }
// };

fetchPunchedLaborByDateDepartment.propTypes = {
  params: PropTypes.shape({
    divisionId: PropTypes.string.isRequired,
    workDayHuman: PropTypes.string.isRequired,
  }).isRequired,
};
