import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
import {
  onCreateSpkISolvedFetchEmployeesStatusTbl,
  onUpdateSpkISolvedFetchEmployeesStatusTbl,
} from '../../../graphql/custom_subscriptions';

// Generate the Amplify client
const client = generateClient();

export const StatusSubscription = ({ onStatusUpdate }) => {
  useEffect(() => {
    const subscribeToCreate = client
      .graphql({
        query: onCreateSpkISolvedFetchEmployeesStatusTbl,
      })
      .subscribe({
        next: (response) => {
          const newRecord = response?.data?.onCreateSpkISolvedFetchEmployeesStatusTbl;
          if (newRecord) {
            console.log('New record received (onCreate):', newRecord);
            onStatusUpdate(newRecord);
          } else {
            console.warn('No data in onCreate subscription response');
          }
        },
        error: (err) => console.error('Subscription error (onCreate):', err),
        complete: () => console.log('onCreate subscription complete'),
      });

    console.warn('Subscription initialized');

    const subscribeToUpdate = client
      .graphql({
        query: onUpdateSpkISolvedFetchEmployeesStatusTbl,
      })
      .subscribe({
        next: (response) => {
          const updatedRecord = response?.data?.onUpdateSpkISolvedFetchEmployeesStatusTbl;
          if (updatedRecord) {
            console.log('Record updated (onUpdate):', updatedRecord);
            onStatusUpdate(updatedRecord);
          } else {
            console.warn('No data in onUpdate subscription response');
          }
        },
        error: (err) => console.error('Subscription error (onUpdate):', err),
        complete: () => console.log('onUpdate subscription complete'),
      });

    // Cleanup subscriptions on unmount
    return () => {
      subscribeToCreate.unsubscribe();
      subscribeToUpdate.unsubscribe();
    };
  }, [onStatusUpdate]);

  return null; // This component doesn't render anything visually
};

StatusSubscription.propTypes = {
  onStatusUpdate: PropTypes.func.isRequired,
};