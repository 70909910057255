import { allTeamMembersByDivisionId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const listAllTeamMembersByDivisionId = async (divisionId, nextoken, limit=250) => {
  try {
    let gqlParams = {}
    if (nextoken!==undefined && nextoken!=='') {
      gqlParams = {
        divisionId: divisionId,
        limit: limit,
        nextToken: nextoken
      }
    } else {
      gqlParams = {
        divisionId: divisionId,
        limit: limit
      }
    }

    const response = await client
    .graphql({
      query: allTeamMembersByDivisionId,
      variables: gqlParams
    })

    console.info('response: ', response);
    
    const results = response.data.teamMembersByDivisionId

    return results;
  } catch (err) {
    return('listAllTeamMembersByDivisionId error: ', [err]);
  }

}

listAllTeamMembersByDivisionId.propTypes = {
  divisionId: PropTypes.string.isRequired,
  nextToken: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
};