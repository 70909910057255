import PropTypes from 'prop-types';
import React from 'react';
import {
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Tooltip,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

// decorative components
import { Divider } from '../Divider';

// utility functions
import { truncateString } from '../functions/strings.jsx';

// form components
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { FormSelectMulti } from '../Form/Select/FormSelectMulti.jsx';

export const EquipmentSummaryTable = (props) => {
  const {
    tableData=[],
    operatorsDriversSelectOptions=[],
    selectedDrivers=[],
    handleDriverChange,
    selectedOperators=[],
    handleOperatorChange,
    updateUnitSummary,
    toggleUnitsButtonState,
    toggleAllUnitsButtonState,
    handleAuxillaryUnitChange,
    handleCustomAuxillaryUnitChange,
    handleCustomUnitChange,
    auxillaryUnitOptions=[],
    allAuxillaryUnitOptions,
    handleUnitRowTotalChange,
    unitTotalAmount=0.00,
    djtTotalAmount=0.00,
    costItem,
    control,
    register,
    errors,
    getValues,
    handleUnitQuantityChange,
    isEstimate,
  } = props;

  // console.info('operatorsDriversSelectOptions: ', operatorsDriversSelectOptions);

  
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const orderedData = tableData?.sort((a, b) => a?.orderedIndex - b?.orderedIndex);

  return (
    <>
    <TableContainer>
      <Table variant={'compact'} size={'compact'}>
        <Thead>
          <Tr>
            <Th></Th>
            <Th textAlign={'right'}><Text as="span" pl='10px' mr={'10px'} className='dark-sfpro-heading-4'>UNIT ID</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'></Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>QUANTITY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>BASELINE RATE</Text></Th>
            <Th textAlign={'right'}><Text as="span" pl='10px' className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderedData?.map((data, index) => {
            const unitCodeString = String(data?.UnitCode)
            const is700Series = (unitCodeString?.length===3 && unitCodeString?.charAt(0)==='7') ? true : false
            const isCustomAuxillary = (data?.isCustomAuxillary===true) ? true : false
            const isAuxiliary = (data?.isAuxillaryUnit===true) ? true : false
            const isCustomEquipment = (data?.isCustomEquipment===true) ? true : false
            const isReadOnly = !(data?.isCustomEquipment)
            const showAuxDropdown = (data?.isAuxillaryUnit===false && auxillaryUnitOptions[index]?.length>0 && data?.isCustomEquipment===false)
            const showRateInput = (isReadOnly)
            const showDriverRow = (data?.requiresDriver===true && !isEstimate)
            const showOperatorRow = (data?.requiresOperator===true && !isEstimate)
            const unitCodeLong = data?.UnitCode?.toString()
            const unitCodeShort = unitCodeLong?.split('/')[0]
            const selectedCustomAuxUnit = (data?.Description!=='') ? data?.Description : null
            const unitRateRegex = /\//ig;
            const unitRate = (data?.UnitRate.search(unitRateRegex)>-1) ? data?.UnitRate : ''

            let isUnitAuxAddedToPrimary = false
            if (!data?.isCustomEquipment && data?.UnitCategory==='auxillary' && data?.isAuxillaryUnit===true) {
              isUnitAuxAddedToPrimary=true
            }
            if (data?.isAuxChild===true) {
              isUnitAuxAddedToPrimary=true
            }

            let driversArr = []
            selectedDrivers?.forEach((item) => {
              if (item[0] === data?.UnitId) {
                if (item?.length>0) {
                  let itemLen = item?.length-1
                  let lastItems = -itemLen
                  let tmpArr = item?.slice(lastItems)
                  tmpArr?.forEach((item, index) => {
                    driversArr[index] = {label: item?.drivenBy?.firstName+' '+item?.drivenBy?.lastName, value: item?.drivenById}
                  })
                }
              }
            })
            
            let operatorsArr = []
            selectedOperators?.forEach((item) => {
              if (item[0] === data?.UnitId) {
                if (item?.length>0) {
                  let itemLen = item?.length-1
                  let lastItems = -itemLen
                  let tmpArr = item?.slice(lastItems)
                  tmpArr?.forEach((item, index) => {
                    operatorsArr[index] = {label: item?.operatedBy?.firstName+' '+item?.operatedBy?.lastName, value: item?.operatedById}
                  })
                }
              }
            })

            return(
              <React.Fragment key={'fragment_'+index}>
                {(index>0 && (!isUnitAuxAddedToPrimary || ( data?.UnitCategory==="auxillary" && data?.isAuxillaryUnit===true && data?.isCustomAuxillary===true ))) && 
                <Tr key={'tr_units_divider_'+index}><Td colSpan='7' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
                <Tr key={'tr_units_'+index}>

                  {/* trashicon */}
                  <Td key={'td_units_0_'+index} w={'44px'}>
                    <Box w={'42px'} >
                      {(!isUnitAuxAddedToPrimary || ( data?.UnitCategory==="auxillary" && data?.isAuxillaryUnit===true && data?.isCustomAuxillary===true )) && 
                      <ButtonSenaryWithIconOnly
                        mt='5px'
                        key={'td_0_units_delete_'+index}
                        name={'units_delete_row_'+index}
                        icon='delete'
                        iconsize='22px'
                        onClick={() => {
                          if ((data?.UnitCategory==='available' || data?.UnitCategory==='unavailable') && (data?.isAuxillaryUnit!==true && data?.isCustomEquipment!==true)) {
                            // console.log('data 1: ', data)
                            if (data?.UnitCategory==='available') {
                              toggleUnitsButtonState(data.OriginalIndex)
                            } else {
                              toggleAllUnitsButtonState(data?.OriginalIndex, data?.ButtonIndex)
                            }
                          }
                          updateUnitSummary('remove', data, index)
                        }}
                      />}
                      </Box>
                  </Td>
                  
                  {/* unitcode */}
                  <Td key={'td_units_1_'+index} textAlign={'right'} >
                    <Box>
                      <TextInput
                        key={'unitcode_'+index}
                        register={register}
                        errors={errors}
                        // width='102px'
                        fieldname={`unit[${index}].UnitCode`}
                        fieldtype={'hidden'}
                      />
                      {/* hide any 7xx series unit codes */}
                      {(!is700Series) && <Tooltip label={unitCodeLong||''}><Text as="span" mr={'10px'} textStyle={'text-7'}>&nbsp;&nbsp;{unitCodeShort}</Text></Tooltip>}
                    </Box>
                  </Td>

                  {/* description */}
                  <Td colSpan={(isCustomAuxillary || isCustomEquipment) ? 2 : 1 } key={'td_units_2_'+index}>
                    {(isCustomAuxillary===true) ?
                      <>
                        <Box ml={'7px'} width={(isCustomAuxillary) ? '450px' : '200px'}>
                          <FormSelectSimple
                            key={'description_'+index}
                            register={register}
                            control={control}
                            errors={errors}
                            isRequired={true}
                            rules={{ required: "Auxiliary Unit is required" }}
                            onChange={e => handleCustomAuxillaryUnitChange(e, index)}
                            selectedoption={selectedCustomAuxUnit || ''}
                            isDisabled={false}
                            optionsArray={
                              allAuxillaryUnitOptions?.map((auxunit) => ({ 
                                label: auxunit?.name, value: auxunit?.id
                              }))
                            }
                            fieldname={`unit[${index}].Description`}
                            prettyname='Description'
                            placeholder={'Select stand-alone auxiliary unit or start typing'}
                          />
                          {errors.unit?.[index]?.Description && errors.unit?.[index]?.Description.type === "required" && (
                            <Text as="span" textStyle={'text-2'} color='var(--error-red)'>Stand-alone auxiliary equipment required</Text>
                          )}
                        </Box>
                      </>
                      :
                      <>
                        <Box ml={'7px'} width={(isCustomEquipment) ? '450px' : '200px'}>
                          <TextInput
                            key={'description_'+index}
                            fieldname={`unit[${index}].Description`}
                            prettyname='Description'
                            register={register} 
                            errors={errors}
                            isRequired={true}
                            onBlur={(e) => (e.target.value!=='') && handleCustomUnitChange(index, e.target.value)}
                            isReadOnly={isReadOnly}
                            placeholder='Add rental equipment description'
                            fieldtype={(isCustomEquipment) ? 'text' : 'hidden'}
                            fieldvalue={(!isCustomEquipment) ? data?.Description : ''}
                            />
                            {errors.unit?.[index]?.Description && errors.unit?.[index]?.Description.type === "required" && (
                              <Text as="span" textStyle={'text-2'} color='var(--error-red)'>Rental equipment description required</Text>
                            )}
                      
                          {(isUnitAuxAddedToPrimary) && 
                            <Box w={'450px'}>
                              <Tag
                                ml={'18px'}
                                size={'md'}
                                key={'md'}
                                borderRadius='6px'
                                variant='solid'
                                colorScheme='spiketaggray'
                                onClick={() => {
                                  if (data?.UnitCategory==='available' || data?.UnitCategory==='unavailable') {
                                    if (data?.UnitCategory==='available') {
                                      toggleUnitsButtonState(data?.OriginalIndex)
                                    } else {
                                      toggleAllUnitsButtonState(data?.OriginalIndex, data?.ButtonIndex)
                                    }
                                  }
                                  updateUnitSummary('remove', data, index)
                                }}
                              >
                                <TagLabel>{data?.Description}</TagLabel>
                                <TagCloseButton />
                              </Tag>
                            </Box>}
                          {(!isCustomEquipment && !isUnitAuxAddedToPrimary) && 
                            <Tooltip label={data.Description||''}>
                              <Text as="span" textStyle={'text-7'}>&nbsp;&nbsp;{truncateString(data?.Description, 60)}</Text>
                            </Tooltip>
                          }
                        </Box>
                      </>
                    }

                    <TextInput
                      key={'description_h_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit${index}Description`}
                      fieldtype='hidden'
                    />
                    <TextInput
                      key={'origdescription_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].OrigDescription`}
                      fieldtype='hidden'
                    />
                  </Td>
                  
                  {/* attached aux unit dropdown */}
                  {(!isCustomAuxillary && !isCustomEquipment) && 
                  <Td key={'td_units_3_'+index}>
                    <Box key={'td_units_3_box_'+index} width='228px'>
                      {(showAuxDropdown) ? <FormSelectSimple
                        key={'auxunit_'+index}
                        register={register}
                        control={control}
                        errors={errors}
                        onChange={e => handleAuxillaryUnitChange(e, index)}
                        isDisabled={false}
                        optionsArray={
                          auxillaryUnitOptions[index]?.map((auxunits) => ({ 
                            label: auxunits?.spkAuxillaryUnitTbl?.name, 
                            value: auxunits?.spkAuxillaryUnitTbl?.id, 
                            parentId: auxunits?.spkAuxillaryUnitTbl?.parentId
                          }))
                        }
                        fieldname={`unit[${index}].AuxUnit`}
                        selectedoption={{label: 'Add auxiliary units', value: ''}}
                      /> : <TextInput
                        key={'auxunit_'+index}
                        register={register}
                        errors={errors}
                        fieldname={`unit[${index}].AuxUnit`}
                        isReadOnly={true}
                        isDisabled={true}
                        fieldvalue=''
                        fieldtype={'hidden'}
                      />}
                    </Box>
                  </Td>}

                  {/* quantity */}
                  <Td key={'td_units_4_'+index}>
                    {/* if standalone aux or rental */}
                    {(isAuxiliary || isCustomAuxillary || isUnitAuxAddedToPrimary) && <TextInput
                      key={'quantity_s_'+index}
                      width='120px'
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].Quantity`}
                      isReadOnly={false}
                      onChange={(e) => handleUnitQuantityChange(index, e.target.value)}
                      placeholder=''
                      isDisabled={(getValues(`unit[${index}].UnitRate`)==="$0.00") ? true : false}
                    />}
                  </Td>

                  {/* baseline */}
                  <Td key={'td_units_5_'+index}>
                    {(showRateInput) ? <>
                    <TextInput
                      key={'unitrate_s_'+index}
                      register={register}
                      errors={errors}
                      width='150px'
                      fieldname={`unit[${index}].UnitRate`}
                      isReadOnly={true}
                      fieldtype={'hidden'}
                      defaultValue='1'/>
                    <Text as="span" textStyle={'text-2'}>&nbsp;&nbsp;{(isCustomAuxillary) ? getValues(`unit[${index}].UnitRate`) : unitRate}</Text></>
                    : <><TextInput
                      key={'unitrate_i_'+index}
                      register={register}
                      errors={errors}
                      width='150px'
                      fieldname={`unit[${index}].UnitRate`}
                      isReadOnly={true}
                      isDisabled={true}
                      fieldvalue={data?.UnitRate || ''}
                      fieldtype={'hidden'}
                    /></>
                    }
                  </Td>

                  {/* total */}
                  <Td key={'td_units_6_'+index} textAlign={'right'}>
                    <TextInput
                      key={'rowtotal_'+index}
                      width='115px'
                      register={register}
                      textAlign='right'
                      errors={errors}
                      fieldname={`unit[${index}].RowTotal`}
                      fieldvalue={costItem[index] || ''}
                      placeholder=""
                      isRequired={false} 
                      isReadOnly={false}
                      onBlur={(e) => handleUnitRowTotalChange(index, e.target.value)}
                    />
                    <TextInput
                      key={'unitid_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].UnitId`}
                      fieldvalue={data?.TeamMemberId || ''}
                      fieldtype='hidden'
                    />
                    <TextInput
                      key={'unitrate_h_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].UnitRate`}
                      fieldtype='hidden'
                    />
                    <TextInput
                      key={'unitrequiresd_h_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].requiresDriver`}
                      fieldtype='hidden'
                      fieldvalue={showDriverRow ? 'true' : 'false'}
                    />
                    <TextInput
                      key={'unitrequireso_h_'+index}
                      register={register}
                      errors={errors}
                      fieldname={`unit[${index}].requiresOperator`}
                      fieldtype='hidden'
                      fieldvalue={showOperatorRow ? 'true' : 'false'}
                    />
                  </Td>

                </Tr>
                
                {(showDriverRow) && 
                <Tr key={'tr_units_d_'+index}>
                  <Td key={'tr_units_d_0_'+index} colSpan='2'></Td>
                  <Td key={'tr_units_d_1_'+index} colSpan='4'>
                  <Box pl='25px'><Text textStyle='text-9'>Drivers</Text></Box>
                    <HStack key={'tr_units_d_2_'+index} pb='10px'>
                      <Box key={'tr_units_d_3_'+index} 
                        ml={'7px'}
                        width='450px'>
                        <FormSelectMulti
                          key={'tr_units_selected_drivers_4_'+index} 
                          register={register}
                          control={control}
                          errors={errors}
                          onChange={(event, action) => handleDriverChange(event, action, index)}
                          selectedoption={selectedDrivers[index]}
                          defaultValue={driversArr||null}
                          optionsArray={operatorsDriversSelectOptions}
                          fieldname={`unit[${index}].Driver`}
                          placeholder={'Select drivers'}
                          isMulti
                        />
                      </Box>
                    </HStack>
                  </Td>
                </Tr>
                }
                {(showOperatorRow) && 
                <Tr key={'tr_units_o_'+index}>
                  <Td key={'tr_units_o_0_'+index} colSpan='2'></Td>
                  <Td key={'tr_units_o_1_'+index} colSpan='4'>
                    <Box pl='25px'><Text textStyle='text-9'>Operators</Text></Box>
                    <HStack key={'tr_units_o_2_'+index} pb='10px'>
                      <Box 
                        key={'tr_units_o_3_'+index} 
                        ml={'7px'}
                        width='450px'>
                        <FormSelectMulti
                          key={'tr_units_selected_operators_4_'+index} 
                          register={register}
                          control={control}
                          errors={errors}
                          onChange={(event, action) => handleOperatorChange(event, action, index)}
                          selectedoption={selectedOperators[index]}
                          defaultValue={operatorsArr||null}
                          optionsArray={operatorsDriversSelectOptions}
                          fieldname={`unit[${index}].Operator`}
                          placeholder={'Select operators'}
                          isMulti
                        />
                      </Box>
                    </HStack>
                  </Td>
                </Tr>
                }
              </React.Fragment>
            )
          })}
          
          {(tableData?.length>0) && <Tr key={'tr_units_divider_end'}><Td colSpan='7' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
          <Tr><Td colSpan='7' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
        <Tr>
            <Th colSpan='7' textAlign={'right'}>
              <Box><Text as="span" textStyle='text-7' pr='10px'>Equipment Total:&nbsp;&nbsp;{USDollar.format(unitTotalAmount)}</Text></Box>
              <Box mt='5px'><Text as="span" textStyle='text-8' pr='10px'>DJT Total:&nbsp;&nbsp;{USDollar.format(djtTotalAmount)}</Text></Box>
            </Th>
          </Tr>

        </Tfoot>
      </Table>
    </TableContainer></>
  )
};

// PropTypes validation
EquipmentSummaryTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  operatorsDriversSelectOptions: PropTypes.arrayOf(PropTypes.object),
  selectedDrivers: PropTypes.array,
  handleDriverChange: PropTypes.func.isRequired,
  selectedOperators: PropTypes.array,
  handleOperatorChange: PropTypes.func.isRequired,
  updateUnitSummary: PropTypes.func.isRequired,
  toggleUnitsButtonState: PropTypes.func.isRequired,
  toggleAllUnitsButtonState: PropTypes.func.isRequired,
  handleAuxillaryUnitChange: PropTypes.func.isRequired,
  handleCustomAuxillaryUnitChange: PropTypes.func.isRequired,
  handleCustomUnitChange: PropTypes.func.isRequired,
  auxillaryUnitOptions: PropTypes.arrayOf(PropTypes.array).isRequired,
  allAuxillaryUnitOptions: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  handleUnitRowTotalChange: PropTypes.func.isRequired,
  unitTotalAmount: PropTypes.number.isRequired,
  djtTotalAmount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  costItem: PropTypes.array,
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  handleUnitQuantityChange: PropTypes.func.isRequired,
  isEstimate: PropTypes.bool,
};

export default EquipmentSummaryTable;