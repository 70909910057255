import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Stack,
  VStack,
  useDisclosure,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
} from "@chakra-ui/react";

// globals
import { AppContext } from '../AppContext.jsx';

// graphql components
import { listAllTeamMembers } from '../graphqlCompnents/TeamMember/listAllTeamMembers.jsx';
import { listAllTeamMembersByDivisionId } from '../graphqlCompnents/TeamMember/listAllTeamMembersByDivisionId.jsx';
import { fetchLatestSyncStatus } from '../graphqlCompnents/TeamMember/fetchLatestSyncStatus.jsx';
import { StatusSubscription } from '../graphqlCompnents/TeamMember/StatusSubscription.jsx';

// form components
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';
import { TextInputGroup } from '../Form/Input/TextInputGroup.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// decorative components
import { Divider } from '../Divider';

// Buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// spike forms
// import { CreateTeamMember } from './CreateTeamMember.jsx';
import { ViewTeamMember } from './ViewTeamMember.jsx';
import { EditTeamMember } from './EditTeamMember.jsx';

// API gateway calls
import { getISolvedEmployees } from '../APIGateway/getISolvedEmployees.jsx';

export const TeamMembers = () => {

  const [ selectedTeamMemberId, setSelectedTeamMemberId ] = useState('')
  // const [ selectedTeamMemberType, setSelectedTeamMemberType ] = useState(null)
  // const { isOpen: isCreateTeamMemberOpen , onOpen: onCreateTeamMemberOpen, onClose: onCreateTeamMemberClose } = useDisclosure()
  // const { isOpen: isCreateTeamMemberOpen , onOpen: onCreateTeamMemberOpen, onClose: onCreateClose } = useDisclosure()
  // const { isOpen: isEditTeamMemberOpen , onOpen: onEditTeamMemberOpen, onClose: onEditTeamMemberClose } = useDisclosure()
  const { isOpen: isEditTeamMemberOpen , onOpen: onEditTeamMemberOpen, onClose: onEditClose } = useDisclosure()
  const { isOpen: isViewTeamMemberOpen , onOpen: onViewTeamMemberOpen, onClose: onViewTeamMemberClose } = useDisclosure()

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };

  // load up all team members with id, name, division, jobclass, ???
  const [allGroupedMembers, setAllGroupedMembers] = useState({}); // Full grouped data
  const [groupedMembers, setGroupedMembers] = useState({}); // Active or filtered data
  // eslint-disable-next-line no-unused-vars
  const [allTeamMembers, setAllTeamMembers] = useState([]); // Flat list of all employees
  const [latestStatus, setLatestStatus] = useState(null);

  const [ showInactive, setShowInactive ] = useState(false)
  useEffect(() => {
    if (showInactive) {
      setGroupedMembers(allGroupedMembers);
    } else {
      const activeOnly = Object.entries(allGroupedMembers).reduce((acc, [division, members]) => {
        acc[division] = members.filter(member => member.isActive);
        return acc;
      }, {});
      console.log('activeOnly: ', activeOnly)
      setGroupedMembers(activeOnly);
    }
  }, [showInactive, allGroupedMembers]);
  
  const CustomCloseDrawerButton = (type) => {
    let closeDrawer = null
    // if (type==='new') {
    //   closeDrawer = onCreateTeamMemberClose
    // }
    if (type==='view') {
      closeDrawer = onViewTeamMemberClose
    }
    if (type==='edit') {
      closeDrawer = onEditTeamMemberClose
    }

    return(
      <IconButton 
        variant={'icononlybutton'}
        icon={<AvailableIcons boxSize={'22px'} iconRef={'close'} />} 
        onClick={closeDrawer}
      />
    )
  }

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onEditTeamMemberClose = () => {
    handleRefresh()
    setSelectedTeamMemberId('')
    onEditClose()
  }

  // const onCreateTeamMemberClose = () => {
  //   handleRefresh()
  //   setSelectedTeamMemberId('')
  //   onCreateClose()
  // }
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);
  // console.log(' ----- store: ', store)
  const groups = store?.userData?.groups
  const isGlobalAdmin = groups?.includes('exec_admin')
  const isHumanResources =  groups?.includes('human_resources')
  const canMutate = (isGlobalAdmin || isHumanResources) ? true : false
  const supervisorDivisionId = store?.userData?.divisionId
  const currentTeamMemberId = store?.userData?.id
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);

  const formSubmit = async (values, event) => {
    event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)
  }

  const handleOpenDrawer = (type, id) => {

    // if (type==='new') {
    //   onCreateTeamMemberOpen()
    // }

    if (type==='view') {
      setSelectedTeamMemberId(id, onViewTeamMemberOpen())
    }

    if (type==='edit') {
      // setDjtIsParent(isParent)
      setSelectedTeamMemberId(id, onEditTeamMemberOpen())
    }
    
  }

  const syncEmployees = async () => {
    setLatestStatus(null); // Clear the status
    console.log('----- About to sync employees:', currentTeamMemberId);
    setIsSyncDisabled(true); // Disable the button upon clicking
    await getISolvedEmployees({ createdById: currentTeamMemberId });
    console.warn('----- Sync initiated. Subscription should pick up changes.');
  };

  // useEffect(() => {
  //   const fetchAllTeamMembers = async () => {
  //     try {
  //       let response = null
  //       if (canMutate) {
  //         response = await listAllTeamMembers('', 500);
  //       } else  {
  //         response = await listAllTeamMembersByDivisionId(supervisorDivisionId ,'', 500);
  //       }
  //       const employees = response.items;
  //       console.info('employees: ', employees);
  
  //       const allTeamMembers = employees.map((employee) => ({
  //         ...employee,
  //         teamMemberType: 'employee',
  //       }));
  //       console.info('allTeamMembers: ', allTeamMembers);
  
  //       setAllTeamMembers(allTeamMembers);
  
  //       const membersByDivision = allTeamMembers.reduce((acc, member) => {
  //         const divisionName = member.division?.prettyname || 'Unknown Division';
  //         if (!acc[divisionName]) {
  //           acc[divisionName] = [];
  //         }
  //         acc[divisionName].push(member);
  //         return acc;
  //       }, {});
  //       console.info('membersByDivision: ', membersByDivision);
  
  //       for (const division in membersByDivision) {
  //         membersByDivision[division].sort((a, b) => {
  //           if (a.isActive !== b.isActive) return a.isActive ? -1 : 1;
  //           if (a.lastName !== b.lastName) return a.lastName.localeCompare(b.lastName);
  //           return a.firstName.localeCompare(b.firstName);
  //         });
  //       }
  //       console.info('membersByDivision: ', membersByDivision);
  
  //       console.log('groupedMembers: ', groupedMembers)
  //       const sortedGroupedMembers = Object.keys(groupedMembers)
  //         .sort((a, b) => a.localeCompare(b)) // Alphabetical sort
  //         .reduce((acc, key) => {
  //           acc[key] = groupedMembers[key];
  //           return acc;
  //         }, {});

  //       console.log('sortedGroupedMembers: ', sortedGroupedMembers)
  //       setAllGroupedMembers(sortedGroupedMembers); // Store full data

  //       setGroupedMembers(
  //         Object.entries(membersByDivision).reduce((acc, [division, members]) => {
  //           acc[division] = members.filter(member => member.isActive);
  //           return acc;
  //         }, {})
  //       ); // Default to active members
  //     } catch (error) {
  //       console.error('Failed to fetch team members:', error);
  //     }
  //   };
  
  //   fetchAllTeamMembers();
  // }, [canMutate, groupedMembers, refreshIndex, supervisorDivisionId]);

  useEffect(() => {
    const fetchAllTeamMembers = async () => {
      try {
        let response = canMutate
          ? await listAllTeamMembers('', 500)
          : await listAllTeamMembersByDivisionId(supervisorDivisionId, '', 500);
        
        const employees = response.items.map((employee) => ({
          ...employee,
          teamMemberType: 'employee',
        }));
  
        const membersByDivision = employees.reduce((acc, member) => {
          const divisionName = member.division?.prettyname || 'Unknown Division';
          if (!acc[divisionName]) {
            acc[divisionName] = [];
          }
          acc[divisionName].push(member);
          return acc;
        }, {});
  
        Object.values(membersByDivision).forEach((members) =>
          members.sort((a, b) => {
            if (a.isActive !== b.isActive) return a.isActive ? -1 : 1;
            if (a.lastName !== b.lastName) return a.lastName.localeCompare(b.lastName);
            return a.firstName.localeCompare(b.firstName);
          })
        );
  
        setAllGroupedMembers(membersByDivision);
  
        setGroupedMembers(
          Object.entries(membersByDivision).reduce((acc, [division, members]) => {
            acc[division] = members.filter((member) => member.isActive);
            return acc;
          }, {})
        );
      } catch (error) {
        console.error('Failed to fetch team members:', error);
      }
    };
  
    fetchAllTeamMembers();
  }, [canMutate, refreshIndex, supervisorDivisionId]);
  
  const [searchInputText, setSearchInputText] = useState('');
  const searchTeamMembers = (searchString) => {
    if (!searchString) {
      // Reset based on toggle
      if (showInactive) {
        setGroupedMembers(allGroupedMembers);
      } else {
        const activeOnly = Object.entries(allGroupedMembers).reduce((acc, [division, members]) => {
          acc[division] = members.filter(member => member.isActive);
          return acc;
        }, {});
        setGroupedMembers(activeOnly);
      }
      return;
    }
  
    const lowercasedSearch = searchString.toLowerCase();
  
    const sourceData = showInactive ? allGroupedMembers : groupedMembers;
  
    const filteredResults = Object.entries(sourceData).reduce((acc, [division, members]) => {
      const matchingMembers = members.filter((member) => {
        const firstNameMatch = member.firstName?.toLowerCase().includes(lowercasedSearch);
        const lastNameMatch = member.lastName?.toLowerCase().includes(lowercasedSearch);
        return firstNameMatch || lastNameMatch;
      });
      if (matchingMembers.length > 0) {
        acc[division] = matchingMembers;
      }
      return acc;
    }, {});
  
    setGroupedMembers(filteredResults);
  };

  const resetSearch = () => {
    setValue('searchInput', '');
    if (showInactive) {
      setGroupedMembers(allGroupedMembers);
    } else {
      const activeOnly = Object.entries(allGroupedMembers).reduce((acc, [division, members]) => {
        acc[division] = members.filter(member => member.isActive);
        return acc;
      }, {});
      setGroupedMembers(activeOnly);
    }
  };

  const DivisionMemberCard = ({ 
    member
    // divisionName 
  }) => {
    return (
      <Box 
        // borderWidth="1px" 
        borderRadius="6px" 
        overflow="hidden" 
        p={5} 
        m={2} 
        backgroundColor={'var(--dark-menu-background)'}
        // w={'350px'}
      >
        <Box 
          display="flex" 
          alignItems="flex-start" // Align items to the top of the container
        >
          <VStack 
            align="flex-start" // Align items at the top within VStack
            spacing={2} // Optional: Adjust spacing between VStack children
          >
            <Box>
              <Avatar 
                mx='5px' 
                bg='black' 
                color='var(--dark-gold-primary)' 
                size='md' 
                name={`${member.firstName} ${member.lastName}`} src={member?.imgUrl} 
              />
            </Box>
            <Spacer/>
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
                  variant='teamMemberEllipses' 
                  color={'var(--dark-gold-primary)'}
                  backgroundColor={'var(--dark-menu-background)'}
                  w={'60px'}
                  iconsize='18px'
                  height='28px'
                  mb='3px'
                  // isDisabled={(divisionName==='ExxonMobil' || divisionName==='Citgo') ? true : false}
                  // isDisabled={!tokenObj || !isFormLoaded || isMenuDisabled || disabledToolbars[djt?.id] || !isLoggedInQB}
                />
                <MenuList>
                  {!canMutate && <MenuItem 
                    onClick={() => handleOpenDrawer('view', `${member.teamMemberType}#${member.id}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
                    View Team Member
                  </MenuItem>}
                  {canMutate && <MenuItem 
                    onClick={() => handleOpenDrawer('edit', `${member.teamMemberType}#${member.id}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    View/Edit Team Member
                  </MenuItem>}
                  <MenuItem 
                    onClick={() => console.log('REPORT INCIDENT')}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    Report Incident
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </VStack>
          <Stack direction={['row', 'column']} spacing='5px' ml="3">
            <Box><Text as="span" textStyle='heading-3'>
              {member?.firstName} {(member.goesBy) && `"${member.goesBy}"`} {(member?.middleName) && member?.middleName} {member?.lastName}
            </Text></Box>
            <Box paddingBottom={'5px'}><Text as="span" textStyle='text-2'>{member?.billableDivisionJobClass?.appDisplayName}</Text></Box>
            <Box><Text as="span" textStyle='text-2'>Employment: {(member?.employmentCategoryCode==='FT') ? 'Full-time' : 'Temp-to-Hire'}</Text></Box>
            
            <Box>
              {/* <Badge colorScheme={member.isActive ? "green" : "red"}>{member.isActive ? 'Active User' : 'Inactive User'}</Badge> */}
              <Text as="span" textStyle='text-2'>Status: {member.isActive ? "Active" : "Inactive"}</Text>
            </Box>
            <Box>
              {/* <Badge colorScheme={member.isERPUser ? "green" : "red"}>{member.isERPUser ? 'ERP User' : 'Non ERP User'}</Badge> */}
              <Text as="span" textStyle='text-2'>ERP User: {member.isERPUser ? "Yes" : "No"}</Text>
            </Box>
            {!member.isOnboarded && <Box>
              <Badge colorScheme={member.isOnboarded ? "green" : "red"}>{!member.isOnboarded && 'Incomplete Onboarding'}</Badge>
            </Box>}
          </Stack>
        </Box>
      </Box>
    );
  };

  DivisionMemberCard.propTypes = {
    member: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      goesBy: PropTypes.string,
      middleName: PropTypes.string,
      imgUrl: PropTypes.string,
      employmentCategoryCode: PropTypes.string,
      teamMemberType: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      isERPUser: PropTypes.bool,
      isOnboarded: PropTypes.bool,
      billableDivisionJobClass: PropTypes.shape({
        appDisplayName: PropTypes.string.isRequired
      })
    }).isRequired,
    onCardClick: PropTypes.func,
    divisionName: PropTypes.string.isRequired,
  };

  const DivisionLayout = ({ divisionData }) => {
    return (
      <Box>
        {Object.entries(divisionData).map(([divisionName, members]) => (
          // (divisionName!=='ExxonMobil' && divisionName!=='Citgo') && 
          <Box key={divisionName}>
            <Box key={divisionName} mb={8}>
              <Box display="flex" alignItems="center" mb={4}>
                <Text as="span" textStyle='heading-1' pr={'15px'}>{divisionName}</Text>
                <AvailableIcons boxSize={'22px'} iconRef={'iconTeamMembers'} color={'var(--dark-gold-primary)'}/>
                <Text as="span" textStyle='heading-2' pl={'5px'}>{members.length} Members</Text>
              </Box>
              <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
              {/* <Grid templateColumns="repeat(auto-fill, minmax(270px, 1fr))" gap={6}> */}
                {members.map(member => (
                  <DivisionMemberCard key={member.id} member={member} divisionName={divisionName} />
                ))}
              </Grid>
            </Box>
            <Divider mt='25px' mb='25px'/>
          </Box>
        ))}
      </Box>
    );
  };

  DivisionLayout.propTypes = {
    divisionData: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          goesBy: PropTypes.string,
          middleName: PropTypes.string,
          imgUrl: PropTypes.string,
          // teamMemberType: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
          billableDivisionJobClass: PropTypes.shape({
            appDisplayName: PropTypes.string.isRequired
          })
        })
      )
    ).isRequired,
  };

  const searchIconCancel = <IconButton
    isDisabled={searchInputText?.length===0}
    variant='withIconQuaternary' 
    bg='var(--dark-module-bg)'
    h={'24px'}
    icon={<AvailableIcons boxSize={'24px'} iconRef={'searchReset'} color={'var(--dark-text-grey-1)'} />}
    onClick={() => { 
      resetSearch();
      setSearchInputText('');
     }}
  />

  const searchIcon = <AvailableIcons boxSize={'24px'} iconRef={'searchMagnify'} color={'var(--dark-text-grey-1)'} />

  // Fetch the latest sync status
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const results = await fetchLatestSyncStatus();
        if (results && results.length > 0) {
          setLatestStatus(results[0]);
        }
      } catch (err) {
        console.error('Error fetching latest status:', err);
      }
    };
    fetchStatus();
  }, []);

  // Callback to handle subscription updates
  const handleStatusUpdate = (newStatus) => {
    console.log('Updating status with:', newStatus);
    setLatestStatus(newStatus);
  };

  useEffect(() => {
    // Re-enable the button if status is COMPLETE or 5 minutes have passed
    if (latestStatus) {
      if (latestStatus.lambdaStatus === 'COMPLETE') {
        setIsSyncDisabled(false);
      } else if (latestStatus.startDateTime) {
        const fiveMinutesLater = new Date(latestStatus.startDateTime).getTime() + 5 * 60 * 1000;
        const now = new Date().getTime();

        if (now >= fiveMinutesLater) {
          setIsSyncDisabled(false);
        } else {
          const timeout = setTimeout(() => {
            setIsSyncDisabled(false);
          }, fiveMinutesLater - now);

          return () => clearTimeout(timeout); // Cleanup timeout
        }
      }
    }
  }, [latestStatus]); // Trigger whenever latestStatus changes
  
  return (
    <>
    <Container as="form" maxW="1600" mb={12} onSubmit={handleSubmit(formSubmit, onError)}>
      <ModuleBox>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>View Team Members</Text>
          </Box>
          {/* <Spacer /> */}
        
        </Flex>
        <Box w={'300px'} >
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Show Inactive Team Members</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="showInactive"
                isChecked={showInactive}
                onChange={() => {
                  setValue('showInactive', !showInactive)
                  setShowInactive(!showInactive)
                  // setSelectedTCUser('')
                }}
              />
            </Box>
          </Flex>
        </Box>
      </ModuleBox>

      <ModuleBox>
        <Grid
          w="100%"
          // maxW="400px"
          templateColumns="1fr auto"
          alignItems="center"
          gap={4}
        >
          <Box paddingRight={'15px'}>
            <ButtonPrimaryPlain
              type="button"
              onClick={syncEmployees}
              // width={'100%'}
              name={'sync-employees'}
              value={'Sync iSolved Employees'}
              isDisabled={isSyncDisabled}
            />
          </Box>

          <StatusSubscription onStatusUpdate={handleStatusUpdate} />
          <Box w={'600px'} maxW={'600px'} >
            {/* {console.log('Rendering with latestStatus:', latestStatus)} */}
            {latestStatus ? (
              <Text>
                Status: {latestStatus.lambdaStatus} <br />
                Start: {new Date(latestStatus.startDateTime).toLocaleString()} <br />
                End: {latestStatus.endDateTime ? new Date(latestStatus.endDateTime).toLocaleString() : 'In Progress'} <br />
                {latestStatus.statusResults ? (
                  <>
                    Team Members Added: {JSON.parse(latestStatus.statusResults).addedRecords} <br />
                    Team Members Updated: {JSON.parse(latestStatus.statusResults).updatedRecords}
                  </>
                ) : (
                  'Results: Not available'
                )}
              </Text>
            ) : (
              <Text>No status available. Waiting for status schange.</Text>
            )}
          </Box>
          
        </Grid>
      </ModuleBox>


      <ModuleBox>
        <Box w={'400px'}>
          <TextInputGroup
            register={register}
            errors={errors}
            fieldname="searchInput"
            fieldlabel="Search Team Members by Name"
            fieldvalue={searchInputText} // This dynamically controls the input's value
            prettyname="Search"
            placeholder="Search by team member name"
            onChange={(e) => {
              const value = e.target.value;
              setSearchInputText(value); // Update the state directly
              searchTeamMembers(value); // Trigger the search logic
            }}
            leftButtonElement={searchIcon}
            rightButtonElement={searchIconCancel}
            buttonDisabled={!searchInputText || searchInputText.trim().length === 0} // Disable button only when input is empty
            buttonElementOnClick={() => {
              resetSearch(); // Reset the search results
              setSearchInputText(''); // Clear the input field
            }}
          />

        </Box> 
      </ModuleBox>
      
      <ModuleBox mt='25px'>
        <Box>
          <DivisionLayout divisionData={groupedMembers} />
        </Box>
      </ModuleBox>

    </Container>

    {/* <ModuleDrawer
      onClose={onCreateTeamMemberClose}
      isOpen={isCreateTeamMemberOpen}
      bodyContent={<CreateTeamMember srcAction={'new'} adminId={currentTeamMemberId} onClose={onCreateTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('new')}/>}
      size={'full'}
    /> */}
    <ModuleDrawer
      onClose={onViewTeamMemberClose}
      isOpen={isViewTeamMemberOpen}
      bodyContent={<ViewTeamMember srcId={selectedTeamMemberId} onClose={onViewTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('view')}/>}
      size={'full'}
    />
    <ModuleDrawer
      onClose={onEditTeamMemberClose}
      isOpen={isEditTeamMemberOpen}
      bodyContent={<EditTeamMember srcId={selectedTeamMemberId} onClose={onEditTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('edit')}/>}
      size={'full'}
    />
    </>

  );
}

TeamMembers.propTypes = {
  selectedTeamMemberId: PropTypes.string,
  selectedTeamMemberType: PropTypes.string,
  // isCreateTeamMemberOpen: PropTypes.bool,
  isEditTeamMemberOpen: PropTypes.bool,
  isViewTeamMemberOpen: PropTypes.bool,
  showInactive: PropTypes.bool,
  allGroupedMembers: PropTypes.object,
  groupedMembers: PropTypes.object,
  currentTeamMemberId: PropTypes.string,
  divisions: PropTypes.array,
  refreshIndex: PropTypes.number,
};
