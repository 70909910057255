export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    createSpkTeamMemberTbl(input: $input, condition: $condition) {
      id
      allSort
      loginEmail
      firstName
      middleName
      lastName
      goesBy
      dob
      hireDate
      billableDivisionJobClassId
      divisionId
      doTrackPunches
      djtHideByDefault
      imgUrl
      badgeNumber
      employeeNumber
      memberOf
      addStreet
      addCity
      addState
      addZipcode
      phoneNum
      isActive
      isGlobalAdmin
      emergcyContName
      emergcyContPhone
      canTransfer
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamMemberAvatar = /* GraphQL */ `
  mutation updateTeamMemberAvatar(
    $input: UpdateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    updateSpkTeamMemberTbl(input: $input, condition: $condition) {
      id
      imgUrl
    }
  }
`;
export const updateTeamMemberAdmin = /* GraphQL */ `
  mutation updateTeamMemberAdmin(
    $input: UpdateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    updateSpkTeamMemberTbl(input: $input, condition: $condition) {
      loginEmail
      isERPUser
      isOnboarded
      billableDivisionJobClassId
      memberOf
      isGlobalAdmin
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation updateTeamMember(
    $input: UpdateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    updateSpkTeamMemberTbl(input: $input, condition: $condition) {
      firstName
      middleName
      lastName
      dob
      hireDate
      divisionId
      termDate
      addStreet
      addCity
      addState
      addZipcode
      phoneNum
      isActive
      emergcyContName
      emergcyContPhone
      billableDivisionJobClassId
      avatarId
      badgeNumber
      employeeNumber
      isGlobalAdmin
    }
  }
`;
export const updateTeamMemberPersonal = /* GraphQL */ `
  mutation updateTeamMemberPersonal(
    $input: UpdateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    updateSpkTeamMemberTbl(input: $input, condition: $condition) {
      firstName
      middleName
      lastName
      dob
      hireDate
      divisionId
      termDate
      addStreet
      addCity
      addState
      addZipcode
      phoneNum
      isActive
      emergcyContName
      emergcyContPhone
      billableDivisionJobClassId
      avatarId
      badgeNumber
      employeeNumber
      isGlobalAdmin
    }
  }
`;
export const createUploadedDocument = /* GraphQL */ `
  mutation createUploadedDocument(
    $input: CreateSpkUploadedDocumentTblInput!
    $condition: ModelSpkUploadedDocumentTblConditionInput
  ) {
    createSpkUploadedDocumentTbl(input: $input, condition: $condition) {
      id
      bucket
      key
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const createPubSubMessage = /* GraphQL */ `
  mutation createPubSubMessage(
    $input: CreateSpkPubSubMessageTblInput!
    $condition: ModelSpkPubSubMessageTblConditionInput
  ) {
    createSpkPubSubMessageTbl(input: $input, condition: $condition) {
      id
      subject
      message
      messageJSON
      fromId
      sentAt
      topicId
      publisherId
      publisherType
      statusId
      createdAt
      updatedAt
    }
  }
`;
// export const createPubSubMessage = /* GraphQL */ `
//   mutation createPubSubMessage(
//     $input: CreateSpkPubSubMessageTblInput!
//     $condition: ModelSpkPubSubMessageTblConditionInput
//   ) {
//     createSpkPubSubMessageTbl(input: $input, condition: $condition) {
//       id
//       subject
//       message
//       topicId
//       publisherId
//       publisherType
//       statusId
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const createPubSubTopic = /* GraphQL */ `
  mutation createPubSubTopic(
    $input: CreateSpkPubSubTopicTblInput!
    $condition: ModelSpkPubSubTopicTblConditionInput
  ) {
    createSpkPubSubTopicTbl(input: $input, condition: $condition) {
      id
      name
      nameCode
      categoryId
      subscribeGroups
      publishGroups
      createdById
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation createContact(
    $input: CreateSpkContactTblInput!
    $condition: ModelSpkContactTblConditionInput
  ) {
    createSpkContactTbl(input: $input, condition: $condition) {
      id
      isMachineGenerated
      contactTypeId
      firstName
      middleName
      lastName
      companyName
      phone
      address
      url
      notes
      isActive
      isDisplayed
      searchTags
      createdAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation updateContact(
    $input: UpdateSpkContactTblInput!
    $condition: ModelSpkContactTblConditionInput
  ) {
    updateSpkContactTbl(input: $input, condition: $condition) {
      id
      isMachineGenerated
      contactTypeId
      firstName
      middleName
      lastName
      companyName
      phone
      address
      url
      notes
      isActive
      isDisplayed
      searchTags
      updatedAt
    }
  }
`;
export const createPrimaryUnit = /* GraphQL */ `
  mutation createPrimaryUnit(
    $input: CreateSpkPrimaryUnitTblInput!
    $condition: ModelSpkPrimaryUnitTblConditionInput
  ) {
    createSpkPrimaryUnitTbl(input: $input, condition: $condition) {
      id
      allSort
      code
      codeSortable
      isInspectedDaily
      name
      year
      make
      model
      color
      tag
      vin
      serial
      irpiftaRegistered
      teamMemberId
      isMarried
      marriageId
      purchDate
      purchAmt
      purchNotes
      soldDate
      soldAmt
      soldNotes
      isActive
      billRateDay
      billRateHour
      typeId
      subTypeId
      seatAxle
      cgvw
      unladWt
      isDOTReg
      reqCDLDriver
      odometer
      createdAt
      updatedAt
    }
  }
`;
export const createInitialPrimaryUnit = /* GraphQL */ `
  mutation createInitialPrimaryUnit(
    $input: CreateSpkPrimaryUnitTblInput!
    $condition: ModelSpkPrimaryUnitTblConditionInput
  ) {
    createSpkPrimaryUnitTbl(input: $input, condition: $condition) {
      id
      allSort
      code
      codeSortable
      name
      year
      make
      model
      color
      tag
      vin
      serial
      irpiftaRegistered
      teamMemberId
      isMarried
      marriageId
      purchDate
      purchAmt
      purchNotes
      soldDate
      soldAmt
      soldNotes
      isActive
      billRateDay
      billRateHour
      typeId
      subTypeId
      seatAxle
      cgvw
      unladWt
      isDOTReg
      reqCDLDriver
      currentLocation
      isInOperation
      canTransfer
      isInspectedDaily
      requiresOperator
      requiresDriver
      qbExpenseCategoryId
      qbExpenseCategoryNumber
      qbExpenseCategory
      iconId
      odometer
      createdAt
      updatedAt
    }
  }
`;
// export const updateUnit = /* GraphQL */ `
//   mutation updateUnit(
//     $input: UpdateSpkUnitTblInput!
//     $condition: ModelSpkUnitTblConditionInput
//   ) {
//     updateSpkUnitTbl(input: $input, condition: $condition) {
//       id
//       code
//       name
//       year
//       make
//       model
//       color
//       tag
//       vin
//       serial
//       teamMemberId
//       category
//       subCategory
//       isMarried
//       marriageId
//       purchDate
//       purchAmt
//       purchNotes
//       soldDate
//       soldAmt
//       soldNotes
//       isActive
//       seatAxle
//       cgvw
//       unladWt
//       isDOTReg
//       reqCDLDriver
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const updatePrimaryUnit = /* GraphQL */ `
  mutation updatePrimaryUnit(
    $input: UpdateSpkPrimaryUnitTblInput!
    $condition: ModelSpkPrimaryUnitTblConditionInput
  ) {
    updateSpkPrimaryUnitTbl(input: $input, condition: $condition) {
      id
      code
      name
      year
      make
      model
      color
      tag
      vin
      serial
      irpiftaRegistered
      teamMemberId
      isMarried
      marriageId
      purchDate
      purchAmt
      purchNotes
      soldDate
      soldAmt
      soldNotes
      isActive
      typeId
      subTypeId
      seatAxle
      cgvw
      unladWt
      isDOTReg
      reqCDLDriver
      unitSmallImageId
      unitMediumImageId
      requiresOperator
      requiresDriver
      isInspectedDaily
      odometer
      updatedAt
    }
  }
`;

export const createPrimaryUnitAuxillaryUnits = /* GraphQL */ `
  mutation CreatePrimaryUnitAuxillaryUnits(
    $input: CreatePrimaryUnitAuxillaryUnitsInput!
    $condition: ModelPrimaryUnitAuxillaryUnitsConditionInput
  ) {
    createPrimaryUnitAuxillaryUnits(input: $input, condition: $condition) {
      id
      spkAuxillaryUnitTblId
      spkPrimaryUnitTblId
      createdAt
      updatedAt
    }
  }
`;
export const updatePrimaryUnitAuxiliaryUnits = /* GraphQL */ `
  mutation updatePrimaryUnitAuxiliaryUnits(
    $input: UpdatePrimaryUnitAuxillaryUnitsInput!
    $condition: ModelPrimaryUnitAuxillaryUnitsConditionInput
  ) {
    updatePrimaryUnitAuxillaryUnits(input: $input, condition: $condition) {
      id
      spkAuxillaryUnitTblId
      spkPrimaryUnitTblId
      updatedAt
    }
  }
`;
export const deletePrimaryUnitAuxUnits = /* GraphQL */ `
  mutation deletePrimaryUnitAuxUnits(
    $input: DeletePrimaryUnitAuxillaryUnitsInput!
    $condition: ModelPrimaryUnitAuxillaryUnitsConditionInput
  ) {
    deletePrimaryUnitAuxillaryUnits(input: $input, condition: $condition) {
      id
    }
  }
`;
export const fixCanTransferTeamMember = /* GraphQL */ `
  mutation fixCanTransferTeamMember(
    $input: UpdateSpkTeamMemberTblInput!
    $condition: ModelSpkTeamMemberTblConditionInput
  ) {
    updateSpkTeamMemberTbl(input: $input, condition: $condition) {
      canTransfer
    }
  }
`;
export const fixCanTransferPrimaryUnit = /* GraphQL */ `
  mutation fixCanTransferPrimaryUnit(
    $input: UpdateSpkPrimaryUnitTblInput!
    $condition: ModelSpkPrimaryUnitTblConditionInput
  ) {
    updateSpkPrimaryUnitTbl(input: $input, condition: $condition) {
      canTransfer
    }
  }
`;
export const createDivisionClientContact = /* GraphQL */ `
  mutation createDivisionClientContact(
    $input: CreateSpkDJTClientTblInput!
    $condition: ModelSpkDJTClientTblConditionInput
  ) {
    createSpkDJTClientTbl(input: $input, condition: $condition) {
      id
      divisionId
      contactCompany
      contactName
      contactEmail
      contactPhone
      isActive
      createdAt
      updatedAt
    }
  }
`;
// export const createPrimaryUnitInspection = /* GraphQL */ `
//   mutation createPrimaryUnitInspection(
//     $input: CreateSpkPrimaryUnitInspectionTblInput!
//     $condition: ModelSpkPrimaryUnitInspectionTblConditionInput
//   ) {
//     createSpkPrimaryUnitInspectionTbl(input: $input, condition: $condition) {
//       divisionDateForFailure
//       divisionDate
//       unitId
//       inspectedOn
//       inspectedAt
//       inspectedById
//       createdById
//       divisionId
//       dateUnit
//       statusId

//       unit {
//         code
//         type {
//           name
//         }
//         subType {
//           name
//         }
//       }
//       createdBy {
//         firstName
//         lastName
//       }
      
//       inspectedBy {
//         firstName
//         lastName
//       }
      
//       status {
//         status
//         statusQuery
//       }
//       timeToInspect
//     }
//   }
// `;
// id: ID!
      // divisionDateForFailure: String! @index(name: "inspectedUnitsByDivisionByDateFailureIndex", queryField: "inspectedUnitsByDivisionByDateFailure", sortKeyFields: ["failureCount"])	#composite of division # date 87e0ca1a-42c9-4e72-ae80-c9a507bfbd50#2023-01-24
      // divisionDate: String! @index(name: "inspectedUnitsByDivisionByDateIndex", queryField: "inspectedUnitsByDivisionByDate")	#composite of division # date 87e0ca1a-42c9-4e72-ae80-c9a507bfbd50#2023-01-24
      // unitId: ID! @index(name: "unitInspectionsByUnitIdIndex", queryField: "unitInspectionsByUnitId", sortKeyFields: ["inspectedOn"])
      // inspectedOn: AWSDate!
      // inspectedAt: AWSDateTime!
      // inspectedById: ID!
      // createdById: ID!
      // divisionId: ID!
      // dateUnit: String! @index(name: "unitInspectionByDateByUnitIdIndex", queryField: "unitInspectionByDateByUnitId")	# 2023-03-10#46b2ff3e-7c9f-4229-92d2-da6292584dc3
      // statusId: ID!
// CLEAN THIS QUERY UP AFTER DEMO
export const createPrimaryUnitInspection = /* GraphQL */ `
mutation CreatePrimaryUnitInspection(
  $input: CreateSpkPrimaryUnitInspectionTblInput!
  $condition: ModelSpkPrimaryUnitInspectionTblConditionInput
) {
  createSpkPrimaryUnitInspectionTbl(input: $input, condition: $condition) {
    id
    divisionDateForFailure
    divisionDate
    unitId
    inspectedOn
    inspectedAt
    inspectedById
    createdById
    divisionId
    dateUnit
    statusId
    failureCount
    timeToInspect
    createdAt
    updatedAt
  }
}
`;
export const createPrimaryUnitCompletedInspection = /* GraphQL */ `
  mutation createPrimaryUnitCompletedInspection(
    $input: CreateSpkPrimaryUnitCompletedInspectionTblInput!
    $condition: ModelSpkPrimaryUnitCompletedInspectionTblConditionInput
  ) {
    createSpkPrimaryUnitCompletedInspectionTbl(
      input: $input
      condition: $condition
    ) {
      id
    }
  }
`;
export const createDailyJobTicket = /* GraphQL */ `
  mutation createDailyJobTicket(
    $input: CreateSpkDailyJobTicketTblInput!
    $condition: ModelSpkDailyJobTicketTblConditionInput
  ) {
    createSpkDailyJobTicketTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSpkDJTLabor = /* GraphQL */ `
  mutation createSpkDJTLabor(
    $input: CreateSpkDJTLaborTblInput!
    $condition: ModelSpkDJTLaborTblConditionInput
  ) {
    createSpkDJTLaborTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSpkDJTEquipment = /* GraphQL */ `
  mutation createSpkDJTEquipment(
    $input: CreateSpkDJTEquipmentTblInput!
    $condition: ModelSpkDJTEquipmentTblConditionInput
  ) {
    createSpkDJTEquipmentTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSpkDJTMaterial = /* GraphQL */ `
  mutation createSpkDJTMaterial(
    $input: CreateSpkDJTMaterialTblInput!
    $condition: ModelSpkDJTMaterialTblConditionInput
  ) {
    createSpkDJTMaterialTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
// export const updateTMPopularity = /* GraphQL */ `
//   mutation updateTMPopularity(
//     $input: UpdateSpkDJTEmployeePopularityTblInput!
//     $condition: ModelSpkDJTEmployeePopularityTblConditionInput
//   ) {
//     updateSpkDJTEmployeePopularityTbl(input: $input, condition: $condition) {
//       department
//       employeeNumber
//       deptEmpComposite
//       popularity
//       createdAt
//       updatedAt
//     }
//   }
// `;
// export const increaseTMPopularity = /* GraphQL */ `
//   mutation increaseTMPopularity(
//     $input: UpdateSpkDJTLaborPopularityTblInput!
//     $condition: ModelSpkDJTLaborPopularityTblConditionInput
//   ) {
//     updateSpkDJTLaborPopularityTbl(input: $input, condition: $condition) {
//       departmentEmployeeNumber
//       department
//       employeeNumber
//       popularity
//       createdAt
//       updatedAt
//     }
//   }
// `;
// export const increaseTMPopularity = /* GraphQL */ `
//   mutation increaseTMPopularity($departmentEmployeeNumber: String!) {
//     increaseDJTLaborPopularity(
//       departmentEmployeeNumber: $departmentEmployeeNumber
//     ) {
//       departmentEmployeeNumber
//       department
//       employeeNumber
//       popularity
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const increaseDJTLaborPopularity = /* GraphQL */ `
  mutation increaseDJTLaborPopularity($departmentEmployeeNumber: String!) {
    increaseDJTLaborPopularity(
      departmentEmployeeNumber: $departmentEmployeeNumber
    ) {
      departmentEmployeeNumber
      # department
      # employeeNumber
      popularity
      # createdAt
      # updatedAt
    }
  }
`;
export const increaseDJTUnitPopularity = /* GraphQL */ `
  mutation IncreaseDJTUnitPopularity(
    $divisionIdUnitId: String!
    $divisionId: ID!
    $unitId: ID!
  ) {
    increaseDJTUnitPopularity(
      divisionIdUnitId: $divisionIdUnitId
      divisionId: $divisionId
      unitId: $unitId
    ) {
      divisionIdUnitId
      divisionId
      unitId
      popularity
    }
  }
`;
export const deleteDailyJobTicket = /* GraphQL */ `
  mutation deleteDailyJobTicket(
    $input: DeleteSpkDailyJobTicketTblInput!
    $condition: ModelSpkDailyJobTicketTblConditionInput
  ) {
    deleteSpkDailyJobTicketTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDJTMaterial = /* GraphQL */ `
  mutation deleteDJTMaterial(
    $input: DeleteSpkDJTMaterialTblInput!
    $condition: ModelSpkDJTMaterialTblConditionInput
  ) {
    deleteSpkDJTMaterialTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDJTEquipment = /* GraphQL */ `
  mutation deleteDJTEquipment(
    $input: DeleteSpkDJTEquipmentTblInput!
    $condition: ModelSpkDJTEquipmentTblConditionInput
  ) {
    deleteSpkDJTEquipmentTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDJTLabor = /* GraphQL */ `
  mutation deleteDJTLabor(
    $input: DeleteSpkDJTLaborTblInput!
    $condition: ModelSpkDJTLaborTblConditionInput
  ) {
    deleteSpkDJTLaborTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDJTSignature = /* GraphQL */ `
  mutation updateDJTSignature(
    $input: UpdateSpkDailyJobTicketTblInput!
    $condition: ModelSpkDailyJobTicketTblConditionInput
  ) {
    updateSpkDailyJobTicketTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDJTStatusId = /* GraphQL */ `
  mutation updateDJTStatusId(
    $input: UpdateSpkDailyJobTicketTblInput!
    $condition: ModelSpkDailyJobTicketTblConditionInput
  ) {
    updateSpkDailyJobTicketTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDJT = /* GraphQL */ `
  mutation updateDJT(
    $input: UpdateSpkDailyJobTicketTblInput!
    $condition: ModelSpkDailyJobTicketTblConditionInput
  ) {
    updateSpkDailyJobTicketTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
// export const updateDJT = /* GraphQL */ `
//   mutation updateDJT(
//     $input: UpdateSpkDailyJobTicketTblInput!
//     $condition: ModelSpkDailyJobTicketTblConditionInput
//   ) {
//     updateSpkDailyJobTicketTbl(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;
// export const updateDJT = /* GraphQL */ `
//   mutation updateDJT(
//     $input: UpdateSpkDailyJobTicketTblInput!
//     $condition: ModelSpkDailyJobTicketTblConditionInput
//   ) {
//     updateSpkDailyJobTicketTbl(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;
export const updatePubSubMessageStatus = /* GraphQL */ `
  mutation updatePubSubMessageStatus(
    $input: UpdateSpkPubSubMessageTblInput!
    $condition: ModelSpkPubSubMessageTblConditionInput
  ) {
    updateSpkPubSubMessageTbl(input: $input, condition: $condition) {
      id
      statusId
    }
  }
`;
export const createClientContact = /* GraphQL */ `
  mutation createClientContact(
    $input: CreateSpkDJTClientContactTblInput!
    $condition: ModelSpkDJTClientContactTblConditionInput
  ) {
    createSpkDJTClientContactTbl(input: $input, condition: $condition) {
      id
      clientId
      name
      email
      emailVerified
      phone
    }
  }
`;
export const createDJTUnitDriver = /* GraphQL */ `
  mutation createDJTUnitDriver(
    $input: CreateSpkDJTDriverTblInput!
    $condition: ModelSpkDJTDriverTblConditionInput
  ) {
    createSpkDJTDriverTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDJTUnitOperator = /* GraphQL */ `
  mutation createDJTUnitOperator(
    $input: CreateSpkDJTOperatorTblInput!
    $condition: ModelSpkDJTOperatorTblConditionInput
  ) {
    createSpkDJTOperatorTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createClientSummaryEmailSent = /* GraphQL */ `
  mutation createClientSummaryEmailSent(
    $input: CreateSpkDJTClientSummaryEmailSentInput!
    $condition: ModelSpkDJTClientSummaryEmailSentConditionInput
  ) {
    createSpkDJTClientSummaryEmailSent(input: $input, condition: $condition) {
      id
      teamMemberId
      supervisorName
      supervisorEmail
      sentToEmail
      sentToName
      sentToCC
      sentToClient
      djtId
      dateSent
      djtDate
      djtDescription
      djtPO
      message
      summaryType
      laborCost
	    equipmentCost
	    materialCost
	    totalCost
      createdAt
      updatedAt
    }
  }
`;
export const createNewDJTClient = /* GraphQL */ `
  mutation createNewDJTClient(
    $input: CreateSpkDJTClientTblInput!
    $condition: ModelSpkDJTClientTblConditionInput
  ) {
    createSpkDJTClientTbl(input: $input, condition: $condition) {
      id
      divisionId
      contactCompany
      contactName
      contactEmail
      clientBillingEmail
      contactPhone
      isActive
      staleAfterDate
      isSyncedToQB
      qbId
      qbClassName
      qbClassId
      qbSandboxClassName
      qbSandboxClassId
      qbSandboxId
      qbDisplayName
      qbCustomer
      qbCompany
      qbStreetAddress
      qbCity
      qbState
      qbCountry
      qbZip
      qbPhone
      qbEmail
      qbCustomerType
      createdAt
      updatedAt
    }
  }
`;
export const createClientPO = /* GraphQL */ `
  mutation createClientPO(
    $input: CreateSpkDJTPOTblInput!
    $condition: ModelSpkDJTPOTblConditionInput
  ) {
    createSpkDJTPOTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createClientContract = /* GraphQL */ `
  mutation createClientContract(
    $input: CreateSpkClientContractTblInput!
    $condition: ModelSpkClientContractTblConditionInput
  ) {
    createSpkClientContractTbl(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const createDJTCurrentStatusJournal = /* GraphQL */ `
  mutation createDJTCurrentStatusJournal(
    $input: CreateSpkDJTCurrentStatusJournalInput!
    $condition: ModelSpkDJTCurrentStatusJournalConditionInput
  ) {
    createSpkDJTCurrentStatusJournal(input: $input, condition: $condition) {
      id
      djtId
      createdDateTime
      createdBy
      statusId
      note
      createdAt
      updatedAt
    }
  }
`;
export const UpdateDJTLaborUsed = /* GraphQL */ `
  mutation UpdateDJTLaborUsed(
    $input: UpdateSpkDJTLaborTblInput!
    $condition: ModelSpkDJTLaborTblConditionInput
  ) {
    updateSpkDJTLaborTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDJTEquipment = /* GraphQL */ `
  mutation updateDJTEquipment(
    $input: UpdateSpkDJTEquipmentTblInput!
    $condition: ModelSpkDJTEquipmentTblConditionInput
  ) {
    updateSpkDJTEquipmentTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDJTMaterial = /* GraphQL */ `
  mutation updateDJTMaterial(
    $input: UpdateSpkDJTMaterialTblInput!
    $condition: ModelSpkDJTMaterialTblConditionInput
  ) {
    updateSpkDJTMaterialTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDailyJobTicketLabor = /* GraphQL */ `
  mutation updateDailyJobTicketLabor(
    $input: UpdateSpkDJTLaborTblInput!
    $condition: ModelSpkDJTLaborTblConditionInput
  ) {
    updateSpkDJTLaborTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDailyJobTicketEquipment = /* GraphQL */ `
  mutation updateDailyJobTicketEquipment(
    $input: UpdateSpkDJTEquipmentTblInput!
    $condition: ModelSpkDJTEquipmentTblConditionInput
  ) {
    updateSpkDJTEquipmentTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDailyJobTicketMaterial = /* GraphQL */ `
  mutation updateDailyJobTicketMaterial(
    $input: UpdateSpkDJTMaterialTblInput!
    $condition: ModelSpkDJTMaterialTblConditionInput
  ) {
    updateSpkDJTMaterialTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDailyJobTicketJournal = /* GraphQL */ `
  mutation createDailyJobTicketJournal(
    $input: CreateSpkDJTCurrentStatusJournalInput!
    $condition: ModelSpkDJTCurrentStatusJournalConditionInput
  ) {
    createSpkDJTCurrentStatusJournal(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteUnitOperator = /* GraphQL */ `
  mutation deleteUnitOperator(
    $input: DeleteSpkDJTOperatorTblInput!
    $condition: ModelSpkDJTOperatorTblConditionInput
  ) {
    deleteSpkDJTOperatorTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteUnitDriver = /* GraphQL */ `
  mutation deleteUnitDriver(
    $input: DeleteSpkDJTDriverTblInput!
    $condition: ModelSpkDJTDriverTblConditionInput
  ) {
    deleteSpkDJTDriverTbl(input: $input, condition: $condition) {
      id
    }
  }
`;
// export const updateClientContactPhoneEmail = /* GraphQL */ `
//   mutation updateClientContactPhoneEmail(
//     $input: UpdateSpkDJTClientTblInput!
//     $condition: ModelSpkDJTClientTblConditionInput
//   ) {
//     updateSpkDJTClientTbl(input: $input, condition: $condition) {
//       id
//       contactEmail
//       contactPhone
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const updateClientContactPhoneEmail = /* GraphQL */ `
  mutation updateClientContactPhoneEmail(
    $input: UpdateSpkDJTClientContactTblInput!
    $condition: ModelSpkDJTClientContactTblConditionInput
  ) {
    updateSpkDJTClientContactTbl(input: $input, condition: $condition) {
      id
      name
      email
      emailVerified
      phone
      createdAt
      updatedAt
    }
  }
`;
export const createQueuedUnitInspection = /* GraphQL */ `
  mutation createQueuedUnitInspection(
    $input: CreateSpkPrimaryUnitInspectionQueueTblInput!
    $condition: ModelSpkPrimaryUnitInspectionQueueTblConditionInput
  ) {
    createSpkPrimaryUnitInspectionQueueTbl(
      input: $input
      condition: $condition
    ) {
      id
      divisionId
      unitId
      unit {
        code
        type {
          name
        }
        subType {
          name
        }
      }
      active
      code
      # statusId
      # status {
      #   id
      #   status
      # }
      createdById
      createdOn
      createdAt
      updatedAt
    }
  }
`;
// this happens along with createQueuedUnitInspection
export const updatePrimaryUnitStatus = /* GraphQL */ `
  mutation updatePrimaryUnitStatus(
    $input: UpdateSpkPrimaryUnitStatusTblInput!
    $condition: ModelSpkPrimaryUnitStatusTblConditionInput
  ) {
    updateSpkPrimaryUnitStatusTbl(input: $input, condition: $condition) {
      code
      unitId
      statusId
      status {
        status
        statusQuery
      }
      unit {
        subTypeId
        type {
          name
        }
        subType {
          name
        }
      }
      # createdOn
      # createdAt
      # updatedAt
    }
  }
`;

export const createUnitDocument = /* GraphQL */ `
  mutation createUnitDocument(
    $input: CreateSpkUnitDocumentTblInput!
    $condition: ModelSpkUnitDocumentTblConditionInput
  ) {
    createSpkUnitDocumentTbl(input: $input, condition: $condition) {
      id
      unitId
      createdById
      uploadedDateTime
      uploadedDocumentId
      unitDocumentTypeId
      unitIdUnitDocumentTypeId
      active
      isActive
      notes
      imageSetId
      createdAt
      updatedAt
    }
  }
`;
export const updatePrimaryUnitInspectionQueue = /* GraphQL */ `
  mutation updatePrimaryUnitInspectionQueue(
    $input: UpdateSpkPrimaryUnitInspectionQueueTblInput!
    $condition: ModelSpkPrimaryUnitInspectionQueueTblConditionInput
  ) {
    updateSpkPrimaryUnitInspectionQueueTbl(
      input: $input
      condition: $condition
    ) {
      id
      allSort
      divisionId
      unitId
      active
      code
      createdById
      claimedById
      createdOn
      claimedOn
      completedOn
      inspectedDateTime
      inspectedById
      inspectionId
      createdAt
      updatedAt
    }
  }
`;
export const createClientSummaryEmailCCEmail = /* GraphQL */ `
  mutation createClientSummaryEmailCCEmail(
    $input: CreateSpkDJTClientSummaryEmailCCEmailInput!
    $condition: ModelSpkDJTClientSummaryEmailCCEmailConditionInput
  ) {
    createSpkDJTClientSummaryEmailCCEmail(
      input: $input
      condition: $condition
    ) {
      id
      # email
      # clientId
      # addedById
      # isVerified
      # isActive
      # createdAt
      # updatedAt
    }
  }
`;
export const updateSpikeClient = /* GraphQL */ `
  mutation updateSpikeClient(
    $input: UpdateSpkDJTClientTblInput!
    $condition: ModelSpkDJTClientTblConditionInput
  ) {
    updateSpkDJTClientTbl(input: $input, condition: $condition) {
      id
      allSort
      divisionId
      contactCompany
      clientURL
      clientBillingEmail
      contactName
      contactEmail
      contactPhone
      isActive
      isSyncedToQB
      qbId
      qbClassName
      qbClassId
      qbSandboxClassName
      qbSandboxClassId
      qbSandboxId
      qbDisplayName
      qbCustomer
      qbCompany
      qbStreetAddress
      qbCity
      qbState
      qbCountry
      qbZip
      qbPhone
      qbEmail
      qbCustomerType
      staleAfterDate
    }
  }
`;
export const createSpikeClient = /* GraphQL */ `
  mutation createSpikeClient(
    $input: CreateSpkDJTClientTblInput!
    $condition: ModelSpkDJTClientTblConditionInput
  ) {
    createSpkDJTClientTbl(input: $input, condition: $condition) {
      id
      allSort
      divisionId
      contactCompany
      clientURL
      clientBillingEmail
      contactName
      contactEmail
      contactPhone
      isActive
      isSyncedToQB
      qbId
      qbClassName
      qbClassId
      qbSandboxClassName
      qbSandboxClassId
      qbSandboxId
      qbDisplayName
      qbCustomer
      qbCompany
      qbStreetAddress
      qbCity
      qbState
      qbCountry
      qbZip
      qbPhone
      qbEmail
      qbCustomerType
      staleAfterDate
      createdAt
      updatedAt
    }
  }
`;
export const createSpkPrimaryUnitStatus = /* GraphQL */ `
  mutation CreateSpkPrimaryUnitStatus(
    $input: CreateSpkPrimaryUnitStatusTblInput!
    $condition: ModelSpkPrimaryUnitStatusTblConditionInput
  ) {
    createSpkPrimaryUnitStatusTbl(input: $input, condition: $condition) {
      code
      allSort
      unitId
      statusId
      notes
      inService
      inServiceAlert
      divisionId
      lastInspected
      lastInspectedAt
      createdOn
      createdAt
      updatedAt
    }
  }
`;
export const updateSpkPrimaryUnitStatus = /* GraphQL */ `
  mutation UpdateSpkPrimaryUnitStatus(
    $input: UpdateSpkPrimaryUnitStatusTblInput!
    $condition: ModelSpkPrimaryUnitStatusTblConditionInput
  ) {
    updateSpkPrimaryUnitStatusTbl(input: $input, condition: $condition) {
      code
      allSort
      unitId
      statusId
      notes
      inService
      inServiceAlert
      divisionId
      lastInspected
      lastInspectedAt
      createdOn
      createdAt
      updatedAt
    }
  }
`;
export const createDJTPrimaryUnitBillableRate = /* GraphQL */ `
  mutation createDJTPrimaryUnitBillableRate(
    $input: CreateSpkDJTPrimaryUnitBillableRateTblInput!
    $condition: ModelSpkDJTPrimaryUnitBillableRateTblConditionInput
  ) {
    createSpkDJTPrimaryUnitBillableRateTbl(
      input: $input
      condition: $condition
    ) {
      unitCode
      unitId
      hourlyRate
      shiftRate
      dayRate
      createdAt
      updatedAt
    }
  }
`;
export const updateSpkDJTPrimaryUnitBillableRateTbl = /* GraphQL */ `
  mutation UpdateSpkDJTPrimaryUnitBillableRateTbl(
    $input: UpdateSpkDJTPrimaryUnitBillableRateTblInput!
    $condition: ModelSpkDJTPrimaryUnitBillableRateTblConditionInput
  ) {
    updateSpkDJTPrimaryUnitBillableRateTbl(
      input: $input
      condition: $condition
    ) {
      unitCode
      unitId
      hourlyRate
      shiftRate
      dayRate
      createdAt
      updatedAt
    }
  }
`;
export const createStatementDocumentUnit = /* GraphQL */ `
  mutation createStatementDocumentUnit(
    $input: CreateSpkStatementDocumentUnitTblInput!
    $condition: ModelSpkStatementDocumentUnitTblConditionInput
  ) {
    createSpkStatementDocumentUnitTbl(input: $input, condition: $condition) {
      id
      transactionId
      unitId
      createdAt
      updatedAt
    }
  }
`;
export const createStatementVendorMap = /* GraphQL */ `
  mutation createStatementVendorMap(
    $input: CreateSpkStatementVendorMapTblInput!
    $condition: ModelSpkStatementVendorMapTblConditionInput
  ) {
    createSpkStatementVendorMapTbl(input: $input, condition: $condition) {
      vendorName
      vendorTypeKey
      qbVendorId
      createdAt
      updatedAt
    }
  }
`;
export const updateStatementVendorMap = /* GraphQL */ `
  mutation updateStatementVendorMap(
    $input: UpdateSpkStatementVendorMapTblInput!
    $condition: ModelSpkStatementVendorMapTblConditionInput
  ) {
    updateSpkStatementVendorMapTbl(input: $input, condition: $condition) {
      vendorName
      vendorTypeKey
      qbVendorId
      createdAt
      updatedAt
    }
  }
`;
export const createStatementQuickBooksVendor = /* GraphQL */ `
  mutation createStatementQuickBooksVendor(
    $input: CreateSpkStatementQuickBooksVendorTblInput!
    $condition: ModelSpkStatementQuickBooksVendorTblConditionInput
  ) {
    createSpkStatementQuickBooksVendorTbl(
      input: $input
      condition: $condition
    ) {
      qbId
      allSort
      qbDisplayName
      qbDisplayNameLower
      createdAt
      updatedAt
    }
  }
`;
export const updateStatementQuickBooksVendor = /* GraphQL */ `
  mutation updateStatementQuickBooksVendor(
    $input: UpdateSpkStatementQuickBooksVendorTblInput!
    $condition: ModelSpkStatementQuickBooksVendorTblConditionInput
  ) {
    updateSpkStatementQuickBooksVendorTbl(
      input: $input
      condition: $condition
    ) {
      qbId
      allSort
      qbDisplayName
      qbDisplayNameLower
      createdAt
      updatedAt
    }
  }
`;
export const updateCreditCardChartOfAccounts = /* GraphQL */ `
  mutation updateCreditCardChartOfAccounts(
    $input: UpdateSpkCreditCardChartOfAccountsTblInput!
    $condition: ModelSpkCreditCardChartOfAccountsTblConditionInput
  ) {
    updateSpkCreditCardChartOfAccountsTbl(
      input: $input
      condition: $condition
    ) {
      qbChartOfAccountsId
      allSort
      qbChartOfAccountsName
      qbChartOfAccountsFullyQualifiedName
      qbChartOfAccountsActive
      createdAt
      updatedAt
    }
  }
`;
export const createCreditCardChartOfAccounts = /* GraphQL */ `
  mutation createCreditCardChartOfAccounts(
    $input: CreateSpkCreditCardChartOfAccountsTblInput!
    $condition: ModelSpkCreditCardChartOfAccountsTblConditionInput
  ) {
    createSpkCreditCardChartOfAccountsTbl(
      input: $input
      condition: $condition
    ) {
      qbChartOfAccountsId
      allSort
      qbChartOfAccountsName
      qbChartOfAccountsFullyQualifiedName
      qbChartOfAccountsActive
      createdAt
      updatedAt
    }
  }
`;
export const createCreditCardTeamMemberMap = /* GraphQL */ `
  mutation createCreditCardTeamMemberMap(
    $input: CreateSpkCreditCardTeamMemberMapTblInput!
    $condition: ModelSpkCreditCardTeamMemberMapTblConditionInput
  ) {
    createSpkCreditCardTeamMemberMapTbl(input: $input, condition: $condition) {
      qbChartOfAccountsId
      allSort
      teamMemberId
      isActive
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const updateCreditCardTeamMemberMap = /* GraphQL */ `
  mutation updateCreditCardTeamMemberMap(
    $input: UpdateSpkCreditCardTeamMemberMapTblInput!
    $condition: ModelSpkCreditCardTeamMemberMapTblConditionInput
  ) {
    updateSpkCreditCardTeamMemberMapTbl(input: $input, condition: $condition) {
      qbChartOfAccountsId
      allSort
      teamMemberId
      isActive
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const createIFTAFuelTaxTracking = /* GraphQL */ `
  mutation createIFTAFuelTaxTracking(
    $input: CreateSpkIFTAFuelTaxTrackingTblInput!
    $condition: ModelSpkIFTAFuelTaxTrackingTblConditionInput
  ) {
    createSpkIFTAFuelTaxTrackingTbl(input: $input, condition: $condition) {
      id
      allSort
      statementDate
      transactionId
      yearQuarter
      unitId
      teamMemberId
      stateTwoChar
      gallons
      odometer
      isApproved
      approvedById
      createdAt
      updatedAt
    }
  }
`;
export const updateIFTAFuelTaxTracking = /* GraphQL */ `
  mutation updateIFTAFuelTaxTracking(
    $input: UpdateSpkIFTAFuelTaxTrackingTblInput!
    $condition: ModelSpkIFTAFuelTaxTrackingTblConditionInput
  ) {
    updateSpkIFTAFuelTaxTrackingTbl(input: $input, condition: $condition) {
      id
      allSort
      statementDate
      transactionId
      yearQuarter
      unitId
      teamMemberId
      stateTwoChar
      gallons
      odometer
      isApproved
      approvedById
      createdAt
      updatedAt
    }
  }
`;
export const createPlaidInstitution = /* GraphQL */ `
  mutation createPlaidInstitution(
    $input: CreateSpkPlaidInstitutionTblInput!
    $condition: ModelSpkPlaidInstitutionTblConditionInput
  ) {
    createSpkPlaidInstitutionTbl(input: $input, condition: $condition) {
      id
      userId
      accessToken
      institutionId
      institution
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaidInstitution = /* GraphQL */ `
  mutation updatePlaidInstitution(
    $input: UpdateSpkPlaidInstitutionTblInput!
    $condition: ModelSpkPlaidInstitutionTblConditionInput
  ) {
    updateSpkPlaidInstitutionTbl(input: $input, condition: $condition) {
      id
      userId
      accessToken
      institutionId
      institution
      createdAt
      updatedAt
    }
  }
`;
export const createPlaidTransaction = /* GraphQL */ `
  mutation createPlaidTransaction(
    $input: CreateSpkPlaidTransactionTblInput!
    $condition: ModelSpkPlaidTransactionTblConditionInput
  ) {
    createSpkPlaidTransactionTbl(input: $input, condition: $condition) {
      id
      institution_id
      account_id
      account_owner
      amount
      authorized_date
      date
      location
      merchant_entity_id
      name
      merchant_name
      payment_channel
      pending
      pending_transaction_id
      pending_transaction
      transaction_code
      transaction_id
      transaction_type
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaidTransaction = /* GraphQL */ `
  mutation updatePlaidTransaction(
    $input: UpdateSpkPlaidTransactionTblInput!
    $condition: ModelSpkPlaidTransactionTblConditionInput
  ) {
    updateSpkPlaidTransactionTbl(input: $input, condition: $condition) {
      id
      institution_id
      account_id
      account_owner
      amount
      authorized_date
      date
      location
      merchant_entity_id
      name
      merchant_name
      payment_channel
      pending
      pending_transaction_id
      pending_transaction
      transaction_code
      transaction_id
      transaction_type
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaidPendingTransaction = /* GraphQL */ `
  mutation updatePlaidPendingTransaction(
    $input: UpdateSpkPlaidPendingTransactionTblInput!
    $condition: ModelSpkPlaidPendingTransactionTblConditionInput
  ) {
    updateSpkPlaidPendingTransactionTbl(input: $input, condition: $condition) {
      id
      cardNumber
      date
      merchant
      transactionType
      amount
      pendingSort
      postedTransactionId
      createdAt
      updatedAt
    }
  }
`;
export const createPlaidSyncTransactionCursor = /* GraphQL */ `
  mutation createPlaidSyncTransactionCursor(
    $input: CreateSpkPlaidSyncTransactionCursorTblInput!
    $condition: ModelSpkPlaidSyncTransactionCursorTblConditionInput
  ) {
    createSpkPlaidSyncTransactionCursorTbl(
      input: $input
      condition: $condition
    ) {
      id
      cursor
      lastUpdated
      previousUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaidCreditCardTransaction = /* GraphQL */ `
  mutation updatePlaidCreditCardTransaction(
    $input: UpdateSpkPlaidCreditCardTransactionTblInput!
    $condition: ModelSpkPlaidCreditCardTransactionTblConditionInput
  ) {
    updateSpkPlaidCreditCardTransactionTbl(
      input: $input
      condition: $condition
    ) {
      id
      accountNumber
      purchaseDate
      purchaseMerchant
      purchaseAmount
      purchaseInstitution
      accountId
      date
      amount
      merchantEntityId
      merchantEntityName
      store
      categoryId
      category
      location
      paymentChannel
      transactionId
      transactionType
      status
      approvedById
      createdAt
      updatedAt
    }
  }
`;
export const createSpkCapitalOneCreditCardTransactionReceipt = /* GraphQL */ `
  mutation createSpkCapitalOneCreditCardTransactionReceipt(
    $input: CreateSpkCapitalOneCreditCardTransactionReceiptTblInput!
    $condition: ModelSpkCapitalOneCreditCardTransactionReceiptTblConditionInput
  ) {
    createSpkCapitalOneCreditCardTransactionReceiptTbl(
      input: $input
      condition: $condition
    ) {
      id
      capitalOneInstantNotificationId
      plaidCreditCardTransactionId
      isReceiptUploaded
      allSort
      matchStatus
      # uploadedDocumentId
      quickbooksExpenseId
      teamMemberId
      divisionId
      clientId
      expenseCategoryId
      unitId
      iftaFuelTaxTrackId
      addedNotes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCapitalOneInstantNotification = /* GraphQL */ `
  mutation updateCapitalOneInstantNotification(
    $input: UpdateSpkCapitalOneInstantNotificationTblInput!
    $condition: ModelSpkCapitalOneInstantNotificationTblConditionInput
  ) {
    updateSpkCapitalOneInstantNotificationTbl(
      input: $input
      condition: $condition
    ) {
      id
      allSort
      capitalOneCreditCardTransactionReceiptId
      plaidTransationId
      isMatched
      isSubmitted
      status
      lastFour
      purchaseDate
      purchaseMerchant
      purchaseAmount
      purchaseInstitution
      createdAt
      updatedAt
    }
  }
`;
export const updateCapitalOneCreditCardTransactionReceipt = /* GraphQL */ `
  mutation updateCapitalOneCreditCardTransactionReceipt(
    $input: UpdateSpkCapitalOneCreditCardTransactionReceiptTblInput!
    $condition: ModelSpkCapitalOneCreditCardTransactionReceiptTblConditionInput
  ) {
    updateSpkCapitalOneCreditCardTransactionReceiptTbl(
      input: $input
      condition: $condition
    ) {
      id
      allSort
      matchStatus
      qbTransactionEntityId
      isReceiptUploaded
      quickbooksExpenseId
      clientId
      expenseCategoryId
      unitId
      addedNotes
      hasIssue
      issue
      createdAt
      updatedAt
    }
  }
`;
export const createCapitalOneTransactionReceiptDocumentLink = /* GraphQL */ `
  mutation createCapitalOneTransactionReceiptDocumentLink(
    $input: CreateSpkCapitalOneTransactionReceiptDocumentLinkTblInput!
    $condition: ModelSpkCapitalOneTransactionReceiptDocumentLinkTblConditionInput
  ) {
    createSpkCapitalOneTransactionReceiptDocumentLinkTbl(
      input: $input
      condition: $condition
    ) {
      id
      receiptId
      uploadedDocumentId
      createdAt
      updatedAt
    }
  }
`;
export const createUserNavigation = /* GraphQL */ `
  mutation createUserNavigation(
    $input: CreateSpkNavMenuUserTblInput!
    $condition: ModelSpkNavMenuUserTblConditionInput
  ) {
    createSpkNavMenuUserTbl(input: $input, condition: $condition) {
      id
      tmId
      navMenuId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createNavMenuUser = /* GraphQL */ `
  mutation createNavMenuUser(
    $input: CreateSpkNavMenuUserTblInput!
    $condition: ModelSpkNavMenuUserTblConditionInput
  ) {
    createSpkNavMenuUserTbl(input: $input, condition: $condition) {
      id
      tmId
      navMenuId
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNavMenuUser = /* GraphQL */ `
  mutation deleteNavMenuUser(
    $input: DeleteSpkNavMenuUserTblInput!
    $condition: ModelSpkNavMenuUserTblConditionInput
  ) {
    deleteSpkNavMenuUserTbl(input: $input, condition: $condition) {
      id
      tmId
      navMenuId
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnitDocument = /* GraphQL */ `
  mutation updateUnitDocument(
    $input: UpdateSpkUnitDocumentTblInput!
    $condition: ModelSpkUnitDocumentTblConditionInput
  ) {
    updateSpkUnitDocumentTbl(input: $input, condition: $condition) {
      id
      unitId
      createdById
      uploadedDateTime
      uploadedDocumentId
      unitDocumentTypeId
      unitIdUnitDocumentTypeId
      active
      isActive
      notes
      imageSetId
      createdAt
      updatedAt
      __typename
    }
  }
`;