import { Authenticator } from '@aws-amplify/ui-react';
import "../Login.css";
import Logo from "../img/login-screen-spike-logo@1x.png"
// import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { ThemeProvider, defaultTheme } from '@aws-amplify/ui-react';
export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    const doNavigate = async () => {
      if (route === 'authenticated') {
        // await DataStore.clear();
        navigate(from, { replace: true });
      }
    }
    doNavigate()
  }, [route, navigate, from]);
  
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Email address',
        label: ''
      },
      password: {
        placeholder: 'Password',
        label: '',
        hideShowPassword: true
      }
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    resetPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Confirm your Password:',
        label: 'New Password Label',
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  const customTheme = {
    ...defaultTheme,
    tokens: {
      colors: {
        brand: {
          primary: {
            '10': '#f0e68c',  // Light yellow
            '80': '#ffd700',  // Bright yellow (Spike's gold)
            '90': '#F0C67D',  // Hover color
          },
        },
        background: {
          primary: {
            '10': '#1c1c1e', // Dark background for the form
          },
        },
        font: {
          interactive: {
            '10': '#ffffff99',  // Text color inside input fields
          },
        },
      },
      components: {
        button: {
          primary: {
            backgroundColor: {
              value: '#ffd700', // Gold background for buttons
            },
            borderRadius: {
              value: '20px',   // Button's rounded corners
            },
            _hover: {
              backgroundColor: {
                value: '#F0C67D', // Hover effect for the button
              },
            },
          },
        },
        fieldcontrol: {
          color: {
            value: '#ffffff99',  // Input placeholder color
          },
        },
      },
    },
  };


  return (     
      <div className="container-center-horizontal">
        <div className="login screen">
          <img className="login-screen-spike-logo" src={Logo} alt="login-screen-Spike-logo" />
          <div className="overlap-group">
            <h1 className="title sfpro-bold-white-34px">Sign in</h1>
              <ThemeProvider theme={customTheme}>
              <Authenticator 
                  formFields={formFields} 
                  hideSignUp={true}
                  components={{
                    Header() { return null; }  // Hide default "Sign in" header
                  }}
                ></Authenticator>
              </ThemeProvider>
              
              {/* <Authenticator onStateChange={(authState) => console.warn('authState: ', authState)} formFields={formFields} hideSignUp={true}></Authenticator> */}
          </div>
        </div>
      </div>
  );
}
