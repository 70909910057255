import { listAllISolvedFetchEmployeesStatus } from '../../../graphql/custom_queries';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchLatestSyncStatus = async () => {
  try {

    const gqlParams = {
      allSort: "all",
      sortDirection: 'DESC',
      limit: 1
    }

    const response = await client
    .graphql({
      query: listAllISolvedFetchEmployeesStatus,
      variables: gqlParams
    })

    console.info('fetchLatestSyncStatus response: ', response);
    
    const results = response.data.allISolvedFetchEmployeesStatus.items

    console.info('fetchLatestSyncStatus results: ', results);

    return results;
  } catch (err) {
    return('fetchLatestSyncStatus error: ', [err]);
  }

}