export const getCustomers = async (token, environment) => {
  if (!token) {
    console.warn('getCustomers called without a token')
    return null
  }

  // console.warn(' %----- getCustomers token: ', token)
  // console.warn(' %----- getCustomers environment: ', environment)

  const response = await fetch('https://wvkgh5kfgc2b5yqiqhqryofwvm0xoill.lambda-url.us-east-1.on.aws/?token='+token+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  const dataJSON = await response.json()
  console.log('getCustomers response: ', dataJSON)
  let qbresponse = ''

  // console.log('about to load with this customer data: ', dataJSON)
  // if data.JSON.fault.error[0].code = "3200". we ned to refresh
  if (dataJSON.fault?.error.length>0) {
    console.log('GOT AN ERROR in getCustomers: ', dataJSON)
    qbresponse = null
  }
  if (dataJSON.QueryResponse?.Customer.length>0) {
    qbresponse = dataJSON.QueryResponse.Customer
  }
  // console.log('qbresponse is: ', qbresponse)
  // setCustomers(dataJSON.QueryResponse.Customer)
  return qbresponse
}