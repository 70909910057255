import { 
  djtSearchByDivisionStatus, 
  djtSearchByDivisionTeamMemberStatus, 
  djtSearchByDivisionTeamMemberClientStatus, 
  djtSearchByDivisionClientStatus } from '../../../graphql/custom_queries';

import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const searchDjtsByDivision = async (props) => {
  
  if (!props) return
  try {    

    // searchProps.statusGroup = 'submitted-invoiced'
    // searchProps.statusIds = `${pendingApprovalStatusId},${invoicedQBStatusId},${invoicedOtherStatusId}`
    
    // props now include djtStatusId to search
    // djtStatusId, divisionId, djtClientId, djtDate, 'djtDateStart', 'djtDateEnd', limit
    // 0. ALL, ORDER BY DATE DESC, LIMIT n    djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC
    // 1. CLIENT, ORDER BY DATE DESC, LIMIT n     djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC, filter: {djtClientId: {eq: "87ac8687-c55c-405b-98ba-df3a34426721"}}
    // 2. DATE, ORDER BY DATE DESC, LIMIT n       djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC, djtDate: {eq: "2023-01-24"}
    // 3. DATE RANGE, ORDER BY DATE DESC, LIMIT n     djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC, djtDate: {between: ["2023-01-01","2023-01-27"]})
    // 4. CLIENT, DATE, ORDER BY DATE DESC, LIMIT n     djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC, djtDate: {eq: "2023-01-27"}, filter: {djtClientId: {eq: "aae660ec-67f7-4387-a925-d74b76ac85a4"}}
    // 5, CLIENT, DATE RANGE, ORDER BY DATE DESC, LIMIT n     djtDivisionId: "87e0ca1a-42c9-4e72-ae80-c9a507bfbd50", limit: 5, sortDirection: DESC, djtDate: {between: ["2023-01-01", "2023-01-27"]}, filter: {djtClientId: {eq: "aae660ec-67f7-4387-a925-d74b76ac85a4"}}
    const { djtStatusId, statusGroup, statusIds, divisionId, teamMemberId, djtClientId, djtDate, djtEndDate, limit=999, nextToken, nextTokenArray=[] } = props;

    const isConsecutiveSearch = (statusGroup === 'submitted-invoiced') ? true : false
    const statusIdsArray = (statusIds) ? statusIds?.split(',') : []
    const queryFilters = [
      { //0
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
      },
      { //1
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
      },
      { //2
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {eq: djtDate}
      },
      { //3
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {between: [djtDate, djtEndDate]}
      },
      { //4
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {eq: djtDate}, 
      },
      { //5
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {between: [djtDate, djtEndDate]}, 
      },
      { //6 
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
      },
      { //7
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {eq: djtDate}, 
      },
      { //8
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {between: [djtDate, djtEndDate]}, 
      },
      { //9
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {eq: djtDate}
      },
      { //10
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
        djtDate: {between: [djtDate, djtEndDate]}
      },
      { //11
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit: limit, 
        sortDirection: 'DESC',
        nextToken: nextToken,
      },
    ]
    
    const hasClient = (djtClientId!=='' && djtClientId!==null)
    const hasDjtDate = (djtDate!=='' && djtDate!==null)
    const hasDjtEndDate = (djtEndDate!=='' && djtEndDate!==null)
    const hasTeamMember = (teamMemberId!=='' && teamMemberId!==null)

    let queryFilter = 0
    // by default, search for Division, TeamMember, Status
    if (hasTeamMember) {
      queryFilter = 11
    }
    // client only
    if (hasClient && !hasDjtDate && !hasDjtEndDate) {
      if (hasTeamMember) {
        queryFilter = 6
      } else {
        queryFilter = 1
      }
    }
    // client and startdate only
    if (hasClient && hasDjtDate && !hasDjtEndDate) {
      if (hasTeamMember) {
        queryFilter = 7
      } else {
        queryFilter = 4
      }
    }
    // client and startdate and enddate
    if (hasClient && hasDjtDate && hasDjtEndDate) {
      if (hasTeamMember) {
        queryFilter = 8
      } else {
        queryFilter = 5
      }
    }
    // startdate only
    if (!hasClient && hasDjtDate && !hasDjtEndDate) {
      if (hasTeamMember) {
        queryFilter = 9
      } else {
        queryFilter = 2
      }
    }
    // startdate and enddate only
    if (!hasClient && hasDjtDate && hasDjtEndDate) {
      if (hasTeamMember) {
        queryFilter = 10
      } else {
        queryFilter = 3
      }
    }
    // division and status only use query 0
    // djtStatusId = equlals the status id for 'queued-approval-required', 468eb65a-8471-47e9-98cb-3258570f71fa
    
    // 87e0ca1a-42c9-4e72-ae80-c9a507bfbd50#092af981-097a-42cd-b929-277a5755eb1d#468eb65a-8471-47e9-98cb-3258570f71fa
    // 87e0ca1a-42c9-4e72-ae80-c9a507bfbd50#092af981-097a-42cd-b929-277a5755eb1d#2a279c1e-110c-4223-a2bd-e411fc51f89e
    // console.log(' <<<<<< queryFilter: ', queryFilter)
    // console.log(' <<<<<< queryFilters[queryFilter]: ', queryFilters[queryFilter])
    
    // --- TEMPORARY ---
    // --- These for loops were tmeporary, they need to be replaced with a single query ---
    // --- There is a new query 'djtStatusByStatusGroup' that will be used to replace these for loops --
    // --- TEMPORARY ---

    console.log('queryFilter: ', queryFilter)

    // Division TeamMember Status
    if (queryFilter===9 || queryFilter===10 || queryFilter===11) {
      // console.log(' <<<<<< queryFilters[queryFilter]: ', queryFilters[queryFilter])
      let results = {}
      let currentResults = null
      let resultsArray = []
      if (isConsecutiveSearch) {
        
        for (const [index, id] of statusIdsArray.entries()) {
          // nextTokenArray is an array of objects {id: 'nextToken'}. Itereate through and find a matching nextToken based on the id
          let nextToken = null
          for (const obj of nextTokenArray.entries()) {
            if (obj.id === id) {
              nextToken = obj.nextToken
              break
            }
          }
          const newQuery = {
            djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${id}`,
            limit: 350, 
            // limit: limit, 
            sortDirection: 'DESC',
            nextToken: nextToken,
          }
          console.warn(' ----- newQuery: ', newQuery)

          const response = await client.graphql({
            query: djtSearchByDivisionTeamMemberStatus,
            variables: newQuery
          });
          console.warn(' ++------ response: ', response)
          // const currentResultsItems = response?.data?.djtByDivisionTeamMemberStatusComposite?.items
          // const currentResultsNextToken = response?.data?.djtByDivisionTeamMemberStatusComposite?.nextToken
          
          currentResults = response?.data?.djtByDivisionTeamMemberStatusComposite?.items
          // console.warn('currentResults: ', currentResults)
          // now put currentResultsItems and currentResultsNextToken into an object and push into resultsArray
          // resultsArray.push({id: id, items: currentResultsItems, nextToken: currentResultsNextToken})
          resultsArray.push(...currentResults)
          // console.info('resultsArray: ', resultsArray)
        }
        results.nextToken = null
        results.items = resultsArray
        // console.info('FINAL RESULTS: ', resultsArray)
        // console.info('FINAL RESULTS: ', results)
        return results
        // return resultsArray;
      } else {
        const response = await client.graphql({
          query: djtSearchByDivisionTeamMemberStatus,
          variables: queryFilters[queryFilter]
        });
        results = response.data.djtByDivisionTeamMemberStatusComposite
        return results
      }
    }

    // Division TeamMember Client Status
    if (queryFilter===6 || queryFilter===7 || queryFilter===8) {
      let results = {}
      let currentResults = null
      let resultsArray = []
      if (isConsecutiveSearch) {
        for (const [index, id] of statusIdsArray.entries()) {
          const newQuery = {
            djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${id}`,
            // limit: limit, 
            limit: 350, 
            sortDirection: 'DESC',
            nextToken: null,
          }
          // const response = await API.graphql(graphqlOperation(djtSearchByDivisionTeamMemberStatus, 
          //   newQuery
          // ))
          const response = await client.graphql({
            query: djtSearchByDivisionTeamMemberStatus,
            variables: newQuery
          });
          currentResults = response.data.djtByDivisionTeamMemberStatusComposite.items
          resultsArray.push(...currentResults)
          // console.info('resultsArray: ', resultsArray)
        }
        results.nextToken = null
        results.items = resultsArray
        // console.info('FINAL RESULTS: ', resultsArray)
        // console.info('FINAL RESULTS: ', results)
        return results
      } else {
        // const response = await API.graphql(graphqlOperation(djtSearchByDivisionTeamMemberClientStatus, 
        //   queryFilters[queryFilter]
        // ))
        const response = await client.graphql({
          query: djtSearchByDivisionTeamMemberClientStatus,
          variables: queryFilters[queryFilter]
        });
        // console.log('response: ', response)
        const results = response.data.djtByDivisionTeamMemberClientStatus
        // alert('query: 2')
        return results
      }
      
    }

    // Division Status - this needs to be the end point when searching for approval required - all team members
    if (queryFilter===0 || queryFilter===2 || queryFilter===3) {
      let results = {}
      let currentResults = null
      let resultsArray = []
      if (isConsecutiveSearch) {
        for (const [index, id] of statusIdsArray.entries()) {

          if (!teamMemberId) {
            const newQuery = {
              djtDivisionStatus: `${divisionId}#${id}`,
              // djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${id}`,
              limit: limit, 
              sortDirection: 'DESC',
              nextToken: null,
            }
            console.warn(' $----- newQuery: ', newQuery)
            // const response = await API.graphql(graphqlOperation(djtSearchByDivisionStatus, 
            //   newQuery
            // ))
            const response = await client.graphql({
              query: djtSearchByDivisionStatus,
              variables: newQuery
            });
            // console.warn(' $----- response: ', response)
            currentResults = response?.data?.djtByDivisionStatusComposite?.items
            // console.warn('currentResults: ', currentResults)
            resultsArray.push(...currentResults)
          } else {
            const newQuery = {
              // djtDivisionStatus: `${divisionId}#${id}`,
              djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${id}`,
              limit: limit, 
              sortDirection: 'DESC',
              nextToken: null,
            }
            // console.warn(' +----- newQuery: ', newQuery)
            // const response = await API.graphql(graphqlOperation(djtSearchByDivisionTeamMemberStatus, 
            //   newQuery
            // ))
            const response = await client.graphql({
              query: djtSearchByDivisionTeamMemberStatus,
              variables: newQuery
            });
            // console.warn('response: ', response)
            currentResults = response.data.djtByDivisionTeamMemberStatusComposite.items
            // console.warn('currentResults: ', currentResults)
            resultsArray.push(...currentResults)
          }
          
          // console.info('resultsArray: ', resultsArray)
        }
        results.nextToken = null
        results.items = resultsArray
        // console.info('FINAL RESULTS: ', resultsArray)
        // console.info('FINAL RESULTS: ', results)
        return results
      } else {
        // console.log('queryFilters[queryFilter]: ', queryFilters[queryFilter])
        // const response = await API.graphql(graphqlOperation(djtSearchByDivisionStatus, 
        //   queryFilters[queryFilter]
        // ))
        const response = await client.graphql({
          query: djtSearchByDivisionStatus,
          variables: queryFilters[queryFilter]
        });
        // console.log('query=0: ', response)
        const results = response.data.djtByDivisionStatusComposite
        // console.log('results: ', results)
        return results
      }
      
    }

    // Division Client Status
    if (queryFilter===1 || queryFilter===4 || queryFilter===5) {
      let results = {}
      let currentResults = null
      let resultsArray = []
      if (isConsecutiveSearch) {
        for (const [index, id] of statusIdsArray.entries()) {
          const newQuery = {
            djtDivisionClientStatus: `${divisionId}#${djtClientId}#${id}`, 
            limit: limit, 
            sortDirection: 'DESC',
            nextToken: null,
          }
          // const response = await API.graphql(graphqlOperation(djtSearchByDivisionTeamMemberStatus, 
          //   newQuery
          // ))
          const response = await client.graphql({
            query: djtSearchByDivisionTeamMemberStatus,
            variables: newQuery
          });
          currentResults = response.data.djtByDivisionTeamMemberStatusComposite.items
          resultsArray.push(...currentResults)
          // console.info('resultsArray: ', resultsArray)
        }
        results.nextToken = null
        results.items = resultsArray
        // console.info('FINAL RESULTS: ', resultsArray)
        // console.info('FINAL RESULTS: ', results)
        return results
      } else {
        // const response = await API.graphql(graphqlOperation(djtSearchByDivisionClientStatus, 
        //   queryFilters[queryFilter]
        // ))
        const response = await client.graphql({
          query: djtSearchByDivisionClientStatus,
          variables: queryFilters[queryFilter]
        });
        const results = response.data.djtByDivisionClientStatus
        // alert('query: 4')
        return results
      }
      
    }
  } catch(err) {
    return('error searchDjtsByDivision: ', [err]);
  }


}