import { getPersonalTopicId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const getTeamMemberTopicId = async (teamMemberId) => {
  if (teamMemberId) {
    try {
      const variables = {
        filter: {
          name: {
            eq: "user#"+teamMemberId
          }
        }
      };
      const response = await client
      .graphql({
        query: getPersonalTopicId,
        variables
      })
      
      const topicId = await response?.data?.listSpkPubSubTopicTbls?.items[0]?.id;
      return topicId;
    } catch (err) {
      return('getTeamMemberTopicId error: ', [err]);
    }
  } else {
    return('Missing email')
  }
}

getTeamMemberTopicId.propTypes = {
  teamMemberId: PropTypes.node.isRequired,
};