import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { createSpkDJTLabor } from '../../../graphql/custom_mutations';

const client = generateClient();

export const addDJTLabor = async (props) => {
  console.log('adding djt labor to db: ', props);
  if (props) {
    try {
      console.log('trying mutation')
      const response = await client.graphql({
        query: createSpkDJTLabor,
        variables: { input: props }
      });

      console.log('addDJTLabor response: ', response)
      const results = response.data.createSpkDJTLaborTbl.id;
      console.log('addDJTLabor results: ', results)
      return results;
    } catch (err) {
      return 'error addDJTLabor: ' + err;
    }
  } else {
    return null;
  }
};

addDJTLabor.propTypes = {
  props: PropTypes.object.isRequired,
};
