/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Avatar,
  Grid,
} from "@chakra-ui/react";

// graphql
import { fetchTeamMemberById } from '../graphqlCompnents/TeamMember/fetchTeamMemberById.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// decorative components
import { Divider } from '../Divider';

// utility functions
import { spellOutDate } from '../functions/dateTime.jsx';

// components
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// API Gateway
import { fetchCognitoUserGroupsByUser } from '../APIGateway/fetchCognitoUserGroupsByUser.jsx';


export const ViewTeamMember = ({ srcId, onClose }) => {
  // if (!srcId) return
  const srcIdSplit=srcId.split('#')
  const teamMemberType = srcIdSplit[0]
  const teamMemberId = srcIdSplit[1]

  const [ teamMember, setTeamMember ] = useState({})
  const [ teamMemberGroups, setTeamMemberGroups ] = useState('')
  // const [ selectedJobClass, setSelectedJobClass ] = useState(null)

  const getUsersGroups = async (userEmail) => {
    const groups = await fetchCognitoUserGroupsByUser(userEmail)
    return groups;
  }

  const formatGroupName = (groupName) => {
    return groupName
      .replace('exec_admin', 'executive_admin')
      .split('_')  // Split the name by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
      .join(' ');  // Join words with spaces
  };

  useEffect(() => {

    const getTeamMember = async (id) => {
      const teamMember = await fetchTeamMemberById(id)
      console.warn(' ----- TEAMMEMBER: ', teamMember)

      setTeamMember(teamMember)
      
      if (teamMember?.isERPUser===true) {
        getUsersGroups(teamMember.loginEmail).then((groups) => {
          const groupNamesFormatted = groups?.map(group => formatGroupName(group?.GroupName)).join(', ');
          setTeamMemberGroups(groupNamesFormatted)
        })
      }
      
    }

    getTeamMember(teamMemberId)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ teamMemberType, teamMemberId])

  const ProfileCard = ({ teamMember }) => {  
    return (
      <Flex alignItems="center">
        <Avatar border='1px solid var(--dark-component-border)' mr='25px' bg='black' color='var(--dark-gold-primary)' size='xl' name={`${teamMember?.firstName} ${teamMember?.lastName}`} src={teamMember?.imgUrl} />
        <Box w={'700px'}>
          <Text w={'700px'} textStyle='heading-2'>{teamMember?.firstName} {(teamMember?.goesBy) && `"${teamMember?.goesBy}"`} {(teamMember?.middleName) && teamMember?.middleName} {teamMember?.lastName}  </Text>
          <Grid templateColumns='repeat(3, 1fr)' pt={'15px'} gridColumnGap="20px">
            <Flex pb={'5px'} alignItems="center" w={'195px'} >
              <Text mr='5px' textStyle='text-2'>Status:</Text>
              <Text mr='2px' textStyle='text-1'>{(teamMember?.isActive===true) ? 'Active' : 'Inactive'}</Text>
              {(teamMember?.isActive===true) ? 
                <AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'fillcheckcircle'} color={'var(--success-green)'}/> : 
                <AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'tmoff'} color={'var(--error-red)'}/>
              }
            </Flex>
            <Flex pb={'5px'} alignItems="center">
              {(teamMember?.loginEmail) && <><AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'email'} color={'var(--dark-text-grey-1)'}/>
              <Text mr='5px' textStyle='text-2'>{teamMember?.loginEmail}</Text></>}
            </Flex>
            <Box></Box>

            <Flex alignItems="center">
              {/* <Text mr='5px' textStyle='text-2'>Global Admin:</Text><Text mr='2px' textStyle='text-1'>{(teamMember?.isGlobalAdmin===true) ? 'Yes' : 'No' || '\u00A0'}</Text> */}
            </Flex>
            <Flex alignItems="center">
              {(teamMember?.phoneNum) && <><AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'phone'} color={'var(--dark-text-grey-1)'}/>
              <Text mr='5px' textStyle='text-2'>{teamMember?.phoneNum}</Text></>}
            </Flex>

            
          </Grid>
        </Box>
      </Flex>
    );
  };

  ProfileCard.propTypes = {
    teamMember: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      goesBy: PropTypes.string, // Optional
      loginEmail: PropTypes.string.isRequired,
      phoneNum: PropTypes.string,
    }).isRequired,
  };
  
  const PersonalInfoCard = ({ teamMember }) => {
  
    const InfoSection = ({ title, content }) => (
      <Flex justify="space-between" align="center" my={2}>
        <Box>
          <Text textStyle={'text-8'}>{title}</Text>
          <Text textStyle={'text-1'}>{content||' '}</Text>
        </Box>
      </Flex>
    );

    InfoSection.propTypes = {
      title: PropTypes.string,
      content: PropTypes.string,
    };
  
    return (
      <Box p={6} rounded="md" shadow="base">
        <Flex direction={['column', 'row']} justify="space-between">
          <Box flex="1">
            <InfoSection title="First Name" content={teamMember?.firstName} />
            <InfoSection title="Middle Name" content={teamMember?.middleName || '\u00A0'} />
            <InfoSection title="Last Name" content={teamMember?.lastName} />
            <InfoSection title="Goes By" content={teamMember?.goesBy || '\u00A0'} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Address" content={teamMember?.addStreet || '\u00A0'} />
            <InfoSection title="City" content={teamMember?.addCity || '\u00A0'} />
            <InfoSection title="State" content={teamMember?.addState || '\u00A0'} />
            <InfoSection title="Zip code" content={teamMember?.addZipcode || '\u00A0'} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Date of Birth" content={spellOutDate(teamMember?.dob) || '\u00A0'} />
            <InfoSection title="Phone Number" content={teamMember?.phoneNum} />
            <InfoSection title="Employee Number" content={teamMember?.employeeNumber} />
            {/* <InfoSection title="Emergency Contact Name" content={teamMember?.emergcyContName || '\u00A0'} />
            <InfoSection title="Emergency Contact Phone" content={teamMember?.emergcyContPhone || '\u00A0'} /> */}
          </Box>
        </Flex>
      </Box>
    );
  };

  PersonalInfoCard.propTypes = {
    teamMember: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      goesBy: PropTypes.string,
      phoneNum: PropTypes.string,
      dob: PropTypes.string,
      addStreet: PropTypes.string,
      addCity: PropTypes.string,
      addState: PropTypes.string,
      addZipcode: PropTypes.string,
      emergcyContName: PropTypes.string,
      emergcyContPhone: PropTypes.string,
    }).isRequired,
  };
  
  const WorkInfoCard = ({ teamMember }) => {
    if (!teamMember) return
    // console.warn('teamMember: ', teamMember)
  
    const InfoSection = ({ title, content }) => (
      <Flex justify="space-between" align="center" my={2}>
        <Box>
          <Text textStyle={'text-8'}>{title}</Text>
          <Text textStyle={'text-1'}>{content}</Text>
        </Box>
      </Flex>
    );

    InfoSection.propTypes = {
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    };
  
    return (
      <Box p={6} rounded="md" shadow="base">
        <Flex direction={['column', 'row']} justify="space-between">
          <Box flex="1">
            <InfoSection title="Division" content={teamMember?.division?.prettyname || '\u00A0'} />
            <InfoSection title="Job Class" content={teamMember?.billableDivisionJobClass?.appDisplayName || '\u00A0'} />
            {/* <InfoSection title="Employment Type" content={''} />
            <InfoSection title="TimeCo Badge Number" content={''} /> */}
            
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Date of Hire" content={spellOutDate(teamMember?.hireDate) || spellOutDate(teamMember.startDate) || '\u00A0'} />
            <InfoSection title="Employment Status" content={(teamMember?.isActive) ? 'Active' : 'Inactive'} />
            <InfoSection title="Groups" content={teamMemberGroups} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Created On" content={spellOutDate(teamMember?.createdAt?.split('T')[0]) || '\u00A0'} />
            <InfoSection title="Last Updated" content={spellOutDate(teamMember?.updatedAt?.split('T')[0]) || '\u00A0'} />
            <InfoSection title="Is Global Admin" content={(teamMember?.isGlobalAdmin===true) ? 'Yes' : 'No' || '\u00A0'} />
            {/* <InfoSection title="Is Active" content={(teamMember?.isActive===true) ? 'Yes' : 'No' || '\u00A0'} /> */}
          </Box>
        </Flex>
      </Box>
    );
  };

  WorkInfoCard.propTypes = {
    teamMember: PropTypes.shape({
      division: PropTypes.shape({
        prettyname: PropTypes.string,
      }),
      billableDivisionJobClass: PropTypes.shape({
        appDisplayName: PropTypes.string,
      }),
      badgeNumber: PropTypes.string,
      hireDate: PropTypes.string,
      startDate: PropTypes.string,
      isActive: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      isGlobalAdmin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]),
    }).isRequired,
  };

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };


  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown}>
      
      <ModuleBoxDrawer>
        <Flex>
          {(teamMember.firstName) && <ProfileCard teamMember={teamMember} />}
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>
      </ModuleBoxDrawer>

      <Box my={'25px'} />

      <ModuleBoxDrawer>
          <Box>
            <Flex alignItems="center">
              <Text textStyle='heading-1'>Personal Information</Text>
            </Flex>
          </Box>

          <PersonalInfoCard teamMember={teamMember} />
          
      </ModuleBoxDrawer>

      <Box my={'25px'} />

      <ModuleBoxDrawer>
      
        <Box>
          <Flex alignItems="center">
            <Text textStyle='heading-1'>Spike Information</Text>
            
          </Flex>
        </Box>
        <WorkInfoCard teamMember={teamMember} />
      </ModuleBoxDrawer>
    </Container>

    <Box paddingBottom={'50px'} />
    </>
  );
};

ViewTeamMember.propTypes = {
  srcId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};