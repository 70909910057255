/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  HStack,
  Avatar,
  Grid,
  Checkbox,
  Stack,
  FormLabel,
  FormControl,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";

// graphql
import { fetchTeamMemberById } from '../graphqlCompnents/TeamMember/fetchTeamMemberById.jsx';
import { updateTeamMemberAdministrative } from '../graphqlCompnents/TeamMember/updateTeamMemberAdministrative.jsx';
import { fetchJobClassRatesByDivisionId } from '../graphqlCompnents/JobClass/fetchJobClassRatesByDivisionId.jsx';
import { updateCognitoUsersGroups } from '../APIGateway/updateCognitoUsersGroups.jsx';
import { fetchCognitoUsers } from '../APIGateway/fetchCognitoUsers.jsx';
import { createCognitoUser } from '../APIGateway/createCognitoUser.jsx';
import { updateCognitoUser } from '../APIGateway/updateCognitoUser.jsx';
import { fetchNavMenuForGroups } from '../graphqlCompnents/TeamMember/fetchNavMenuForGroups.jsx';
import { fetchMenuItemsByTeamMemberId } from '../graphqlCompnents/TeamMember/fetchMenuItemsByTeamMemberId.jsx';
import { addNavMenuUser } from '../graphqlCompnents/TeamMember/addNavMenuUser.jsx';
import { removeNavMenuUser } from '../graphqlCompnents/TeamMember/removeNavMenuUser.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonCopyClipboardWithIcon } from '../Form/Button/ButtonCopyClipboardWithIcon.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// decorative components
import { Divider } from '../Divider';

// utility functions
import { spellOutDate } from '../functions/dateTime.jsx';
import { delay } from '../functions/generic.jsx';

// components
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// API Gateway
import { fetchCognitoUserGroupsByUser } from '../APIGateway/fetchCognitoUserGroupsByUser.jsx';
import { useCognitoUserGroups} from '../APIGateway/fetchCognitoGroups.jsx';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';

export const EditTeamMember = ({ srcId, onClose }) => {
  const srcIdSplit=srcId.split('#')
  const teamMemberType = srcIdSplit[0]
  const teamMemberId = srcIdSplit[1]

  const [ teamMember, setTeamMember ] = useState({})
  const [ teamMemberEmail, setTeamMemberEmail ] = useState('')
  const [ teamMemberPhone, setTeamMemberPhone ] = useState('')
  const [ emailIsPending, setEmailIsPending ] = useState(true)
  const [ teamMemberGroups, setTeamMemberGroups ] = useState('')

  const { 
    setError, control, register, handleSubmit, clearErrors, setValue, 
    formState: { errors, isSubmitting }
  } = useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });
  
  const onError = (errors, e) => console.error(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState('')
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const [ selectedDivision, setSelectedDivision ] = useState(null)
  const [ jobClassOptions, setJobClassOptions ] = useState([])
  const [ selectedJobClass, setSelectedJobClass ] = useState(null)
  const [ isERPUserActive, setIsERPUserActive ] = useState(true)
  const [ cognitoAccountExists, setCognitoAccountExists ] = useState(false)
  const [ isolvedEmailAddress, setIsolvedEmailAddress ] = useState('')
  const [ groupsSelected, setGroupsSelected ] = useState(["team_member"])
  const [ isIssuedCreditCard, setIsIssuedCreditCard ] = useState('')
  const { userPoolGroups } = useCognitoUserGroups();
  const [ adminIsLocked, setAdminIsLocked ] = useState(false); // undeletable admin
  const [ formIsUpdated, setFormIsUpdated ] = useState(false);

  const getUsersGroups = async (userEmail) => {
    const groups = await fetchCognitoUserGroupsByUser(userEmail)
    return groups;
  }

  const formatGroupName = (groupName) => {
    return groupName
      .replace('exec_admin', 'executive_admin')
      .split('_')  // Split the name by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
      .join(' ');  // Join words with spaces
  };

  const listUsersParams = {
    "AttributesToGet": [
      "email"
    ]
  }

  const [ currentCognitoUsers, setCurrentCognitoUsers ] = useState([])
  useEffect(() => {
    const fetchCurrentCognitoUsers = async () => {
      try {
        const userList = await fetchCognitoUsers(listUsersParams);
        setCurrentCognitoUsers(userList || []);
        console.warn(' ----- userList: ', userList)
      } catch (err) {
        setError(err.message);
        setCurrentCognitoUsers([]);
      }
    };

    fetchCurrentCognitoUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ allGroupMenuItems, setAllGroupMenuItems ] = useState([])
  useEffect(() => {
    const fetchGroupMenuItems = async () => {
      try {
        const groupMenuItems = await fetchNavMenuForGroups();
        console.log('groupMenuItems: ', groupMenuItems)
        setAllGroupMenuItems(groupMenuItems);
      } catch (error) {
        console.error('Failed to fetch group menu items:', error);
      }
    }

    fetchGroupMenuItems();
  },[])
  
  const [ teamMembersNavMenuItems, setTeamMembersNavMenuItems ] = useState([])
  useEffect(() => {
    const fetchNavMenuItems = async (teamMemberId) => {
      try {
        const navMenuItems = await fetchMenuItemsByTeamMemberId(teamMemberId);
    
        // Map to set default values if properties are missing
        const processedNavMenuItems = navMenuItems.map(item => ({
          id: item.id,  // Team member's specific navigation item ID
          navMenuId: item.navMenu?.id || null,  // Reference to navMenu.id
          displayOrder: item.displayOrder || 0,
          isPhoneSafe: item.isPhoneSafe ?? false,
          linkTo: item.navMenu?.linkTo || '',  // Pull from navMenu if available
          menuAltText: item.navMenu?.menuAltText || '',
          menuClass: item.navMenu?.menuClass || '',
          menuItem: item.navMenu?.menuItem || ''
        }));
    
        setTeamMembersNavMenuItems(processedNavMenuItems);
      } catch (error) {
        console.error('Failed to fetch team member menu items:', error);
      }
    };
    const getTeamMember = async (id) => {
      await fetchNavMenuItems(id);
      const teamMember = await fetchTeamMemberById(id)
      console.warn(' ----- TEAMMEMBER: ', teamMember)
      
      setIsERPUserActive(teamMember?.isERPUser===true ? true : false)
      setAdminIsLocked((teamMember?.isLocked===true) ? true : false)
      setIsolvedEmailAddress(teamMember?.isolvedEmail||'')
      if (teamMember?.divisionId) {
        handleDivisionChanged({
          label: teamMember?.division?.prettyname,
          value: teamMember?.division?.id
        })
      }
      if (teamMember?.billableDivisionJobClassId) {
        handleJobClassChanged({
          label: teamMember?.billableDivisionJobClass?.appDisplayName,
          value: teamMember?.billableDivisionJobClass?.divisionPosition
        })
      }

      setTeamMember(teamMember)
      setTeamMemberEmail(teamMember?.loginEmail)
      setTeamMemberPhone(teamMember?.phoneNum||'')
      let pendingEmailAddress = (teamMember?.lognEmail==='pending') ? true : false
      setEmailIsPending(pendingEmailAddress)
      
      if (teamMember?.isERPUser===true) {
        getUsersGroups(teamMember.loginEmail).then((groups) => {
          // console.log('groups: ', groups)
          const groupNamesFormatted = groups?.map(group => formatGroupName(group?.GroupName)).join(', ');
          setTeamMemberGroups(groupNamesFormatted)
        })
      }
      
    }

    getTeamMember(teamMemberId)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ teamMemberType, teamMemberId, refreshIndex])

  const [checkboxLabels, setCheckboxLabels] = useState([]);
  const [checkedLabels, setCheckedLabels] = useState([]);
  useEffect(() => {
    const fetchCheckboxData = async () => {
      try {
        // Parse userPoolGroups and sort
        const fetchedCheckboxLabels = userPoolGroups?.map(group => group.GroupName).sort((a, b) => {
            if (a === 'issued_credit_card') return 1;
          if (b === 'issued_credit_card') return -1;
          return 0;
        });
  
        console.log('Fetched all checkbox labels: ', fetchedCheckboxLabels);
        const sortedLabels = fetchedCheckboxLabels.sort((a, b) => a.localeCompare(b));
        setCheckboxLabels(sortedLabels);
  
        // Fetch and parse user groups if loginEmail is present
        if (teamMember.loginEmail && teamMember.isERPUser===true) {
          const userEmail = teamMember.loginEmail;  
          const response = await fetchCognitoUserGroupsByUser(userEmail);
  
          if (!Array.isArray(response)) {
            throw new Error('Parsed response is not an array');
          }
  
          const fetchedCheckedLabels = response.map(group => group.GroupName);
  
          setIsIssuedCreditCard(fetchedCheckedLabels.includes('issued_credit_card'));
          setValue('issuedCreditCard', fetchedCheckedLabels.includes('issued_credit_card'));
          setGroupsSelected(fetchedCheckedLabels.filter(group => group !== 'issued_credit_card'));
          setCheckedLabels(fetchedCheckedLabels);
        }
      } catch (error) {
        console.error('Error fetching checkbox data:', error);
      }
    };
  
    if (userPoolGroups?.length > 0) {
      fetchCheckboxData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMember, userPoolGroups]);

  const [ checkedGroups, setCheckedGroups ] = useState(['team_member']);
  const handleGroupOnChange = (label) => {
    console.log('label:', label); // Logging the current label clicked
    setFormIsUpdated(true)
    setCheckedLabels(prevCheckedLabels => {
        const currentIndex = prevCheckedLabels.indexOf(label);
        let newCheckedLabels = [...prevCheckedLabels]; // Copying the current state

        console.log('currentIndex:', currentIndex); // Index of the current label
        console.log('prevCheckedLabels:', prevCheckedLabels); // State before any changes

        if (currentIndex === -1) {
            newCheckedLabels.push(label); // Adding the label if it's not found
        } else {
            newCheckedLabels.splice(currentIndex, 1); // Removing the label if found
        }

        // Ensure "team_member" is always included
        if (!newCheckedLabels.includes("team_member")) {
            newCheckedLabels.push("team_member");
        }

        setGroupsSelected(
            newCheckedLabels.filter(group => group !== 'issued_credit_card')
        );

        setIsIssuedCreditCard(newCheckedLabels.includes('issued_credit_card'));
        setValue('issuedCreditCard', newCheckedLabels.includes('issued_credit_card'));

        console.log('newCheckedLabels:', newCheckedLabels); // New state after changes
        setCheckedGroups(newCheckedLabels);
        return newCheckedLabels; // Returning the new state
    });
  };

  const getUniqueMenuItemIdsByGroupNames = (groupMenuItems, groupNames) => {
    if (!groupMenuItems || !Array.isArray(groupMenuItems.items)) {
        console.warn('No group menu items found or invalid structure');
        return [];
    }

    // Continue processing if the structure is correct
    const filteredItems = groupMenuItems.items.filter(item =>
        groupNames.includes(item.cognitoGroup.name)
    );

    return [...new Set(filteredItems.map(item => item.menuItemId))];
  };

  const [ uniqueMenuItems, setUniqueMenuItems ] = useState([]);

  useEffect(() => {
    if (allGroupMenuItems && Array.isArray(allGroupMenuItems.items) && checkedGroups.length > 0) {
        const uniqueMenuItemIds = getUniqueMenuItemIdsByGroupNames(allGroupMenuItems, checkedGroups);
        setUniqueMenuItems(uniqueMenuItemIds);
        console.log('uniqueMenuItemIds:', uniqueMenuItemIds);
    } else {
        console.log('Skipping processing; `allGroupMenuItems` or `checkedGroups` are not ready.');
    }
  }, [checkedGroups, allGroupMenuItems]);

  async function updateNavMenuItemsForTeamMember(
    tmId,                  // Team member's unique ID
    newNavMenuIds,         // Array of new nav menu IDs to assign to the user
    usersCurrentNavItems   // Array of the user’s current nav menu items
  ) {
    console.log('tmId: ', tmId);
    console.log('newNavMenuIds: ', newNavMenuIds);
    console.log('usersCurrentNavItems: ', usersCurrentNavItems);

    // Step 1: Extract the current navMenuIds for this user
    const currentNavMenuIds = usersCurrentNavItems.map(record => record.navMenuId);

    // Step 2: Determine which items to create and which to delete
    const toDelete = currentNavMenuIds.filter(id => !newNavMenuIds.includes(id));
    console.log('toDelete: ', toDelete);
    const toCreate = newNavMenuIds.filter(id => !currentNavMenuIds.includes(id));
    console.log('toCreate: ', toCreate);

    // Step 3: Delete records not in newNavMenuIds
    await Promise.all(
      usersCurrentNavItems
        .filter(record => toDelete.includes(record.navMenuId))
        .map(record =>
            removeNavMenuUser({ id: record.id })
        )
    );

    // Step 4: Create records for newNavMenuIds
    await Promise.all(
      toCreate.map(navMenuId =>
        addNavMenuUser({
          tmId,
          navMenuId,
          isActive: true
        })
      )
    );
  }

  updateNavMenuItemsForTeamMember.PropTypes = {
    tmId: PropTypes.string.isRequired,
    newNavMenuIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    usersCurrentNavItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  
  const ProfileCard = ({ teamMember }) => {  
    return (
      <Flex alignItems="center">
        <Avatar border='1px solid var(--dark-component-border)' mr='25px' bg='black' color='var(--dark-gold-primary)' size='xl' name={`${teamMember?.firstName} ${teamMember?.lastName}`} src={teamMember?.imgUrl} />
        <Box w={'700px'}>
          <Text w={'700px'} textStyle='heading-2'>{teamMember?.firstName} {(teamMember?.goesBy) && `"${teamMember?.goesBy}"`} {(teamMember?.middleName) && teamMember?.middleName} {teamMember?.lastName}  </Text>
          <Grid templateColumns='repeat(3, 1fr)' pt={'15px'} gridColumnGap="20px">
            <Flex pb={'5px'} alignItems="center" w={'195px'} >
              <Text mr='5px' textStyle='text-2'>Status:</Text>
              <Text mr='2px' textStyle='text-1'>{(teamMember?.isActive===true) ? 'Active' : 'Inactive'}</Text>
              {(teamMember?.isActive===true) ? 
                <AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'fillcheckcircle'} color={'var(--success-green)'}/> : 
                <AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'tmoff'} color={'var(--error-red)'}/>
              }
            </Flex>
            <Flex pb={'5px'} alignItems="center">
              {(teamMember?.loginEmail) && <><AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'email'} color={'var(--dark-text-grey-1)'}/>
              <Text mr='5px' textStyle='text-2'>{teamMember?.loginEmail}</Text></>}
            </Flex>
            <Box></Box>
            <Flex alignItems="center">
              {/* <Text mr='5px' textStyle='text-2'>Global Admin:</Text><Text mr='2px' textStyle='text-1'>{(teamMember?.isGlobalAdmin===true) ? 'Yes' : 'No' || '\u00A0'}</Text> */}
            </Flex>
            <Flex alignItems="center">
              {(teamMember?.phoneNum) && <><AvailableIcons mr={'5px'} boxSize={'22px'} iconRef={'phone'} color={'var(--dark-text-grey-1)'}/>
              <Text mr='5px' textStyle='text-2'>{teamMember?.phoneNum}</Text></>}
            </Flex>
          </Grid>
        </Box>
      </Flex>
    );
  };

  ProfileCard.propTypes = {
    teamMember: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      goesBy: PropTypes.string, // Optional
      loginEmail: PropTypes.string.isRequired,
      phoneNum: PropTypes.string,
      imgUrl: PropTypes.string,
    }).isRequired,
  };
  
  const PersonalInfoCard = ({ teamMember }) => {
  
    const InfoSection = ({ title, content }) => (
      <Flex justify="space-between" align="center" my={2}>
        <Box>
          <Text textStyle={'text-8'}>{title}</Text>
          <Text textStyle={'text-1'}>{content||' '}</Text>
        </Box>
      </Flex>
    );

    InfoSection.propTypes = {
      title: PropTypes.string,
      content: PropTypes.string,
    };
  
    return (
      <Box p={6} rounded="md" shadow="base">
        <Flex direction={['column', 'row']} justify="space-between">
          <Box flex="1">
            <InfoSection title="First Name" content={teamMember?.firstName} />
            <InfoSection title="Middle Name" content={teamMember?.middleName || '\u00A0'} />
            <InfoSection title="Last Name" content={teamMember?.lastName} />
            <InfoSection title="Goes By" content={teamMember?.goesBy || '\u00A0'} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Address" content={teamMember?.addStreet || '\u00A0'} />
            <InfoSection title="City" content={teamMember?.addCity || '\u00A0'} />
            <InfoSection title="State" content={teamMember?.addState || '\u00A0'} />
            <InfoSection title="Zip code" content={teamMember?.addZipcode || '\u00A0'} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Date of Birth" content={spellOutDate(teamMember?.dob) || '\u00A0'} />
            <InfoSection title="Phone Number" content={teamMember?.phoneNum} />
            <InfoSection title="Employee Number" content={teamMember?.employeeNumber} />
          </Box>
        </Flex>
      </Box>
    );
  };

  PersonalInfoCard.propTypes = {
    teamMember: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      goesBy: PropTypes.string,
      phoneNum: PropTypes.string,
      dob: PropTypes.string,
      addStreet: PropTypes.string,
      addCity: PropTypes.string,
      addState: PropTypes.string,
      addZipcode: PropTypes.string,
      emergcyContName: PropTypes.string,
      emergcyContPhone: PropTypes.string,
      emergcyContRelation: PropTypes.string,
      employeeNumber: PropTypes.string,

    }).isRequired,
  };
  
  const WorkInfoCard = ({ teamMember }) => {
    if (!teamMember) return
  
    const InfoSection = ({ title, content }) => (
      <Flex justify="space-between" align="center" my={2}>
        <Box>
          <Text textStyle={'text-8'}>{title}</Text>
          <Text textStyle={'text-1'}>{content}</Text>
        </Box>
      </Flex>
    );

    InfoSection.propTypes = {
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    };
  
    return (
      <Box p={6} rounded="md" shadow="base">
        <Flex direction={['column', 'row']} justify="space-between">
          <Box flex="1">
            <InfoSection title="Division" content={teamMember?.division?.prettyname || '\u00A0'} />
            <InfoSection title="Job Class" content={teamMember?.billableDivisionJobClass?.appDisplayName || '\u00A0'} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Date of Hire" content={spellOutDate(teamMember?.hireDate) || spellOutDate(teamMember.startDate) || '\u00A0'} />
            <InfoSection title="Employment Status" content={(teamMember?.isActive) ? 'Active' : 'Inactive'} />
            <InfoSection title="Groups" content={teamMemberGroups} />
          </Box>
          <Divider orientation="vertical" mx={6} />
          <Box flex="1">
            <InfoSection title="Created On" content={spellOutDate(teamMember?.createdAt?.split('T')[0]) || '\u00A0'} />
            <InfoSection title="Last Updated" content={spellOutDate(teamMember?.updatedAt?.split('T')[0]) || '\u00A0'} />
            <InfoSection title="Is Global Admin" content={(teamMember?.isGlobalAdmin===true) ? 'Yes' : 'No' || '\u00A0'} />
          </Box>
        </Flex>
      </Box>
    );
  };

  WorkInfoCard.propTypes = {
    teamMember: PropTypes.shape({
      division: PropTypes.shape({
        prettyname: PropTypes.string,
      }),
      billableDivisionJobClass: PropTypes.shape({
        appDisplayName: PropTypes.string,
      }),
      badgeNumber: PropTypes.string,
      hireDate: PropTypes.string,
      startDate: PropTypes.string,
      isActive: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      isGlobalAdmin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]),
    }).isRequired,
  };

  const [formEnabled, setFormEnabled] = useState({
    "personal": false,
    "spike": false
  });

  const enableUpdateForm = (section, value) => {
    if (!section && !value) return
    // Update state based on the section
    setFormEnabled(prevState => ({
      ...prevState, 
      [section]: value||false
    }));
  }

  const updateCognitoAccount = async (oldEmailAddress, newEmailAddress, newPhoneNumber, groupNames, allUserGroupsArray) => {
    console.info('Updating Cognito account...');
    // update groups using groupNames
    await manageUserGroups(oldEmailAddress, groupNames, allUserGroupsArray);

    const params = {
      "oldEmailAddress": oldEmailAddress,
      "newEmailAddress": newEmailAddress,
    }
    if (newPhoneNumber) {
      params.newPhoneNumber = newPhoneNumber
    }

    const response = await updateCognitoUser(params);

    try {
      if (response?.statusCode === 200) {
        return true;
      }
      if (response?.statusCode === 400) {
        const errorMessage = JSON.parse(response?.body)?.errorMessage
        console.log({errorMessage})
        return false
      }
    } catch (error) {
      console.error("Failed to update Cognito user:", error);
      return false;
    }
  }

  const createCognitoAccount = async (emailAddress, phoneNumber) => {
    const user = {
      "emailAddress": emailAddress,
      "phoneNumber": phoneNumber,
    }

    console.log('creating cognito account with: ', user)

    const response = await createCognitoUser(user);

    try {
      if (response?.statusCode === 200) {
        return true;
      }
      if (response?.statusCode === 400) {
        const errorMessage = JSON.parse(response?.body)?.errorMessage
        console.log({errorMessage})
        return false
      }
    } catch (error) {
      console.error("Failed to create Cognito user:", error);
      return false;
    }
  }

  async function createAndSetupUser(userAccount, userPhone, groupNames, allUserGroupsArray) {
    try {
      setSubmitModalContent('Creating user account...');
      setSubmissionProgress(20);
    
      // ----- COGNITO ----- //
      const created = await createCognitoAccount(userAccount, userPhone);
      if (!created) throw new Error('Failed to create user account.');
    
      setSubmitModalContent('Adding user to groups...');
      console.info('About to add user to groups...: ', groupNames)
      await manageUserGroups(userAccount, groupNames, allUserGroupsArray);

    } catch (error) {
      console.error('Error creating user account:', error);
      setSubmitModalContent('Error creating user account.');
      setSubmissionProgress(100);
      setSubmitModalCloseDisabled(false);
      return
    }
  }

  async function manageUserGroups(userAccount, groupNames, allUserGroupsArray) {
    console.info('userAccount: ', userAccount)
    console.info('groupNames: ', groupNames)
    console.info('allUserGroupsArray: ', allUserGroupsArray)
    try {
      // Calculate which groups need to be removed
      // Make sure we're using group names for comparison
      const currentGroups = allUserGroupsArray.map(group => 
        typeof group === 'string' ? group : group.GroupName
      );
      const groupsToRemove = currentGroups.filter(group => !groupNames.includes(group));
      
      setSubmitModalContent(`Managing user groups: ${groupNames.length} to add, ${groupsToRemove.length} to remove...`);
      setSubmissionProgress(40);
      
      let progress = 40;
      const progressIncrement = 50 / (groupNames.length + groupsToRemove.length);
      
      // Add user to new groups
      for (const groupName of groupNames) {
        try {
          setSubmitModalContent(`Adding user to group...`);
          setSubmitModalContent(`${groupName}`);
          const result = await updateCognitoUsersGroups({
            userName: userAccount,
            groupName,  // Already a string
            groupAction: 'add'
          });
          console.log(`Added user to group...`);
          console.log(`${groupName}:`, result);
          progress += progressIncrement;
          setSubmissionProgress(Math.min(90, Math.floor(progress)));
        } catch (error) {
          console.error(`Failed to add user to group ${groupName}:`, error);
          setSubmitModalContent(`Failed to add user to group ${groupName}: ${error.message}`);
          continue;
        }
      }
      
      // Remove user from old groups
      for (const groupName of groupsToRemove) {
        try {
          // Extract group name if it's an object
          const actualGroupName = typeof groupName === 'string' ? groupName : groupName.GroupName;
          setSubmitModalContent(`Removing user from group...`);
          setSubmitModalContent(`${actualGroupName}`);
          const result = await updateCognitoUsersGroups({
            userName: userAccount,
            groupName: actualGroupName,  // Make sure we're passing just the name
            groupAction: 'remove'
          });
          console.log(`Removed user from group:`);
          console.log(`${actualGroupName}:`, result);
          progress += progressIncrement;
          setSubmissionProgress(Math.min(90, Math.floor(progress)));
        } catch (error) {
          console.error(`Failed to remove user from group ${groupName}:`, error);
          setSubmitModalContent(`Failed to remove user from group ${groupName}: ${error.message}`);
          continue;
        }
      }
      
      setSubmitModalContent('Finished updating user groups');
      setSubmissionProgress(100);
      setSubmitModalCloseDisabled(false);
      
      return true;
  
    } catch (error) {
      console.error('Error managing user groups:', error);
      setSubmitModalContent(`Error managing user groups: ${error.message}`);
      setSubmissionProgress(100);
      setSubmitModalCloseDisabled(false);
      throw error;
    }
  }

  const formSubmit = async (values, event) => {
    if (event.key === 'Enter' || event.type === 'submit') {
        console.log('Prevent form from submitting/reloading the page');
        event.preventDefault();
    }

    onSubmitModalOpen();
    setSubmitModalContent('Processing team member data...')
    setSubmissionProgress(10)

    const groupsSelectedSafe = groupsSelected || [];
    const issuedCreditCardSafe = isIssuedCreditCard ?? false;

    let memberOfGroups = [...groupsSelectedSafe];

    // Append "issued_credit_card" if issuedCreditCard is true
    if (issuedCreditCardSafe) {
        memberOfGroups = [...memberOfGroups, "issued_credit_card"];
    }

    let userIsGlobalAdmin = false;
    if (memberOfGroups.includes('exec_admin')) {
      userIsGlobalAdmin = true;
    }

    const billableDivisionJobClassId = values?.selectJobClass?.value;
    const userLogin = values?.userAccount;

    const teamMemberData = {
      id: teamMember.id,
      loginEmail: userLogin,
      isERPUser: isERPUserActive||false,
      isOnboarded: true,
      billableDivisionJobClassId: billableDivisionJobClassId,
      memberOf: memberOfGroups,
      isGlobalAdmin: userIsGlobalAdmin,
    };

    try {
      // Update SpkTeamMemberTbl
      await updateTeamMemberAdministrative(teamMemberData);
      console.info('Team member data updated successfully');
    } catch (error) {
      console.error('Failed to update team member data:', error);
    }

    // Either create or update the Cognito account for the user.
    console.warn('about to step into create/update cognito.');
    console.log('cognitoAccountExists: ', cognitoAccountExists);
    console.log('isUpdatingExistingCognitoUser: ', isUpdatingExistingCognitoUser);
    try {
      console.warn('now in create/update cognito.');
      // if this user was already inCognito then we are updating.
      if (!cognitoAccountExists && !isUpdatingExistingCognitoUser) { 
        console.warn('no cognito account and not updating existing cognito user - creating creating cognito account')
        await createAndSetupUser(userLogin, teamMemberPhone, memberOfGroups, userPoolGroups);
        console.info('User created and set up successfully');
       
      } else {
        console.warn('cognito account exists or updating existing cognito user - updating cognito account')
        // Update the user's email address and phone number
        // will need for admin to be able to update only phone number when that changes in iSolved.
        await updateCognitoAccount(teamMember.loginEmail, userLogin, teamMemberPhone, memberOfGroups, userPoolGroups);
        console.info('User account updated successfully');
      }
    } catch (error) {
      console.error('Error creating and setting up user:', error);
    }

    // Update the users nav menu items
    try {
      await updateNavMenuItemsForTeamMember(
        teamMember.id,
        uniqueMenuItems,       // Array of new nav menu IDs
        teamMembersNavMenuItems // Now properly formatted with `id` and `navMenuId`
      );
      console.info('Team member navigation updated successfully: ', uniqueMenuItems.toString());
    } catch (error) {
      console.error('Error updating team member navigation:', error);
    }

    setSubmissionProgress(100)
    setSubmitModalCloseDisabled(false)
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const handleDivisionChanged = async (option) => {
    if (!option) return;

    setSelectedJobClass(null);
    setValue('selectDivision', option);
    clearErrors(['selectDivision'])
    setSelectedDivision(option)

    console.warn('WE HAVE DIVISION, NOW SETTING JOB CLASS OPTIONS: ', option?.value)

    const jobClassRates = await updateJobClassRates(option?.value);
    const activePositionsOptions = jobClassRates
    .filter(jobClassRate => jobClassRate?.isActive && !jobClassRate?.isHiddenByDefault)
    .sort((a, b) => a.hourlyRate.localeCompare(b.hourlyRate))
    .map(jobClassRate => {
      const id = jobClassRate.divisionPosition
      const displayName = jobClassRate.appDisplayName;
      return {
        value: id,
        label: displayName,
      };
    });

    setJobClassOptions(activePositionsOptions);
  }

  const updateJobClassRates = async (divisionId) => {
    try {
      const jobClassRates = await fetchJobClassRatesByDivisionId(divisionId);
      console.warn('----- jobClassRates: ', jobClassRates)
      return jobClassRates;
    } catch (error) {
      console.error('Failed to fetch job class rates:', error);
    }
  }

  const handleJobClassChanged = (option) => {
    if (!option) return;
    if (selectedJobClass) {
      if (selectedJobClass.value !== option.value) {
        setFormIsUpdated(true)
      }
    }

    setSelectedJobClass(option)
    setValue('selectJobClass', option);
    clearErrors(['selectJobClass'])
  }

  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };


  const [ validatedEmailAddress, setValidatedEmailAddress ] = useState('')
  const [ isUpdatingExistingCognitoUser, setIsUpdatingExistingCognitoUser ] = useState(false)
  const validateEmail = async (event) => {
    if (!event) return;
    
    const emailInput = event.target.value||'';
    let hasError = false;
    let errorMessage = '';
    
    const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    function checkUserEmailExists(emailToCheck) {
      console.log('currentCognitoUsers: ', currentCognitoUsers)
      return currentCognitoUsers.some(user => 
        user.Attributes.some(attribute => 
          attribute.Name === "email" && attribute.Value.toLowerCase() === emailToCheck.toLowerCase()
        )
      );
    }

    const currentLoginEmailExistsInCognito = checkUserEmailExists(teamMemberEmail)
    console.log('currentLoginEmailExistsInCognito: ', currentLoginEmailExistsInCognito)
    if (currentLoginEmailExistsInCognito) {
      setIsUpdatingExistingCognitoUser(true)
    }

    if (emailRegEx.test(emailInput)) {
      const alreadyExists = checkUserEmailExists(emailInput)
      console.log('alreadyExists: ', alreadyExists)
      console.log('emailInput: ', emailInput)

      if (alreadyExists && !currentLoginEmailExistsInCognito) {
        setCognitoAccountExists(true)
        if (teamMemberEmail!==validatedEmailAddress) {
          hasError = true;
          errorMessage = "This email login is already in use. Email addresses must be unique for each team member to log in.";
        }
      } else {
        setCognitoAccountExists(false)
      }
    } else {
      hasError = true;
      errorMessage = "This is not a valid email address";
    }
    
    if (hasError) {
      setValue('userAccount', '')
      setError("userAccount", {
        type: "manual",
        message: errorMessage
      });
    } else {
      clearErrors('userAccount');
    }
    
    setValidatedEmailAddress(emailInput)
    if (teamMemberEmail!==emailInput) {
      setFormIsUpdated(true)
    } else {
      setFormIsUpdated(false)
    }

    return
  }

  useEffect(() => {
    if (teamMemberEmail) {
      validateEmail({target: {value: teamMemberEmail}})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[teamMemberEmail])

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>
      
      <ModuleBoxDrawer>
        <Flex>
          {(teamMember.firstName) && <ProfileCard teamMember={teamMember} />}
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>
      </ModuleBoxDrawer>

      <Box my={'25px'} />

      <ModuleBoxDrawer>
          <Box>
            <Flex alignItems="center">
              <Text textStyle='heading-1'>Personal Information</Text>
            </Flex>
          </Box>

          <PersonalInfoCard teamMember={teamMember} />
          
      </ModuleBoxDrawer>

      <Box my={'25px'} />

      <ModuleBoxDrawer>
        <Box>
          <Flex alignItems="center">
            <Text textStyle='heading-1'>Spike Information</Text>
          </Flex>
        </Box>
        <WorkInfoCard teamMember={teamMember} />
      </ModuleBoxDrawer>

      <Box my={'25px'} />
      
      <ModuleBoxDrawer>
        <Box>
          <Flex alignItems="center">
            <Text textStyle='heading-1'>ERP Information</Text>
          </Flex>
        </Box>

        <>
          <Box w={'40%'} my={'25px'}>
            <Flex>
              <Box>
                <Text as="span" textStyle='label-1'>{(adminIsLocked) ? 'This administrator cannot be deactivated.' : 'Team Member is Currently Active'}</Text><br/>
              </Box>
            </Flex>
          </Box>

          <Box w={'40%'} my={'25px'}>
            <Flex>
              <Box>
                <Text as="span" textStyle='label-1'>{(!teamMemberPhone) && 'Access to ERP requires a cell phone number.'}</Text><br/>
              </Box>
              <Box>
                <Text as="span" textStyle='label-1'>{(isERPUserActive) ? 'User has access to ERP.' : 'Access to ERP is currently disabled.'}</Text><br/>
              </Box>
              <Spacer />
              <Box>
                <FormSwitch
                  register={register}
                  control={control}
                  fieldname="isERPUserActive"
                  isChecked={isERPUserActive}
                  onChange={() => {
                    setValue('isERPUserActive', !isERPUserActive)
                    setIsERPUserActive(!isERPUserActive)
                    setFormIsUpdated(true)
                  }}
                  isDisabled={emailIsPending || !teamMemberPhone}
                />
              </Box>
            </Flex>
          </Box>

          {(isERPUserActive) && <>
            {(isolvedEmailAddress) && 
              <Box w={'40%'} my={'25px'}>
                <Text as="span" textStyle='label-1'>iSolved Email: {isolvedEmailAddress}</Text>
                <ButtonCopyClipboardWithIcon 
                  mx={'15px'}
                  my={'15px'}
                  text={isolvedEmailAddress}
                  name='copy'
                  iconsize='22px'
                />
              </Box>
            }

            {(teamMemberEmail) && 
              <Box w={'40%'} my={'25px'}>
                <Text as="span" textStyle='label-1'>ERP Email Login: {teamMemberEmail}</Text>
              </Box>
            }

            <Box w={'40%'}>
              <FormControl isInvalid={errors.userAccount}>
                {!currentCognitoUsers?.length>0 ? (
                  <FormLabel htmlFor='userAccount'>Fetching All Team Member Email Accounts...</FormLabel>
                ) : (
                  <FormLabel htmlFor='userAccount'>Team Member Login Email {errors?.userAccount?.message}</FormLabel>
                )}
                <Skeleton isLoaded={currentCognitoUsers?.length>0}>
                  <TextInput
                    register={register}
                    errors={errors}
                    fieldtype="text"
                    fieldname="userAccount"
                    prettyname="Login Email"
                    fieldvalue={validatedEmailAddress||''}
                    placeholder="Enter team member email address"
                    isRequired={true}
                    onChange={validateEmail}
                  />
                </Skeleton>
              </FormControl>
            </Box>

            <Box w={'40%'} my={'25px'}>
              <Text as="span" textStyle='label-2'>Cell Phone: {teamMemberPhone}</Text>
            </Box>

            <Box w={'40%'} my={'25px'}>
              <Text as="span" textStyle='label-2'>Division: {selectedDivision?.label}</Text>
            </Box>

            <Box w={'40%'} my={'25px'}>

              <Box w={'40%'} my={'25px'}>
                <FormSelectSimple
                  key='select_jobclass'
                  register={register}
                  control={control}
                  errors={errors}
                  isRequired={true}
                  rules={{ required: "Job Class is required" }}
                  fieldname="selectJobClass"
                  fieldlabel="Select Default Job Class"
                  placeholder={'Select job class or start typing'}
                  optionsArray={jobClassOptions?.map(option  => ({
                    label: option.label,
                    value: option.value,
                  }))}
                  onChange={(e) => handleJobClassChanged(e)}
                  selectedoption={selectedJobClass||''}
                  isDisabled={!selectedDivision}
                />
              </Box>
              
              <TextInput
                register={register}
                errors={errors}
                fieldname="issuedCreditCard"
                value={isIssuedCreditCard}
                fieldtype="hidden"
              />
              
              <Stack 
                paddingBottom={'25px'} 
                direction={['row', 'column']}>
                <Box paddingBottom={'10px'}>
                  <Text as="span" textStyle='label-2'>Team Member Groups</Text>
                </Box>
                {checkboxLabels.map((label) => {
                  if (label==='issued_credit_card') {
                    return (
                      <Box key={label}>
                        <Checkbox 
                          paddingBottom={'10px'}
                          key={label}
                          name={label}
                          borderColor='spikeorange.300'
                          colorScheme='spikeorange'
                          isChecked={checkedLabels.includes(label)}
                          onChange={() => handleGroupOnChange(label)}
                        >
                          Team member has been issued a credit card
                        </Checkbox>
                      </Box>
                    )
                  } else {
                    if (label!=='team_member') {
                      return (
                        <Checkbox 
                          paddingBottom={'10px'}
                          key={label}
                          name={label}
                          borderColor='spikeorange.300'
                          colorScheme='spikeorange'
                          isChecked={checkedLabels.includes(label)}
                          onChange={() => handleGroupOnChange(label)}
                        >
                          {formatGroupName(label)}
                        </Checkbox>
                      )
                    }
                  }
                })}
                <Checkbox 
                  key={'team_member'}
                  name={'team_member'}
                  borderColor='spikeorange.300'
                  colorScheme='spikeorange'
                  isChecked={true}
                >
                  Team Member (All ERP users are automatically assigned to this group)
                </Checkbox>
              </Stack>
            </Box>

          </>}

          <HStack my={'25px'}>
            <Box w={'40%'}>
              <ButtonSecondaryPlain 
                width={'100%'}
                name='cancel'
                value='Cancel'
                onClick={() => {
                  enableUpdateForm('spike', false)
                  enableUpdateForm('personal', false)
                  handleRefresh()
                }}
              />  
            </Box>

            <Box w={'60%'}>
              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                width={'100%'}
                name={(formEnabled.spike===true && formEnabled.personal===true) ? 'submit-convert' : 'submit-spike'}
                value={isSubmitting? 'Updating SPike Information' : 'Update Spike Information'}
                isDisabled={
                  !formIsUpdated
                  || !selectedDivision
                  || !selectedJobClass 
                }
              />
            </Box>
          </HStack>

        </>
      </ModuleBoxDrawer>
    </Container>

    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Team member has been created.'}
      closeButtonVisible={true}
    />
    <Box paddingBottom={'50px'} />
    </>
  );
};

EditTeamMember.propTypes = {
  srcId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};