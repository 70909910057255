import { fetchAuthSession } from 'aws-amplify/auth';

// // Configure the URL for the api query.
const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/getisolvedemployees';

export const getISolvedEmployees = async (props) => {
    const { createdById } = props;

    const params = { createdById };
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;

    const inputJSON = JSON.stringify(params);

    try {
        const response = await fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
            },
            body: inputJSON,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const data = await response.json(); // Quick response from Lambda
        console.log('Lambda response:', data);
        return data; // Returns confirmation message
        // return true;
    } catch (error) {
        console.error('Error triggering Lambda:', error);
        throw error;
    }
};

// export const getISolvedEmployees = async (props) => {

//     const {
//         createdById,
//     } = props
  
//     const params = {
//         "createdById": createdById
//     };

//     // Refresh the session to ensure it's current and to get the latest authentication tokens
//     const session = await fetchAuthSession();
//     const authToken = `Bearer ${session.tokens.idToken}`;
    
//     // Prepare the request payload
//     let inputJSON = JSON.stringify(params);  

//     try {
//         const response = await fetch(endpointUrl, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authToken,
//         },
//         body: inputJSON,
//         })
//         if (!response.ok) {
//             throw new Error('Network response was not ok: ' + response.statusText);
//         }
//         const data = await response.json();  // Parses the JSON body and waits for it
//         return data;  // Returns the data to the caller
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         throw error;  // Re-throw the error if you want to handle it further up the call stack
//     }

// };
