import { useEffect, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from "../AppContext.jsx";
import { DashboardLayout } from "./DashboardLayout"; 
import { fetchISolvedEmployeeSchedule } from '../iSolved/fetchISolvedEmployeeSchedule.jsx';
import { fetchiSolvedEmployeeByTeamMemberId } from '../graphqlCompnents/TimeAttendance/fetchiSolvedEmployeeScheduleByEmployeeId.jsx';

const moduleMapping = {
  credit_card_manager: 'CreditCardManagerModule',
  credit_card_receipt_manager: 'CreditCardReceiptManagerModule',
  exec_admin: 'ExecAdminModule',
  issued_credit_card: 'IssuedCreditCardModule',
  maintenance: 'MaintenanceModule',
  supervisor: 'SupervisorModule',
  team_member: 'TeamMemberModule',
};

const getCachedData = (key, defaultValue) => {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : defaultValue;
};

const setCachedDataInStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const transformScheduleData = (timecardData) => {
  if (!timecardData) return { punchEntries: [], totalHours: '0.00', weeklyHours: '0.00', mostRecentPunch: { date: '', time: '' } };
  console.log('timecardData: ', timecardData);
  
  const now = new Date();
  const dayOfWeek = now.getDay();
  const daysSinceMonday = (dayOfWeek + 6) % 7;
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - daysSinceMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  // inPunchId!==null
  // remove any records from timecardData where timecardData.inPunchId is null
  timecardData = timecardData.filter((punch) => punch.inPunchId !== null);

  // Sort timecardData by inPunchDateTimeEffective in descending order to get the most recent punch at the start
  const sortedData = [...timecardData].sort((a, b) => new Date(b.inPunchDateTimeEffective) - new Date(a.inPunchDateTimeEffective));

  let totalHours = 0;
  let weeklyHours = 0;
  const punchEntries = sortedData.map((day) => {
    const inTime = day.inPunchDateTimeEffective ? new Date(day.inPunchDateTimeEffective) : null;
    const outTime = day.outPunchDateTimeEffective ? new Date(day.outPunchDateTimeEffective) : null;
    const hoursWorked = inTime && outTime ? ((outTime - inTime) / (1000 * 60 * 60)).toFixed(2) : '0.00';

    if (inTime && outTime) {
      totalHours += parseFloat(hoursWorked);
      if (inTime >= startOfWeek) weeklyHours += parseFloat(hoursWorked);
    }

    // Format time and remove leading zero from the hour
    const formatTime = (date) =>
      date
        ? date
            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            .replace(/^0/, '') // Remove leading zero from hour if present
        : 'N/A';

    return {
      date: new Date(day.timecardDate).toISOString().split('T')[0], // Format to 'YYYY-MM-DD'
      punchIn: formatTime(inTime),
      punchOut: formatTime(outTime),
      hoursWorked,
    };
  });

  // Now the most recent punch is the first entry in sorted punchEntries
  const mostRecentPunch = punchEntries.length > 0 && punchEntries[0].punchIn !== 'N/A' ? {
    date: punchEntries[0].date,  // Already formatted as 'YYYY-MM-DD'
    time: punchEntries[0].punchIn, // Already formatted
  } : { date: '', time: '' };

  return {
    punchEntries,
    totalHours: totalHours.toFixed(2),
    weeklyHours: weeklyHours.toFixed(2),
    mostRecentPunch,
  };
};

export const Dashboard = () => {
  const { store } = useContext(AppContext);
  const currentTeamMemberId = store?.userData?.id ?? '';
  const unsubmittedReceiptCount = store?.userData?.receiptCount;

  const memoizedUserGroups = useMemo(() => store?.userData?.groups || [], [store?.userData?.groups]);
  const availableModules = useMemo(() => {
    return memoizedUserGroups.reduce((acc, group) => {
      if (moduleMapping[group]) acc.push(moduleMapping[group]);
      return acc;
    }, []);
  }, [memoizedUserGroups]);

  const [scheduleData, setScheduleData] = useState(null);
  
  // Initialize with default values to avoid undefined
  const [cachedData, setCachedData] = useState(() => getCachedData('dashboardPunchData', {
    totalHoursWorked: '0.00',
    currentWeekHours: '0.00',
    mostRecentPunchIn: { date: '', time: '' }
  }));

  useEffect(() => {
    const getISolvedEmployeeId = async () => {
      try {
        const employeeData = await fetchiSolvedEmployeeByTeamMemberId(currentTeamMemberId);
        if (employeeData && employeeData.employeeNumber) {
          await getISolvedEmployeeSchedule(employeeData.employeeNumber);
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    const getISolvedEmployeeSchedule = async (employeeNumber) => {
      try {
        console.log('employeeNumber: ', employeeNumber);
        const employeeSchedule = await fetchISolvedEmployeeSchedule(employeeNumber);
        console.log('employeeSchedule: ', employeeSchedule);
        setScheduleData(employeeSchedule);
      } catch (error) {
        console.error('Error fetching employee schedule:', error);
      }
    };

    if (currentTeamMemberId) {
      getISolvedEmployeeId();
    }
  }, [currentTeamMemberId]);

  useEffect(() => {
    if (scheduleData && scheduleData.length > 0) {
      console.log('scheduleData: ', scheduleData);
      const transformedData = transformScheduleData(scheduleData[0].timecardData);
      const newCachedData = {
        totalHoursWorked: transformedData.totalHours,
        currentWeekHours: transformedData.weeklyHours,
        mostRecentPunchIn: transformedData.mostRecentPunch?.date && transformedData.mostRecentPunch?.time 
          ? {
            date: transformedData.mostRecentPunch.date, // Already in 'YYYY-MM-DD' format
            time: transformedData.mostRecentPunch.time, // Already in 'HH:MM' format
          }
          : { date: '', time: '' },
      };
      console.log('newCachedData: ', newCachedData);
  
      setCachedData(newCachedData);
      setCachedDataInStorage('dashboardPunchData', newCachedData);
    }
  }, [scheduleData]);

  

  const transformedData = useMemo(
    () => transformScheduleData(scheduleData?.[0]?.timecardData || []),
    [scheduleData]
  );

  return (
    <>
      {/* Only render DashboardLayout if cachedData has values */}
      {cachedData && (
        <DashboardLayout 
          modules={availableModules} 
          totalHoursWorked={cachedData.totalHoursWorked}
          currentWeekHours={cachedData.currentWeekHours}
          mostRecentPunchIn={cachedData.mostRecentPunchIn}
          unsubmittedReceiptCount={unsubmittedReceiptCount}
          punchEntries={transformedData.punchEntries}
        />
      )}
    </>
  );
};

Dashboard.propTypes = {
  store: PropTypes.shape({
    userData: PropTypes.shape({
      isGlobalAdmin: PropTypes.bool,
      divisionId: PropTypes.string.isRequired,
    }),
  }),
};