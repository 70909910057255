import { allTeamMembersSortableByLastName } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const listAllTeamMembers = async (nextoken, limit=250) => {
  try {
    let gqlParams = {}
    if (nextoken!==undefined && nextoken!=='') {
      gqlParams = {
        allSort: "all",
        sortDirection: 'ASC',
        limit: limit,
        nextToken: nextoken
      }
    } else {
      gqlParams = {
        allSort: "all",
        sortDirection: 'ASC',
        limit: limit
      }
    }

    const response = await client
    .graphql({
      query: allTeamMembersSortableByLastName,
      variables: gqlParams
    })

    // console.info('response: ', response);

    // const filteredItems = response.data.allTeamMembersSortableByLastName.items.filter(
    //   item => item.isActive 
    //     && item.isERPUser 
    //     && item.isOnboarded 
    //     && item.isolvedEmail 
    //     // && item.billableDivisionJobClass.className!=='Pending Data'
    //   )

    // const results = {}
    // results.items = filteredItems
    // results.nextToken = response.data.allTeamMembersSortableByLastName.nextToken
    
    const results = response.data.allTeamMembersSortableByLastName

    return results;
  } catch (err) {
    return('listAllTeamMembers error: ', [err]);
  }

}

listAllTeamMembers.propTypes = {
  nextToken: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
};