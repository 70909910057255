export const onNewMessageWithUserTopic = /* GraphQL */ `
  subscription onNewMessageWithUserTopic(
    $filter: ModelSubscriptionSpkPubSubMessageTblFilterInput
  ) {
    onCreateSpkPubSubMessageTbl(filter: $filter) {
      id
      subject
      message
      messageJSON
      # from
      fromId
      sentAt
      topicId
      publisherId
      publisherType
      statusId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSpkPubSubMessage = /* GraphQL */ `
  subscription onCreateSpkPubSubMessage(
    $filter: ModelSubscriptionSpkPubSubMessageTblFilterInput
  ) {
    onCreateSpkPubSubMessageTbl(filter: $filter) {
      id
      subject
      message
      messageJSON
      fromId
      from {
        id
        loginEmail
        firstName
        middleName
        lastName
        goesBy
        imgUrl
        avatarId
      }
      sentAt
      topicId
      topic {
        id
        name
        nameCode
        categoryId
        category {
          id
          name
          desc
          code
          createdAt
          updatedAt
        }
        isActive
        createdAt
        updatedAt
      }
      publisherId
      publisherType
      statusId
      status {
        id
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpkPubSubMessage = /* GraphQL */ `
  subscription onUpdateSpkPubSubMessage(
    $filter: ModelSubscriptionSpkPubSubMessageTblFilterInput
  ) {
    onUpdateSpkPubSubMessageTbl(filter: $filter) {
      id
      subject
      message
      messageJSON
      fromId
      from {
        id
        loginEmail
        firstName
        middleName
        lastName
        goesBy
        imgUrl
        avatarId
      }
      sentAt
      topicId
      topic {
        id
        name
        nameCode
        categoryId
        category {
          id
          name
          desc
          code
          createdAt
          updatedAt
        }
        isActive
        createdAt
        updatedAt
      }
      publisherId
      publisherType
      statusId
      status {
        id
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrimaryUnitInspection = /* GraphQL */ `
  subscription onCreatePrimaryUnitInspection(
    $filter: ModelSubscriptionSpkPrimaryUnitInspectionTblFilterInput
  ) {
    onCreateSpkPrimaryUnitInspectionTbl(filter: $filter) {
      createdAt
      createdBy {
        firstName
        goesBy
        middleName
        lastName
      }
      createdById
      id
      inspectedBy {
        firstName
        goesBy
        middleName
        lastName
      }
      inspectedById
      statusId
      status {
        isAvailable
        status
      }
      timeToInspect
      unit {
        code
        subType {
          name
        }
        type {
          name
        }
      }
      unitId
    }
  }
`;
export const onCreateSpkISolvedFetchEmployeesStatusTbl = /* GraphQL */ `
  subscription OnCreateSpkISolvedFetchEmployeesStatusTbl(
    $filter: ModelSubscriptionSpkISolvedFetchEmployeesStatusTblFilterInput
  ) {
    onCreateSpkISolvedFetchEmployeesStatusTbl(filter: $filter) {
      id
      allSort
      startDateTime
      endDateTime
      createdById
      createdBy {
        firstName
        middleName
        lastName
        goesBy
      }
      lambdaStatus
      statusResults
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpkISolvedFetchEmployeesStatusTbl = /* GraphQL */ `
  subscription OnUpdateSpkISolvedFetchEmployeesStatusTbl(
    $filter: ModelSubscriptionSpkISolvedFetchEmployeesStatusTblFilterInput
  ) {
    onUpdateSpkISolvedFetchEmployeesStatusTbl(filter: $filter) {
      id
      allSort
      startDateTime
      endDateTime
      createdById
      createdBy {
        firstName
        middleName
        lastName
        goesBy
      }
      lambdaStatus
      statusResults
      createdAt
      updatedAt
      __typename
    }
  }
`;