import PropTypes from "prop-types";
import { Box, Text, HStack, VStack, Icon } from "@chakra-ui/react";
import { FiMapPin, FiCalendar, FiDroplet, FiTool } from "react-icons/fi";
import { ModuleBox } from "../Structural/ModuleBox";

// Reusable InfoBox component
const InfoBox = ({ icon, label, value, iconColor, subLabel = null }) => (
  <Box
    backgroundColor={'var(--dark-menu-background)'}
    borderRadius="6px" 
    p={'25px'}
    display="flex"
    justifyContent="space-between"
    alignItems="flex-start"
    minW="250px"
    minH="130px"
  >
    <VStack align="start" spacing={1}>
      <Text fontSize="sm" color="gray.400">
        {label}
      </Text>
      {subLabel && (
        <Text fontSize="xs" color="yellow.400">
          {subLabel}
        </Text>
      )}
      <Text fontSize="xl" fontWeight="bold" color="gray.200">
        {value}
      </Text>
    </VStack>
    <Icon as={icon} boxSize={8} color={iconColor} />
  </Box>
);

// Prop validation for InfoBox
InfoBox.propTypes = {
  icon: PropTypes.elementType.isRequired, // Ensures an icon component
  label: PropTypes.string.isRequired,    // Label for the box
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value can be a string or number
  iconColor: PropTypes.string.isRequired, // Color for the icon
  subLabel: PropTypes.string, // Optional sublabel (e.g., "This Month")
};

// Main UnitInfoDashBar component
export const UnitInfoDashBar = () => {
  return (
    <ModuleBox>
      <Box>
        {/* Header */}
        <Box paddingBottom={'25px'}>
          <Text as="span" textStyle='heading-1'>View Units</Text>
        </Box>

        {/* Info Boxes */}
        <HStack 
          spacing={'25px'} 
          // justify="space-between"
          >
          <InfoBox
            icon={FiMapPin}
            label="Location"
            value="Exxon"
            iconColor="yellow.400"
          />
          <InfoBox
            icon={FiCalendar}
            label="Last Inspected"
            value="Tues Jan 13"
            iconColor="blue.400"
          />
          <InfoBox
            icon={FiDroplet}
            label="Fuel This Month"
            value="$267.50"
            iconColor="green.400"
            subLabel={'This Month'}
          />
          <InfoBox
            icon={FiTool}
            label="Maintenance This Year"
            value="$184.20"
            iconColor="teal.400"
            subLabel={'This Year'}
          />
        </HStack>
      </Box>
    </ModuleBox>
  );
};

