/* eslint-disable react/prop-types */
import { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Center,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  IconButton,
  Tooltip,
  Flex,
  Spacer,
  Switch,
  Stack,
  HStack,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react'

// graphql components
import { fetchDJTParentalStatusById } from '../graphqlCompnents/DJT/fetchDJTParentalStatusById.jsx';
import { updateDailyJobTicket } from '../graphqlCompnents/DJT/updateDailyJobTicket.jsx';
import { getStatusIdByStatusCode } from '../graphqlCompnents/DJT/getStatusIdByStatusCode.jsx';
import { fetchLaborByDJTId } from '../graphqlCompnents/DJT/fetchLaborByDJTId.jsx';
import { updateDailyJobTicketAttachedLabor } from '../graphqlCompnents/DJT/updateDailyJobTicketAttachedLabor.jsx';
import { fetchEquipmentByDJTId } from '../graphqlCompnents/DJT/fetchEquipmentByDJTId.jsx';
import { updateDailyJobTicketAttachedEquipment } from '../graphqlCompnents/DJT/updateDailyJobTicketAttachedEquipment.jsx';
import { fetchMaterialByDJTId } from '../graphqlCompnents/DJT/fetchMaterialByDJTId.jsx';
import { updateDailyJobTicketAttachedMaterial } from '../graphqlCompnents/DJT/updateDailyJobTicketAttachedMaterial.jsx';
import { fetchDJTsByParentId } from '../graphqlCompnents/DJT/fetchDJTsByParentId.jsx';
import { addDJTJournal } from '../graphqlCompnents/DJT/addDJTJournal.jsx';
import { updateDJTDate } from '../graphqlCompnents/DJT/updateDJTDate.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';

// generic functions
import { truncateString } from '../functions/strings.jsx';
import { formatCurrency } from '../functions/currency.jsx';
import { delay } from '../functions/generic.jsx';
import { awsDateTimeNow, 
  spellOutDate, 
  adjustAndFormatDate, 
  formatDateFn, 
  todaysDateYMD } from '../functions/dateTime.jsx';

// spike forms
import { ViewDailyJobTicket } from './ViewDailyJobTicket.jsx';
import { CreateDailyJobTicket } from './CreateDailyJobTicket.jsx';

// form components
import { ButtonSecondaryPlainWithRef } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// modals
import { CenteredConfirmDeleteDJTAlert } from '../Modal/CenteredConfirmDeleteDJTAlert.jsx';
import { CenteredDeleteRestoreWorkingDJTModal } from '../Modal/CenteredDeleteRestoreWorkingDJTModal.jsx';

export const ResultsTableDailyJobTicketsByClientByDate = (props) => {
  const {
    djtSearchResults=null,
    loadMoreOnClick,
    loadMoreDisabled,
    handleRefresh,
    currentDivisionId,
    divisionHasOneClient,
    handleShowAllToggle,
    handleTabChange,
    showSearchResutlsTable,
    isReviewing,
    approvalRequiredCount,
    doShowAllSupervisorsSwitch=false,
    handleGeneratePdf,
    currentTab,
    // loadMoreRef,
    register,
    errors,
  } = props;

  console.log('currentDivisionId: ', currentDivisionId)


  const djtSearchResultsLength = djtSearchResults?.length
  let confirmDeleteAlertCloseDisabled = true;
  const [ hasConfirmedDelete , setHasConfirmedDelete ] = useState(false)

  const [ deleteRestoreWorkingModalContent, setDeleteRestoreWorkingModalContent ] = useState(null)
  const [ deleteRestoreWorkingModalCloseDisabled, setDeleteRestoreWorkingModalCloseDisabled ] = useState(true)
  const [ deleteRestoreWorkingModalProgress, setDeleteRestoreWorkingModalProgress ] = useState(0)
  const [ deleteRestoreActionType, setDeleteRestoreActionType ] = useState('delete')  // or delete||restore

  const loadMoreRef = useRef(null)

  const { isOpen: isViewDjtOpen , onOpen: onViewDjtOpen, onClose: onViewDjtClose } = useDisclosure()
  const { isOpen: isCreateDjtOpen , onOpen: onCreateDjtOpen, onClose: onCreateDjtClose } = useDisclosure()
  const { isOpen: isDuplicateDjtOpen , onOpen: onDuplicateDjtOpen, onClose: onDuplicateDjtClose } = useDisclosure()
  const { isOpen: isEditDjtOpen , onOpen: onEditDjtOpen, onClose: onEditDjtClose } = useDisclosure()

  const confirmDeleteCancelRef = useRef()
  const { isOpen: isConfirmDeleteAlertOpen , onOpen: onConfirmDeleteAlertOpen, onClose: onConfirmDeleteAlertClose } = useDisclosure()

  const { isOpen: isDeleteRestoreWorkingOpen , onOpen: onDeleteRestoreWorkingOpen, onClose: onDeleteRestoreWorkingClose } = useDisclosure()

  const [ deleteProps, setDeleteProps ] = useState(null)

  const [ approvalRequiredCallout, setApprovalRequiredCallout ] = useState(null)

  useEffect(() => {
    if (approvalRequiredCount>0) {
      setApprovalRequiredCallout(<Box mr={'2px'}><AvailableIcons boxSize={'13px'} iconRef={'fillcircle'} color={'var(--alert-red)'} /></Box>)
    } else {
      setApprovalRequiredCallout(null)
    }
  },[approvalRequiredCount])

  useEffect(() => {
    if (hasConfirmedDelete===true && deleteProps!==null) {
      handleDeleteDailyJobTicket(deleteProps)
      setHasConfirmedDelete(false)
      setDeleteProps(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hasConfirmedDelete, deleteProps])

  // refresh the search results after the drawer is closed.
  const [ closeAfterOpening, setCloseAfterOpening ] = useState(false)
  useEffect(() => {
    // if isOpen and closeAfterOpening = true then trigger, otherwise toggle closeAfterOpening = true
    if (!isDuplicateDjtOpen && closeAfterOpening) {
      handleRefresh()
      setCloseAfterOpening(false)
    } 
    (isDuplicateDjtOpen && !closeAfterOpening) && setCloseAfterOpening(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isDuplicateDjtOpen])

  useEffect(() => {
    // if isOpen and closeAfterOpening = true then trigger, otherwise toggle closeAfterOpening = true
    if (!isEditDjtOpen && closeAfterOpening) {
      handleRefresh()
      setCloseAfterOpening(false)
      // console.log('handleRefresh')
    } 
    (isEditDjtOpen && !closeAfterOpening) && setCloseAfterOpening(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isEditDjtOpen])

  useEffect(() => {
    // if isOpen and closeAfterOpening = true then trigger, otherwise toggle closeAfterOpening = true
    if (!isCreateDjtOpen && closeAfterOpening) {
      handleRefresh()
      setCloseAfterOpening(false)
      // console.log('handleRefresh')
    } 
    (isCreateDjtOpen && !closeAfterOpening) && setCloseAfterOpening(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isCreateDjtOpen])

  // character length of client and description text
  const clientTruncateValue = useBreakpointValue({
    sm: 15,
    md: 15,
    lg: 24,
    xl: 45,
    '2xl': 60
  })

  const descriptionTruncateValue = useBreakpointValue({
    sm: 33,
    md: 33,
    lg: 55,
    xl: 65,
    '2xl': 70
  })

  const [ djtId, setDjtId ] = useState(null)
  let djtParentId = null

  const handleOpenDrawer = (type, id) => {
    // id of djt to view, edit or duplicate
    // type of action: new djt, duplicate djt, view djt, edit djt

    // setDjtParentId(parentId)
    // setDjtIsParent(isParent)

    if (type==='new') {
      onCreateDjtOpen()
    }

    if (id) {
      if (type==='duplicate') {
        // setDjtParentId(parentId)
        // setDjtIsParent(false)
        // setDjtId(id)
        setDjtId(id, onDuplicateDjtOpen())
      }
  
      if (type==='view') {
        setDjtId(id, onViewDjtOpen())
      }

      if (type==='edit') {
        // setDjtIsParent(isParent)
        setDjtId(id, onEditDjtOpen())
      }
    }
    
  }

  // VERIFY WE ARE SENDING PARENT ID IF NEEDED
  useEffect(() => {
    (djtParentId && djtId) && onDuplicateDjtOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[djtParentId, djtId])
  
  const CustomCloseDrawerButton = (type) => {
    let closeDrawer = null
    if (type==='new') {
      closeDrawer = onCreateDjtClose
    }
    if (type==='view') {
      closeDrawer = onViewDjtClose
    }
    if (type==='duplicate') {
      closeDrawer = onDuplicateDjtClose
    }
    if (type==='edit') {
      closeDrawer = onEditDjtClose
    }

    return(
      <IconButton 
        variant={'icononlybutton'}
        icon={<AvailableIcons boxSize={'22px'} iconRef={'close'} />} 
        onClick={closeDrawer}
      />
    )
  }

  const handleDeleteDailyJobTicket = async (props) => {
    
    const {id, teamMember, division, client} = props
    if (!id || !teamMember || !division || !client) return
    
    if (!hasConfirmedDelete) {
      setDeleteProps(props, onConfirmDeleteAlertOpen())
      return
    }


    setToolbarDisabled(true)
    setButtonsDisabled(true)
    setDeleteRestoreActionType('delete')
    setDeleteRestoreWorkingModalProgress(10)
    setDeleteRestoreWorkingModalContent('Gathering tickets to delete...')
    setDeleteRestoreWorkingModalCloseDisabled(true)
    const {
      parentId,
      isDuplicate,
      duplicateOfId,
      isParent,
    } = await fetchDJTParentalStatusById(id)

    // console.log(' >>>>> delete : ', {parentId, isDuplicate, duplicateOfId, isParent})
    // previously saved djt's that have been one-off submitted, still need to show up here, just as disabled except view button

    // determine what type of deletion we are doing
    // options are : 
    //      STANDALONE -            parentId:  "",      isDuplicate:  false,  duplicateOfId:  null,     isParent:  false
    //      STANDALONE CHILD -      parentId:  !empty,  isDuplicate:  true,   duplicateOfId:  !empty,   isParent:  !empty
    //      PARENT WITH CHILDREN -  parentId:  "",      isDuplicate:  false,  duplicateOfId:  null,     isParent:  true

    let djtType = null

    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === false) {
      djtType = 'standalone'
      // update djtCurrentStatus and isActive
      setDeleteRestoreWorkingModalContent('Ticket is standalone.')
      // await delay(300)
    }

    if (parentId !== '' && isDuplicate === true && duplicateOfId !== '' && isParent !== '') {
      djtType = 'standalone_child'
      // update djtCurrentStatus and isActive
      setDeleteRestoreWorkingModalContent('Ticket is a standalone child.')
      // await delay(300)
    }
    
    if (parentId !== '' && isDuplicate === false && duplicateOfId !== '' && isParent === true) {
      // standalone parent
      djtType = 'standalone'
      // update djtCurrentStatus and isActive
      setDeleteRestoreWorkingModalContent('Ticket is a standalone parent.')
      // await delay(300)
    }
    
    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === true) {
      djtType = 'parent_with_children'
      // update djtCurrentStatus and isActive
      setDeleteRestoreWorkingModalContent('Ticket is a parent with children.')
      // await delay(300)
    }
    setDeleteRestoreWorkingModalProgress(20)
    if (!djtType) {
      // alert('ERROR DETERMINING TYPE: pid:'+parentId+' id:'+isDuplicate+' did:'+duplicateOfId+' ip:'+isParent)
    } else {
      // console.log('deactivate with type: ', djtType)
      setDeleteRestoreWorkingModalContent('Initiating delete...')
      // await delay(300)
      await deactivateDJTChain(id, djtType, teamMember, division, client)
    }

    setDeleteRestoreWorkingModalContent('')
    setToolbarDisabled(false)
    setButtonsDisabled(false)
    setDeleteRestoreWorkingModalCloseDisabled(false)
    setDeleteRestoreWorkingModalProgress(100)
  }

  const handleRestoreDailyJobTicket = async (props) => {
    const {id, teamMember, division, client} = props
    // console.log(' >>>>> restore props: ', props)
    if (!id || !teamMember || !division || !client) return

    onDeleteRestoreWorkingOpen()

    setToolbarDisabled(true)
    setButtonsDisabled(true)
    setDeleteRestoreActionType('restore')
    setDeleteRestoreWorkingModalProgress(10)
    setDeleteRestoreWorkingModalContent('Gathering tickets to restore...')
    // await delay(300)
    setDeleteRestoreWorkingModalCloseDisabled(true)

    const {
      parentId,
      isDuplicate,
      duplicateOfId,
      isParent,
    } = await fetchDJTParentalStatusById(id)
    // console.log('parentId: ', parentId)
    // console.log('isDuplicate: ', isDuplicate)
    // console.log('duplicateOfId: ', duplicateOfId)
    // console.log('isParent: ', isParent)
    // console.log('teamMember: ', teamMember)
    // console.log('division: ', division)
    // console.log('client: ', client)

    // previously saved djt's that have been one-off submitted, still need to show up here, just as disabled except view button

    // determine what type of deletion we are doing
    // options are : 
    //      STANDALONE -            parentId:  "",      isDuplicate:  false,  duplicateOfId:  null,     isParent:  false
    //      STANDALONE CHILD -      parentId:  !empty,  isDuplicate:  true,   duplicateOfId:  !empty,   isParent:  !empty
    //      PARENT WITH CHILDREN -  parentId:  "",      isDuplicate:  false,  duplicateOfId:  null,     isParent:  true

    let djtType = null

    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === false) {
      djtType = 'standalone'
      setDeleteRestoreWorkingModalContent('Ticket is standalone.')
      // await delay(300)
      // update djtCurrentStatus and isActive
    }

    if (parentId !== '' && isDuplicate === true && duplicateOfId !== '' && isParent !== '') {
      djtType = 'standalone_child'
      setDeleteRestoreWorkingModalContent('Ticket is a standalone child.')
      // await delay(300)
      // update djtCurrentStatus and isActive
    }

    if (parentId !== '' && isDuplicate === false && duplicateOfId !== '' && isParent === true) {
      // standalone parent
      djtType = 'standalone'
      // update djtCurrentStatus and isActive
      setDeleteRestoreWorkingModalContent('Ticket is a standalone parent.')
      // await delay(300)
    }
    
    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === true) {
      djtType = 'parent_with_children'
      setDeleteRestoreWorkingModalContent('Ticket is a parent with children.')
      // await delay(300)
      // update djtCurrentStatus and isActive
    }

    if (!djtType) {
      // alert('ERROR DETERMINING TYPE: pid:'+parentId+' id:'+isDuplicate+' did:'+duplicateOfId+' ip:'+isParent)
    } else {
      // console.log('deactivate with type: ', djtType)
      setDeleteRestoreWorkingModalContent('Initiating restore...')
      // await delay(300)
      await activateDJTChain(id, djtType, teamMember, division, client)
    }

    setDeleteRestoreWorkingModalContent('')
    setToolbarDisabled(false)
    setButtonsDisabled(false)
    setDeleteRestoreWorkingModalCloseDisabled(false)
    setDeleteRestoreWorkingModalProgress(100)

  }

  const updateDatesChained = async (djtId, date, dateDifference, type) => {
    if (!djtId || !date || !dateDifference || !type) return

    // console.info('incoming date:', date)

    const props = {
      id: djtId, 
      djtDate: formatDateFn(date, 'ymd', '-'),
    }

    

    if (type==='standalone' || type==='standalone_child') {
      await updateDJTDate(props)
    }

    if (type==='parent_with_children') {
      
      //update the aprent first
      await updateDJTDate(props)

      // get all of the children attached to this parent and their props
      // client, division, status all stay the same.
      // just need the djt id and the teamMemberId
      const djtChildren = await fetchAllDJTChildrenByParentId(djtId)
      
      // loop through any/all children for update
      for (const child of djtChildren) {
        
        //calculate the new date for this child
        //get its current date, then apply the difference to that date, and set the adjusted date
        const existingDate = new Date(child.djtDate + 'T00:00:00')
        // console.log('exisintg date: ', existingDate)
        // const adjustedDate = new Date(new Date(existingDate).getTime() + dateDifference * 24 * 60 * 60 * 1000)
        const adjustedDate = adjustAndFormatDate(existingDate, dateDifference)

        // update the props for this specific child
        props.id = `${child.id}`
        props.djtDate = adjustedDate
        
        await updateDJTDate(props)
        
      }

    }

  }

  const deactivateDJTChain = async (djtId, type, teamMember, division, client) => {
    setToolbarDisabled(true)
    if (!djtId || !type || !teamMember || !division || !client) return

    // teamMemberId is what was undefined
    // console.log('type of record being deleted: ', type)
    // get the status id for a deleted status
    const djtStatusId = await getStatusIdByStatusCode('deleted')

    const journalProps = {
      djtId: djtId,
      createdDateTime: awsDateTimeNow(),
      createdBy: teamMember,
      statusId: djtStatusId,
    }

    // build the props for standalone update
    // djtDivisionTeamMemberStatus
    // djtDivisionTeamMemberClientStatus

    const updateProps = {
      id: djtId, 
      currentStatusId: djtStatusId, 
      isActive: false,
      djtDivisionStatus: `${division}#${djtStatusId}`,
      djtDivisionTeamMemberStatus: `${division}#${teamMember}#${djtStatusId}`,
      djtDivisionTeamMemberClientStatus: `${division}#${teamMember}#${client}#${djtStatusId}`,
      djtDivisionClientStatus: `${division}#${client}#${djtStatusId}`,
    }

    setDeleteRestoreWorkingModalContent('Determing type...')
    // await delay(300)
    setDeleteRestoreWorkingModalProgress(30)
    // standalone tickets only need the parent updated
    if (type==='standalone' || type==='standalone_child') {

      setDeleteRestoreWorkingModalContent('Deleting ticket, one moment...')
      setDeleteRestoreWorkingModalProgress(40)

      // update the djt status
      await updateDJTStatus(updateProps)
      setDeleteRestoreWorkingModalContent('Journaling actions...')
      // await delay(300)
      await addDJTJournal(journalProps)
      setDeleteRestoreWorkingModalContent('Journaled...')
      // await delay(300)
      // refresh the search results
      // handleRefresh()
    }

    
    // batch deactivate requires removing multiple tickets in one go
    if (type==='parent_with_children') {
      setDeleteRestoreWorkingModalContent('Deleting ticket, one moment...')
      setDeleteRestoreWorkingModalProgress(40)
      // this is the top-level parent to djt children. we are deleting this djt 
      // and all of the children below it. same idea will be applied to setting a PO# or attaching
      // a bid to a prent. It will cascade down for each child node of the djt.

      // update the parent djt status
      await updateDJTStatus(updateProps)
      setDeleteRestoreWorkingModalContent('Journaling actions...')
      // await delay(300)
      await addDJTJournal(journalProps)
      
      // console.log('this should be the top level id: ', props.id)

      // get all of the children attached to this parent and their props
      // client, division, status all stay the same.
      // just need the djt id and the teamMemberId
      const djtChildren = await fetchAllDJTChildrenByParentId(djtId)
      
      // console.log('these are the children of the djt being deleted.: ', djtChildren)

      setDeleteRestoreWorkingModalProgress(50)

      // loop through any/all children for update
      for (const child of djtChildren) {

        const djtStatusId = await getStatusIdByStatusCode('deleted')

        setDeleteRestoreWorkingModalProgress(deleteRestoreWorkingModalProgress+1)
        
        setDeleteRestoreWorkingModalContent('Deleting ticket, one moment...')
        // await delay(300)

        // update the props for this specific child
        updateProps.id = `${child.id}`
        updateProps.djtDivisionTeamMemberStatus = `${division}#${child.teamMemberId}#${djtStatusId}`
        updateProps.djtDivisionTeamMemberClientStatus = `${division}#${child.teamMemberId}#${client}#${djtStatusId}`
        
        await updateDJTStatus(updateProps)
        journalProps.djtId = child.id
        setDeleteRestoreWorkingModalContent('Journaling actions...')
        // await delay(300)
        await addDJTJournal(journalProps)
        setDeleteRestoreWorkingModalContent('Journaled...')
        // await delay(300)

        // refresh the search results
        // handleRefresh()
      }

    }

    setDeleteRestoreWorkingModalContent('Deleted')
    // await delay(300)

    // refresh the search results
    handleRefresh()

    setToolbarDisabled(false)
    
  }

  const activateDJTChain = async (djtId, type, teamMember, division, client) => {
    setToolbarDisabled(true)
    if (!djtId || !type || !teamMember || !division || !client) return

    // console.log('type of record being restores: ', type)
    // get the status id for a deleted status
    const djtStatusId = await getStatusIdByStatusCode('queued-pending-submission')

    const journalProps = {
      djtId: djtId,
      createdDateTime: awsDateTimeNow(),
      createdBy: teamMember,
      statusId: djtStatusId,
    }

    // build the props for standalone update
    const props = {
      id: djtId, 
      currentStatusId: djtStatusId, 
      isActive: true,
      djtDivisionStatus: `${division}#${djtStatusId}`,
      djtDivisionTeamMemberStatus: `${division}#${teamMember}#${djtStatusId}`,
      djtDivisionTeamMemberClientStatus: `${division}#${teamMember}#${client}#${djtStatusId}`,
      djtDivisionClientStatus: `${division}#${client}#${djtStatusId}`,
    }
    
    setDeleteRestoreWorkingModalContent('Determing type...')
    // await delay(300)
    setDeleteRestoreWorkingModalProgress(30)

    // standalone tickets only need the parent updated
    if (type==='standalone' || type==='standalone_child') {

      setDeleteRestoreWorkingModalContent('Restoring ticket...')
      setDeleteRestoreWorkingModalProgress(40)

      
      // update the djt status
      await updateDJTStatus(props)
      setDeleteRestoreWorkingModalContent('Journaling actions...')
      // await delay(300)
      await addDJTJournal(journalProps)
      setDeleteRestoreWorkingModalContent('Journaled...')
      // await delay(300)

      // refresh the search results
      // handleRefresh()
    }

    // batch deactivate requires removing multiple tickets in one go
    if (type==='parent_with_children') {
      setDeleteRestoreWorkingModalContent('Restoring ticket...')
      setDeleteRestoreWorkingModalProgress(40)
      // this is the top-level parent to djt children. we are deleting this djt 
      // and all of the children below it. same idea will be applied to setting a PO# or attaching
      // a bid to a prent. It will cascade down for each child node of the djt.

      // update the parent djt status
      await updateDJTStatus(props)
      setDeleteRestoreWorkingModalContent('Journaling actions...')
      // await delay(300)
      await addDJTJournal(journalProps)
      // console.log('this should be the top level id: ', props.id)

      // get all of the children attached to this parent and their props
      // client, division, status all stay the same.
      // just need the djt id and the teamMemberId
      const djtChildren = await fetchAllDJTChildrenByParentId(djtId)

      setDeleteRestoreWorkingModalProgress(50)
      
      // console.log('these are the children of the djt being deleted.: ', djtChildren)

      // loop through any/all children for update
      for (const child of djtChildren) {
        setDeleteRestoreWorkingModalProgress(deleteRestoreWorkingModalProgress+1)
        
        setDeleteRestoreWorkingModalContent('Restoring ticket...')
        // await delay(300)
        // update the props for this specific child
        props.id = `${child.id}`
        props.djtDivisionTeamMemberStatus = `${division}#${child.teamMemberId}#${djtStatusId}`
        props.djtDivisionTeamMemberClientStatus = `${division}#${child.teamMemberId}#${client}#${djtStatusId}`
        
        await updateDJTStatus(props)
        setDeleteRestoreWorkingModalContent('Journaling actions...')
        // await delay(300)

        journalProps.djtId = child.id
        await addDJTJournal(journalProps)
        setDeleteRestoreWorkingModalContent('Journaled...')
        // await delay(300)
        // console.log('this should be the child id: ', props.id)

        // refresh the search results
        // handleRefresh()
      }

    }

    setDeleteRestoreWorkingModalContent('Restored')
    // await delay(300)

    // refresh the search results
    handleRefresh()

    setToolbarDisabled(false)
    
  }

  // State to track if the input has been focused
  const [hasFocused, setHasFocused] = useState(false);

  useEffect(() => {
    // Only show the input if it hasn't been focused yet
    if (hasFocused) {
      // Set a timer to show the input after the component mounts
      const timer = setTimeout(() => {
        
          // After showing, set another timer to hide it if not focused within 5 seconds
          const hideTimer = setTimeout(() => {
            if (hasFocused) {
              setHasFocused(false);
              setMutableDateType(currentArray => {
                let newArray = new Array(currentArray.length).fill('hidden');
                return newArray;
              });
            }
          }, 3750);
          // Clean up this timer
          return () => clearTimeout(hideTimer);
        
      }, 3000); // Immediately after mounting, consider showing the input

      // Clean up the initial timer on component unmount
      return () => clearTimeout(timer);
    }

  }, [hasFocused]); // Depend on hasFocused to decide if we need to show the input again

  const handleDateFocus = () => {
    setHasFocused(true);
  };

  const handleDateChange = async (event, index, estimate) => {

    setHasFocused(true);
    
    const newDate = event.target.value
    const originalDate = estimate.djtDate
    const dateDifference = Math.floor((new Date(newDate) - new Date(originalDate)) / (1000 * 60 * 60 * 24))
    
    const id = estimate.id
    const {
      parentId,
      isDuplicate,
      duplicateOfId,
      isParent,
    } = await fetchDJTParentalStatusById(id)

    let djtType = null

    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === false) {
      djtType = 'standalone'
    }

    if (parentId !== '' && isDuplicate === true && duplicateOfId !== '' && isParent !== '') {
      djtType = 'standalone_child'
    }
    
    if (parentId === null && isDuplicate === false && duplicateOfId === null && isParent === true) {
      djtType = 'parent_with_children'
    }

    if (!djtType) {
      alert('ERROR DETERMINING TYPE: pid:'+parentId+' id:'+isDuplicate+' did:'+duplicateOfId+' ip:'+isParent)
    } else {
      await updateDatesChained(id, newDate, dateDifference, djtType)
    }

    // setMutableDateType('hidden')
    setMutableDateType(currentArray => {
      let newArray = new Array(currentArray.length).fill('hidden');
      return newArray;
    });

    // refresh the search results
    handleRefresh()
  }

  const updateDJTStatus = async (props) => {
    if (!props) return

    // take in props.isActive and set in equipment and labor and material

    const djtId = props.id

    // update the djt status
    await updateDailyJobTicket(props)

    // now get any labor records attached to this ticket
    const attachedLabor = await fetchLaborByDJTId(djtId)

    // deactivate the attached labor
    await updateAttachedLabor(attachedLabor, props.isActive)
    
    // now get any equipment records attached to this ticket
    const attachedEquipment = await fetchEquipmentByDJTId(djtId)

    // deactivate the attached equipment
    await updateAttachedEquipment(attachedEquipment, props.isActive)

    // now get any material records attached to this ticket
    const attachedMaterial = await fetchMaterialByDJTId(djtId)

    // deactivate the attached material
    await updateAttachedMaterial(attachedMaterial, props.isActive)

  }

  const updateAttachedLabor = async (laborArray, isActive) => {
    if (!laborArray || laborArray?.length===0) return
    for (const labor of laborArray) {
      // await updateDailyJobTicketAttachedLabor({id: labor.id, isActive: false})
      await updateDailyJobTicketAttachedLabor({id: labor.id, isActive: isActive})
    }
    return
  }

  const updateAttachedEquipment = async (equipmentArray, isActive) => {
    if (!equipmentArray || equipmentArray?.length===0) return
    for (const equipment of equipmentArray) {
      await updateDailyJobTicketAttachedEquipment({id: equipment.id, isActive: isActive})
    }
    return
  }

  const updateAttachedMaterial = async (materialArray, isActive) => {
    if (!materialArray || materialArray?.length===0) return
    for (const material of materialArray) {
      await updateDailyJobTicketAttachedMaterial({id: material.id, isActive: isActive})
    }
    return
  }

  const fetchAllDJTChildrenByParentId = async (parentId) => {
    if (!parentId) return null
    // const results = await fetchDjtDuplicatesByParentId(parentId)
    const results = await fetchDJTsByParentId(parentId)
    return results
  }

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })

  // future feature giving the super the ability to toggle on viewing only their submitted djts
  const [ searchFilter, setSearchFilter ] = useState(false)

  // create a component out of this
  const [ toolbarDisabled, setToolbarDisabled ] = useState(false)
  useEffect(() => {
    const delayChangeState = async () => {
      await delay(3000)
      setToolbarDisabled(false)
    }
    if (toolbarDisabled===true) {
      delayChangeState()
    }
  },[toolbarDisabled])
  const [ buttonsDisabled, setButtonsDisabled ] = useState(false)

  const Toolbar = (props) => {
    const { djt, id, teamMember, division, client, isParent, parentId} = props
    // const showEditOnlyButton = (type==='approval-required') ? true : false
    // const showEditDeleteButtons = (type==='saved') ? true : false
    // const showRestoreOnlyButton = (type==='restore') ? true : false;
    // // const showViewDuplicateButtons = (type!=='deleted') ? true : false
    // const toolbarWidth = (showEditDeleteButtons) ? '245px' : '123px'
    // console.log('TOOLBAR PROPS: ', props)

    //currentTab 0=drafts, 1=submitted, 2=approval-required, 3=deleted
    return(

      <>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
            variant='withIconOnlySenary' 
            backgroundColor={'var(--dark-main-background)'}
            w={'60px'}
            iconsize='18px'
            height='28px'
            mb='3px'
            isDisabled={toolbarDisabled}
          />
          <MenuList>
          {(currentTab===0 || currentTab===2) && <MenuItem 
              onClick={() => handleOpenDrawer('edit', id)}
              icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
              Edit
            </MenuItem>}

            <MenuItem 
              onClick={() => handleOpenDrawer('view', id)}
              icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
              View
            </MenuItem>

            {(currentTab===0) && <MenuItem 
              onClick={() => handleOpenDrawer('duplicate', id, isParent, parentId)}
              icon={<AvailableIcons boxSize={'22px'} iconRef={'duplicate'} />} >
              Duplicate
            </MenuItem>}

            <MenuItem 
              onClick={() => {
                setToolbarDisabled(true)
                //const attachedObj = await generatePdf(djt, token, environment, true, true)
                // handleGeneratePdf(id, djtDate, true)
                handleGeneratePdf(djt, null, null, true, false)
              }}
              icon={<AvailableIcons boxSize={'22px'} iconRef={'download'} />} >
              Download PDF
            </MenuItem>
            
            {(currentTab===3 || currentTab===0) && <MenuDivider />}

            {(currentTab===3) && <MenuItem 
                onClick={() => handleRestoreDailyJobTicket({id: id, teamMember: teamMember, division: division, client: client})}
                color={'var(--success-green)'}
                icon={<AvailableIcons boxSize={'22px'} iconRef={'restore'} />} >
                Restore
              </MenuItem>}
            
            {(currentTab===0) && <MenuItem 
                onClick={() => handleDeleteDailyJobTicket({id: id, teamMember: teamMember, division: division, client: client})}
                color={'var(--error-red)'}
                icon={<AvailableIcons boxSize={'22px'} iconRef={'delete'} />} >
                Delete
              </MenuItem>}
          </MenuList>
        </Menu>
      </>
    )
  }

  function handleConfirmDeleteAlertClosed() {
    // if submitted then delete, else skip
    onConfirmDeleteAlertClose()
    setHasConfirmedDelete(true)
    onDeleteRestoreWorkingOpen()
  }

  function handleDeleteRestoreModalClosed() {
    // if submitted then delete, else skip
    onDeleteRestoreWorkingClose()
    // setHasConfirmedDelete(true)
  }

  const [ mutableDateType, setMutableDateType ] = useState([]);
  useEffect(() => {
    let newArray = new Array(djtSearchResultsLength).fill('hidden');
    setMutableDateType(newArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[djtSearchResults])

  return (
    <>
    <ModuleBox>
    <Tabs 
      borderColor='var(--dark-module-divider)' 
      size='md' 
      variant='enclosed' 
      colorScheme='spikedarktabs' 
      onChange={(index) => handleTabChange(index)}>
      {(buttonsDisabled===false) && <TabList>
        <Tab><Text>Drafts</Text></Tab>
        <Tab><Text>Submitted</Text></Tab>
        <Tab>{approvalRequiredCallout} <Text>Approval Required</Text></Tab>
        <Tab><Text>Deleted</Text></Tab>
      </TabList>}
      <TabPanels>
        <TabPanel>
          <TableContainer>
            <HStack mb='15px'>
              <Text className={searchHeaderTextClass}>Drafts</Text>
              <Spacer/>
              <ButtonQuaternaryWithIcon
                name='new'
                leftIcon='new'
                iconsize='22px'
                isDisabled={buttonsDisabled}
                onClick={() => handleOpenDrawer('new', null)}
                value='Create New Job Ticket'
              />
            </HStack>
            <Table variant={'compact'} size={'compact'} >
              <Thead>
                <Tr>
                  <Th><Text as="span" textStyle='dark-heading-4'>{(divisionHasOneClient) ? 'LOCATION' : 'CLIENT'}</Text></Th>
                  <Th><Text as="span" textStyle='dark-heading-4'>DATE</Text></Th>
                  <Th><Text as="span" textStyle='dark-heading-4'>DESCRIPTION</Text></Th>
                  <Th textAlign='left' pl={'38px'}><Text as="span" textStyle='dark-heading-4'>ACTIONS</Text></Th>
                </Tr>
              </Thead>
              {(showSearchResutlsTable) ? <Tbody>
                {(djtSearchResults?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                {djtSearchResults?.map((djt, index) => {
                  const indent = (djt?.isDuplicate===true && djt?.waitingForParent!==true) ? '25px' : '0px'
                  const goesBy = djt?.teamMember?.goesBy
                  const firstName = (goesBy && goesBy!=="") ? goesBy : djt?.teamMember?.firstName
                  const supervisorName = `${firstName} ${djt?.teamMember?.lastName}`
                  const djtDivision = djt?.djtDivisionId
                  const djtClient = djt?.djtClient?.id
                  const djtTeamMember = djt?.teamMemberId
                  const djtDate = djt?.djtDate||todaysDateYMD()
                  // setIsReview(false)
                  return(
                    <Fragment key={'frag_1_'+index}>
                    <Tr key={'tr_'+index}>
                      <Td py='6px' key={'td_2_'+index}>
                        <Text as="span" pl={indent} className='dark-sfpro-text-2'>
                          <Tooltip label={(divisionHasOneClient) ? djt?.djtLocation : djt?.djtClient?.contactCompany}>
                            <Text as="span" pl={indent} className='dark-sfpro-text-2'>
                              {(divisionHasOneClient) 
                                ? truncateString(djt?.djtLocation, clientTruncateValue)
                                : truncateString(djt?.djtClient?.contactCompany, clientTruncateValue)
                              }
                            </Text>
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td py='6px' key={'td_1_'+index}>
                        {(mutableDateType[index]==='date') && <TextInput
                          register={register}
                          errors={errors}
                          variant='unstyled'
                          fieldname={`mutableDate[${index}]`}
                          onChange={(e) => handleDateChange(e, index, djt)}
                          isRequired={true} 
                          fieldtype='date'
                          defaultValue={djtDate}
                          width='110px'
                        />}

                        {(mutableDateType[index]!=='date') &&
                          <Text onClick={() => {
                            handleDateFocus()
                            setMutableDateType(currentArray => {
                              let newArray = new Array(currentArray.length).fill('hidden');
                              newArray[index] = 'date';
                              return newArray;
                            });
                            }} as="span" className='dark-sfpro-text-2'>
                            <Tooltip label={'Select to change date.'}>
                              {spellOutDate(djtDate, 'apple-fy')}
                            </Tooltip>
                          </Text>
                        }
                      </Td>
                      <Td py='6px' key={'td_3_'+index} my='5px'>
                        <Text as="span" className='dark-sfpro-text-2' my='5px'>
                          <Tooltip label={
                            <Stack m='10px'>
                              <Text>{`Supervisor: ${supervisorName}`}</Text>
                              <Text>{`Description: ${djt?.djtNotes}`}</Text>
                              <Text>Summary:</Text>
                              <Text>{`Labor Total: ${formatCurrency(djt?.djtLaborTotal)}`}</Text>
                              <Text>{`Equipment Total: ${formatCurrency(djt?.djtEquipmentTotal)}`}</Text>
                              <Text>{`Materials Total: ${formatCurrency(djt?.djtMaterialTotal)}`}</Text>
                              <Text>{`Total: ${formatCurrency(djt?.djtTotalBilled)}`}</Text>
                            </Stack>
                            }>
                            {truncateString(djt?.djtNotes, descriptionTruncateValue)}
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td key={'td_4_'+index}>
                        <Box w='100%' align='right'><Toolbar 
                          djt={djt}
                          id={djt?.id}
                          teamMember={djtTeamMember}
                          division={djtDivision}
                          client={djtClient}
                          djtDate={djt?.djtDate}
                        /></Box>
                      </Td>
                    </Tr>
                    <Tr key={'tr_divider_b_'+index}>
                      <Td key={'td_divider_b_'+index} colSpan={4} borderBottom={'1px solid var(--dark-module-divider)'}></Td>
                    </Tr>
                    </Fragment>
                  )
                })}
                <Tr><Td colSpan={4}  borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
              </Tbody>  : <Tbody><Tr><Td colSpan={4} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>}
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                    <Flex>
                      <Flex mt='13px'>

                      {(doShowAllSupervisorsSwitch) && <><Box>
                          <Text as="span" textStyle='text-2'>Show All Supervisors: </Text>
                        </Box>
                        <Spacer />
                        <Box ml={'10px'}>
                          <Switch
                            colorScheme={'switch'}
                            size={'lg'}
                            id="showAllResults"
                            isChecked={searchFilter}
                            isDisabled={buttonsDisabled}
                            onChange={() => {
                              // setValue('doShowHours', e)
                              handleShowAllToggle(!searchFilter)
                              setSearchFilter(!searchFilter)
                            }}
                          />
                        </Box></>}

                      </Flex>

                      <Spacer />
                      <Box>
                        <Center>
                          <ButtonSecondaryPlainWithRef 
                            name='loadMore'
                            ref={loadMoreRef} 
                            isDisabled={(buttonsDisabled===true) ? true : loadMoreDisabled} 
                            onClick={loadMoreOnClick} 
                            value={(loadMoreDisabled) ? 'No More Results' : 'Load More'} //'Load More'
                            my='5px'
                            px='15px'
                          />
                        </Center>
                      </Box>
                    </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
          
        </TabPanel>
        <TabPanel>
          <TableContainer>
            <HStack mb='15px'>
              <Text className={searchHeaderTextClass}>Submitted</Text>
              <Spacer/>
              <ButtonQuaternaryWithIcon
                name='new'
                leftIcon='new'
                iconsize='22px'
                isDisabled={buttonsDisabled}
                onClick={() => handleOpenDrawer('new', null)}
                value='Create New Job Ticket'
              />
            </HStack>
            <Table variant={'compact'} size={'compact'}>
              <Thead>
                <Tr>
                  <Th><Text as="span" className='dark-sfpro-heading-4'>{(divisionHasOneClient) ? 'LOCATION' : 'CLIENT'}</Text></Th>
                  <Th><Text as="span" className='dark-sfpro-heading-4'>DATE</Text></Th>
                  <Th><Text as="span" className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
                  <Th textAlign='left'><Text as="span" className='dark-sfpro-heading-4'>ACTIONS</Text></Th>
                  <Th><Text as="span" className='dark-sfpro-heading-4'>STATUS</Text></Th>
                </Tr>
              </Thead>
              {(showSearchResutlsTable) ? <Tbody>
                {(djtSearchResults?.length===0) && <Tr><Td py='6px' colSpan={5}><Center>No results found</Center></Td></Tr>}
                {djtSearchResults?.map((djt, index) => {

                  // console.warn(' *----- djt?.currentStatus: ', djt?.currentStatus?.statusCode)
                  let currentStatus = ''
                  const currentDjtStatusCode = djt?.currentStatus?.statusCode
                  // queued-pending-submission = Pending
                  if (currentDjtStatusCode === 'queued-pending-approval') currentStatus = 'Pending'

                  if (
                    currentDjtStatusCode === 'invoiced-via-quickbooks' || 
                    currentDjtStatusCode === 'invoiced-via-other' || 
                    currentDjtStatusCode === 'invoiced-via-quickbooks-native'
                  ) currentStatus = 'Invoiced'
                  // invoiced-via-quickbooks  = Invoiced
                  // invoiced-via-other       = Invoiced
                  // invoiced-via-quickbooks-native = Invoiced

                  if (currentDjtStatusCode === 'uninvoiced-donotbill') currentStatus = 'Saved'
                  // uninvoiced-donotbill     = Saved

                  // if parentId is null, the parentId is going to be this djt id
                  // const djtParentId = (djt?.parentId!=='') ? djt.parentId : djt.id
                  const djtParentId = djt.parentId
                  const indent = (djt?.isDuplicate===true && djt?.waitingForParent!==true) ? '25px' : '0px'
                  // show all djts is off by default
                  const goesBy = djt?.teamMember?.goesBy
                  const firstName = (goesBy && goesBy!=="") ? goesBy : djt?.teamMember?.firstName
                  const supervisorName = `${firstName} ${djt?.teamMember?.lastName}`
                  
                  // const currentStatusOrder = Number(djt?.currentStatus?.order)
                  // if (currentStatusOrder>=50) currentStatus = 'Invoiced'
                  // setIsReview(false)
                  return(
                    <Fragment key={'frag_1_'+index}>
                      <Tr key={'tr_'+index}>
                      <Td py='6px' key={'td_2_'+index}>
                        <Text as="span" pl={indent} className='dark-sfpro-text-2'>
                          <Tooltip label={(divisionHasOneClient) ? djt?.djtLocation : djt?.djtClient?.contactCompany}>
                            {(divisionHasOneClient) 
                              ? truncateString(djt?.djtLocation, clientTruncateValue)
                              : truncateString(djt?.djtClient?.contactCompany, clientTruncateValue)
                            }
                          </Tooltip>
                        </Text></Td>
                      <Td py='6px' key={'td_1_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>{spellOutDate(djt?.djtDate, 'apple-fy')}</Text>
                      </Td>
                      <Td py='6px' key={'td_3_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>
                          <Tooltip label={
                              <Stack m='10px'>
                                <Text>{`Supervisor: ${supervisorName}`}</Text>
                                <Text>{`${djt?.djtNotes}`}</Text>
                            </Stack>
                            }>
                            {truncateString(djt?.djtNotes, descriptionTruncateValue)}
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td key={'td_4_'+index}>
                        <Box w='100%' align='right'>
                          <Toolbar 
                            djt={djt}
                            id={djt?.id}
                            parentId={djtParentId}
                            index={index}
                            type={'submitted'}
                            djtDate={djt?.djtDate}
                            isParent={djt?.isParent}
                          />
                        </Box>
                      </Td>
                      <Td key={'td_5_'+index}>
                        <Box w='100%' align='left'>
                        <Text as="span" className='dark-sfpro-text-2'>{currentStatus}</Text> 
                        </Box>
                      </Td>
                    </Tr>
                    <Tr><Td key={'tr_divider_bottom_'+index} colSpan={6} borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
                  </Fragment>
                  )
                })}
                <Tr><Td colSpan={5}  borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
              </Tbody> : <Tbody><Tr><Td colSpan={5} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>}
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                  <Flex>
                    <Flex mt='13px'>
                    {(doShowAllSupervisorsSwitch) && <><Box>
                        <Text as="span" textStyle='text-2'>Show All Supervisors: </Text>
                      </Box>
                      <Spacer />
                      <Box ml={'10px'}>
                        <Switch
                          colorScheme={'switch'}
                          size={'lg'}
                          id="showAllResults"
                          isChecked={searchFilter}
                          onChange={() => {
                            handleShowAllToggle(!searchFilter)
                            setSearchFilter(!searchFilter)
                          }}
                        />
                      </Box></>}
                    </Flex>
                    <Spacer />
                    <Box>
                      <Center>
                        <ButtonSecondaryPlainWithRef 
                          name='loadMore'
                          ref={loadMoreRef} 
                          isDisabled={loadMoreDisabled} 
                          onClick={loadMoreOnClick} 
                          value={(loadMoreDisabled) ? 'No More Results' : 'Load More'}
                          my='5px'
                          px='15px'
                        />
                      </Center>
                    </Box>
                  </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel>
        <Box w={'100%'}>
          <Text className={searchHeaderTextClass}>Approval Required</Text>
            <Table variant={'compact'} size={'compact'} w={'100%'}>
              <Thead>
                <Tr>
                  <Th w={'20%'}><Text as="span" className='dark-sfpro-heading-4'>CLIENT</Text></Th>
                  <Th w={'20%'}><Text as="span" className='dark-sfpro-heading-4'>DATE</Text></Th>
                  <Th w={'50%'}><Text as="span" className='dark-sfpro-heading-4'>REQUEST</Text></Th>
                  <Th w={'10%'} textAlign='left'><Text as="span" className='dark-sfpro-heading-4'>REVIEW</Text></Th>
                </Tr>
              </Thead>
              {(showSearchResutlsTable) ? <Tbody>
                {(djtSearchResults?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                {djtSearchResults?.map((djt, index) => {
                  // console.log('djt: ', djt)
                  // if parentId is null, the parentId is going to be this djt id
                  // const djtParentId = (djt?.parentId!=='') ? djt.parentId : djt.id
                  const djtParentId = djt.parentId
                  const indent = (djt?.isDuplicate===true && djt?.waitingForParent!==true) ? '25px' : '0px'
                  // show all djts is off by default
                  const goesBy = djt?.teamMember?.goesBy
                  const firstName = (goesBy && goesBy!=="") ? goesBy : djt?.teamMember?.firstName
                  const supervisorName = `${firstName} ${djt?.teamMember?.lastName}`
                  // setIsReview(true)
                  return(
                    <Fragment key={'frag_1_'+index}><Tr 
                      key={'tr_'+index} 
                      // borderColor={'red'}
                      >
                      <Td py='6px' key={'td_2_'+index}>
                        <Text as="span" pl={indent} className='dark-sfpro-text-2'>
                          <Tooltip label={djt?.djtClient?.contactCompany}>
                            {djt?.djtClient?.contactCompany}
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td py='6px' key={'td_1_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>{spellOutDate(djt?.djtDate, 'apple-fy')}</Text>
                      </Td>
                      <Td py='6px' key={'td_3_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>
                          <Tooltip label={
                              <Stack m='10px'>
                                <Text>{`Supervisor: ${supervisorName}`}</Text>
                                <Text>{`${djt?.djtNotes}`}</Text>
                            </Stack>
                            }><Box mb={'10px'} mr={'20px'}><Text as="span" className='dark-sfpro-text-2'>{`${djt?.bouncedAdminReason}`}</Text></Box>
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td key={'td_4_'+index}>
                        <Box align='right'>
                          <Toolbar 
                            djt={djt}
                            id={djt?.id}
                            parentId={djtParentId}
                            index={index}
                            type={'approval-required'}
                            djtDate={djt?.djtDate}
                            isParent={djt?.isParent}
                          />
                        </Box>
                      </Td>
                    </Tr>
                    <Tr><Td key={'tr_divider_bottom_'+index} colSpan={4} borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
                    </Fragment>
                  )
                })}
                <Tr><Td colSpan={4}  borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
              </Tbody> : <Tbody><Tr><Td colSpan={4} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>}
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                  <Flex>
                    <Flex mt='13px'>
                    {(doShowAllSupervisorsSwitch) && <><Box>
                        <Text as="span" textStyle='text-2'>Show All Supervisors: </Text>
                      </Box>
                      <Spacer />
                      <Box ml={'10px'}>
                        <Switch
                          colorScheme={'switch'}
                          size={'lg'}
                          id="showAllResults"
                          isChecked={searchFilter}
                          onChange={() => {
                            handleShowAllToggle(!searchFilter)
                            setSearchFilter(!searchFilter)
                          }}
                        />
                      </Box></>}
                    </Flex>
                    <Spacer />
                    <Box>
                      <Center>
                        <ButtonSecondaryPlainWithRef 
                          name='loadMore'
                          ref={loadMoreRef} 
                          isDisabled={loadMoreDisabled} 
                          onClick={loadMoreOnClick} 
                          value={(loadMoreDisabled) ? 'No More Results' : 'Load More'}
                          my='5px'
                          px='15px'
                        />
                      </Center>
                    </Box>
                  </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </Box>
        </TabPanel>
        <TabPanel>
        <TableContainer>
          <Text className={searchHeaderTextClass}>Deleted</Text>
            <Table variant={'compact'} size={'compact'} >
              <Thead>
                <Tr>
                  <Th w={'20%'}><Text as="span" className='dark-sfpro-heading-4'>CLIENT</Text></Th>
                  <Th w={'20%'}><Text as="span" className='dark-sfpro-heading-4'>DATE</Text></Th>
                  <Th w={'50%'}><Text as="span" className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
                  <Th w={'10%'} textAlign='left'><Text as="span" className='dark-sfpro-heading-4'>RESTORE</Text></Th>
                </Tr>
              </Thead>
              {(showSearchResutlsTable) ? <Tbody>
                {(djtSearchResults?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                {djtSearchResults?.map((djt, index) => {
                  // console.log('djt: ', djt)
                  // if parentId is null, the parentId is going to be this djt id
                  // const djtParentId = (djt?.parentId!=='') ? djt.parentId : djt.id
                  const djtParentId = djt.parentId
                  const indent = (djt?.isDuplicate===true && djt?.waitingForParent!==true) ? '25px' : '0px'
                  // show all djts is off by default
                  const goesBy = djt?.teamMember?.goesBy
                  const firstName = (goesBy && goesBy!=="") ? goesBy : djt?.teamMember?.firstName
                  const supervisorName = `${firstName} ${djt?.teamMember?.lastName}`

                  const djtDivision = djt?.djtDivisionId
                  const djtClient = djt?.djtClient?.id
                  const djtTeamMember = djt?.teamMemberId

                  // setIsReview(false)
                  return(
                    <Fragment key={'frag_1_'+index}><Tr 
                      key={'tr_'+index} 
                      // borderColor={'red'}
                      >
                      <Td py='6px' key={'td_2_'+index}><Text as="span" pl={indent} className='dark-sfpro-text-2'><Tooltip label={djt?.djtClient?.contactCompany}>{truncateString(djt?.djtClient?.contactCompany, clientTruncateValue)}</Tooltip></Text></Td>
                      <Td py='6px' key={'td_1_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>{spellOutDate(djt?.djtDate, 'apple-fy')}</Text>
                      </Td>
                      <Td py='6px' key={'td_3_'+index}>
                        <Text as="span" className='dark-sfpro-text-2'>
                          <Tooltip label={
                              <Stack m='10px'>
                                <Text>{`Supervisor: ${supervisorName}`}</Text>
                                <Text>{`${djt?.djtNotes}`}</Text>
                            </Stack>
                            }>
                            {truncateString(djt?.djtNotes, 40)}
                          </Tooltip>
                        </Text>
                      </Td>
                      <Td key={'td_4_'+index}>
                        <Box w='100%' align='right'>
                          <Toolbar 
                            djt={djt}
                            id={djt?.id}
                            parentId={djtParentId}
                            index={index}
                            type={'restore'}
                            teamMember={djtTeamMember}
                            division={djtDivision}
                            client={djtClient}
                            djtDate={djt?.djtDate}
                            isParent={djt?.isParent}
                          />
                        </Box>
                      </Td>
                    </Tr>
                    <Tr><Td key={'tr_divider_bottom_'+index} colSpan={4} borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
                    </Fragment>
                  )
                })}
                <Tr><Td colSpan={4}  borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
              </Tbody>  : <Tbody><Tr><Td colSpan={4} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>}
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                  <Flex>
                    <Flex mt='13px'>
                    {(doShowAllSupervisorsSwitch) && <><Box>
                        <Text as="span" textStyle='text-2'>Show All Supervisors: </Text>
                      </Box>
                      <Spacer />
                      <Box ml={'10px'}>
                        <Switch
                          colorScheme={'switch'}
                          size={'lg'}
                          id="showAllResults"
                          isChecked={searchFilter}
                          onChange={() => {
                            handleShowAllToggle(!searchFilter)
                            setSearchFilter(!searchFilter)
                          }}
                        />
                      </Box></>}
                    </Flex>
                    <Spacer />
                    <Box>
                      <Center>
                        <ButtonSecondaryPlainWithRef 
                          name='loadMore'
                          ref={loadMoreRef} 
                          isDisabled={loadMoreDisabled} 
                          onClick={loadMoreOnClick} 
                          value={(loadMoreDisabled) ? 'No More Results' : 'Load More'}
                          my='5px'
                          px='15px'
                        />
                      </Center>
                    </Box>
                  </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabPanels>
    </Tabs>
      
    </ModuleBox>

    <CenteredConfirmDeleteDJTAlert
      isAlertOpen={isConfirmDeleteAlertOpen}
      onAlertClose={onConfirmDeleteAlertClose}
      cancelRef={confirmDeleteCancelRef}
      closeButtonDisabled={confirmDeleteAlertCloseDisabled}
      handleConfirmDeleteAlertClosed={handleConfirmDeleteAlertClosed}
    />

    <CenteredDeleteRestoreWorkingDJTModal
      isModalOpen={isDeleteRestoreWorkingOpen}
      onModalClose={onDeleteRestoreWorkingClose}
      content={deleteRestoreWorkingModalContent}
      closeButtonDisabled={deleteRestoreWorkingModalCloseDisabled}
      handleDeleteRestoreModalClosed={handleDeleteRestoreModalClosed}
      progress={deleteRestoreWorkingModalProgress}
      actionType={deleteRestoreActionType}
    />

    <ModuleDrawer
      onClose={onCreateDjtClose}
      isOpen={isCreateDjtOpen}
      bodyContent={<CreateDailyJobTicket selectedDivision={currentDivisionId} srcAction={'new'} onClose={onCreateDjtClose} drawerCloseButton={CustomCloseDrawerButton('new')}/>}
      size={'full'}
    />

    <ModuleDrawer
      onClose={onViewDjtClose}
      isOpen={isViewDjtOpen}
      bodyContent={<ViewDailyJobTicket srcId={djtId} onClose={onViewDjtClose} drawerCloseButton={CustomCloseDrawerButton('view')}/>}
      size={'full'}
    />

    <ModuleDrawer
      onClose={onDuplicateDjtClose}
      isOpen={isDuplicateDjtOpen}
      bodyContent={<CreateDailyJobTicket selectedDivision={currentDivisionId} srcAction={'duplicate'} srcId={djtId} parentId={djtParentId} onClose={onDuplicateDjtClose} drawerCloseButton={CustomCloseDrawerButton('duplicate')}/>}
      size={'full'}
    />

    <ModuleDrawer
      onClose={onEditDjtClose}
      isOpen={isEditDjtOpen}
      bodyContent={<CreateDailyJobTicket selectedDivision={currentDivisionId} reviewing={isReviewing} srcAction={'edit'} srcId={djtId} onClose={onEditDjtClose} drawerCloseButton={CustomCloseDrawerButton('edit')}/>}
      size={'full'}
    />
    </>
  )
}

ResultsTableDailyJobTicketsByClientByDate.propTypes = {
  djtSearchResults: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    isDuplicate: PropTypes.bool,
    waitingForParent: PropTypes.bool,
    teamMember: PropTypes.shape({
      goesBy: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }),
    djtDivisionId: PropTypes.string,
    djtClient: PropTypes.shape({
      id: PropTypes.string,
      contactCompany: PropTypes.string
    }),
    teamMemberId: PropTypes.string,
    djtDate: PropTypes.string,
    djtLocation: PropTypes.string,
    djtNotes: PropTypes.string,
    djtLaborTotal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    djtEquipmentTotal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    djtMaterialTotal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    djtTotalBilled: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    currentStatus: PropTypes.shape({
      statusCode: PropTypes.string
    }),
    bouncedAdminReason: PropTypes.string,
    isParent: PropTypes.bool
  })),
  loadMoreOnClick: PropTypes.func.isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  divisionHasOneClient: PropTypes.bool.isRequired,
  handleShowAllToggle: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  showSearchResutlsTable: PropTypes.bool.isRequired,
  isReviewing: PropTypes.bool.isRequired,
  approvalRequiredCount: PropTypes.number.isRequired,
  doShowAllSupervisorsSwitch: PropTypes.bool,
  handleGeneratePdf: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};