import { listUnitDocumentsByImageSetId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchUnitDocumentsByImageSetId = async (imageSetId) => {
  if (!imageSetId) return;

  try {
    const gqlParams = {
      imageSetId: imageSetId
    };

    const response = await client.graphql({
      query: listUnitDocumentsByImageSetId,
      variables: gqlParams
    });

    const results = response.data.unitDocumentsByImageSetId.items

    return results;
  } catch (err) {
    return ('error fetchUnitDocumentsByImageSetId: ', [err]);
  }
};

fetchUnitDocumentsByImageSetId.propTypes = {
  imageSetId: PropTypes.string.isRequired,
};
