import { fetchAuthSession } from 'aws-amplify/auth';

export async function fetchCognitoUsers(params) {
  // const { UserPoolId, AttributesToGet, Filter, Limit, PaginationToken } = params;
  const { UserPoolId, AttributesToGet } = params;

  try {
    // Ensure the current user's session is up-to-date
    // const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    // console.log('Current user refreshed:', currentUser);

    // const session = await Auth.currentSession();
    // const authToken = `Bearer ${session.getIdToken().getJwtToken()}`;

    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;

    const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminlistusers';
    const inputObj = {
      UserPoolId,
      AttributesToGet,
    };

    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken,
      },
      body: JSON.stringify(inputObj)
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    console.log('response', JSON.stringify(response, null, 2));
    const data = await response.json();
    return data.body;  // Assuming the data is wrapped in a "body" property

  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    throw error;  // Re-throwing the error to be handled by the caller
  }
}
