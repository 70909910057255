// When we fetch the transaction for this id
// need to determine what type of expense category this is
// Fuel, Food, Maint, Office, Other
// query the vendormap table to to see if we have a vendor that matches 'officemax/depot 6047'
// this will give use the qb vendor 'parent' record where we can get the default expense category type
// then set default expense category option accordingly

import { useState, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Image as ChakraImage,  // Rename the Chakra UI Image import
  Spacer,
  VStack,
  Text,
  Container,
  useDisclosure,
  useBreakpointValue,
  Input,
} from "@chakra-ui/react";

import confetti from 'canvas-confetti';

import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';

import PropTypes from 'prop-types';

// uuid
import { v4 as uuid } from 'uuid';

// react-hook-form
import { useForm } from 'react-hook-form';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSelectMulti } from '../Form/Select/FormSelectMulti.jsx';
import { stateOptions } from '../Form/Select/stateOptions.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// form buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSeptenaryWithIconOnly } from '../Form/Button/ButtonSeptenaryWithIconOnly.jsx';
import { ButtonNonaryWithIconOnly } from '../Form/Button/ButtonNonaryWithIconOnly.jsx';
import { ButtonDuodenaryPlain } from '../Form/Button/ButtonDuodenaryPlain.jsx';
import { ButtonQuaternaryPlain } from '../Form/Button/ButtonQuaternaryPlain.jsx';

// graphql and external components
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchAllUnits } from '../graphqlCompnents/Unit/fetchAllUnits.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
import { addIFTAFuelTracking } from '../graphqlCompnents/Statement/addIFTAFuelTracking.jsx';
import { fetchQuickTransaction } from '../graphqlCompnents/Statement/fetchQuickTransaction.jsx';
import { fetchManualTransaction } from '../graphqlCompnents/Statement/fetchManualTransaction.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { createCapitalOneReceipt } from '../graphqlCompnents/Statement/createCapitalOneReceipt.jsx';
import { updateCapitalOneReceipt } from '../graphqlCompnents/Statement/updateCapitalOneReceipt.jsx';
import { addStatementDocumentUnit } from '../graphqlCompnents/Statement/addStatementDocumentUnit.jsx';
import { fetchCapitalOneReceiptByInstantNotificationId } from '../graphqlCompnents/Statement/fetchCapitalOneReceiptByInstantNotificationId.jsx';
import { updateInstantNotification } from '../graphqlCompnents/Statement/updateInstantNotification.jsx';
import { addStatementDocumentLink } from '../graphqlCompnents/Statement/addStatementDocumentLink.jsx';

// generic funcitons
import { stripToNumbersAndDecimal } from '../functions/number.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// modal
import { CenteredSingleButtonConfirmationModal } from '../Modal/CenteredSingleButtonConfirmationModal.jsx';
import { delay } from '../functions/generic.jsx';

// Helper function for delay
// const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// parse query for possible id
function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const QuickCameraUploadStatement = (props) => {

  const {
    id,
    selectedActionType,
    onClose
  } = props;

  // grab global context values
  const { store } = useContext(AppContext);

  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox'

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id

  // current users default division
  const usersDivisionId = store?.userData?.divisionId

  // current user groups
  const userGroups = store?.userData?.groups

  const isSupervisor = userGroups?.includes('supervisor')

  console.log('selectedActionType: ', selectedActionType)

  const query = useQueryParams();

  let externallyOpened = false;
  let transactionId = query.get('id')
  if (transactionId) {
    externallyOpened = true;
  } else {
    transactionId = id||null;
  }

  // modal
  const { isOpen: isConfirmModalOpen , onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure()
  const [ confirmationModalContent, setConfirmationModalContent ] = useState(null);
  const [ confirmationModalHeaderText, setConfirmationModalHeaderText ] = useState(null);
  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  const [ isAlreadyUploaded, setIsAlreadyUploaded ] = useState(null);
  const [ isEditMode, setIsEditMode ] = useState(false);
  const [ uploadingNewReceipt, setUploadingNewReceipt ] = useState(false);

  async function handleConfirmModalClosed() {
    // either redirect to user receipts or close the modal
    onConfirmModalClose()
    await delay(500)
    if (externallyOpened) {
      handleRedirect();
    } else {
      handleClose();
      // onClose()
    }
  }

  // const canvasRef = useRef(null);
  // const myConfetti = useRef(null); // Store confetti instance in a ref

  // useEffect(() => {
  //   // Initialize the confetti instance when the canvas is rendered
  //   if (canvasRef.current && !myConfetti.current) {
  //     myConfetti.current = confetti.create(canvasRef.current, {
  //       resize: true,
  //       useWorker: true
  //     });
  //   }
  // }, [canvasRef.current]);

  

  // const triggerConfetti = () => {
  //   if (myConfetti.current) {
  //     myConfetti.current({
  //       particleCount: 100,
  //       spread: 70,
  //       origin: { y: 0.6 },
  //       colors: ['#bb0000', '#ffffff']
  //     });
  //   } else {
  //     console.warn("Confetti instance not initialized");
  //   }
  // };

  const canvasRef = useRef(null);
  const myConfetti = useRef(null); // Store confetti instance in a ref

  const defaults = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8']
  };

  useEffect(() => {
    if (canvasRef.current && !myConfetti.current) {
      myConfetti.current = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true
      });
    }
  }, [canvasRef.current]);

  const shoot = () => {
    if (myConfetti.current) {
      // Star confetti
      myConfetti.current({
        ...defaults,
        particleCount: 40,
        scalar: 1.2,
        shapes: ['star']
      });

      // Small circle confetti for contrast
      myConfetti.current({
        ...defaults,
        particleCount: 10,
        scalar: 0.75,
        shapes: ['circle']
      });
    }
  };

  const triggerConfetti = () => {
    shoot(); // First shot
    setTimeout(shoot, 100); // Second shot
    setTimeout(shoot, 200); // Third shot
  };



  useEffect(() => {
    // Set the workerSrc to the file in the public folder
    GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  }, []);


  // check if capitalOneInstantNotificationId in SpkCapitalOneCreditCardTransactionReceiptTbl already exists
  // if it does, this receipt has already been processed
  // check if the id exists in instant notification table so we verify they didn't just type a random id

  const [ receiptHasIssue, setReceiptHasIssue ] = useState(false);
  const [ receiptIssue, setReceiptIssue ] = useState('');
  const [ showReceiptUploadOptions, setShowReceiptUploadOptions ] = useState(true);
  const [ editModeIsLocked, setEditModeIsLocked ] = useState(false);
  useEffect(() => {
    const checkTransactions = async () => {
      const transactions = await fetchCapitalOneReceiptByInstantNotificationId(transactionId);
      // this probably needs to change to disabled?
      const transactionsCount = transactions.length;
      if (transactionsCount>0) {
        // this receipt has already been submitted
        setIsAlreadyUploaded(true);
        let transaction = transactions[0];
        console.log('transactionx: ', transaction)
        if (transaction?.hasIssue===true) {
          setReceiptHasIssue(true);
          setReceiptIssue(transaction?.issue);
          setIsEditMode(true);
          setShowReceiptUploadOptions(false);
        } else {
          setConfirmationModalHeaderText('Receipt Already Uploaded');
          setConfirmationModalContent('This receipt has already been uploaded.');
          setShowConfirmationModal(true);
        }
      } else {
        // we don't ever get here?
        // setConfirmationModalHeaderText('Receipt Uploaded');
        // setConfirmationModalContent('Your receipt is uploaded. Thank you!');
        setIsAlreadyUploaded(false);
        setIsEditMode(false);
      }
    }
    
    if (transactionId) {
      checkTransactions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[transactionId])

  const handleShowReplaceReceipt = (show) => () => {
    setShowReceiptUploadOptions(show);
    setEditModeIsLocked(true);
    if (show===true) {
      console.log('showing the replace receipt options')
    } else {
      console.log('hiding the replace receipt options and setting showUploadForm to true')
      setShowUploadForm(true);
      setShowUploadFormRest(true);
    }
  }

  useEffect(() => {
    if (showConfirmationModal && confirmationModalContent && confirmationModalHeaderText) {
      triggerConfetti();
      onConfirmModalOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showConfirmationModal, confirmationModalContent, confirmationModalHeaderText])

  const [ transactionData, setTransactionData ] = useState([]);
  useEffect(() => {

    const getTeamMemberAccounts = async (tmId) => {
      if (!tmId) {
        console.error('No team member id provided.');
        return;
      }
      try {
        const accounts = await fetchAccountsByTeamMember(qbEnv, tmId);

        // Extract cardLastFour values
        const cards = accounts.map(account => account.cardLastFour);

        // setTeamMemberCards(cards)
        return cards;
      } catch (error) {
        console.error('Failed to fetch team member accounts:', error);
      }
    }

    const fetchTransaction = async (id) => {
      if (!id) {
        console.error('No transaction id provided.');
        return;
      }
      try {
        let transaction = {};
        if (selectedActionType === 'upload') {
          transaction = await fetchQuickTransaction(id);
        } else {
          transaction = await fetchManualTransaction(id);
    
          // Check if no transaction data is returned
          if (!transaction || Object.keys(transaction).length === 0) {
            console.warn('No transaction found, generating a new ID.');
            transaction = { id: uuid() };
          }
        }
        console.log('new transaction data: ', transaction);
        setTransactionData(transaction);
        return transaction;
      } catch (error) {
        alert('Failed to fetch transaction.');
        console.error('Failed to fetch transaction:', error);
        handleRedirect();
      }
    };

    const verifyCardHolder = async () => {
      try {
        const transaction = await fetchTransaction(transactionId);
        console.info('transaction: ', transaction)
        const usersCards = await getTeamMemberAccounts(currentTeamMemberId);
        // console.info('usersCards: ', usersCards)
        const cardMatch = usersCards.includes(transaction.accountNumber);
        // console.info('transaction.accountNumber: ', transaction.accountNumber);
        console.info('cardMatch: ', cardMatch);
        // setIsUserCardVerified(cardMatch);
      } catch (error) {
        alert('Failed to verify card holder.');
        console.error('Failed to verify card holder:', error);
        handleRedirect();
      }

    }
    if (transactionId && currentTeamMemberId && selectedActionType) {
      verifyCardHolder()
    }
  },[transactionId, currentTeamMemberId, qbEnv, selectedActionType])

  const fileInputRef = useRef(null);

  // --- REACT-HOOK-FORM ---
  const { 
    setError, 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      isSubmitting,
      errors
    }} = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const [combinedImageFile, setCombinedImageFile] = useState(null);
  const [ file, setFile ] = useState(null)
  const [ images, setImages ] = useState([]);
  const [ streaming, setStreaming ] = useState(false);
  const [ previewMode, setPreviewMode] = useState(false);
  const [ mode, setMode] = useState('Single');  // Track user selection of Single or Combine and now File
  const [ showUploadForm, setShowUploadForm] = useState(false);
  const [ showUploadFormRest, setShowUploadFormRest ] = useState(false);
  const [ isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [ selectedUnits, setSelectedUnits] = useState([]);
  const [ unitOptions, setUnitOptions] = useState([])
  const [ iftaUnitOptions, setIFTAUnitOptions ] = useState([])
  const [ selectedIFTAUnitOption, setSelectedIFTAUnitOption ] = useState([])

  useEffect(() => {
    const fetchAndSetUnits = async () => {
      try {
        const units = await fetchAllUnits();
        // console.log('units: ', units)
        units.sort((a, b) => a.codeSortable.localeCompare(b.codeSortable));

        // create IFTA unit options
        const iftaUnits = units.filter(unit => unit.irpiftaRegistered === true && unit.isActive === true);
        
        const iftaUnitOptions = iftaUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));

        setIFTAUnitOptions(iftaUnitOptions)

        // reduce down to active units
        const activeUnits = units.filter(unit => unit.isActive === true);
        // Create unitOptions
        const options = activeUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));
        
        // console.log('units: ', units)
        setUnitOptions(options)

      } catch (error) {
        console.error('Failed to fetch units:', error);
      }
    };

    fetchAndSetUnits();

  }, []); 

  const [ selectedDivision, setSelectedDivision ] = useState('');
  const [ divisionOptions, setDivisionOptions ] = useState([])
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        // Fetch divisions for 'all'
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Fetch divisions for 'special'
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Combine and sort options
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
        // Find the division that matches usersDivisionId
        const matchingDivision = combinedOptions.find(option => option.value === usersDivisionId);
  
        // Set selectedDivision if a match is found
        if (matchingDivision) {
          setSelectedDivision(matchingDivision);
          setValue('selectDivision', matchingDivision.value);
        }
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDivisionId]); // Add usersDivisionId to the dependency array to rerun if it changes

  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([])

  // Fetch clients whenever selectedDivisionId changes
  useEffect(() => {
    const fetchClients = async () => {
      if (selectedDivision?.value) {
        try {
          const response = await fetchContactCompaniesByDivision(selectedDivision.value);
          const clientOptions = response.map(client => ({
            value: client.value,
            label: client.label,
          }));

          // Add the ' -- None --' option at the beginning
          clientOptions.unshift({ value: '', label: ' - None -' });
        
          setClients(clientOptions);
          setSelectedClient(clientOptions[0])
          setValue('selectClient', clientOptions[0])
        } catch (error) {
          console.error('Failed to fetch clients:', error);
        }
      }
    };

    fetchClients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDivision]); // Add selectedDivisionId to the dependency array

  // if there is a selected client in the transactions data we need to prepopulate the selected client
  useEffect(() => {
    const getClient = (id) => {
      const client = clients.find((client) => client.value === id);

      // If no client is found, return null or a default value
      if (!client) return '';

      // Return the formatted object based on the found client
      const clientOption = {
        value: client.value,
        label: client.label,
      };
      setSelectedClient(clientOption);
    };

    (transactionData?.capitalOneCreditCardTransactionReceipt?.clientId && clients.length) && getClient(transactionData?.capitalOneCreditCardTransactionReceipt?.clientId);
  },[clients, transactionData])


  //fetchQuickBooksExpenseCategories
  const [ expenseCategories, setExpenseCategories ] = useState([])
  const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('')
  const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  useEffect(() => {
    const fetchAllExpenseCategories = async () => {
      try {
        const response = await fetchQuickBooksExpenseCategories(qbEnv);
        // console.log('response: ', response)
        const categories = response.map(expense => ({
          value: expense.qbId,
          label: expense.appName,
          isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
        }));

        // Add the ' -- None --' option at the beginning
        // categories.unshift({ value: '', label: ' - None -' });

        // console.log('categories: ', categories)

        setExpenseCategories(categories);
        // setSelectedExpenseCategory(categories[0])
        // setValue('selectExpenseCategory', categories[0])
      } catch (error) {
        console.error('Failed to fetch expense categories:', error);
      }
    };

    fetchAllExpenseCategories();
  }, [qbEnv]);

  // const handleClose = () => {
  //   window.close();
  // };

  const handleRedirect = () => {
    const { hostname, port } = window.location;

    console.log('hostname: ', hostname);
    console.log('port: ', port);

    // Define a base URL depending on environment
    let baseUrl;

    // Check if running locally
    if (hostname === 'localhost' || hostname === '192.168.10.226') {
      // Use the specified port if in local development
      baseUrl = `https://${hostname}:${port}/user-receipts`;
    } else {
      // For non-localhost environments, use the production base URL without port
      baseUrl = `https://${hostname}/user-receipts`;
    }

    console.log('baseUrl: ', baseUrl);
    // Perform the redirect
    window.location.href = baseUrl;

    // window.location.href = 'https://brain.spikeenterprise.io';
    // let hostname = window.location.hostname;
    // window.location.href = hostname + '/user-receipts';
    // window.location.href = `https://${hostname}/user-receipts`;
  }

  const updateSelectedExpenseCategory = (event) => {
    console.log('event: ', event)
    setIsIFTATracked(event?.isIFTATracked===true ? true : false)
    setSelectedExpenseCategory(event);
    setValue('selectExpenseCategory', event.value)
    clearErrors('selectExpenseCategory')

    setShowUploadFormRest(true)
  }

  // if we are in edit mode we need to prepopulate the selected expense category
  useEffect(() => {
    const getExpenseCategory = (id) => {
      const expense = expenseCategories.find((category) => category.value === id);
  
      // If no expense is found, return null or a default value
      if (!expense) return '';
  
      // Return the formatted object based on the found expense
      const expenseCategory = {
        value: expense.value,
        label: expense.label,
        isIFTATracked: expense?.isIFTATracked === true ? true : false,
      };
      updateSelectedExpenseCategory(expenseCategory)
      // console.log('expenseCategory: ', expenseCategory);
      // setSelectedExpenseCategory(expenseCategory);
    };

    (transactionData?.capitalOneCreditCardTransactionReceipt?.expenseCategoryId && 
      expenseCategories.length) && getExpenseCategory(transactionData?.capitalOneCreditCardTransactionReceipt?.expenseCategoryId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[expenseCategories, transactionData])

  const updateSelectedDivision = (event) => {
    setSelectedDivision(event);
  };

  const updateSelectedClient = (event) => {
    setSelectedClient(event);
    setValue('selectClient', event.value)
  };

  // we need to see if there are any selected units in the transaction data and prepopulate the selectedUnits based on those if they exist
  useEffect(() => {

    const getUnits = async (units) => {
      const selectedUnits = units?.map(unit => {
        // Find the matching unit option where the `value` matches the `unitId`
        const matchedOption = unitOptions.find(option => option.value === unit.unitId);
        
        // Return the matched option (or null if not found)
        return matchedOption || null;
      }).filter(option => option !== null);  // Filter out any nulls if no matches are found
      
      console.log('unit options: ', selectedUnits);
      setSelectedUnits(selectedUnits);

    }
    

    (transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items?.length>0 && unitOptions.length) && getUnits(transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items);
  },[transactionData, unitOptions])

  const handleUnitsChange = (event, action) => {
    if (action?.action === 'select-option') {
      const addedOption = { value: action?.option?.value, label: action?.option?.label, key: action?.option?.value };
      setSelectedUnits([...selectedUnits, addedOption]);
    }
    if (action?.action === 'remove-value') {
      const removedOption = { value: action?.removedValue?.value, label: action?.removedValue?.label, key: action?.removedValue?.value };
      const newUnits = selectedUnits.filter((unit) => unit.value !== removedOption.value);
      setSelectedUnits(newUnits);
    }
  };

  const [ selectedState, setSelectedState ] = useState('')
  const updateSelectedState = (event) => {
    if (event.value==='') {
      setSelectedState(event)
      setValue('selectState', event)
      setError('selectState', {
        type: 'manual',
        message: 'State is required'
      })
    } else {
      setSelectedState(event)
      setValue('selectState', event)
      clearErrors('selectState')
    }
  }

  const handleIFTAUnitChange = (event) => {
    setSelectedIFTAUnitOption(event);
    setValue('selectOTRUnit', event.value)
    clearErrors('selectOTRUnit')
  };
  
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const videoRef = useRef(null);

  // useEffect(() => {
  //   const getVideoDevices = async () => {
  //     try {
  //       const devices = await navigator.mediaDevices.enumerateDevices();
  //       await delay(500);
  //       const videoInputs = devices.filter(device => device.kind === 'videoinput');
  //       setVideoDevices(videoInputs);

  //       // alert( JSON.stringify(videoInputs) );
  
  //       // Set default device to the first one if no device is already selected
  //       if (videoInputs.length > 0 && !selectedDevice) {
  //         setSelectedDevice(videoInputs[0].deviceId);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching video devices:', error);
  //     }
  //   };
  
  //   getVideoDevices();
  // }, [selectedDevice]);

  // Automatically start the camera when the page loads
  useEffect(() => {
    // const initializeCamera = async () => {
    //   console.log('INITIALIZING CAMERA ON PAGE LOAD');

    //   const hasPermission = await checkAndRequestCameraPermissions();
    //   console.log('HAS PERMISSION: ', hasPermission);

    //   if (hasPermission && videoRef.current) {
    //     await getVideoDevices();
    //     startCamera();  // Start the camera after devices are fetched and permissions are granted
    //   }
      
    // };
    const tryGetUserMedia = async (videoDevices) => {
      const constraints = [
        // Try 1: Simple environment camera request
        { 
          video: { 
            facingMode: { ideal: 'environment' }
          } 
        },
        // Try 2: Basic video request
        { 
          video: true 
        },
        // Try 3: Try each device explicitly
        ...videoDevices.map(device => ({
          video: { 
            deviceId: { exact: device.deviceId },
            facingMode: { ideal: 'environment' }
          }
        }))
      ];
    
      for (const constraint of constraints) {
        try {
          console.log('Trying constraint:', constraint);
          const stream = await navigator.mediaDevices.getUserMedia(constraint);
          return stream;
        } catch (error) {
          console.log('Constraint failed:', constraint, error);
          continue;
        }
      }
      
      return null;
    };
    
    const initializeCamera = async () => {
      try {
        console.log('Initializing camera...');
        
        // First try to get all video devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        await delay(500);
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        
        if (videoDevices.length === 0) {
          throw new Error('No camera devices found');
        }
    
        // Try progressive fallback approaches
        const stream = await tryGetUserMedia(videoDevices);
        
        if (!stream) {
          throw new Error('Failed to get camera stream');
        }
    
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play();
          setStreaming(true);
          setIsVideoLoaded(true);
        }
    
      } catch (error) {
        console.error('Camera initialization error:', error);
        handleCameraError(error);
      }
    };
    

    // by default show this for new receipts
    if (isAlreadyUploaded===false && !isEditMode) {
      console.log('starting camera at 1')
      initializeCamera();
    }
    // we also need to show this if showReceiptUploadOptions is true and isEditMode is true and isAlreadyUploaded = true
    if (showReceiptUploadOptions && isEditMode && isAlreadyUploaded && editModeIsLocked) {
      console.log('showing camera for edit mode')
      initializeCamera();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadyUploaded, isEditMode, showReceiptUploadOptions, editModeIsLocked]);

  const stopCamera = (mode) => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
      // setStreaming(false);
    }
    setMode({mode}||'');
  }; 
  
  const switchCamera = async (deviceId) => {
    stopCamera(); // Stop current camera before switching
    await delay(500); // Add a small delay before starting the new camera
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: deviceId } } });
      videoRef.current.srcObject = stream;
      await videoRef.current.play();
      alert('Camera started successfully');
    } catch (error) {
      alert('Failed to start camera: ' + error.message);
      if (error.name === 'NotReadableError') {
        alert('The camera is already in use by another application.');
      }
    }
  };

  const handleDeviceChange = (event) => {
    const selectedDeviceId = event.target.value;
    alert('Selected device: ' + selectedDeviceId);
    setSelectedDevice(selectedDeviceId); // Update state
    switchCamera(selectedDeviceId); // Call the function to switch the camera
  };
  
  const handleCameraError = (error) => {
    console.error('Camera error:', error);
    
    let errorMessage = 'Failed to access camera. ';
    
    if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
      errorMessage += 'Please close other apps that might be using the camera and try again.';
    } else if (error.name === 'NotAllowedError' || error.name === 'SecurityError') {
      errorMessage += 'Please grant camera permissions and refresh the page.';
    } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
      errorMessage += 'No camera found on this device.';
    } else if (error.name === 'OverconstrainedError') {
      errorMessage += 'Unable to find a suitable camera. Please try a different one.';
    }
    
    // alert(errorMessage);
  };

  // Function to start the camera once permissions are granted
  const [ showMultiPictureButtons, setShowMultiPictureButtons ] = useState(false);
  const startCamera = async (deviceId = null) => {
    if (!videoRef.current) {
      console.error('Video element not ready');
      return;
    }
  
    // Stop any existing streams
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  
    try {
      // Start with a basic constraint
      let constraints = {
        video: deviceId ? 
          { deviceId: { exact: deviceId } } : 
          { facingMode: { ideal: 'environment' } }
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      await videoRef.current.play();
      setStreaming(true);
      setIsVideoLoaded(true);
      
    } catch (error) {
      console.error('Failed to start camera:', error);
      
      // If the specific device failed, try fallback to any camera
      if (deviceId) {
        try {
          const fallbackStream = await navigator.mediaDevices.getUserMedia({ video: true });
          videoRef.current.srcObject = fallbackStream;
          await videoRef.current.play();
          setStreaming(true);
          setIsVideoLoaded(true);
        } catch (fallbackError) {
          handleCameraError(fallbackError);
        }
      } else {
        handleCameraError(error);
      }
    }
  };

  const checkAndRequestCameraPermissions = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'camera' });
      if (permission.state === 'denied') {
        alert('Camera access is denied. Please enable it in your browser settings.');
        return false;
      } else if (permission.state === 'prompt' || permission.state === 'granted') {
        // Request camera access if prompted
        await navigator.mediaDevices.getUserMedia({ video: true });
        return true;
      }
      return true;
    } catch (error) {
      console.error('Error requesting camera permissions:', error);
      return false;
    }
  };

  const getVideoDevices = async () => {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    console.log('deviceInfos: ', deviceInfos);
    
    const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
    
    // Check for device labels only after permissions are granted
    if (videoDevices.length > 0) {
      const backCamera = videoDevices.find(device => 
        device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear')
      );
  
      if (backCamera) {
        console.log('Back camera found:', backCamera);
        setSelectedDevice(backCamera.deviceId);
      } else {
        // Default to the first device if no back camera is found
        console.log('Defaulting to the first camera');
        setSelectedDevice(videoDevices[0].deviceId);
      }
    } else {
      console.warn('No video input devices found');
    }
  };

  const capturePhoto = () => {
    setModeIsLocked(true);
    const video = videoRef.current;
    const canvas = canvasRef.current;
  
    if (!video || !canvas) {
      console.warn("Video or Canvas is not ready");
      return;
    }
  
    const width = video.videoWidth;
    const height = video.videoHeight;
  
    // Set canvas dimensions to match video dimensions
    canvas.width = width;
    canvas.height = height;
  
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/png');
  
    setImages([...images, dataUrl]);
    setPreviewMode(true);
  
    // Stop the camera stream after capturing the photo
    if (video.srcObject) {
      video.srcObject.getTracks().forEach(track => track.stop());
    }
    // setStreaming(false);
    setShowMultiPictureButtons(true);
  };

  const handleRetake = () => {
    // Reset preview mode to false so the camera is shown again
    setPreviewMode(false);
  
    // Remove the last captured image (if any)
    setImages(images.slice(0, -1));
  
    // Restart the camera if needed (if you're stopping the camera after capture)
    startCamera();  // Ensure the camera view is restarted for retaking

  };

  const handleKeep = () => {
    setPreviewMode(false);
    if (mode === 'Single') {
      handleDone();
    }
  };

  const handleDone = async () => {
    const combinedImage = await combineImages(images);
    setImages([combinedImage]);
    const file = dataURLtoFile(combinedImage, uuid()+'.png');
    setCombinedImageFile(file);
    setShowUploadForm(true);
    setUploadingNewReceipt(true);
  };
  
  const combineImages = async (images) => {
    const loadedImages = await Promise.all(images.map(src => {
      return new Promise((resolve, reject) => {
        const img = new window.Image();  // Use the global Image constructor
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    }));
  
    const totalHeight = loadedImages.reduce((sum, img) => sum + img.height, 0);
    const maxWidth = Math.max(...loadedImages.map(img => img.width));
  
    const canvas = document.createElement('canvas');
    canvas.width = maxWidth;
    canvas.height = totalHeight;
    const context = canvas.getContext('2d');
  
    let yOffset = 0;
    loadedImages.forEach(img => {
      context.drawImage(img, 0, yOffset);
      yOffset += img.height;
    });
  
    return canvas.toDataURL('image/png');
  };
  
   

  // We are already doing this on page load
  // useEffect(() => {
  //   const checkPermissionsAndFetchDevices = async () => {
  //     const hasPermission = await checkAndRequestCameraPermissions();
  //     if (hasPermission) {
  //       await getVideoDevices();
  //       startCamera();  // Start the camera after devices are fetched and permissions are granted
  //     }
  //   };
  
  //   if (isAlreadyUploaded===false) {
  //     console.log('checking permissions on load')
  //     checkPermissionsAndFetchDevices();
  //   }
    
  // }, [isAlreadyUploaded, editModeIsLocked]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetForm = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    // setImage(null)
    // setMode('Single');
    // setMode('');
    // setFile(null)
    // setImages([]);
    // setStreaming(true);
    // setDevices([]);
    // setSelectedDevice('');
    // setSelectedCamera('');
    // setPreviewMode(false);
    // setShowUploadForm(false);
    setSelectedUnits([]);
    setSelectedDivision('');
    setSelectedClient('');
    setValue('odometer', '');
    setValue('gallons', '');
    setSelectedIFTAUnitOption('');
    setSelectedExpenseCategory('');
    clearErrors();
  };

  // const [uploadTypeOption, setUploadTypeOption] = useState(null);

  // const handleUploadTypeSelect = (value) => {
  //   setUploadTypeOption(value);
  // };

  const handleOdometerKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleOdometerChange = (e) => {
    if (e.target.value==='') return
    const miles = stripToNumbersAndDecimal(e.target.value);
    setValue('odometer', miles);
    clearErrors('odometer');
  };

  const handleGallonsKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleGallonsChange = (e) => {
    if (e.target.value==='') return
    const gallons = stripToNumbersAndDecimal(e.target.value);
    setValue('gallons', gallons);
    clearErrors('gallons');
  };
  
  // const handleUpload = () => {
  //   if (combinedImageFile) {
  //     console.log('combinedImageFile: ', combinedImageFile)
  //     // const statementResult = await addStatementDocument()
  //     // await the insert record into statements with the client, division
  //     // then using the statementId create the records for the units
  //     // then using the statement id we can pass the statementId to the uploadStatement function
  //     const statementId = '01234567890'
  //     uploadStatement(combinedImageFile, statementId);
  //   } else {
  //     alert('No image to upload.');
  //   }
  // };

  const formSubmit = async (values) => {

    // if selectedActionType is manual we need to handle for different transaction data fields
    // this can be done before any plaid transaction data is received
    let isManualUpload = false;
    if (selectedActionType==='manual') {
      isManualUpload = true;
    }

    // if (window.navigator && window.navigator.vibrate) {
    //   window.navigator.vibrate([50]); // Simple haptic feedback on supported devices
    // }
    // if (qbEnv!=='sandbox') return;
    if (!values.selectDivision) return
    console.log('Form Values:', values);
    console.log('currentTeamMemberId: ', currentTeamMemberId)
    console.log('selectedExpenseCategory: ', selectedExpenseCategory)
    console.log('selectedClient: ', selectedClient)
    console.log('selectedUnits: ', selectedUnits)
    
    // update the SpkIFTAFuelTaxTrackingTbl table
    // allSort, satementDate, statementId, yearQuarter, unitId, teamMemberId, stateTwoChar, gallons, odometer
    // selectOTRUnit, odometer, gallons

    // if they chose to keep the existing uploaded receipt image, we need to just update everything but the uploaded documents

    if (combinedImageFile || files || isEditMode) {
      console.log('receipt files: ', combinedImageFile||files);

      // use existing for update or generate new one for create
      const receiptId = isEditMode ? transactionData.capitalOneCreditCardTransactionReceiptId : uuid();

      // clientId, expenseCategory, units
      const statementId = receiptId;
      const statementParams = {
        id: statementId,
        allSort: "all",
        isReceiptUploaded: true,
        addedNotes: values?.addedNotes,
        divisionId: selectedDivision?.value,
        ...(selectedClient?.value && { clientId: selectedClient.value }),
        teamMemberId: currentTeamMemberId,
        ...(selectedExpenseCategory?.value && { expenseCategoryId: selectedExpenseCategory.value }),
      };

      if (!isManualUpload) {
        statementParams.capitalOneInstantNotificationId = transactionData.id;
        statementParams.matchStatus = "ready";   // if hasIssues this needs to be fixed-issues
      } else {
        statementParams.matchStatus = "manual";
      }
  
      try {


        // uploadingNewReceipt

        // if this is diesel for OTR, update the iftaTracking table
        // this is going to have to be updated th reference the new transaction table
        if (isIFTATracked) {
          const iftaTrackingJSON = {
            allSort: 'all',
            transactionId: statementId,
            unitId: selectedIFTAUnitOption?.value,
            teamMemberId: currentTeamMemberId,
            stateTwoChar: values?.selectState?.value,
            gallons: values?.gallons,
            odometer: values?.odometer,
          }
  
          // update iftaTracking table
          console.log('iftaTrackingJSON: ', iftaTrackingJSON)
          const iftaFuelTaxTrackId = await addIFTAFuelTracking(iftaTrackingJSON)
          console.log('iftaFuelTaxTrackId: ', iftaFuelTaxTrackId)
          statementParams.iftaFuelTaxTrackId = iftaFuelTaxTrackId
        }

        let receiptId = ''
        if (isEditMode && !isManualUpload) {
          const updateReceiptResultId = await updateCapitalOneReceipt(statementParams)
          console.log('updateReceiptResultId: ', updateReceiptResultId)
          receiptId = updateReceiptResultId
          if (uploadingNewReceipt) {
            if (combinedImageFile) {
              // Upload the combined image file
              const uploadedDocument = await uploadStatement(combinedImageFile, transactionData.id);
              console.log('Uploaded combined image file: ', uploadedDocument);
  
              // Link the combined uploaded document to the receipt
              const createLinkId = await addStatementDocumentLink({
                receiptId: updateReceiptResultId,
                uploadedDocumentId: uploadedDocument?.documentId
              });
              console.log('createLinkId for combined file: ', createLinkId);
            } else if (files && files.length > 0) {
              // If individual files exist, upload each one
              const uploadedDocuments = await Promise.all(
                files.map(file => uploadStatement(file, transactionData.id))
              );
              console.log('Uploaded individual files: ', uploadedDocuments);
  
              // Link each uploaded document to the receipt
              for (const uploadedDocument of uploadedDocuments) {
                const createLinkId = await addStatementDocumentLink({
                  receiptId: updateReceiptResultId,
                  uploadedDocumentId: uploadedDocument?.documentId
                });
                console.log('createLinkId for individual file: ', createLinkId);
              }
            }
          }
        } else {
          const createReceiptResultId = await createCapitalOneReceipt(statementParams)
          console.log('createReceiptResultId: ', createReceiptResultId)
          receiptId = createReceiptResultId
          // If a combined image file exists, upload it, otherwise upload the individual files
          if (combinedImageFile) {
            // Upload the combined image file
            const uploadedDocument = await uploadStatement(combinedImageFile, transactionData.id);
            console.log('Uploaded combined image file: ', uploadedDocument);

            // Link the combined uploaded document to the receipt
            const createLinkId = await addStatementDocumentLink({
              receiptId: createReceiptResultId,
              uploadedDocumentId: uploadedDocument?.documentId
            });
            console.log('createLinkId for combined file: ', createLinkId);
          } else if (files && files.length > 0) {
            // If individual files exist, upload each one
            const uploadedDocuments = await Promise.all(
              files.map(file => uploadStatement(file, transactionData.id))
            );
            console.log('Uploaded individual files: ', uploadedDocuments);

            // Link each uploaded document to the receipt
            for (const uploadedDocument of uploadedDocuments) {
              const createLinkId = await addStatementDocumentLink({
                receiptId: createReceiptResultId,
                uploadedDocumentId: uploadedDocument?.documentId
              });
              console.log('createLinkId for individual file: ', createLinkId);
            }
          } else {
            throw new Error('No image or file to upload.');
          }
        }

        if (!isManualUpload) {
          const updateParams = {
            id: transactionData.id,
            allSort: 'all',
            capitalOneCreditCardTransactionReceiptId: receiptId,
            isSubmitted: true,
            // status: 'has-receipt',
            status: receiptHasIssue ? "fixed-issue" : "has-receipt",
          }
  
          const updateSubmitted = await updateInstantNotification(updateParams)
          console.log('updateSubmitted: ', updateSubmitted)
        }
        
        // Create an array of promises for inserting unit records if this is NOT ifta
        const unitPromises = selectedUnits.map(unit => {
          const unitParams = {
            unitId: unit.value,
            transactionId: transactionData.id,
          };
          return addStatementDocumentUnit(unitParams);
        });
  
        // Wait for all unit insertions to complete
        await Promise.all(unitPromises);
  
        console.log('Upload and database operations completed successfully.');
        setConfirmationModalHeaderText('Success!');
        setConfirmationModalContent('Your receipt has been uploaded. Thank you!');
      } catch (error) {
        console.error('Error during form submission:', error);
        setConfirmationModalHeaderText('Error');
        setConfirmationModalContent(error.message);
        // alert('There was an error processing your request. Please try again.');
      }
    } else {
      alert('No image to upload.');
    }
    
    setShowConfirmationModal(true);
  };
  
  async function uploadStatement(file) {
    // Referernce for when multi-file receipt upload is defined
    // https://medium.com/@steven_creates/uploading-multiple-files-to-aws-s3-using-react-js-hooks-react-aws-s3-1dd29221c91c
    // It might work better to sack the images into one image and THEN process that image
    // https://github.com/lukechilds/merge-images

    // setIsUploadStatementDisabled(true)
    // console.log('uploaded file info: ', file)
    // This needs to be a UUID not TM id, we don't want to overwrite what is already there.
    // const imgKey = teamMemberId + '-' + file.name;
    // const imgKey = uuid() + ':' + currentTeamMemberId + ':' + file.name + ':' + statementDocumentId;
    
    // let credentials = await Auth.currentCredentials();
    // console.log("imgKey: ",imgKey)
    // let identityId = credentials.identityId;
    const bucketVersion = qbEnv === 'sandbox' ? 'dev' : 'prod';
    let userBucket = `analyze-expense-statements-${bucketVersion}`;
    // const params = {
    //   bucket: userBucket,
    //   identityId: identityId, 
    //   key: imgKey
    // }

    // 1. add the document to SpkUploadedDocumentsTbl, return the documentId
    // 2. upload to 'analyze-expense-statements'
    // 3. duplicate file to 'textract-analyzexpense-sourcebucket-1rors02oulldp' for textract

    // const uploadData = await uploadFileToS3({ file: file, bucket: userBucket, key: imgKey })
    const uploadData = await uploadFileToS3({ file: file, bucket: userBucket })
    console.log('uploadData: ', uploadData)

    // const duplicateFile = await Storage.put(imgKey, file, {
    //   bucket: 'textract-analyzexpense-sourcebucket-1rors02oulldp',
    //   contentType: file.type
    // })
    // console.log('File has been dropped in source bucket for processing: ', duplicateFile)

    return uploadData

    // At this point:
    // 1.) The uploaded file has been given a unique key as uuid:teamMemberId:fileName
    // AND a record has been created in SpkDocumentUploadsTbl with the key for reference
    // 2.) The file has been saved to protected S3 for owner/admin access
    // 3.) The file has ALSO been put in the S3 bucket 'textract-analyzexpense-sourcebucket-1rors02oulldp'
    // for processing
  }
  
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const [ modeIsLocked, setModeIsLocked ] = useState(false);
  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  useEffect(() => {
    if (mode !== '' && mode !== 'File') {
      startCamera();
    }
    if (mode==='File') {
      console.log('mode: ', mode)
      const video = videoRef.current;
      const canvas = canvasRef.current;
      canvas.width = '0px';
      canvas.height = '0px';
      // Stop the camera stream after capturing the photo
      if (video.srcObject) {
        video.srcObject.getTracks().forEach(track => track.stop());
      }
    //   stopCamera();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);  // Array to hold preview images

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to array
    setFiles(selectedFiles);

    // Generate previews for each selected file
    const previewPromises = selectedFiles.map(file => generatePreview(file));

    // Wait for all previews to be generated
    Promise.all(previewPromises).then(previewImages => {
      setPreviews(previewImages);  // Update state with all previews
    });
    if (selectedFiles.length > 0) {
      setShowUploadForm(true);
    }
  };

  const generatePreview = async (file) => {
    if (file.type.startsWith("image/")) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);  // Resolve with the image URL
        };
        reader.readAsDataURL(file);
      });
    } else if (file.type === "application/pdf") {
      return generatePdfPreview(file);
    }
    return null;
  };

  const generatePdfPreview = async (pdfFile) => {
    const fileReader = new FileReader();
    return new Promise((resolve) => {
      fileReader.onload = async function () {
        const typedarray = new Uint8Array(this.result);

        const loadingTask = pdfjsLib.getDocument(typedarray);
        const pdf = await loadingTask.promise;

        // Get the first page of the PDF
        const page = await pdf.getPage(1);

        // Set up a canvas for rendering
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 0.5 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;

        // Convert canvas to data URL and resolve it
        resolve(canvas.toDataURL());
      };

      fileReader.readAsArrayBuffer(pdfFile);
    });
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleClose = () => {
    //reset all states to their initial values
    setModeIsLocked(false);
    setImages([])
    setMode('Single');
    setFiles(null)
    setPreviews([])
    setStreaming(false);
    setCombinedImageFile(null);
    setIsVideoLoaded(false);
    setUnitOptions([]);
    setIFTAUnitOptions([]);
    setSelectedIFTAUnitOption([]);
    setSelectedDevice('');
    setPreviewMode(false);
    setShowUploadForm(false);
    setSelectedUnits([]);
    setSelectedDivision('');
    setSelectedClient('');
    setValue('odometer', '');
    setValue('gallons', '');
    setSelectedExpenseCategory('');
    clearErrors();
    onClose();
  };

  return (
    <>
      <Container padding='25px' as="form" onSubmit={handleSubmit(formSubmit, onError)}>

      <div>
        {/* Button to trigger confetti */}
        {/* <button type="button" onClick={triggerConfetti}>Launch Drawer Confetti</button> */}
        
        {/* Drawer with canvas for confetti */}
        <div>
          <canvas
            ref={canvasRef}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000,
              pointerEvents: 'none',
            }}
          />
        </div>
      </div>

        {(isAlreadyUploaded===false || isEditMode===true) && (
          <>
            <Flex
              direction="column"
              maxW="800px"
              mx="auto"
              color="white"
              minH="100vh"
            >
              <Box>
                <Flex direction="row" alignItems="flex-start" justifyContent="space-between" pb="25px">
                  {/* Left Container */}
                  <Box>
                    <Text as="span" textStyle="heading-1">{receiptHasIssue ? "Edit Receipt" : "Upload Receipt"}</Text>
                    {transactionData?.purchaseMerchant && (
                      <Text as="span" textStyle="label-2" display="block" mt="4px">
                        {transactionData?.purchaseMerchant} ${transactionData?.purchaseAmount}
                      </Text>
                    )}
                    {receiptHasIssue && (
                      <Text as="span" textStyle="label-2" display="block" mt="4px" color="red.500">{receiptIssue}</Text>
                    )}
                  </Box>

                  {/* Spacer between Left and Right Content */}
                  <Spacer />

                  {/* Right Container - Button */}
                  <Box>
                    {!externallyOpened && (
                      <ButtonQuaternaryWithIcon 
                        name="closeDrawer"
                        iconsize="26px"
                        leftIcon="close"
                        value="Cancel"
                        onClick={handleClose}
                      />
                    )}
                  </Box>
                </Flex>

                <Box>
                  <VStack spacing={4}>
                    <>
                      {(isEditMode && !editModeIsLocked) && (<>
                        <ButtonPrimaryPlain
                          type='button'
                          name='replaceReceipt'
                          value='Replace Receipt'
                          onClick={handleShowReplaceReceipt(true)}
                        />
                        <ButtonPrimaryPlain
                          type='button'
                          name='keepReceipt'
                          value='Edit Details'
                          onClick={handleShowReplaceReceipt(false)}
                        />
                      </>)}
                      
                      {images.length > 0 && (
                        <Box>
                          {images.map((image, index) => (
                            <ChakraImage key={index} src={image} alt={`Receipt ${index + 1}`} />
                          ))}
                          {images.length > 0 && previewMode && (
                            <Flex direction="column" align="center" w="100%">
                              <Flex 
                                justify="space-between"
                                align="center"
                                w={'90%'}
                                my={'25px'}
                                mx={'auto'}
                              >
                                <ButtonQuaternaryPlain
                                  type='button'
                                  name='retake'
                                  value='Retake'
                                  onClick={handleRetake}
                                  w='45%'
                                />
                                <ButtonPrimaryPlain
                                  // mx={'20px'}
                                  type="button"
                                  onClick={handleKeep}
                                  name="keep"
                                  value="Keep"
                                  w='45%'
                                />
                              </Flex>
                            </Flex>
                          )}
                        </Box>
                      )}

                      {/* If selectedActionType = manual we need to allow them to select an image from the 
                          receipt uploads that did not have an instant notification. */}

                      {(mode==='File') && (
                        <>
                          <Flex wrap="wrap" mt={4}>
                            {previews.map((preview, index) => (
                              <Box key={index} m={2}>
                                <img src={preview} alt={`Preview ${index + 1}`} width="200px" />
                              </Box>
                            ))}
                          </Flex>
                          <Box>
                            <Text as="span" textStyle='label-2'>Upload PDF or Image</Text>
                            <Input 
                              name='fileUpload'
                              id='fileUpload'
                              paddingTop={'6px'} 
                              paddingLeft={'8px'} 
                              placeholder='Choose File(s)' 
                              type="file" 
                              accept="image/jpeg, image/jpg, image/png, application/pdf"
                              multiple
                              onChange={handleFileChange}
                              ref={fileInputRef} />
                          </Box>
                        </>
                      )}

                      {showMultiPictureButtons && 
                        <>
                          {images.length > 0 && !previewMode && mode === 'Combine' && !showUploadForm && (
                            <ButtonPrimaryPlain
                              // mx={'20px'}
                              type="button"
                              onClick={startCamera}
                              name="continue"
                              value="Take another picture"
                            />
                          )}
                          {showMultiPictureButtons && !previewMode && !showUploadForm && (
                            <ButtonQuaternaryPlain
                              type='button'
                              name='done'
                              value='I am done taking pictures'
                              onClick={handleDone}
                            />
                          )}
                        </>
                      }

                      {(!showUploadForm) && <>
                        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                          {/* <Box width="100%" position="relative">
                            <Box>
                              <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                style={mode!=='File' ? { width: '100%', height: 'auto' } : {width: '0px', height: '0px'}}
                                onLoadedMetadata={() => setIsVideoLoaded(true)}
                              ></video>
                            </Box>
                          </Box> */}
                          {/* Camera Selection Dropdown */}
                          {videoDevices.length > 0 && (
                            <Box mb={4} width="100%">
                              {/* <Text as="span" textStyle="label-2">Select Camera:</Text> */}
                              <FormSelectSimple
                                register={register}
                                control={control}
                                errors={errors}
                                onChange={handleDeviceChange}
                                selectedoption={selectedDevice||''}
                                optionsArray={videoDevices?.map(( device, index ) => ({
                                  label: device.label || `Camera ${index + 1}`,
                                  value: device.deviceId,
                                }))}
                                fieldname='seletCamera'
                                prettyname='Select Camera'
                                fieldlabel="Select Camera:"
                                placeholder={'Select camera'}
                                issearchable={false}
                              />
                            </Box>
                          )}

                          {/* Video Display */}
                          <Box width="100%" position="relative">
                            <Box>
                              <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                style={mode !== 'File' ? { width: '100%', height: 'auto' } : { width: '0px', height: '0px' }}
                                onLoadedMetadata={() => setIsVideoLoaded(true)}
                              ></video>
                            </Box>
                          </Box>
                          {( !previewMode && isVideoLoaded && !showUploadForm && mode!=='File') && (
                            <>
                              <Flex direction="column" align="center" w="100%">
                              {!previewMode && (
                                <Flex justify="space-between" align="center" w={'90%'} my={'25px'}>
                                  {/* Left-aligned upload button isSupervisor */}
                                  <Box w='40px'>
                                    {(!isMobile || (isMobile && isSupervisor)) && ( 
                                      <ButtonNonaryWithIconOnly
                                        h='24px'
                                        w='24px'
                                        name={'uploadFile'}
                                        icon='uploadFile'
                                        iconsize='24px'
                                        onClick={() => { handleModeChange('File'); }}
                                        isDisabled={modeIsLocked}
                                      />
                                    )}
                                  </Box>
                                  
                                  {/* Center-aligned SINGLE and COMBINE buttons */}
                                  {(mode!=='File') && <Flex justify="center" align="center" flex="1" paddingLeft={'12px'}>
                                    <ButtonDuodenaryPlain
                                      w='105px'
                                      name='single'
                                      value='SINGLE'
                                      onClick={() => { handleModeChange('Single'); }}
                                      isDisabled={modeIsLocked}
                                      state={mode === 'Single' ? 'selected' : 'default'}
                                    />
                                    <ButtonDuodenaryPlain
                                      w='120px'
                                      name='combine'
                                      value='COMBINE'
                                      onClick={() => { handleModeChange('Combine'); }}
                                      isDisabled={modeIsLocked}
                                      state={mode === 'Combine' ? 'selected' : 'default'}
                                    />
                                  </Flex>}

                                  <Box w='40px' />
                                </Flex>
                              )}

                              {/* Second row - centered button */}
                              <Flex justify="center" w="90%">
                                {!showMultiPictureButtons && (
                                  <ButtonSeptenaryWithIconOnly
                                    h='64px'
                                    w='64px'
                                    mt='5px'
                                    name='capture'
                                    icon='capture'
                                    iconsize='40px'
                                    onClick={capturePhoto}
                                  />
                                )}
                              </Flex>
                            </Flex>
                          </>
                        )}
                      </Box>

                        <canvas ref={canvasRef} width={640} height={480} style={{ display: 'none' }}></canvas>

                        
                      </>}
                    </>
                  </VStack>
                </Box>
              </Box>

              {showUploadForm && (
                <Box mt={'25px'}>

                  <Box paddingTop={'25px'}>
                    <FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      rules={{ required: "Expense Category is required" }}
                      onChange={e => updateSelectedExpenseCategory(e)}
                      selectedoption={selectedExpenseCategory||''}
                      optionsArray={expenseCategories}
                      fieldname='selectExpenseCategory'
                      prettyname='Expense Category'
                      fieldlabel="Expense Category"
                      placeholder={'Select expense category'}
                      issearchable={false}
                    />
                  </Box>

                  {(showUploadForm && showUploadFormRest) && (<>
                    <Box paddingTop={'25px'}>
                      <TextareaInput
                        register={register}
                        errors={errors}
                        // rules={{ required: "Required" }}
                        fieldname="addedNotes"
                        fieldlabel="Expense Notes"
                        prettyname="Expense Notes"
                        placeholder="Enter any notes about this expense"
                        defaultvalue={isEditMode ? transactionData?.capitalOneCreditCardTransactionReceipt?.addedNotes : ''}
                        // onChange={handleJobDescriptionChange}
                        // onBlur={handleJobDescriptionChange}
                        // isRequired
                      />
                    </Box>
                    
                    <Box paddingTop={'25px'}>
                      <FormSelectSimple
                        key='Division'
                        register={register}
                        control={control}
                        errors={errors}
                        isRequired={true}
                        rules={{ required: "Division is required" }}
                        fieldname="selectDivision"
                        fieldlabel="Division"
                        placeholder={'Select division'}
                        optionsArray={divisionOptions?.map((division, index) => ({
                          key: index,
                          value: division.value,
                          label: division.label
                        }))}
                        onChange={(e) => updateSelectedDivision(e)}
                        selectedoption={selectedDivision||''}
                        issearchable={false}
                      />
                    </Box>

                    <Box paddingTop={'25px'}>
                      <FormSelectSimple
                        key='Client'
                        register={register}
                        control={control}
                        errors={errors}
                        isRequired={true}
                        fieldname="selectClient"
                        fieldlabel="Client"
                        placeholder={'Select client'}
                        optionsArray={clients}
                        onChange={(e) => updateSelectedClient(e)}
                        selectedoption={selectedClient||''}
                        issearchable={false}
                      />
                    </Box>

                    {isIFTATracked ? (<>
                      <Box paddingTop={'25px'}>
                        <FormSelectSimple
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "OTR Unit is required" }}
                          onChange={(event, action) => handleIFTAUnitChange(event, action)}
                          selectedoption={selectedIFTAUnitOption}
                          optionsArray={iftaUnitOptions.map(unit => ({
                            value: unit.value,
                            label: unit.label
                          }))}
                          fieldname="selectOTRUnit"
                          placeholder={'Select OTR unit'}
                          fieldlabel="OTR Unit"
                          issearchable={false}
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <FormSelectSimple
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "State is required" }}
                          onChange={e => updateSelectedState(e)}
                          selectedoption={selectedState||''}
                          optionsArray={stateOptions}
                          fieldname='selectState'
                          prettyname='State'
                          fieldlabel="State"
                          placeholder={'Select state'}
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          // fieldtype="tel"
                          fieldtype="number" step="any"
                          fieldname="odometer"
                          fieldlabel="Odometer"
                          prettyname="Odometer"
                          placeholder="Enter odometer miles"
                          onChange={handleOdometerChange}
                          onKeyPress={handleOdometerKeyPress}
                          isRequired
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          fieldtype="number"
                          fieldname="gallons"
                          fieldlabel="Gallons"
                          prettyname="Gallons"
                          placeholder="Enter gallons purchased"
                          onChange={handleGallonsChange}
                          onKeyPress={handleGallonsKeyPress}
                          isRequired
                        />
                      </Box>

                      </>
                    ) : 
                      <Box paddingTop={'25px'}>
                        <FormSelectMulti
                          register={register}
                          control={control}
                          errors={errors}
                          onChange={(event, action) => handleUnitsChange(event, action)}
                          selectedoption={selectedUnits}
                          optionsArray={unitOptions.map((unit) => ({
                            value: unit.value,
                            label: unit.label
                          }))}
                          fieldname="units"
                          placeholder={'Select units if applicable'}
                          fieldlabel="Units"
                          issearchable={false}
                          isMulti
                        />
                      </Box>
                    }
                  </>)}

                  <Box paddingTop={'25px'}>
                    <Flex gap={4} justifyContent="center" width="100%">
                      <Button 
                        onClick={resetForm} 
                        isDisabled={isSubmitting}
                        colorScheme="red" 
                        width="100%"
                        >Reset</Button>
                      <ButtonPrimaryPlain
                        type="submit"
                        onClick={handleSubmit(formSubmit, onError)}
                        width="100%"
                        name="submit"
                        value={isSubmitting ? "Uploading..." : "Upload"}
                        isDisabled={selectedExpenseCategory==='' || isSubmitting}
                      />
                      
                    </Flex>
                  </Box>

                </Box>
                
              )}
            </Flex>
            <Box h={'300px'}></Box>
          </>
        )}

      </Container>

      <CenteredSingleButtonConfirmationModal
        isModalOpen={isConfirmModalOpen}
        onModalClose={onConfirmModalClose}
        headerIcon='outlinecheckcircle'
        headerIconColor='var(--success-green)'
        headerText={confirmationModalHeaderText}
        bodyContent={confirmationModalContent}
        closeButtonDisabled={false}
        handleSubmitModalClosed={handleConfirmModalClosed}
      />
    </>
  );
};

QuickCameraUploadStatement.propTypes = {
  id: PropTypes.string,
  selectedActionType: PropTypes.string,
  onClose: PropTypes.func,
};