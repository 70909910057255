import { useEffect, useState, useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  // Spacer,
  Spinner,
  Center,
  Table,
  Tbody,
  Tr,
  Td,
  // HStack,
  useDisclosure,
  // useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Badge,
  Image,
  IconButton,
} from "@chakra-ui/react";

// screensize
import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';
// uuid
import { v4 as uuid } from 'uuid';

// globals
import { AppContext } from '../AppContext.jsx';

// generic functions
import { spellOutDate, awsFormatUnknownDate,
  // todaysDateYMD, getPreviousDate 
} from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// Buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// imported components
import { QuickCameraUploadStatement } from './QuickCameraUploadStatement.jsx';
// import { StatementDetail } from './StatementDetail.jsx';
import { TextInputGroup } from '../Form/Input/TextInputGroup.jsx';

// graphql
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { fetchInstantNotificationsByLastFour } from '../graphqlCompnents/Statement/fetchInstantNotificationsByLastFour.jsx';
// import { fetchPostedTransactionsByAccount } from '../graphqlCompnents/Statement/fetchPostedTransactionsByAccount.jsx';
import { fetchPlaidCreditCardTransactionByAccountNumberByDate } from '../graphqlCompnents/Statement/fetchPlaidCreditCardTransactionByAccountNumberByDate.jsx';
import { fetchUploadedReceiptsByStatus } from '../graphqlCompnents/Statement/fetchUploadedReceiptsByStatus.jsx';

import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

export const UserReceipts = () => {
  // if phone, show the small version
  const screenSize = useScreenSize();
  const currentScreenWidth = screenSize.width
  // console.log('currentScreenWidth: ', currentScreenWidth)


  const { 
    isOpen: isCameraUploadStatementOpen , 
    onOpen: onCameraUploadStatementOpen, 
    onClose: uploadReceiptClose 
  } = useDisclosure()

  // const { 
  //   isOpen: isStatementDetailOpen , 
  //   onOpen: onStatementDetailOpen, 
  //   onClose: statementDetailClose 
  // } = useDisclosure()

  const onCameraUploadStatementClose = () => {
    setSelectedTransactionId(null)
    setSelectedActionType(null)
    handleRefresh()
    uploadReceiptClose()
  }
  // const onStatementDetailClose = () => {
  //   handleRefresh()
  //   // statementDetailClose()
  // }
  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = (index) => {

    setSearchInputText('')
    setNextToken('')
    setNextNextToken('')
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
    console.log(`Tab index ${index} clicked, refreshing...`);
  };

  const [ selectedTransactionId, setSelectedTransactionId ] = useState(null)
  const [ selectedActionType, setSelectedActionType ] = useState(null)
  const handleOpenDrawer = (type, id) => {
    setSelectedTransactionId(id)
    setSelectedActionType(type)
  }

  useEffect(() => {
    if (selectedTransactionId && selectedActionType) {
      if (selectedActionType==='upload'||selectedActionType==='manual') {
        onCameraUploadStatementOpen()
      }
  
      if (selectedActionType==='detail') {
        // setStatementId(id, onStatementDetailOpen())
        // setSelectedStatement(statementObject, onStatementDetailOpen())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTransactionId, selectedActionType])

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);

  // const currentTeamMemberName = store?.userData?.name
  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false

  // QuickBooks environment
  // const qbEnv = store?.qbEnvironment;
  const plaidEnv = 'production';

  const userIsMobile = store?.userIsMobile;

  // const [ userData, SetUserData ] = useState({})
  // useEffect(() => {
  //   if (currentTeamMemberName && currentTeamMemberId && currentUserIsGlobalAdmin) {
  //     SetUserData({
  //       name: currentTeamMemberName,
  //       id: currentTeamMemberId,
  //       isGlobalAdmin: currentUserIsGlobalAdmin
  //     })
  //   }
  // },[currentTeamMemberName, currentTeamMemberId, currentUserIsGlobalAdmin]);

  // const [ showResultsSpinner, setShowResultsSpinner ] = useState(false)  

  const [searchInputText, setSearchInputText] = useState('');
  const searchIconCancel = (
    <IconButton
      isDisabled={searchInputText?.length === 0}
      variant="withIconQuaternary"
      bg="var(--dark-module-bg)"
      h="24px"
      icon={
        <AvailableIcons
          boxSize="24px"
          iconRef="searchReset"
          color="var(--dark-text-grey-1)"
        />
      }
      onClick={() => {
        handleRefresh(); // Trigger the same refresh logic
      }}
    />
  );

  const searchIcon = <AvailableIcons boxSize={'24px'} iconRef={'searchMagnify'} color={'var(--dark-text-grey-1)'} />

  const showResultsSpinner = false;
  // const [ currentTab, setCurrentTab ] = useState(0)
  const [ currentTabName, setCurrentTabName ] = useState('Inbox')
  const [ previousTabName, setPreviousTabName ] = useState('Inbox')
  const handleTabChange = async (tab) => {
    
    // setCurrentTab(tab)
    let currentTabNames = ['Inbox', 'Archived', 'Pending Approvals']
    setCurrentTabName(currentTabNames[tab])
    // console.log('currentTabName: ', currentTabNames[tab])

    if (currentTabNames[tab] !== previousTabName) {
      // console.log('Tab changed, resetting...')
      setNextToken('')
      setNextNextToken('')
      setPreviousTabName(currentTabNames[tab])
    }
  }

  // const searchHeaderTextClass = useBreakpointValue({
  //   sm: 'dark-sfpro-heading-2',
  //   md: 'dark-sfpro-heading-2',
  //   lg: 'dark-sfpro-heading-1',
  //   xl: 'dark-sfpro-heading-1',
  //   '2xl': 'dark-sfpro-heading-1'
  // })

  const [ userAccounts, setUserAccounts ] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const accounts = await fetchAccountsByTeamMember(plaidEnv, currentTeamMemberId);
      console.warn('cards of team member: ', accounts);
      setUserAccounts(accounts);
    }
    if (currentTeamMemberId) {
      fetchData()
    }
  },[currentTeamMemberId, plaidEnv])

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  
  // need a way to fetch transactions from posted transactions that never had an instant notification
  // fetchPlaidCreditCardTransactionByAccountNumberByDate = async (lastFour, startDate, limit = 300)
  const [postedPendingMatchTransactions, setPostedPendingMatchTransactions] = useState([]);

  useEffect(() => {
    const fetchPostedTransactionsByAccount = async (accounts, limit) => {
      try {
        const promises = accounts.map(async (account) => {
          console.log('account: ', account);
          console.log('lastFour: ', account.cardLastFour);
          const lastFour = account.cardLastFour;
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 15);
          const transactions = await fetchPlaidCreditCardTransactionByAccountNumberByDate(lastFour, startDate, limit);
          // Filter out and return only records where status = "pending_match"
          return transactions.filter(transaction => transaction.status === "pending_match");
        });

        const results = await Promise.allSettled(promises);

        // Extract fulfilled results and flatten the arrays
        const transactions = results
          .filter(result => result.status === 'fulfilled' && result.value)
          .map(result => result.value)
          .flat();

        return transactions;
      } catch (error) {
        console.error('Error fetching transactions:', error);
        return [];
      }
    };

    if (userAccounts?.length > 0) {
      (async () => {
        const fetchedTransactions = await fetchPostedTransactionsByAccount(userAccounts, 300);
        setPostedPendingMatchTransactions(fetchedTransactions); // Update state with the filtered transactions
      })();
    }
  }, [userAccounts]);

  const [ uploadedReceipts, setUploadedReceipts ] = useState([]);
  useEffect(() => {
    const fetchUploadedReceipts = async () => {
      try {
        const results = await fetchUploadedReceiptsByStatus({status: 'manual', teamMemberId: currentTeamMemberId, limit: 30});

        console.info('RESULTS: ', results);
        
        // Iterate through results to get signed URLs for each item
        const resultsWithSignedUrls = await Promise.all(
          results.map(async (item) => {
            const uploadedDocuments = item?.uploadedDocuments?.items || [];
            // Fetch signed URLs for each document in parallel
            const signedUrls = await Promise.all(
              uploadedDocuments.map(async (doc) => {
                const { identityId, key, bucket } = doc?.uploadedDocument || {};
                if (identityId && key && bucket) {
                  try {
                    const url =  await getSignedUrlForFile({
                      bucketName: bucket,
                      identityId: identityId,
                      fileName: key,
                      fileSecurity: 'protected',
                    });
                    return url;
                  } catch (error) {
                    console.error(`Error fetching signed URL for document ${key}:`, error);
                    return null; // Skip if the URL fetch fails
                  }
                }
                return null; // Skip if any value is missing
              })
            );
  
            // Attach signed URLs to the current item, filtering out any null results
            return {
              ...item,
              signedUrls: signedUrls.filter(Boolean),
            };
          })
        );
  
        // Store processed data with signed URLs in state
        console.info('resultsWithSignedUrls:', resultsWithSignedUrls);
        setUploadedReceipts(resultsWithSignedUrls);
      } catch (error) {
        console.error('Error fetching uploaded receipts or signed URLs:', error);
      }
    };
  
    if (currentTeamMemberId) {
      fetchUploadedReceipts();
    }
  }, [currentTeamMemberId]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!userAccounts || userAccounts.length === 0) return;
  
      try {
        // Filter out accounts where cardLastFour is null or undefined
        const validAccounts = userAccounts.filter(account => account.cardLastFour);
  
        const transactionPromises = validAccounts.map(async (account) => {
          const cardNumber = account.cardLastFour;
  
          const [trueTransactions, falseTransactions] = await Promise.allSettled([
            fetchInstantNotificationsByLastFour(cardNumber, true, 500),
            fetchInstantNotificationsByLastFour(cardNumber, false, 500),
          ]);
  
          const transactions = [];
          if (trueTransactions.status === 'fulfilled' && trueTransactions.value) {
            transactions.push(...trueTransactions.value);
          }
          if (falseTransactions.status === 'fulfilled' && falseTransactions.value) {
            transactions.push(...falseTransactions.value);
          }
  
          return transactions;
        });
  
        const allTransactionResults = await Promise.allSettled(transactionPromises);
  
        const successfulTransactions = allTransactionResults
          .filter(result => result.status === 'fulfilled' && result.value.length > 0)
          .map(result => result.value);
  
        const flattenedTransactions = successfulTransactions.flat();
  
        // Separate transactions into two groups: those with isSubmitted === null and others
        const [nullSubmitted, otherSubmitted] = flattenedTransactions.reduce(
          (acc, transaction) => {
            transaction.isSubmitted === null ? acc[0].push(transaction) : acc[1].push(transaction);
            return acc;
          },
          [[], []]
        );
  
        // Sort both groups by purchaseDate in ascending order
        nullSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
        otherSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
  
        // Concatenate the two groups, with nullSubmitted transactions first
        let sortedTransactions = [...nullSubmitted, ...otherSubmitted];
  
        const unmatchedTransactions = postedPendingMatchTransactions.filter(
          (pendingTransaction) =>
            !sortedTransactions.some(
              (transaction) =>
                transaction.id === pendingTransaction.id ||
                transaction.accountNumber === pendingTransaction.accountNumber
            )
        );
  
        console.log('Unmatched Transactions:', unmatchedTransactions);
  
        // Add unmatched transactions to sortedTransactions
        sortedTransactions = [...sortedTransactions, ...unmatchedTransactions];
  
        console.log('Updated Sorted Transactions:', sortedTransactions);
  
        // Update the state with the updated transactions
        setUserTransactions(sortedTransactions);
  
        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
  
    fetchTransactions();
  }, [userAccounts, refreshIndex, postedPendingMatchTransactions]);

  const [ nextToken, setNextToken ] = useState('')
  const [ nextNextToken, setNextNextToken ] = useState('')
  // const searchLimit = 25;
  // const [ statements, setStatements ] = useState([])

  const handleScroll = (e) => {
    if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log('reached the bottom of the scrollbox: ', nextToken);
      setNextNextToken(nextToken)
    }
  };

  useEffect(() => {
    if (currentTeamMemberId) {
      if (currentUserIsGlobalAdmin) {
        if (currentTabName === 'Inbox' || currentTabName === 'Archived') {
          
          // fetchAndProcessStatements(() => fetchStatementsByStatusTab(currentTabName, searchLimit, nextNextToken), setStatements, setFilteredData);
          
        }
      } else {

        // no longer using statements as the precursor to statement tracking
        // fetchAndProcessStatements(() => fetchStatementsByTeamMember(currentTeamMemberId), setStatements, setFilteredData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamMemberId, refreshIndex, currentUserIsGlobalAdmin, nextNextToken, currentTabName]);

  // this needs to take in ALL statemwents from both inbox and archive
  // then flag the archive version as already saved so they can delete the new one.
  // const groupStatementsByKeys = (statements) => {
  //   console.info('statements:', statements);
  //   const grouped = new Map();
  
  //   statements.forEach(statement => {
  //     const key = `${statement.vendorName}-${statement.purchaseAmount}-${statement.purchaseDate}`;
  //     if (!grouped.has(key)) {
  //       grouped.set(key, []);
  //     }
  //     grouped.get(key).push(statement);
  //   });
  
  //   return grouped;
  // };

  const handleBadgeClick = (id, status) => {
    if (status==='manual-match') {
      // going to need to allow the user to upload an image or select from S3
      console.log('Manual match clicked for ID:', id);
      handleOpenDrawer('manual', id);
    } else {
      // console.log('Badge clicked for ID:', id);
      handleOpenDrawer('upload', id);
      // open drawer for upload receipt code
      // in that code handle for props to be passed in
    }
  };

  const handlePreemptiveUpload = () => {
    let id = uuid();
    handleOpenDrawer('manual', id);

  };

  const [showIssuesTab, setShowIssuesTab] = useState(false);
  const [issueCounts, setIssueCounts] = useState({
    hasIssueCount: 0,
    hasFixedIssueCount: 0,
  });

  useEffect(() => {
    // This useEffect calculates issue counts whenever transactions change
    let newHasIssueCount = 0;
    let newHasFixedIssueCount = 0;

    userTransactions?.forEach((item) => {
      const receiptHasIssue = item?.capitalOneCreditCardTransactionReceipt?.hasIssue || false;
      if (receiptHasIssue) newHasIssueCount++;

      const receiptHasFixedIssue = item?.status;
      if (receiptHasFixedIssue) newHasFixedIssueCount++;
    });

    setIssueCounts({
      hasIssueCount: newHasIssueCount,
      hasFixedIssueCount: newHasFixedIssueCount,
    });

    // console.log('newHasIssueCount: ', newHasIssueCount);
    // console.log('newHasFixedIssueCount: ', newHasFixedIssueCount);
    // Determine if the Issues tab should be shown
    // setShowIssuesTab(newHasIssueCount > 0 || newHasFixedIssueCount > 0);
    setShowIssuesTab(newHasIssueCount > 0);
  }, [userTransactions]);

  const generateRows = (transactions, isMobile, tab) => {
    if (!transactions || transactions.length === 0) {
      return <Fragment><Tr><Td><p>No transactions available.</p></Td></Tr></Fragment>
    }
  
    const rows = [];
    // Map through each transaction and return a simple row with the ID
    transactions.forEach((item, index) => {
      const isManualMatch = item?.status === 'pending_match';
      // if (item.purchaseAmount === '59.86') console.log('item: ', item)
      const receiptHasIssue = item?.capitalOneCreditCardTransactionReceipt?.hasIssue||false;      
      const receiptHasFixedIssue = item?.status==='fixed-issue';
      const isSubmitted = (item.isSubmitted===true) ? true : false;

      if (tab==='Inbox') {
        // Filter out rows so we only show receipts ready to upload
        if (isSubmitted || receiptHasIssue || receiptHasFixedIssue) {
          return; // Skip this iteration if any of these conditions are true
        }
      }

      if (tab==='Archived') {
        // Filter out rows so we only show receipts ready to upload
        if (receiptHasIssue || receiptHasFixedIssue || !isSubmitted) {
          return; // Skip this iteration if any of these conditions are true
        }
      }

      if (tab==='Pending Approvals') {
        // Filter out rows so we only show receipts ready to upload
        if (!receiptHasIssue && !receiptHasFixedIssue) {
          return; // Skip this iteration if any of these conditions are true
        }
      }
      
      let receiptStatus = ''
      let submittedText = '';
      let submittedBadgeColor = 'green';
      if (item.isSubmitted) {
        submittedBadgeColor = 'green';
        receiptStatus = 'submitted';
        submittedText = 'Has Receipt';
      } else {
        submittedBadgeColor = 'red';
        receiptStatus = 'unsubmitted';
        submittedText = 'Upload Receipt';
      }
      if (receiptHasIssue) {
        submittedBadgeColor = 'red';
        receiptStatus = 'has-issue';
        submittedText = 'Receipt Has Issue';
      }

      if (receiptHasFixedIssue) {
        submittedBadgeColor = 'green';
        receiptStatus = 'fixed-issue';
        submittedText = 'Fixed Issue';
      }

      if (isManualMatch) {
        submittedBadgeColor = 'orange';
        receiptStatus = 'manual-match';
        submittedText = 'No Notification';
      }

      let merchantName = '';
      let transactionDate = '';
      let purchaseAmount = '';

      if (isManualMatch) {
        transactionDate = item.date;
        purchaseAmount = item.amount;
        if (isMobile) {
          merchantName = truncateString(item.merchantEntityName, 15)
        } else {
          merchantName = `${item.merchantEntityName} ${item.accountNumber}`
        }
      } else {
        transactionDate = item.purchaseDate;
        purchaseAmount = item.purchaseAmount;
        if (isMobile) {
          merchantName = truncateString(item.purchaseMerchant, 15)
        } else {
          merchantName = `${item.purchaseMerchant} ${item.lastFour}`
        }
      }

      rows.push(
        <Fragment key={`frag_${index}_${item.id}`}>
          <Tr 
            key={`tr_${item.id}`} 
            // onClick={() => handleOpenDrawer('detail', item)} 
            onClick={(e) => {
              e.preventDefault();  // Prevent default browser behavior
              e.stopPropagation(); // Stop event propagation to parent elements
              handleBadgeClick(item.id, receiptStatus); // Call function to handle the click and pass the ID
            }}
            cursor="pointer">
            <Td>
              <Box py={'15px'}>
                <Flex justify="space-between">
                  {/* <Text as="span" textStyle='heading-3'>{item.vendorMap?.vendorDisplayName?.vendorDisplayName || item?.vendorName || 'Unknown Vendor'}</Text> */}
                  <Text as="span" textStyle='heading-3'>{merchantName || 'Unknown Vendor'}</Text>
                  <Text as="span" textStyle='heading-3'>{(transactionDate) ? spellOutDate((awsFormatUnknownDate(transactionDate || '')), 'apple-fy') : 'Empty Date'}</Text>
                </Flex>
                <Flex justify="space-between" mt={2}>
                  <Text as="span" textStyle='text-6'>${purchaseAmount}</Text>
                  <Box>
                    <Badge 
                      // isDisabled={true}
                      variant="solid"
                      borderRadius={'6px'}
                      marginRight={'10px'}
                      paddingTop={'5px'}
                      paddingBottom={'5px'} 
                      colorScheme={submittedBadgeColor} 
                      textAlign="center" 
                      display="inline-block" 
                      minWidth="60px"
                      onClick={(e) => {
                        e.preventDefault();  // Prevent default browser behavior
                        e.stopPropagation(); // Stop event propagation to parent elements
                        handleBadgeClick(item.id, receiptStatus); // Call function to handle the click and pass the ID
                      }}>{submittedText}</Badge>
                  </Box>
                </Flex>
              </Box>
            </Td>
          </Tr>
          <Tr key={`divider_${item.id}`}><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
        </Fragment>
      );
    });

    return rows;
  };

  const formSubmit = async () => {
    // console.log('event: ', event)
    // if (!data.selectDivision) return
    // console.log('Form Values:', data);
  }

  const handleInputChange = (e) => {
    setSearchInputText(e.target.value);
    filterTransactionData(e); // Call the filtering function
  };

  const filterTransactionData = (event) => {
    if (!event) {
      return userTransactions;
    }
    
    const searchTerm = event?.target?.value
    const lowercasedTerm = searchTerm.toLowerCase();
    const tempData =  userTransactions.filter(item =>
      item?.purchaseMerchant?.toLowerCase().includes(lowercasedTerm) ||
      item?.purchaseAmount?.toLowerCase().includes(lowercasedTerm) || 
      item?.lastFour?.toLowerCase().includes(lowercasedTerm)
    );
    // console.log("tempData: ", tempData)
    setUserTransactions(tempData);
  };

  // let leftPadding = (currentScreenWidth>460) ? '15px' : '5px';
  let mobilePaddingX = '15px';

  // Calculate number of transactions where isSubmitted is not true
  const unsubmittedCount = userTransactions.filter(item => item.isSubmitted !== true).length;
  // console.log('unsubmittedCount: ', unsubmittedCount)
  let adjustedBoxWidth = (currentScreenWidth>460) ? currentScreenWidth-220 : '100%';

  const resetSearch = () => {
    // setUserTransactions(userTransactions);
  }

  const searchTransactions = (searchTerm) => {
    if (!searchTerm) {
      return;
    }

    const lowercasedTerm = searchTerm.toLowerCase();
    const tempData =  userTransactions.filter(item =>
      item?.purchaseMerchant?.toLowerCase().includes(lowercasedTerm) ||
      item?.purchaseAmount?.toLowerCase().includes(lowercasedTerm) || 
      item?.lastFour?.toLowerCase().includes(lowercasedTerm)
    );
    setUserTransactions(tempData);
  };

  return (
    <>
      <Container maxW="1600" mb={'50px'} as="form" onSubmit={handleSubmit(formSubmit, onError)}>
        {userIsMobile ?
          <>
            <Box mt={'25px'} w={adjustedBoxWidth}>
              <Box px={mobilePaddingX} w={'100%'} mt={'5px'} mb={'25px'}>
                <TextInputGroup
                  register={register}
                  errors={errors}
                  fieldname="searchInput"
                  fieldlabel="Search Transactions"
                  fieldvalue={searchInputText} // This dynamically controls the input's value
                  prettyname="Search"
                  placeholder="Search date, vendor or amount"
                  onChange={handleInputChange}
                  leftButtonElement={searchIcon}
                  rightButtonElement={searchIconCancel}
                  buttonDisabled={!searchInputText || searchInputText.trim().length === 0} // Disable button only when input is empty
                  // buttonElementOnClick={() => { handleRefresh(); }}
                  buttonElementOnClick={() => {
                    console.log('Button clicked');
                    handleRefresh();
                  }}
                />
              </Box> 
            </Box>

            <Box w={adjustedBoxWidth}>
              <Tabs 
                w={adjustedBoxWidth}
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList>
                  <Tab><Text>Inbox ({unsubmittedCount})</Text></Tab>
                  <Tab><Text>Archived</Text></Tab>
                  {uploadedReceipts?.length>0 && <Tab><Text>Pending Post ({uploadedReceipts.length})</Text></Tab>}
                  {/* <Tab><Text>Pending (1)</Text></Tab> */}
                  {showIssuesTab && <Tab><Text>{`Pending (${issueCounts.hasIssueCount})`}</Text></Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  {uploadedReceipts?.length>0 && <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            
                            {uploadedReceipts.map((item, index) => (
                              <Fragment key={`frag_${index}_${item.id}`}>
                                <Tr 
                                  key={`tr_${item.id}`} 
                                  // onClick={() => handleOpenDrawer('detail', item)} 
                                  onClick={(e) => {
                                    e.preventDefault();  // Prevent default browser behavior
                                    e.stopPropagation(); // Stop event propagation to parent elements
                                    handleBadgeClick(item.id, 'manual-match'); // Call function to handle the click and pass the ID
                                  }}
                                  cursor="pointer">
                                  <Td>
                                    <Box py={'15px'}>
                                      <Flex justify="space-between">
                                        <Text as="span" textStyle='heading-3'>{'Unknown Vendor'}</Text>
                                        <Text as="span" textStyle='heading-3'>{spellOutDate((awsFormatUnknownDate(item.createdAt.split('T')[0] || '')), 'apple-fy')}</Text>
                                      </Flex>
                                      <Box>
                                        <Image src={item.signedUrls[0]} alt="Uploaded Receipt" />
                                      </Box>
                                      {/* <Flex justify="space-between" mt={2}>
                                        <Text as="span" textStyle='text-6'>${item.amount}</Text>
                                        <Box>
                                          <Badge 
                                            // isDisabled={true}
                                            variant="solid"
                                            borderRadius={'6px'}
                                            marginRight={'10px'}
                                            paddingTop={'5px'}
                                            paddingBottom={'5px'} 
                                            colorScheme={'orange'} 
                                            textAlign="center" 
                                            display="inline-block" 
                                            minWidth="60px"
                                            onClick={(e) => {
                                              e.preventDefault();  // Prevent default browser behavior
                                              e.stopPropagation(); // Stop event propagation to parent elements
                                              handleBadgeClick(item.id, 'manual-match'); // Call function to handle the click and pass the ID
                                            }}>No Notification</Badge>
                                        </Box>
                                      </Flex> */}
                                    </Box>
                                  </Td>
                                </Tr>
                                <Tr key={`divider_${item.id}`}><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                              </Fragment>
                            ))}

                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>}

                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}

                </TabPanels>
              </Tabs>
            </Box>
          </>
        :
          <> 
            <ModuleBox>
              <Box w={'400px'}>
                <TextInputGroup
                  register={register}
                  errors={errors}
                  fieldname="searchInput"
                  fieldlabel="Search Transactions"
                  fieldvalue={searchInputText} // This dynamically controls the input's value
                  prettyname="Search"
                  placeholder="Search date, vendor or amount"
                  onChange={handleInputChange}
                  leftButtonElement={searchIcon}
                  rightButtonElement={searchIconCancel}
                  buttonDisabled={!searchInputText || searchInputText.trim().length === 0} // Disable button only when input is empty
                  // buttonElementOnClick={() => { handleRefresh(); }}
                  buttonElementOnClick={() => {
                    console.log('Button clicked');
                    handleRefresh();
                  }}
                />
              </Box> 
            </ModuleBox>

            <ModuleBox>
              <ButtonPrimaryPlain
                type='button'
                name='manualReceipt'
                value='No Instant Notification'
                onClick={handlePreemptiveUpload}
              />
            </ModuleBox>

            <ModuleBox>
              <Tabs 
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList>
                  <Tab><Text>Inbox ({unsubmittedCount})</Text></Tab>
                  <Tab><Text>Archived</Text></Tab>
                  {uploadedReceipts?.length>0 && <Tab><Text>Pending Post ({uploadedReceipts.length})</Text></Tab>}
                  {showIssuesTab && <Tab><Text>{`Pending Approvals (${issueCounts.hasIssueCount})`}</Text></Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                            {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                            
                            {isDataLoaded && generateRows(userTransactions, false, currentTabName)}
                            
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                              {/* 
                                accountNumber
                                amount
                                date
                                status
                                store
                                merchantEntityName
                              */}
                            
                            {isDataLoaded && generateRows(userTransactions, false, currentTabName)}

                            
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  {uploadedReceipts?.length>0 && <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            
                            {uploadedReceipts.map((item, index) => (
                              <Fragment key={`frag_${index}_${item.id}`}>
                                <Tr 
                                  key={`tr_${item.id}`} 
                                  // onClick={() => handleOpenDrawer('detail', item)} 
                                  onClick={(e) => {
                                    e.preventDefault();  // Prevent default browser behavior
                                    e.stopPropagation(); // Stop event propagation to parent elements
                                    handleBadgeClick(item.id, 'manual-match'); // Call function to handle the click and pass the ID
                                  }}
                                  cursor="pointer">
                                  <Td>
                                    <Box py={'15px'}>
                                      <Flex justify="space-between">
                                        <Text as="span" textStyle='heading-3'>{'Unknown Vendor'}</Text>
                                        <Text as="span" textStyle='heading-3'>{spellOutDate((awsFormatUnknownDate(item.createdAt.split('T')[0] || '')), 'apple-fy')}</Text>
                                      </Flex>
                                      <Box>
                                        <Image src={item.signedUrls[0]} alt="Uploaded Receipt" />
                                      </Box>
                                      {/* <Flex justify="space-between" mt={2}>
                                        <Text as="span" textStyle='text-6'>${item.amount}</Text>
                                        <Box>
                                          <Badge 
                                            // isDisabled={true}
                                            variant="solid"
                                            borderRadius={'6px'}
                                            marginRight={'10px'}
                                            paddingTop={'5px'}
                                            paddingBottom={'5px'} 
                                            colorScheme={'orange'} 
                                            textAlign="center" 
                                            display="inline-block" 
                                            minWidth="60px"
                                            onClick={(e) => {
                                              e.preventDefault();  // Prevent default browser behavior
                                              e.stopPropagation(); // Stop event propagation to parent elements
                                              handleBadgeClick(item.id, 'manual-match'); // Call function to handle the click and pass the ID
                                            }}>No Notification</Badge>
                                        </Box>
                                      </Flex> */}
                                    </Box>
                                  </Td>
                                </Tr>
                                <Tr key={`divider_${item.id}`}><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                              </Fragment>
                            ))}

                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>}

                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}

                </TabPanels>
              </Tabs>
            </ModuleBox>
          </>
        }
        
      <ModuleDrawer
        onClose={onCameraUploadStatementClose}
        isOpen={isCameraUploadStatementOpen}
        bodyContent={<QuickCameraUploadStatement selectedActionType={selectedActionType} screenWidth={currentScreenWidth} id={selectedTransactionId} onClose={onCameraUploadStatementClose}/>}
        size={'full'}
      />
          
      {/* <ModuleDrawer
        onClose={onStatementDetailClose}
        isOpen={isStatementDetailOpen}
        bodyContent={<StatementDetail qbEnv={qbEnv} screenWidth={currentScreenWidth} selectedStatement={selectedStatement} userData={userData} onClose={onStatementDetailClose}/>}
        size={'full'}
      /> */}
          
      </Container>
    </> 

  );
};
