// // chakra ui
// import {
//   Button,
// } from '@chakra-ui/react';

// export const ButtonTertiaryPlain = (props) => {
//   const {
//     name,
//     ref,
//     value,
//     onClick,
//     isDisabled,
//     mx,
//     my,
//   } = props;

//   return (
//     <><Button 
//       {...props}
//       name={name}
//       ref={ref}
//       variant='plainTertiary' 
//       onClick={onClick}
//       isDisabled={isDisabled}
//       mx={mx}
//       my={my}
//       >{value}</Button></>
//   )
// }

import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

export const ButtonTertiaryPlain = (props) => {
  const {
    name,
    ref,
    value,
    onClick,
    isDisabled,
    mx,
    my,
  } = props;

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant='plainTertiary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonTertiaryPlain.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};

// Default props
ButtonTertiaryPlain.defaultProps = {
  isDisabled: false, // Default to not disabled
  mx: '0px', // Default margin x
  my: '0px', // Default margin y
};