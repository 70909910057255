import { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  HStack,
  Text,
  Center,
  Stack,
  Flex,
} from "@chakra-ui/react";

// Subscriptions and functions
// import { onCreateTimeCoDailyDepartmentPunches } from '../../graphql/custom_subscriptions.js';
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';
import { todaysDateYMD, spellOutDate } from '../functions/dateTime.jsx';
import { fetchISolvedPunchesByDate } from '../graphqlCompnents/TimeAttendance/fetchISolvedPunchesByDate.jsx';
import { fetchTeamMemberCountByDivision } from '../graphqlCompnents/TimeAttendance/fetchTeamMemberCountByDivision.jsx';
import { listAllMappedDepartments } from '../graphqlCompnents/TimeAttendance/listAllMappedDepartments.jsx';
// import { generateClient } from "aws-amplify/api";

// App context
import { AppContext } from "../AppContext.jsx";

// const client = generateClient();

const PunchCard = ({
  todayLong,
  teamMemberCountAtDivision,
  totalPunchesCountAtDivision,
  teamMemberLatePunches,
  allPunchesAtDivision,
  departmentName,
}) => (
  <Box 
      w={{ 
        base: '100px',
        sm: '360px',
        md: '810px',
        lg: '1000px', 
        xl: '1200px',
        '2xl': '1600px'
      }}
      borderRadius='6px'
      p='25px'
      mt='25px'
      ml='25px'
      bg='var(--dark-module-bg)'
      overflowY={'auto'}
      minh={'100px'}
      maxH={'1000px'}
    >
    <Flex gap='25px'>
      <Box pr='25px' borderRight={'1px solid var(--dark-module-divider)'}>
        <VStack>
          <Box>
            <Center>
              <AvailableIcons color={'var(--dark-gold-primary)'} boxSize={'32px'} iconRef={'clock'} />
              <Text pl='6px' textStyle='title-clock-ins'>{todayLong} Clock Ins</Text>
            </Center>
            <Center>
              <Text pl='6px' textStyle='title-department-name'>{departmentName}</Text>
            </Center>
          </Box>
          <Box>
            <VStack>
              <Box w='60px' h='60px' borderRadius='50%' border='3px solid var(--dark-gold-primary)'>
                <Box m='20px'>
                  <Center>
                    <Text textStyle='inner-circle-1'>{teamMemberCountAtDivision}</Text>
                  </Center>
                </Box>
              </Box>
              <Box>
                <Center>
                  <Text textStyle='title-circle-1'>Total Team Members</Text>
                </Center>
              </Box>
            </VStack>
          </Box>
          
          <HStack>
            <Box pr='25px'>
              <VStack>
                <Box w='60px' h='60px' borderRadius='50%' border='3px solid var(--success-green)'>
                  <Box m='20px'>
                    <Center>
                      <Text textStyle='inner-circle-1'>{totalPunchesCountAtDivision}</Text>
                    </Center>
                  </Box>
                </Box>
                <Box>
                  <Center>
                    <Text textStyle='title-circle-1'>Total Clock Ins</Text>
                  </Center>
                </Box>
              </VStack>
            </Box>
            <Box>
              <VStack>
                <Box w='60px' h='60px' borderRadius='50%' border='3px solid var(--error-red)'>
                  <Box m='20px'>
                    <Center>
                      <Text textStyle='inner-circle-1'>{teamMemberLatePunches}</Text>
                    </Center>
                  </Box>
                </Box>
                <Box>
                  <Center>
                    <Text textStyle='title-circle-1'>Late Clock Ins</Text>
                  </Center>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </VStack>
      </Box>
      <VStack>
        <Box>
          <Text textStyle="title-circle-1">Clock Ins</Text>
          {allPunchesAtDivision.length > 0 ? (
            allPunchesAtDivision.map((item, index) => {
              const timePunched = item?.timePunched || ''; 
              const [hour, minute] = timePunched ? timePunched.split(':').map(Number) : [0, 0]; 
              const punchedInLate = hour >= 8 && minute > 0;

              return (
                <Box key={index} pt="25px">
                  <Stack direction="row">
                    <Box w="100px">
                      <Text color={punchedInLate ? 'red' : 'white'}>
                        {item?.timePunched}
                      </Text>
                    </Box>
                    <Box w="300px">
                      <Text color={punchedInLate ? 'red' : 'white'}>
                        {item?.tmName}
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              );
            })
          ) : (
            <Text>No punch data available</Text>
          )}
        </Box>
      </VStack>
    </Flex>
  </Box>
);

// Adding prop types validation for PunchCard
PunchCard.propTypes = {
  todayLong: PropTypes.string.isRequired,
  teamMemberCountAtDivision: PropTypes.number.isRequired,
  totalPunchesCountAtDivision: PropTypes.number.isRequired,
  teamMemberLatePunches: PropTypes.number.isRequired,
  allPunchesAtDivision: PropTypes.arrayOf(
    PropTypes.shape({
      timePunched: PropTypes.string,
      tmName: PropTypes.string,
    })
  ).isRequired,
  departmentName: PropTypes.string.isRequired,
};

export const PunchTimes = () => {
  const { store } = useContext(AppContext);
  const isGlobalAdmin = store?.userData?.isGlobalAdmin || false;
  const currentDivisionId = store?.userData?.divisionId ?? '';
  const today = todaysDateYMD();
  const todayLong = spellOutDate(today, 'apple-ny');
  const [mappedDepartments, setMappedDepartments] = useState([]);
  const [departmentPunchData, setDepartmentPunchData] = useState([]);

  useEffect(() => {
    const fetchDepartments = async () => {
      const departments = await listAllMappedDepartments();
      console.log('departments: ', departments);
  
      // Step 1: Temporary - Remove departments with departmentCode = "01", "01-0011", or "01-0013"
      const filteredDepartments = departments.filter(dept => 
        dept.departmentCode !== "01" && 
        dept.departmentCode !== "01-0011" && 
        dept.departmentCode !== "01-0013" && 
        dept.departmentCode !== "01-0005" &&  
        dept.departmentCode !== "02-0004" &&  
        dept.divisionId !== "8a442233-b115-472c-8ddc-6813474770d7" && 
        dept.departmentCode !== "02-0006" &&  
        dept.departmentCode !== "02-0008"
      );
  
      // Step 2: Modify the departmentName for the department with departmentCode = "01-0012"
      const updatedDepartments = filteredDepartments.map(dept => 
        dept.departmentCode === "01-0012" ? { ...dept, departmentName: "Oklahoma" } : dept
      );
  
      console.log('updatedDepartments: ', updatedDepartments);
      // Set the filtered and updated departments
      setMappedDepartments(updatedDepartments);
    };
  
    fetchDepartments();
  }, []);

  const updateDailyPunches = useCallback(async (todayDate) => {
    console.log('updateDailyPunches called: ', todayDate);
    try {
      const todaysPunches = await fetchISolvedPunchesByDate(todayDate);
      console.log('todaysPunches: ', todaysPunches);

      const punchesByDivision = todaysPunches.items.reduce((acc, item) => {
        const divisionId = item?.teamMember?.teamMember?.divisionId;
        const punch = {
          timePunched: item?.punchTime,
          tmName: `${item?.teamMember?.teamMember?.firstName} ${
            item?.teamMember?.teamMember?.goesBy
              ? item?.teamMember?.teamMember?.goesBy + ' '
              : ''
          }${item?.teamMember?.teamMember?.lastName}`,
        };

        if (!acc[divisionId]) {
          acc[divisionId] = { punches: [] };
        }

        acc[divisionId].punches.push(punch);
        return acc;
      }, {});

      // const departmentPunchDataArray = await Promise.all(
      //   mappedDepartments.map(async (department) => {
      //     const departmentData = punchesByDivision[department.divisionId] || { punches: [] };
      //     console.log('departmentData: ', department)
      //     console.log('department.divisionId: ', department.divisionId)
      //     const teamMemberData = await fetchTeamMemberCountByDivision(department.divisionId);
      //     const teamMemberCount = teamMemberData?.scannedCount || 0;
      //     const totalPunchesCount = departmentData.punches.length;
      //     const latePunches = departmentData.punches.filter(
      //       (item) => parseInt(item.timePunched?.split(':')[0]) >= 8
      //     ).length;

      //     return {
      //       divisionName: department.departmentName,
      //       totalPunchesCount,
      //       latePunches,
      //       teamMemberCount,
      //       punches: departmentData.punches,
      //     };
      //   })
      // );

      // If the user is a global admin, show punches for all departments
      let departmentPunchDataArray = [];

      if (isGlobalAdmin) {
        departmentPunchDataArray = await Promise.all(
          mappedDepartments.map(async (department) => {
            // console.log('department: ', department);
            const departmentData = punchesByDivision[department.divisionId] || { punches: [] };
            console.log('departmentData: ', departmentData);
            const teamMemberData = await fetchTeamMemberCountByDivision(department.divisionId);
            const teamMemberCount = teamMemberData?.scannedCount || 0;
            const totalPunchesCount = departmentData.punches.length;
            const latePunches = departmentData.punches.filter(
              (item) => parseInt(item.timePunched?.split(':')[0]) >= 8
            ).length;

            return {
              divisionName: department.departmentName,
              totalPunchesCount,
              latePunches,
              teamMemberCount,
              punches: departmentData.punches,
            };
          })
        );
      } else {
        // If the user is NOT a global admin, only show punches for their division
        const userDepartmentData = mappedDepartments.filter(
          (department) => department.divisionId === currentDivisionId
        );
        
        departmentPunchDataArray = await Promise.all(
          userDepartmentData.map(async (department) => {
            const departmentData = punchesByDivision[department.divisionId] || { punches: [] };
            const teamMemberData = await fetchTeamMemberCountByDivision(department.divisionId);
            const teamMemberCount = teamMemberData?.scannedCount || 0;
            const totalPunchesCount = departmentData.punches.length;
            const latePunches = departmentData.punches.filter(
              (item) => parseInt(item.timePunched?.split(':')[0]) >= 8
            ).length;

            return {
              divisionName: department.departmentName,
              totalPunchesCount,
              latePunches,
              teamMemberCount,
              punches: departmentData.punches,
            };
          })
        );
      }
      console.log('departmentPunchDataArray: ', departmentPunchDataArray);
      setDepartmentPunchData(departmentPunchDataArray);
    } catch (error) {
      console.error('Error fetching punches:', error);
    }
  }, [mappedDepartments, isGlobalAdmin, currentDivisionId]);

  // const updateDailyPunches = useCallback(async (todayDate) => {
  //   try {
  //     const todaysPunches = await fetchISolvedPunchesByDate(todayDate);
      
  //     // Group punches by the first two characters of departmentCode
  //     const punchesByDepartmentCode = todaysPunches.items.reduce((acc, item) => {
  //       const departmentCode = item?.teamMember?.teamMember?.departmentCode?.slice(0, 2); // First 2 characters
  //       const punch = {
  //         timePunched: item?.punchTime,
  //         tmName: `${item?.teamMember?.teamMember?.firstName} ${
  //           item?.teamMember?.teamMember?.goesBy
  //             ? item?.teamMember?.teamMember?.goesBy + ' '
  //             : ''
  //         }${item?.teamMember?.teamMember?.lastName}`,
  //       };
  
  //       if (!acc[departmentCode]) {
  //         acc[departmentCode] = { punches: [] };
  //       }
  
  //       acc[departmentCode].punches.push(punch);
  
  //       return acc;
  //     }, {});
  
  //     // Now map through all departments and prepare punch data for each one
  //     const departmentPunchDataArray = await Promise.all(
  //       mappedDepartments.map(async (department) => {
  //         // Use the first two characters of departmentCode for matching
  //         const departmentData = punchesByDepartmentCode[department.departmentCode.slice(0, 2)] || { punches: [] };
  //         const teamMemberData = await fetchTeamMemberCountByDivision(department.divisionId);
  //         const teamMemberCount = teamMemberData?.scannedCount || 0;
  //         const totalPunchesCount = departmentData.punches.length;
  //         const latePunches = departmentData.punches.filter(
  //           (item) => parseInt(item.timePunched?.split(':')[0]) >= 8
  //         ).length;
  
  //         return {
  //           divisionName: department.departmentName,
  //           totalPunchesCount,
  //           latePunches,
  //           teamMemberCount,
  //           punches: departmentData.punches,
  //         };
  //       })
  //     );
  
  //     setDepartmentPunchData(departmentPunchDataArray);
  //   } catch (error) {
  //     console.error('Error fetching punches:', error);
  //   }
  // }, [mappedDepartments]);
  
  useEffect(() => {
    updateDailyPunches(today);
  }, [today, mappedDepartments, updateDailyPunches]);

  // Subscription to listen for new punches
  // useEffect(() => {
  //   const subscribeToPunchUpdates = () => {
  //     const subscription = client
  //       .graphql({
  //         query: onCreateTimeCoDailyDepartmentPunches,
  //       })
  //       .subscribe({
  //         next: async ({ value }) => {
  //           const newPunch = value.data.onCreateTimeCoDailyDepartmentPunches;
  //           console.log('New punch received: ', newPunch);

  //           updateDailyPunches(today);
  //         },
  //         error: (error) => console.error('Error on subscription: ', error),
  //       });

  //     return () => subscription.unsubscribe();
  //   };

  //   if (currentDivisionId) {
  //     subscribeToPunchUpdates();
  //   }
  // }, [currentDivisionId, mappedDepartments, isGlobalAdmin, today, updateDailyPunches]);

  return (
    <>
      {departmentPunchData.map((department, index) => (
        <PunchCard
          key={index}
          todayLong={todayLong}
          teamMemberCountAtDivision={department.teamMemberCount}
          totalPunchesCountAtDivision={department.totalPunchesCount}
          teamMemberLatePunches={department.latePunches}
          allPunchesAtDivision={department.punches}
          departmentName={department.divisionName}
        />
      ))}
      <Box h='25px' />
    </>
  );
};

// Adding prop types validation for PunchTimes (if needed, but most props come from context or internal state)
PunchTimes.propTypes = {
  store: PropTypes.shape({
    userData: PropTypes.shape({
      isGlobalAdmin: PropTypes.bool,
      divisionId: PropTypes.string.isRequired,
    }),
  }),
};