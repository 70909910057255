import { Box, Text, VStack, HStack, Image, Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

export const UnitSummaryTab = () => {
  return (
    <Box bg="gray.900" p={6} borderRadius="md" boxShadow="md">
      {/* Top Content: Image and Details */}
      <HStack spacing={6} align="start">
        {/* Vehicle Image */}
        <Box
          borderRadius="md"
          overflow="hidden"
          border="2px solid"
          borderColor="gray.700"
          flexShrink={0}
        >
          <Image
            src="/path/to/vehicle-image.jpg"
            alt="Vehicle"
            boxSize="200px"
            objectFit="cover"
          />
        </Box>

        {/* Details Section */}
        <VStack align="start" spacing={3} flex="1">
          <Text fontSize="sm" color="gray.400">
            <strong>Unit ID:</strong> 186
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Status:</strong> Active
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Type:</strong> Truck
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Subtype:</strong> Roll-off Truck
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Location:</strong> Exxon
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Make:</strong> VOLVO
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Model:</strong> WG 54
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Year:</strong> 1996
          </Text>
          <Text fontSize="sm" color="pink.400">
            <strong>Mileage/Hrs:</strong> 10000
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Color:</strong> Tan
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Tag:</strong> 1SD088
          </Text>
          <Text fontSize="sm" color="pink.400">
            <strong>VIN/Serial:</strong> 4V2JCBJE1SR847017
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Vehicle Inspection Expiration:</strong> 11/12/24
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>IRP/IFTA Registered:</strong> Yes
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Requires Driver:</strong> Yes
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Requires Operator:</strong> No
          </Text>
        </VStack>

        {/* Auxiliary Section */}
        <VStack align="start" spacing={3} flex="1">
          <Text fontSize="sm" color="pink.400">
            <strong>Transponder Unit:</strong> PikePass 45678809443
          </Text>
          <Text fontSize="sm" color="gray.400">
            <strong>Auxiliary Units:</strong>
          </Text>
          <VStack align="start" spacing={1} pl={4}>
            <Text fontSize="sm" color="gray.400">
              20 Yard Vacuum Box
            </Text>
            <Text fontSize="sm" color="gray.400">
              20 Yard Sludge Roll-Off Containers
            </Text>
            <Text fontSize="sm" color="gray.400">
              Roll-Off Trailer
            </Text>
          </VStack>
        </VStack>
      </HStack>

      {/* Bottom Content: Download Button */}
      <Box mt={6} textAlign="center">
        <Button
          leftIcon={<DownloadIcon />}
          colorScheme="yellow"
          bg="yellow.600"
          _hover={{ bg: "yellow.700" }}
          borderRadius="md"
        >
          Download Summary
        </Button>
      </Box>
    </Box>
  );
};

