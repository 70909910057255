import { VStack } from "@chakra-ui/react";
import { UnitInfoDashBar } from "./UnitInfoDashBar";
import { UnitInfoTabs } from "./UnitInfoTabs";

export const ManageUnits = () => {
  return (
    <VStack spacing={4} align="stretch">
      <UnitInfoDashBar />
      <UnitInfoTabs />
    </VStack>
  );
};

