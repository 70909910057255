import { getPlaidCreditCardTransaction } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchManualTransaction = async (id) => {
  try {
    if (!id) return ('error fetchManualTransaction: id is required');
    
    const gqlParams = { id: id };
    const response = await client.graphql({
      query: getPlaidCreditCardTransaction,
      variables: gqlParams
    });

    console.info('fetchManualTransaction response', response);

    const results = response.data.getSpkPlaidCreditCardTransactionTbl;
    return results;
    
  } catch (err) {
    return ('error fetchManualTransaction: ', [err]);
  }
};

fetchManualTransaction.propTypes = {
  id: PropTypes.string.isRequired,
};