import { updateUnitDocument } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const disableUnitDocument = async (props) => {
  if (!props) return;
  const unitDocumentId = props.id;

  try {
    console.log('params: ', props);

    const gqlParams = {
      input: {id: unitDocumentId, isActive: false, active: "false"}
    };

    const response = await client.graphql({
      query: updateUnitDocument,
      variables: gqlParams
    });

    console.log('updateUnitDocument response: ', response);
    const results = response.data.updateSpkUnitDocumentTbl.id;
    console.log('updateUnitDocument results: ', results);

    return results;
  } catch (err) {
    return ('error disableUnitDocument: ', [err]);
  }
};

disableUnitDocument.propTypes = {
  props: PropTypes.object.isRequired,
};
