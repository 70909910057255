import { listCapitalOneCreditCardTransactionReceiptsByStatus } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUploadedReceiptsByStatus = async (props) => {
  try {

    const { status, teamMemberId, limit } = props;

    const gqlParams = {
      allSort: "all",
      matchStatus: {eq: status},
      limit: limit || 200,
    };

    console.info('GraphQL Params:', gqlParams);

    if (status === 'manual') {
      gqlParams.filter = {teamMemberId: {eq: teamMemberId}};
    }

    // console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: listCapitalOneCreditCardTransactionReceiptsByStatus,
      variables: gqlParams,
    });

    console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.capitalOneCreditCardTransactionReceiptsByStatus) {
      console.warn(`No data returned for status ${status}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.capitalOneCreditCardTransactionReceiptsByStatus.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for status ${status}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchUploadedReceiptsByStatus:', err);
    return null; // Return null on error
  }
};

fetchUploadedReceiptsByStatus.propTypes = {
  status: PropTypes.string.isRequired,
  limit: PropTypes.number,
};