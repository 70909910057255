import { fetchAuthSession } from 'aws-amplify/auth';

const API_ENDPOINT = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminaddusertogroup';

/**
 * Updates a Cognito user's group membership
 * @param {Object} props - The properties object
 * @param {string} props.userName - The username of the Cognito user
 * @param {string} props.groupName - The name of the group to add/remove the user to/from
 * @param {'add' | 'remove'} [props.groupAction='add'] - The action to perform (add or remove)
 * @returns {Promise<Object>} The response from the Lambda function
 */
export const updateCognitoUsersGroups = async ({ userName, groupName, groupAction = 'add' }) => {
  try {
    if (!userName || !groupName) {
      throw new Error('userName and groupName are required');
    }

    // Get current session and auth token
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken.toString()}`;

    // console.log('Sending request:', {
    //   username: userName,
    //   groupname: groupName,
    //   groupaction: groupAction
    // });

    // Make the API call
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken,
      },
      body: JSON.stringify({
        username: userName,
        groupname: groupName,
        groupaction: groupAction,
      })
    });

    const data = await response.json();
    // console.log('Raw API Response:', data);

    // Handle non-200 responses
    if (!response.ok) {
      throw new Error(
        data?.message || 
        data?.error || 
        `Failed to update group membership: ${response.status} ${response.statusText}`
      );
    }

    // If the response has an error property, throw it
    if (data?.error) {
      throw new Error(data.error);
    }

    // The Lambda might return just the result object directly
    return {
      success: true,
      action: groupAction,
      username: userName,
      groupname: groupName,
      result: data
    };

  } catch (error) {
    console.error('Error updating user group membership:', {
      userName,
      groupName,
      groupAction,
      error: error.message,
      fullError: error
    });
    throw error;
  }
};
