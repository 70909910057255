import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Center,
  HStack,
  VStack,
} from "@chakra-ui/react";

// QuickBooks
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural
import { ModuleBox } from '../Structural/ModuleBox.jsx'
import { Divider } from '../Divider.jsx';

// form components
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx'
import { TextInput } from '../Form/Input/TextInput.jsx'
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// Lambdas
import { getCustomers } from '../QuickBooks/getCustomers.jsx'
import { getClasses } from '../QuickBooks/getClasses.jsx';

// graphql
import { fetchAllClients } from '../graphqlCompnents/Billing/fetchAllClients.jsx'
import { updateBillableClient } from '../graphqlCompnents/Billing/updateBillableClient.jsx';
import { addBillableClient } from '../graphqlCompnents/Billing/addBillableClient.jsx';
import { fetchAllDivisions } from '../graphqlCompnents/Billing/fetchAllDivisions.jsx';

// generic functions
import { levenshteinDistance } from '../functions/fuzzy.jsx'
import { todaysDatePlusNDaysYMD } from '../functions/dateTime.jsx'

export const SyncQBCustomer = () => {

  const { control, register, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {}
  });
  

  // grab global context values
  const { store } = useContext(AppContext);

  // quickbooks environment, either sandbox or production
  const qbEnv = store?.qbEnvironment;


  // start states
  const [ selectedCustomer, setSelectedCustomer ] = useState(null)
  const [ selectedClass, setSelectedClass ] = useState(null)
  const [ selectedClient, setSelectedClient ] = useState(null)
  const [ selectedClientObj, setSelectedClientObj ] = useState(null)
  const [ customersForMatching, setCustomersForMatching ] = useState(null)
  const [ clientsForMatching, setClientsForMatching ] = useState(null)
  const [ divisions, setDivisions ] = useState(null)
  const [ selectedDivision, setSelectedDivision ] = useState(null)
  const [ selectedDivisionId, setSelectedDivisionId ] = useState(null)
  const [ selectedActionButton, setSelectedActionButton ] = useState(null)
  const [ submitButtonText, setSubmitButtonText ] = useState(null)
  const [ customers, setCustomers ] = useState(null)
  const [ classes, setClasses ] = useState(null)
  const [ selectedQBCustomerObj, setSelectedQBCustomerObj ] = useState(null)
  const [ existingClients, setExistingClients ] = useState(null)
  const [ clientsNotSyncedToQB, setClientsNotSyncedToQB ] = useState(null)
  const [tokenObj, setTokenObj] = useState(null);

  // ----- start handlers -----

  const handleCustomerChanged = (event) => {
    setSelectedCustomer(event)
    const id = event?.value
    const customer = customers.find((customer) => customer.Id === id)
    setSelectedQBCustomerObj(customer)
    findClosestMatch(clientsForMatching, customer?.FullyQualifiedName, 'clients')
  }

  const handleClassChanged = (option) => {
    setSelectedClass(option)
  }

  const handleDivisionChanged = (option) => {
    setSelectedDivision(option)
    const classForDivision = divisions.find((division) => division.id === option?.value)?.defaultQBClass
    const qbClass = classes?.find((qbClass) => qbClass.Name === classForDivision)
    setSelectedClass({label: qbClass?.FullyQualifiedName, value: qbClass?.Id})
    

    setSelectedDivisionId(option?.value)
  }

  const handleClientChanged = (option) => {
    if (option?.value === "" || option?.label === "") {
      setSelectedClient(null)
      return  // do nothing
    }

    // for selecting Update Existing Client, we need to take the qbid/qbSandboxId 
    // from the client and match it to the id of the customer.

    if (option?.value === "PLACEHOLDER") {
      return  // do nothing
    }

    setSelectedClient(option)
    // now set the data for the full notsynced client obj
    const selectedClientNotSyncedToQB = clientsNotSyncedToQB?.find((client) => client.id === option?.value)||{}
    setSelectedClientObj(selectedClientNotSyncedToQB)
    if (selectedClientNotSyncedToQB?.division?.defaultQBClass) {
      
      const qbClass = classes.find((qbClass) => qbClass.Name === selectedClientNotSyncedToQB?.division?.defaultQBClass)
      handleClassChanged({label: qbClass?.FullyQualifiedName, value: qbClass?.Id})
    }

    // const closestMatch = getClosestMatch(customersForMatching, selectedClientNotSyncedToQB?.contactCompany)
    // setClosestCustomerMatch(closestMatch)
    findClosestMatch(customersForMatching, selectedClientNotSyncedToQB?.contactCompany, 'customers')
    // setClosestCustomerMatch(closestTest)
  }

  const handleSyncQBClientData = async (qbCustomer, action) => {
    if (!action) {
      return
    }
    if (action !== "create" && action !== "update") {
      // kill for now
      return
    }
    if (!selectedDivisionId && action === "create") {
      return
    }

    const customerName = (qbCustomer?.FullyQualifiedName!=='') ? qbCustomer?.FullyQualifiedName : qbCustomer?.CompanyName
    const qbCustomerData = {
      clientBillingEmail: qbCustomer?.PrimaryEmailAddr?.Address||'', 
      isSyncedToQB: true, 
      qbCity: qbCustomer?.ShipAddr?.City||'', 
      qbCompany: qbCustomer?.CompanyName||'', 
      qbCountry: qbCustomer?.ShipAddr?.Country||'', 
      qbCustomer: customerName||'', 
      qbCustomerType: qbCustomer?.CustomerTypeRef?.name||'', 
      qbDisplayName: qbCustomer?.DisplayName||'', 
      qbEmail: qbCustomer?.PrimaryEmailAddr?.Address||'', 
      qbPhone: qbCustomer?.PrimaryPhone?.FreeFormNumber||'', 
      qbState: qbCustomer?.ShipAddr?.CountrySubDivisionCode||'', 
      qbStreetAddress: qbCustomer?.ShipAddr?.Line1||'', 
      qbZip: qbCustomer?.ShipAddr?.PostalCode||'',
      staleAfterDate: todaysDatePlusNDaysYMD(365, '-'),
      ...(qbEnv === 'sandbox' && {
        qbSandboxClassId: selectedClass.value, 
        qbSandboxClassName: selectedClass.label, 
        qbSandboxId: qbCustomer?.Id, 
      }),
      ...(qbEnv === 'production' && {
        qbClassId: selectedClass.value,
        qbClassName: selectedClass.label, 
        qbId: qbCustomer?.Id, 
      }),
      ...(action === 'create' && {
        allSort: "all",
        isActive: true, 
        divisionId: selectedDivisionId, 
        contactCompany: qbCustomer?.CompanyName,
      }),
      ...(action === 'update' && {
        id: selectedClientObj.id,
      }),
    }

    if (action === 'update') {
      const updatedId = await updateClient(qbCustomerData)
      console.warn(' ----- updatedId: ', updatedId)
    }
    if (action === 'create') {
      const newId = await addClient(qbCustomerData)
      console.warn(' ----- newId: ', newId)
    }
    
    resetAllData()
  }

  const handleSelectAction = (action) => {
    console.log(' ----- handleSelectAction: ', action)
    if (!action) {
      return
    }
    if (action === "create") {
      setSubmitButtonText('Create New Client')
      setSelectedActionButton("create")
      // handleNewClient(selectedQBCustomerObj)
    }
    if (action === "update") {
      setSubmitButtonText('Update Existing Client')
      setSelectedActionButton("update")
      // handleSyncQBClientData(selectedQBCustomerObj, "update")
    }
  }
  // ----- end handlers -----

  // ----- temporary -----
  const ObjectRenderer = ({ obj }) => {
    if (!obj) {
      return null;
    }
  
    const renderValue = (value) => {
      if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
        // Use <div> instead of <p> for nested objects to avoid nesting violation.
        return <div style={{marginLeft: '20px'}}><ObjectRenderer obj={value} /></div>;
      } else if (value instanceof Array) {
        // Handle arrays separately to ensure each item is rendered correctly.
        return value.map((item, index) => (
          <div key={index} style={{marginLeft: '20px'}}>
            {renderValue(item)}
          </div>
        ));
      }
      return value.toString();
    };
  
    return (
      <div>
        {Object.entries(obj).map(([key, value]) => (
          // Wrap key with <span> to maintain inline display, then render value.
          <div key={key}>
            <span>{`${key}: `}</span>
            {(value!==null) && renderValue(value)}
          </div>
        ))}
      </div>
    );
  };

  ObjectRenderer.propTypes = {
    obj: PropTypes.obj,
  }
  // ----- end temporary -----

  // ----- generic functions -----

  const resetAllData = () => {
    // now refresh dropdowns
    setSubmitButtonText(null)
    setSelectedActionButton(null)
    setSelectedClass(null)
    setSelectedClient(null)
    setSelectedCustomer(null)
    setSelectedQBCustomerObj(null)
    setSelectedClientObj(null)
    loadClients()
  }

  const loadDivisions = async () => {
    const divisions = await fetchAllDivisions()
    console.warn(' ----- divisions: ', divisions)

    setDivisions(divisions)
    return
  }

  // this needs to run on load
  // await loadQBCustomers(token, environment)
  const loadQBCustomers = async (token, environment) => {
    console.warn(' ----- loadQBCustomers: ', token, environment)
    const qbCustomers = await getCustomers(token, environment)
    console.warn(' ----- qbCustomers: ', qbCustomers)

    setCustomers(qbCustomers)
    setCustomersForMatching(qbCustomers?.map(({ FullyQualifiedName }) => FullyQualifiedName))
    return
  }

  const loadQBClasses = async (token, environment) => {
    console.warn(' ----- loadQBclasses: ', token, environment)
    const qbClasses = await getClasses(token, environment)
    console.warn(' ----- qbClasses: ', qbClasses)
    // const sortedClasses = qbClasses?.Class?.sort(function( a , b ){
    //   return a.Name > b.Name ? 1 : -1;
    // })
    // console.warn(' ----- qbClasses: ', sortedClasses)
    // const sortedClasses = qbClasses?.sort(function( a , b ){
    //   return a.Name > b.Name ? 1 : -1;
    // })
    // console.warn(' ----- qbClasses: ', sortedClasses)
    
    // setClasses(qbClasses?.Class)
    setClasses(qbClasses)
    return
  }

  const loadClients = async () => {
    const spikeClients = await fetchAllClients()
    // setExistingClients(clients)
    filterAndSortClients(spikeClients)
    setClientsForMatching(spikeClients?.map(({ contactCompany }) => contactCompany))
    
  }


  const filterAndSortClients = (clients) => {
    // First, let's sort all the clients alphabetically, regardless of their isSyncedToQB status
    // This makes the next steps easier and keeps our code looking sharp and stylish
    const alphabeticallySortedClients = clients.sort((a, b) => a.contactCompany.localeCompare(b.contactCompany));
  
    // Now, we separate the wheat from the chaff, or in this case, the synced from the not-synced
    // We create two arrays, one for each type of client, because we're inclusive like that
    const notSyncedClients = alphabeticallySortedClients.filter(client => !client.isSyncedToQB);
    const syncedClients = alphabeticallySortedClients.filter(client => client.isSyncedToQB);
  
    // Finally, we bring everyone back together for the big finale
    // notSyncedClients get to go first because, well, they need a bit of encouragement
    setClientsNotSyncedToQB([...notSyncedClients, ...syncedClients]);
    setExistingClients([...notSyncedClients, {id: "PLACEHOLDER", contactCompany: "----- CURRENTLY SYNCED -----"}, ...syncedClients])
    // return [...notSyncedClients, ...syncedClients];
  };

  const updateClient = async (clientData) => {
    // console.warn(' ----- updateClient: ', clientData)
    const response = await updateBillableClient(clientData)
    // console.warn(' ----- response: ', response)
    return response
  }

  const addClient = async (clientData) => {
    const response = await addBillableClient(clientData)
    // console.warn(' ----- response: ', response)
    return response
  }
  
  // ----- end generic functions -----

  useEffect(() => {
    console.log(' ----- tokenObj: ', tokenObj)
    if (tokenObj?.access_token && qbEnv) {
      loadQBCustomers(tokenObj?.access_token, qbEnv);
      loadQBClasses(tokenObj?.access_token, qbEnv);
      loadDivisions()
      loadClients()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObj, qbEnv]);

  const [ matchedQBCustomer, setMatchedQBCustomer ] = useState(null)
  useEffect(() => {
    (matchedQBCustomer) && findClosestMatch(matchedQBCustomer?.id, matchedQBCustomer?.contactCompany, 'clients')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[matchedQBCustomer])

  const findClosestMatch = (arr, target, source) => {
    if (arr?.length === 0 || !target || !source) {
      return null;
    }
    let currentClosestMatch = arr[0];
    let smallestDistance = levenshteinDistance(target, currentClosestMatch);
  
    arr.slice(1).forEach(name => {
      const distance = levenshteinDistance(target, name);
      if (distance < smallestDistance) {
        currentClosestMatch = name;
        smallestDistance = distance;
      }
    });

    if (source !== 'clients') {
      const { Id, FullyQualifiedName } = customers.find((customer) => customer.FullyQualifiedName === currentClosestMatch)
      setMatchedQBCustomer({ Id, FullyQualifiedName})
    }
    
  };

  let isDebug = false;

  return(
    <>
      <QuickBooksAuth 
        qbAppId='Invoices'
        qbEnv={qbEnv}
        onTokenChange={setTokenObj}
        >
        {({ isLoggedInQB, loginQB, logoutQB }) => (
          <Container as="form" maxW="1600" mb={12}>
            <>
              <QBLoginLogoutButtons 
                isLoggedInQB={isLoggedInQB}
                loginQB={loginQB}
                logoutQB={logoutQB}
              />

              {/* possibly disable this if not logged in */}
              {console.log('isLoggedInQB: ', isLoggedInQB)}
              {console.log('customers: ', customers)}
              {(!selectedActionButton) && <>
                <ModuleBox>
                  <VStack align={'left'}>
                    <Box w={'100%'} paddingBottom={'25px'}>
                      <Text textStyle={'heading-1'}>What would you like to do?</Text>
                    </Box>
                    <HStack >
                      <Box w={'320px'} bg={'var(--dark-menu-background)'} borderRadius={'6px'}>
                        <VStack>
                          <Box paddingTop={'25px'} paddingBottom={'11'}>
                            <AvailableIcons boxSize={'53px'} color={'var(--success-green)'} iconRef={'add'} />
                          </Box>
                          <Box>
                            <Text textStyle='heading-2'>Create New Client</Text>
                          </Box>
                          <Box w={'80%'}>
                              <Text align={'center'} textStyle='dark-heading-2'>Create a new client and connect it to a QuickBooks customer.</Text>
                          </Box>
                          <Box w={'48%'} py={'25px'}>
                            <ButtonPrimaryPlain
                              // isDisabled={true}
                              isDisabled={(selectedActionButton === "update")}
                              type="button"
                              width="100%"
                              onClick={() => {
                                handleSelectAction("create")
                              }}
                              name='select_new_client'
                              value='Select'
                            />
                          </Box>
                        </VStack>
                      </Box>
                      <Box w={'16px'}></Box>
                      <Box w={'320px'} bg={'var(--dark-menu-background)'} borderRadius={'6px'}>
                        <VStack>
                          <Box paddingTop={'25px'} paddingBottom={'11'}>
                            <AvailableIcons boxSize={'53px'} color={'var(--success-green)'} iconRef={'refresh'} />
                          </Box>
                          <Box>
                            <Text textStyle='heading-2'>Update Existing Client</Text>
                          </Box>
                          <Box w={'80%'}>
                              <Text align={'center'} textStyle='dark-heading-2'>Update an existing client with an updated QuickBooks customer.</Text>
                          </Box>
                          <Box w={'48%'} py={'25px'}>
                            <ButtonPrimaryPlain
                              // isDisabled={true}
                              isDisabled={(selectedActionButton === "create")}
                              type="button"
                              width="100%"
                              onClick={() => {
                                handleSelectAction("update")
                              }}
                              name='select_update_client'
                              value='Select'
                            />
                          </Box>
                        </VStack>
                      </Box>
                      
                      
                    </HStack>
                  </VStack>
                </ModuleBox>
              </>}

              {(selectedActionButton === "create") && <>
                <ModuleBox>
                  <Box>
                    <HStack >
                      <Box paddingTop={'6px'}>
                        <AvailableIcons boxSize={'25px'} color={'var(--success-green)'} iconRef={'add'} />
                      </Box>
                      <Box>
                        <Text textStyle='heading-1'>Create New Client</Text>
                      </Box>
                    </HStack>
                  </Box>
                </ModuleBox>

                <ModuleBox>
                  <Box>
                    <Text textStyle='heading-2'>1. Select QuickBooks Customer</Text>
                  </Box>
                  <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                    <FormSelectSimple
                      key='select_customer'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectCustomer"
                      // fieldlabel="1. Select QuickBooks Customers"
                      optionsArray={customers?.map(({ Id, FullyQualifiedName }) => ({
                        label: FullyQualifiedName,
                        value: Id,
                      }))}
                      onChange={(e) => handleCustomerChanged(e)}
                      selectedoption={selectedCustomer}
                      placeholder={'Select customer or start typing'}
                      isDisabled={(!customers?.length > 0) || !isLoggedInQB}
                    />
                  </Box>
                  <Divider/>
                  <Box paddingTop={'25px'}>
                    <Text textStyle='heading-2'>2. Select QuickBooks Class</Text>
                  </Box>
                  <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                    <FormSelectSimple
                      key='select_class'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectClass"
                      // fieldlabel="2. Select QuickBooks Class"
                      placeholder={'Select class or start typing'}
                      optionsArray={classes?.map(({ Name, Id }) => ({
                        label: Name,
                        value: Id,
                      }))}
                      onChange={(e) => handleClassChanged(e)}
                      selectedoption={selectedClass}
                      isDisabled={(!customers?.length > 0) || !isLoggedInQB}
                    />
                  </Box>
                  <Divider/>
                  <Box paddingTop={'25px'}>
                    <Text textStyle='heading-2'>3. Select Division</Text>
                  </Box>
                  <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                    <FormSelectSimple
                      key='select_division'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectDivision"
                      // fieldlabel="3. Select Division"
                      placeholder={'Select division or start typing'}
                      optionsArray={divisions?.map(({ defaultQBClass, id }) => ({
                        label: defaultQBClass,
                        value: id,
                      }))}
                      onChange={(e) => handleDivisionChanged(e)}
                      selectedoption={selectedDivision}
                      />
                    </Box>
                    <Divider/>
                    <Box paddingTop={'25px'}>
                      <Text textStyle='heading-2'>4. Create New Client</Text>
                    </Box>
                    <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname="newClientName"
                        // fieldlabel="4. Create New Client"
                        prettyname="New Client"
                        // onChange={handleCompanyDetailsChanged}
                        placeholder="Enter new client name"
                      />
                    </Box>
                </ModuleBox>
              </>}
              {/* update */}

              {(selectedActionButton === "update") && <>
                <ModuleBox>
                  <Box>
                    <HStack >
                      <Box paddingTop={'6px'}>
                        <AvailableIcons boxSize={'25px'} color={'var(--success-green)'} iconRef={'refresh'} />
                      </Box>
                      <Box>
                        <Text textStyle='heading-1'>Update Existing Client</Text>
                      </Box>
                    </HStack>
                  </Box>
                </ModuleBox>

                <ModuleBox>
                  <Box>
                    <Text textStyle='heading-2'>1. Select Client</Text>
                  </Box>
                  <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                    <FormSelectSimple
                      key='select_client'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectClient"
                      // fieldlabel="1. Select Client"
                      optionsArray={existingClients?.map(({ id, contactCompany }) => ({
                        label: `${contactCompany}`,
                        value: id,
                      }))}
                      onChange={(e) => handleClientChanged(e)}
                      selectedoption={selectedClient}
                      placeholder={'Select client or start typing'}
                    />
                  </Box>
                  <Divider/>
                  <Box paddingTop={'25px'}>
                    <Text textStyle='heading-2'>2. Select QuickBooks Customers</Text>
                  </Box>
                  <Box paddingTop={'5px'} paddingBottom={'25px'} px={'25px'} w={'50%'}>
                    <FormSelectSimple
                      key='select_customer'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectCustomer"
                      // fieldlabel="2. Select QuickBooks Customers"
                      optionsArray={customers?.map(({ Id, FullyQualifiedName }) => ({
                        label: FullyQualifiedName,
                        value: Id,
                      }))}
                      onChange={(e) => handleCustomerChanged(e)}
                      selectedoption={selectedCustomer}
                      placeholder={'Select customer or start typing'}
                      isDisabled={(!customers?.length > 0) || !isLoggedInQB}
                    />
                  </Box>
                </ModuleBox>
              </>}

              {(selectedActionButton === "update" || selectedActionButton === "create") && <>
                <ModuleBox>
                  <Center>
                    <HStack py={'25px'}>
                      <Box>
                        <ButtonSecondaryPlain
                          // isDisabled={(!Number(selectedQBCustomerObj?.Id) || !Number(selectedClientObj?.id))}
                          type='button'
                          width={'125px'}
                          name='cancel'
                          value='Cancel'
                          onClick={() => {
                            resetAllData()
                          }}
                        />
                      </Box>
                      <Box w={'25px'}></Box>
                      <Box>
                        <ButtonPrimaryPlain
                          // isDisabled={true}
                          // isDisabled={(Number(selectedQBCustomerObj?.Id) !== Number(selectedClientObj?.id))}
                          type="button"
                          width="355px"
                          onClick={() => {
                            handleSyncQBClientData(selectedQBCustomerObj, selectedActionButton)
                          }}
                          name='sync_qbt'
                          value={submitButtonText}
                        />
                      </Box>
                    </HStack>
                  </Center>
                </ModuleBox>
              </>}

              {(isDebug) && <HStack paddingTop={'25px'}>
                    <Box w={'500px'} border={'1px solid green'}>
                      <Text as="span" textStyle='dark-heading-2'>selectedQBCustomerObj: <ObjectRenderer obj={selectedQBCustomerObj} /></Text>
                    </Box>
                    
                    <Box w={'500px'} border={'1px solid green'}>
                      <Text as="span" textStyle='dark-heading-2'>selectedClientObj: <ObjectRenderer obj={selectedClientObj} /></Text>
                    </Box>
              </HStack>}
              {/* <ModuleBox>
                <Box>
                  <Text className={searchHeaderTextClass}>Select QuickBooks Customer</Text>
                </Box>
                <Box p={'25px'} w={'50%'}>
                  <FormSelectSimple
                    key='select_customer'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectCustomer"
                    fieldlabel="QuickBooks Customers"
                    optionsArray={customers?.map(({ Id, FullyQualifiedName }) => ({
                      label: FullyQualifiedName,
                      value: Id,
                    }))}
                    onChange={(e) => handleCustomerChanged(e)}
                    selectedoption={selectedCustomer}
                    placeholder={'Select a QuickBooks Customer'}
                    isDisabled={(!customers?.length > 0) || !isLoggedInQB}
                  />
                </Box>
                <Box p={'25px'} w={'50%'}>
                  <FormSelectSimple
                    key='select_class'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectClass"
                    fieldlabel="QuickBooks Class"
                    optionsArray={classes?.map(({ Name, Id }) => ({
                      label: Name,
                      value: Id,
                    }))}
                    onChange={(e) => handleClassChanged(e)}
                    selectedoption={selectedClass}
                    isDisabled={(!customers?.length > 0) || !isLoggedInQB}
                  />
                </Box>
              </ModuleBox>

              <ModuleBox>
                <Box>
                  <Text className={searchHeaderTextClass}>Select Spike Client</Text>
                </Box>

                <Box p={'25px'} w={'50%'}>
                  <FormSelectSimple
                    key='select_client'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectClient"
                    fieldlabel="Update Existing Client"
                    optionsArray={existingClients?.map(({ id, contactCompany }) => ({
                      label: `${contactCompany}`,
                      value: id,
                    }))}
                    onChange={(e) => handleClientChanged(e)}
                    selectedoption={selectedClient}
                    placeholder={'Select an Existing Spike Client'}
                  />
                </Box>

                <Box p={'25px'} w={'50%'}>
                  <TextInput
                    register={register}
                    errors={errors}
                    fieldname="newClientName"
                    fieldlabel="Create New Client"
                    prettyname="New Client"
                    // onChange={handleCompanyDetailsChanged}
                    placeholder="Enter client name"
                  />
                </Box>
              </ModuleBox>

              <ModuleBox>
              <Box paddingBottom={'25px'}>
                  <Text as="span" textStyle='dark-heading-2'>Environment: {qbEnv}</Text>
                </Box>
                <Box paddingBottom={'25px'}>
                  <Text as="span" textStyle='dark-heading-2'>isLoggedInQB: {(isLoggedInQB) ? 'true' : 'false'}</Text>
                </Box>
              
                <HStack>
                <Box w={'48%'}>{(customers?.length > 0) && <FormSelectSimple
                    key='select_customer'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectCustomer"
                    fieldlabel="QuickBooks Customers"
                    optionsArray={customers?.map(({ Id, FullyQualifiedName }) => ({
                      label: FullyQualifiedName,
                      value: Id,
                    }))}
                    onChange={(e) => handleCustomerChanged(e)}
                    selectedoption={selectedCustomer}
                    placeholder={'Select a QuickBooks Customer'}
                    // isDisabled={!selectedClient}
                  />}</Box>
                <Box w={'4%'} paddingTop={'35px'} paddingLeft={'8px'}>
                  {(customers?.length > 0) && <AvailableIcons boxSize={'22px'} iconRef={'arrowright'} />}
                </Box>
                <Box w={'48%'}>{(customers?.length > 0) && <FormSelectSimple
                    key='select_client'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectClient"
                    fieldlabel="Spike Clients not Synced"
                    optionsArray={existingClients?.map(({ id, contactCompany }) => ({
                      label: `${contactCompany}`,
                      value: id,
                    }))}
                    onChange={(e) => handleClientChanged(e)}
                    selectedoption={selectedClient}
                    placeholder={'Select a Spike Client'}
                  />}</Box>
                </HStack>

                <Box w='48%' paddingTop={'25px'}>
                  {(customers?.length > 0) && <FormSelectSimple
                    key='select_class'
                    register={register}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    fieldname="selectClass"
                    fieldlabel="QuickBooks Class"
                    optionsArray={classes?.map(({ Name, Id }) => ({
                      label: Name,
                      value: Id,
                    }))}
                    onChange={(e) => handleClassChanged(e)}
                    selectedoption={selectedClass}
                  />}
                </Box>

                
                <Box marginTop={'25px'} padding={'10px'} w={'100%'} border={`1px solid ${matchColor}`}>
                  <Text as="span" textStyle='dark-heading-2'>{customerDebugString} </Text><Button
                    isDisabled={!matchedQBCustomer || !selectedClient}
                    type="button"
                    onClick={() => {
                      handleCustomerChanged({label: matchedQBCustomer?.FullyQualifiedName, value: matchedQBCustomer?.Id});
                    }}
                  >
                    Set QuickBooks Customer
                  </Button>
                </Box>
                <Box marginTop={'25px'} padding={'10px'} w={'100%'} border={`1px solid ${matchColor}`}>
                  <Text as="span" textStyle='dark-heading-2'>If this is a brand new Spike client: </Text>
                  <Text as="span" textStyle='dark-heading-2'>{clientDebugString} </Text>
                  <Box w='400px' py={'25px'}>
                  {(customers?.length > 0) && <FormSelectSimple
                      key='select_division'
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      fieldname="selectDivision"
                      fieldlabel="Spike Division"
                      optionsArray={divisions?.map(({ defaultQBClass, id }) => ({
                        label: defaultQBClass,
                        value: id,
                      }))}
                      onChange={(e) => handleDivisionChanged(e)}
                      selectedoption={selectedDivision}
                    />}
                  </Box>
                  <Button
                    // isDisabled={!selectedCustomer || !selectedClass || selectedClient || !selectedDivisionId}
                    isDisabled={true}
                    type="button"
                    onClick={() => {
                      handleNewClient(selectedQBCustomerObj);
                    }}
                  >
                    Add New Spike Client
                  </Button>
                  <Text as="span" color={'var(--error-red)'} textStyle='dark-heading-2'> {newClientError}</Text>
                </Box>

                <Box paddingTop={'25px'}>
                  <Text as="span" textStyle='dark-heading-2'>Suggested Class: {JSON.stringify(suggestedClass)}</Text>
                </Box>

                {(currentTeamMemberId==='de23b85a-edf2-4a14-9668-ded622d8a856') && <>
                  <Box paddingTop={'25px'}>
                    <Text as="span" textStyle='dark-heading-2'>Selected Division: {JSON.stringify(selectedDivision)}</Text>
                  </Box>
                  
                  <Box paddingTop={'25px'}>
                    <Text as="span" textStyle='dark-heading-2'>Selected Spike Client: {JSON.stringify(selectedClient)}</Text>
                  </Box>

                  <Box paddingTop={'25px'}>
                    <Text as="span" textStyle='dark-heading-2'>Selected QuickBooks Customer: {JSON.stringify(selectedCustomer)}</Text>
                  </Box>

                  <HStack paddingTop={'25px'}>
                    <Box w={'500px'} border={'1px solid green'}>
                      <Text as="span" textStyle='dark-heading-2'>selectedQBCustomerObj: <ObjectRenderer obj={selectedQBCustomerObj} /></Text>
                    </Box>
                    
                    <Box w={'500px'} border={'1px solid green'}>
                      <Text as="span" textStyle='dark-heading-2'>selectedClientObj: <ObjectRenderer obj={selectedClientObj} /></Text>
                    </Box>
                  </HStack>

                  

                  <Box>
                    <Text as="span" textStyle='dark-heading-2'>ID: {selectedQBCustomerObj?.Id}</Text>
                  </Box>
                  <Box>
                    <Text as="span" textStyle='dark-heading-2'>FullyQualifiedName: {selectedQBCustomerObj?.FullyQualifiedName}</Text>
                  </Box>
                  <Box>
                    <Text as="span" textStyle='dark-heading-2'>PrimaryEmailAddr: {selectedQBCustomerObj?.PrimaryEmailAddr?.Address}</Text>
                  </Box>
                </>}

                <HStack paddingTop={'25px'}>
                  <Box w={'48%'}>
                    <ButtonSecondaryPlain
                      // isDisabled={(!Number(selectedQBCustomerObj?.Id) || !Number(selectedClientObj?.id))}
                      type='button'
                      width={'100%'}
                      name='cancel'
                      value='Cancel'
                      onClick={() => {
                        resetAllData()
                      }}
                    />
                  </Box>
                  <Box w={'4%'}></Box>
                  <Box w={'48%'}>
                    <ButtonPrimaryPlain
                      isDisabled={true}
                      // isDisabled={(!Number(selectedQBCustomerObj?.Id) || !selectedClientObj?.id)}
                      type="button"
                      width="100%"
                      onClick={() => {
                        handleSyncQBClientData(selectedQBCustomerObj, "update")
                      }}
                      name='sync_qbt'
                      value='Sync QuickBooks Customer to Spike Client'
                    />
                  </Box>
                </HStack>

              </ModuleBox> */}
            </>
          </Container>
        )}
      </QuickBooksAuth>
      
    </>
  )
}
