/* eslint-disable react/prop-types */
import { useEffect, useContext, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Container,
  Box,
  HStack,
  Text,
  Flex,
  Spacer,
  useBreakpointValue,
  useDisclosure,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Spinner,
} from "@chakra-ui/react";

// graphql components
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchContactCompanyByCompanyId } from '../graphqlCompnents/DJT/fetchContactCompanyByCompanyId.jsx';
import { fetchPunchedLaborByDateDepartment } from '../graphqlCompnents/DJT/fetchPunchedLaborByDateDepartment.jsx';
import { fetchInspectedUnitsByDateByStatus } from '../graphqlCompnents/DJT/fetchInspectedUnitsByDateByStatus.jsx';
import { fetchJobClassRatesByDivisionId } from '../graphqlCompnents/JobClass/fetchJobClassRatesByDivisionId.jsx';
import { fetchAuxillaryUnitOptionsByUnitId } from '../graphqlCompnents/DJT/fetchAuxillaryUnitOptionsByUnitId.jsx';
import { fetchAuxillaryUnitByUnitId } from '../graphqlCompnents/DJT/fetchAuxillaryUnitByUnitId.jsx';
import { fetchDefaultBillableMaterials } from '../graphqlCompnents/DJT/fetchDefaultBillableMaterials.jsx';
import { fetchClientContactsByClientId } from '../graphqlCompnents/DJT/fetchClientContactsByClientId.jsx';
import { fetchAllUnits } from '../graphqlCompnents/DJT/fetchAllUnits.jsx';
import { fetchAllAuxillaryUnitOptions } from '../graphqlCompnents/DJT/fetchAllAuxillaryUnitOptions.jsx';
import { fetchUnitOperators } from '../graphqlCompnents/DJT/fetchUnitOperators.jsx';
import { fetchDailyJobTicketById } from '../graphqlCompnents/DJT/fetchDailyJobTicketById.jsx';
import { addDJTLabor } from '../graphqlCompnents/DJT/addDJTLabor.jsx';
import { addDJTEquipment } from '../graphqlCompnents/DJT/addDJTEquipment.jsx';
import { addDJTUnitDriver } from '../graphqlCompnents/DJT/addDJTUnitDriver.jsx';
import { addDJTUnitOperator } from '../graphqlCompnents/DJT/addDJTUnitOperator.jsx';
import { addDJTMaterial } from '../graphqlCompnents/DJT/addDJTMaterial.jsx';
import { addDailyJobTicket } from '../graphqlCompnents/DJT/addDailyJobTicket.jsx';
import { addClientContact } from '../graphqlCompnents/DJT/addClientContact.jsx';
// import { addClientEmailSummary } from "../graphqlCompnents/DJT/addClientEmailSummary";
import { addClientEmailSummarySent } from '../graphqlCompnents/DJT/addClientEmailSummarySent.jsx';
import { updateTMPopularity } from '../graphqlCompnents/DJT/updateTMPopularity.jsx';
import { updateUnitPopularity } from '../graphqlCompnents/DJT/updateUnitPopularity.jsx';
import { listAllTeamMembers } from '../graphqlCompnents/TeamMember/listAllTeamMembers.jsx';
import { getStatusIdByStatusCode } from '../graphqlCompnents/DJT/getStatusIdByStatusCode.jsx';
import { getStatusCodeByStatusId } from '../graphqlCompnents/DJT/getStatusCodeByStatusId.jsx';
import { fetchClientContactByClientContactId } from '../graphqlCompnents/DJT/fetchClientContactByClientContactId.jsx';
// import { fetchNoTimeCoTempEmployeesByDivisionId } from '../graphqlCompnents/DJT/fetchNoTimeCoTempEmployeesByDivisionId.jsx';
import { addClient } from '../graphqlCompnents/DJT/addClient.jsx';
// import { updateNoTimeCoTempEmployee } from '../graphqlCompnents/DJT/updateNoTimeCoTempEmployee.jsx';
import { getClientBillingEmailByClientId } from '../graphqlCompnents/DJT/getClientBillingEmailByClientId.jsx';
import { updateParentDJTAsParent } from '../graphqlCompnents/DJT/updateParentDJTAsParent.jsx';
import { updateDJTLaborUsed } from '../graphqlCompnents/DJT/updateDJTLaborUsed.jsx';
import { fetchClientPOsByDivisionClient } from '../graphqlCompnents/DJT/fetchClientPOsByDivisionClient.jsx';
import { addClientPO } from '../graphqlCompnents/DJT/addClientPO.jsx';
import { fetchClientPoById } from '../graphqlCompnents/DJT/fetchClientPoById.jsx';
import { updateDailyJobTicket } from '../graphqlCompnents/DJT/updateDailyJobTicket.jsx';
import { fetchDJTsByParentId } from '../graphqlCompnents/DJT/fetchDJTsByParentId.jsx';
import { fetchDJTParentalStatusById } from '../graphqlCompnents/DJT/fetchDJTParentalStatusById.jsx';
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';
import { addClientContract } from '../graphqlCompnents/DJT/addClientContract.jsx';
import { fetchClientContractsByClientId } from '../graphqlCompnents/DJT/fetchClientContractsByClientId.jsx';
import { addDJTJournal } from '../graphqlCompnents/DJT/addDJTJournal.jsx';
import { updateDJTEquipmentUsed } from '../graphqlCompnents/DJT/updateDJTEquipmentUsed.jsx';
import { updateDJTMaterialUsed } from '../graphqlCompnents/DJT/updateDJTMaterialUsed.jsx';
import { fetchUnitOperatorsByEquipmentId } from '../graphqlCompnents/DJT/fetchUnitOperatorsByEquipmentId.jsx';
import { removeUnitOperator } from '../graphqlCompnents/DJT/removeUnitOperator.jsx';
import { fetchUnitDriversByEquipmentId } from '../graphqlCompnents/DJT/fetchUnitDriversByEquipmentId.jsx';
import { removeUnitDriver } from '../graphqlCompnents/DJT/removeUnitDriver.jsx';
import { updateClientContact } from '../graphqlCompnents/DJT/updateClientContact.jsx';
import { fetchVerifiedEmailDomainsByClientId } from '../graphqlCompnents/DJT/fetchVerifiedEmailDomainsByClientId.jsx';
import { fetchCCEmailsByClientId } from '../graphqlCompnents/DJT/fetchCCEmailsByClientId.jsx';
// import { addClientCCEmail } from '../graphqlCompnents/DJT/addClientCCEmail';

// utility functions
import { todaysDatePlusNDaysYMD, todaysDateYMD, 
  nextDayYMD, awsDateTimeNow, isValidDate, 
  spellOutDate, isWeekend } from '../functions/dateTime.jsx';
import { isCurrency, isWholeNumber, converNumberToDouble, convertToCurrency } from '../functions/number.jsx';
import { convertCurrencyToDouble, USDollar } from '../functions/currency.jsx';
import { enforceFormat, formatToPhone, formatToPhoneData } from '../functions/formatPhoneNumber.jsx';
import { doEmailAddressDomainsMatch } from '../functions/match.jsx';
import { prettifyName, validateEmail } from '../functions/generic.jsx';
import { multiplyIfEaster } from '../functions/easter.jsx';
// import { is_touch_enabled } from './../functions/touch'
import { v4 as uuidv4 } from 'uuid';

// aws S3 for signature images
// import { getBase64FromDataUrl } from '../AWS/S3/getBase64FromDataURL';
// import { addFileToUploadedDocument } from '../AWS/S3/addFileToUploadedDocument'
// import { addFileToS3Storage } from '../AWS/S3/addFileToS3Storage'

// globals
import { AppContext } from '../AppContext.jsx';

// icon component
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';
import { ModuleBoxDrawerAttention } from '../Structural/ModuleBoxDrawerAttention.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';
import { FormSelectCreatableSingle } from '../Form/Select/FormSelectCreatableSingle.jsx';

// decorative components
import { Divider } from '../Divider.jsx';

// button components
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonSeptenaryWithIcon } from '../Form/Button/ButtonSeptenaryWithIcon.jsx';
import { ButtonOctonaryWithIcon } from '../Form/Button/ButtonOctonaryWithIcon.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonDenaryPlain } from '../Form/Button/ButtonDenaryPlain.jsx';
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';

// summary table components
import { JobOverview } from './JobOverview.jsx';
import { LaborSummaryTable } from './LaborSummaryTable.jsx';
import { EquipmentSummaryTable } from './EquipmentSummaryTable.jsx';
import { MaterialSummaryTable } from './MaterialSummaryTable.jsx';

// modal components
import { CenteredAllLaborModal } from '../Modal/CenteredAllLaborModal.jsx';
import { CenteredAllUnitsModal } from '../Modal/CenteredAllUnitsModal=.jsx';
import { CenteredSubmitDJTModal } from '../Modal/CenteredSubmitDJTModal.jsx';
import { CenteredLoadDuplicateEditDJTModal } from '../Modal/CenteredLoadDuplicateEditDJTModal.jsx';
import { CenteredConfirmContactDataChange } from '../Modal/CenteredConfirmContactDataChange.jsx';

// signature pad component
import SignaturePad from "react-signature-canvas";
// import { CognitoUserPool } from 'amazon-cognito-identity-js';

import usePreventBackNavigation from '../functions/usePreventBackNavigation.jsx';

// temporary testing imports
// import { validateEmailAddress } from '../ExternalAPIs/Abstract/validateEmailAddress.jsx';

// for duplicate, we just need to consume the djt data and check for djtid
// djt id lets us know we are pre-loading data.
export const CreateDailyJobTicket = (props) => {
  const { 
    onClose,
    reviewing,
    srcAction,
    srcId,
    isParent,
    selectedDivision,
  } = props;

  // console.log('+++ loading +++');
  usePreventBackNavigation();

  // --- SOME INITIAL STATES
  // grab global context values
  const { store } = useContext(AppContext);
  // set the users current division id
  // const currentDivisionId = store?.userData?.divisionId
  const currentDivisionId = selectedDivision||store?.userData?.divisionId;
  // if the division only has one client like Tennessee Valero
  const divisionHasOneClient = store?.divisionHasOneClient
  const divisionAllowsEmailCC = store?.divisionAllowsEmailCC

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id

  // set the current users name for the signature pad and email summary
  const spikeRepresentativeName = store?.userData?.name
  const spikeRepresentativeEmail = store?.userData?.email
  
  const defaultStartTime = store?.defaultStartTime||'08:00'
  const defaultEndTime = store?.defaultEndTime||'17:00'
  const defaultLunchDuration = store?.defaultLunchDuration||'30'
  // show the toggle to merge material with equipment - Valero specific
  const showToggleMergeMaterialEquipment = store?.showMaterialEquipmentMergeToggle||false

  // --- REACT-HOOK-FORM ---
  const { 
    setFocus, setError, control, register, handleSubmit, clearErrors, setValue, getValues, trigger, formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const {
    fields: laborFields,
    append: laborAppend,
    remove: laborRemove,
  } = useFieldArray({ control, name: "labor" });

  const {
    fields: unitFields,
    append: unitAppend,
    insert: unitInsert,
    remove: unitRemove
  } = useFieldArray({ control, name: "unit" });

  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove
  } = useFieldArray({ control, name: "material" });

  const onError = (errors, e) => console.error(errors, e);

  const [ doMergeMaterialEquipment, setDoMergeMaterialEquipment ] = useState(false)
  const isUserMobile = store.userIsMobile||false;
  const isEstimate = (srcAction?.includes('estimate')) ? true : false
  const estimateType = (isEstimate) ? srcAction.split('-')[0] : null
  const isDuplicate = (srcAction==="duplicate" || estimateType==='duplicate') ? true : false
  const isEdit = (srcAction==="edit" || estimateType==='edit') ? true : false
  const isNew = (srcAction==="new" || srcAction==="new-estimate") ? true : false
  const isReviewing = (reviewing===true && srcAction==="edit") ? true : false
  const [ parentId, setParentId ] = useState(null)
  const [ loadedParentId, setLoadedParentId ] = useState(false)
  const [ isChild, setIsChild ] = useState(false)
  const duplicateOfId = (isDuplicate) ? parentId : null
  const [ isTicketDateWeekend, setIsTicketDateWeekend ] = useState(false)
  const [ djtDate, setDjtDate ] = useState(null)  // initialize a null date
  const [ prevDjtDate, setPrevDjtDate ] = useState(null)    // initialize a null previous date
  const [ djtDateLong, setDjtDateLong ] = useState(null)    // initialize a null long spelled out date
  const djtId = (srcId) ? srcId : uuidv4()
  const [ isDisabledWHileSending, setIsDisabledWhileSending ] = useState(false)
  const [ doShowEmailSentResults, setDoShowSentEmailResults ] = useState(false)
  const [ emailSentResults, setEmailSentResults ] = useState(null)
  const fileInputRef = useRef(null)
  const formRef = useRef(null)
  const [ clientContactDisabled, setClientContactDisabled ] = useState(true)
  const unitOperatorsLimit = 100
  const [ verifiedEmailDomains, setVerifiedEmailDomains ] = useState([])
  const [ thirdPartyJobClass, setThirdPartyJobClass ] = useState({})
  const [ clientContactSelected, setClientContactSelected ] = useState(null)
  const [ companySelectedOption, setCompanySelectedOption ] = useState(null)
  const auxUnitsLimit = 100
  const [ djtDuplicateLaborData, setDjtDuplicateLaborData ] = useState(null)
  const [ isDuplicateLaborLoaded, setIsDuplicateLaborLoaded ] = useState(false)
  const [ ticketHasConvertedLabor, setTicketHasConvertedLabor ] = useState(false)
  const [ laborIsLocked, setLaborIsLocked ] = useState(false)
  const [ djtDuplicateEquipmentData, setDjtDuplicateEquipmentData ] = useState(null)
  const [ isDuplicateEquipmentLoaded, setIsDuplicateEquipmentLoaded ] = useState(false)
  const [ currentAuxUnitCode, setCurrentAuxUnitCode ] = useState(700)
  const [ currentlyMissingDriver, setCurrentlyMissingDriver ] = useState(false)
  const [ unitDriversStatus, setUnitDriversStatus ] = useState([])
  const [ currentlyMissingOperator, setCurrentlyMissingOperator ] = useState(false)
  const [ unitOperatorsStatus, setUnitOperatorsStatus ] = useState([])
  const [ isDuplicateMaterialLoaded, setIsDuplicateMaterialLoaded ] = useState(false)
  const [ loadDuplicateModalContent, setLoadDuplicateModalContent ] = useState(null)
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ changedDate, setChangedDate ] = useState(null)
  const [ existingLabor, setExistingLabor ] = useState(null)
  const [ existingEquipment, setExistingEquipment ] = useState(null)
  const [ existingMaterial, setExistingMaterial ] = useState(null)
  const [ bouncedAdminReason, setBouncedAdminReason ] = useState(null)
  const [ hasZeroDollarLabor, setHasZeroDollarLabor ] = useState(false)
  const [ hasZeroDollarPrimary, setHasZeroDollarPrimary ] = useState(false)
  const [ hasZeroDollarAuxilliary, setHasZeroDollarAuxilliary ] = useState(false)
  const [ hasZeroDollarCustomAuxilliary, setHasZeroDollarCustomAuxilliary ] = useState(false)
  const [ hasZeroDollarRental, setHasZeroDollarRental ] = useState(false)
  const [ indexOfFirstZeroDollarRental, setIndexOfFirstZeroDollarRental ] = useState(null)
  const [ lastUnitItemAddedId, setLastUnitItemAddedId ] = useState(null)
  const [ hasZeroDollarMaterial, setHasZeroDollarMaterial ] = useState(false)
  const [ laborSummaryTotal, setLaborSummaryTotal ] = useState(0.00)
  const maxBillableHours = 8
  let billableHours = []
  const [ removedLabor, setRemovedLabor ] = useState([])
  const [ updatedLabor, setUpdatedLabor ] = useState([])
  const [ addedLabor, setAddedLabor ] = useState([])
  const [ duplicatedLaborError, setDuplicatedLaborError ] = useState([])
  const [ laborSummary, setLaborSummary ] = useState([])
  const updateLaborSummaryTotal = (value) => setLaborSummaryTotal((laborSummaryTotal) => laborSummaryTotal + value);
  const [ isClientNew, setIsClientNew ] = useState(false)
  const [ newClientId, setNewClientId ] =useState(null)
  const [ clientContractSelectedOption, setClientContractSelectedOption ] = useState(null)
  const [ availableClientContracts, setAvailableClientContracts ] = useState(null)
  const [ clientPoSelectedOption, setClientPoSelectedOption ] = useState(null)
  const [ availableClientPOs, setAvailableClientPOs ] = useState(null)
  const [ clientId, setClientId ] = useState(null)
  const [ doNotBillState, setDoNoBillState ] = useState(false)
  const [ clientContactInfoChanged, setClientContactInfoChanged ] = useState(false)
  const [ unitSummary, setUnitSummary ] = useState([])
  const [ duplicatedUnitError, setDuplicatedUnitError ] = useState([])
  const [ removedEquipment, setRemovedEquipment ] = useState([])
  const [ updatedEquipment, setUpdatedEquipment ] = useState([])
  const [ addedEquipment, setAddedEquipment ] = useState([])
  let previousParentId = ''
  let previousNewParentId = ''
  const [ unitSummaryTotal, setUnitSummaryTotal ] = useState(0.00)  // store total as a double, display as currency
  let maxAuxillaryOptionsSelected = false
  const [ materialSummary, setMaterialSummary ] = useState([])
  const [ materialSummaryTotal, setMaterialSummaryTotal ] = useState(0.00)  // store total as a double, display as currency
  const [ materialsTotalCost, setMaterialsTotalCost ] = useState('0.00')
  const [ materialsForDay, setMaterialsForDay ] = useState([])
  const [ removedMaterial, setRemovedMaterial ] = useState([])
  const [ updatedMaterial, setUpdatedMaterial ] = useState([])
  const [ addedMaterial, setAddedMaterial ] = useState([])
  const [ companyName, setCompanyName ] = useState(null)
  const [ jobClassSelected, setJobClassSelected ] = useState([])
  let costItem = []
  const [ laborLength, setLaborLength ] = useState(null)
  const [ laborForDay, setLaborForDay ] = useState(null)
  const [ unitsLength, setUnitsLength ] =useState(null)
  const [ unitsForDay, setUnitsForDay ] = useState(null)
  let billableMaterialsForDay = [];
  let materialCostItem = [];
  let  materialPriceItem = [];
  let  materialQuantityItem = [];
  const [ auxillaryUnitOptions, setAuxillaryUnitOptions ] = useState([])
  const [ loadedAllTeamMembers, setLoadedAllTeamMembers ] = useState([])
  const [ allTeamMembers, setAllTeamMembers ] = useState([])
  const [ loadedAllUnits, setLoadedAllUnits ] = useState([])
  const [ allUnits, setAllUnits ] = useState([])
  const isFirstRender = useRef(true)
  const lastMaterialLength = useRef(0)
  const [ clientContactName, setClientContactName ] = useState(null)
  const [ clientContactEmailVerified, setClientContactEmailVerified ]= useState(false)
  const [ clientContactEmailAddress, setClientContactEmailAddress ] = useState(null)
  const [ clientContactId, setClientContactId ] = useState(null)
  const [ attachedContractId, setAttachedContractId ] = useState(null)
  const [ showFileUploadSpinner, setShowFileUploadSpinner ] = useState(false)
  const [ contactChangedTypeClicked, setContactChangedTypeClicked ] = useState(null)
  const [ djtDescription, setDjtDescription ] = useState(null)
  const [ djtPo, setDjtPo ] = useState(null)
  const [ typedDjtDate, setTypedDjtDate ] = useState('')
  // const templEmployeeEndDateGracePeriod = 30
  const [ rowTotalChange, setRowTotalChange ] = useState([])
  const [ djtRunningTotal, setDjtRunningTotal ] = useState(null)
  const [ selectedDrivers, setSelectedDrivers ] = useState([])
  const [ selectedOperators, setSelectedOperators ] = useState([])
  const [ isInitialMaterialLoad, setIsInitialMaterialLoad ] = useState(true)
  const sigCanvasClient = useRef({});
  const sigCanvasSpike = useRef({});
  const contactClientSelectRef = useRef();
  const [ nextLaborToken, setNextLaborToken ] = useState(undefined)
  const [ nextNextLaborToken, setNextNextLaborToken ] = useState(null)
  const [ previousLaborTokens, setPreviousLaborTokens ] = useState([])
  const hasLaborNext = !!nextNextLaborToken
  const hasLaborPrev = previousLaborTokens.length
  const [ currentLaborPage, setCurrentLaborPage ] = useState(0)
  let refetchLaborCount = 0
  const [ isUnitsDataLoading, setIsUnitsDataLoading ] = useState(false)
  let nextUnitsToken = ''
  let currentUnitsPage = 0
  let refetchUnitsCount = 0
  let queryLimit= 24
  const [ showAllTeamMembers, setShowAllTeamMembers ] = useState(false)
  const [ isLaborDataLoading, setIsLaborDataLoading ] = useState(false)
  const [ showAllUnits, setShowAllUnits ] = useState(false)
  const [ operatorsDriversSelectOptions, setOperatorsDriversSelectOptions ] = useState(null)
  const [ currentAllLaborLength, setCurrentAllLaborLength ] = useState(0) 
  const [ currentAllUnitsLength, setCurrentAllUnitsLength ] = useState(0) 
  const [ maxPageReached, setMaxPageReached ] = useState(0)
  const [ maxUnitsPageReached, setMaxUnitsPageReached ] = useState(0)
  const [ pageNumberRecordCount, setPageNumberRecordCount ] = useState([])
  const [ unitsPageNumberRecordCount, setUnitsPageNumberRecordCount ] = useState([])
  const [ unitButtonVariants, setUnitButtonVariants ] = useState([])
  const [ allUnitButtonVariants, setAllUnitButtonVariants ] = useState([])
  const [ materialButtonVariants, setMaterialButtonVariants ] = useState([])
  const [ laborButtonVariants, setLaborButtonVariants ] = useState([])
  const [ allLaborButtonVariants, setAllLaborButtonVariants ] = useState([])
  const [ highestLaborPageLoaded, setHighestLaborPageLoaded ] = useState(0)
  const [ highestUnitsPageLoaded, setHighestUnitsPageLoaded ] = useState(0)
  const [ doShowHoursState, setDoShowHoursState ] = useState(false)
  const [ isVerifiedReadyState, setIsVerifiedReadyState ] = useState(false)
  const [accordianLaborIndex, setAccordianLaborIndex] = useState(-1)
  const [accordianUnitIndex, setAccordianUnitIndex] = useState(-1)
  const [ hideModuleFromClientView, setHideModuleFromClientView ] = useState(false)
  const [ accordianClientSummaryIndex, setAccordianClientSummaryIndex ] = useState(-1)
  const [ accordianClientAuthorizationIndex, setAccordianClientAuthorizationIndex ] = useState(-1)
  const [ hasShownAllLaborModal, setHasShownAllLaborModal ] = useState(false)
  const [ hasShownAllUnitsModal, setHasShownAllUnitsModal ] = useState(false)
  const confirmContactDataChangeCancelRef = useRef()
  const { isOpen: isLaborModalOpen , onOpen: onLaborModalOpen, onClose: onLaborModalClose } = useDisclosure()
  const { isOpen: isUnitsModalOpen , onOpen: onUnitsModalOpen, onClose: onUnitsModalClose } = useDisclosure()
  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const { isOpen: isLoadDuplicateModalOpen , onOpen: onLoadDuplicateModalOpen, onClose: onLoadDuplicateModalClose } = useDisclosure()
  const { isOpen: isConfirmContactDataChangeModalOpen , onOpen: onConfirmContactDataChangeModalOpen, onClose: onConfirmContactDataChangeClose } = useDisclosure()
  let scrollBehavior = 'outside'
  // const [ isMaterialFirstRender, setIsMaterialFirstRender ] = useState(true)
  let confirmContactDataChangeCloseDisabled = true
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  const [ isLaborDisabled, setIsLaborDisabled ] = useState(false)
  const [ isUnitsDisabled, setIsUnitsDisabled ] = useState(false)
  let isMaterialDisabled = false
  const [ loadDuplicatetModalCloseDisabled, setLoadDuplicatetModalCloseDisabled ] = useState(true)
  const [ loadDuplicateProgress, setLoadDuplicateProgress ] = useState(0)
  const inputElement = document.getElementById('contactPhone');
  (inputElement) && inputElement.addEventListener('keydown',enforceFormat);
  (inputElement) && inputElement.addEventListener('keyup',formatToPhone);
  const [ originalAllUnits, setOriginalAllUnits ] = useState([])
  const [ currentEquipmentSearchStringLength, setCurrentEquipmentSearchStringLength ] = useState(0)
  const [ showAllUnitsInSearch, setShowAllUnitsInSearch ] = useState(true)
  const [ ccEmailOptions, setCcEmailOptions ] = useState([])
  const [ ccRecipientSelected, setCcRecipientSelected ] = useState('')
  const [ ccRecipientEmailsSelected, setCcRecipientEmailsSelected ] = useState([])
  const [ clientCompanies, setClientCompanies ] = useState([])
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const [ unitOperators, setUnitOperators ] = useState([])
  const [ billableDefaultMaterials, setBillableDefaultMaterials ] = useState([])
  const [ clientContacts, setClientContacts ] = useState([])
  const [ allAuxillaryUnitOptions, setAllAuxillaryUnitOptions ] = useState([])
  const [ billableRatesJobClass, setbillableRatesJobClass ] = useState([])

  const { data: companies } = useQuery(
    ['fetchContactCompaniesByDivision', currentDivisionId],
    () => fetchContactCompaniesByDivision(currentDivisionId, isEstimate),
    {
      enabled: !!currentDivisionId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (companies?.length>0) {
      setClientCompanies(companies);
    }
  }, [companies]);
  
  const { data: ratesClassData } = useQuery(
    ['fetchJobClassRatesByDivisionId', currentDivisionId],
    () => fetchJobClassRatesByDivisionId(currentDivisionId),
    {
      enabled: !!currentDivisionId,
      refetchOnWindowFocus: false,
    }
  );
  
  useEffect(() => {
    if (ratesClassData) {
      setbillableRatesJobClass(ratesClassData);
    }
  }, [ratesClassData]);
  
  const { data: operatorsData } = useQuery(
    ['fetchUnitOperators', unitOperatorsLimit],
    () => fetchUnitOperators(unitOperatorsLimit),
    {
      enabled: !!currentDivisionId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (operatorsData) {
      setUnitOperators(operatorsData);
    }
  }, [operatorsData]);
  
  const { data: billableMaterials } = useQuery(
    ['fetchDefaultBillableMaterials', currentDivisionId],
    () => fetchDefaultBillableMaterials(currentDivisionId),
    {
      enabled: !!currentDivisionId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (billableMaterials) {
      setBillableDefaultMaterials(billableMaterials);
    }
  }, [billableMaterials]);

  const { data: contactsData } = useQuery(
    ['fetchClientContactsByClientId', companySelectedOption],
    () => fetchClientContactsByClientId(companySelectedOption.value),
    {
      enabled: !!companySelectedOption,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    // console.info('CLIENT CONTACTS CHANGED: ', contactsData)
    if (contactsData) {
      setClientContacts(contactsData);
      // console.info('GOT CLIENT CONTACTS: ', contactsData)
    }
  }, [contactsData]);

  const { data: auxunitoptionsData } = useQuery(
    ['fetchAllAuxillaryUnitOptions', auxUnitsLimit],
    () => fetchAllAuxillaryUnitOptions(auxUnitsLimit),
    {
      enabled: !!currentDivisionId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (auxunitoptionsData) {
      setAllAuxillaryUnitOptions(auxunitoptionsData);
    }
  }, [auxunitoptionsData]);

  // get parentId
  useEffect(() => {
    if (srcId) {
      const getStatus = async () => {
        const result = await fetchDJTParentalStatusById(srcId)
        setParentId(result.parentId)
        setLoadedParentId(true)
      }
      getStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[srcId])
  
  // get child status
  useEffect(() => {
    if (srcId && loadedParentId && srcAction) {
      let child = false
      if (srcAction!=="duplicate" && srcAction!=="edit") {
        child = false
      }
      if (parentId!==null && srcId !== parentId) {
        child = true
      }
      if (srcAction==="duplicate") {
        child = true
      }
      setIsChild(child)
    }
  },[srcId, parentId, srcAction, loadedParentId])
  
  useEffect(() => {
    const todayDate = todaysDateYMD();
    const isTodayWeekend = isWeekend(todayDate);
    setIsTicketDateWeekend(isTodayWeekend||false);
  }, [currentTeamMemberId]);

  useEffect(() => {
    if (djtDate) {
      // console.log('djtDate is set to: ', djtDate);
      setValue('djtDate', djtDate);
      try {
        const isTodayWeekend = isWeekend(djtDate);
        setIsTicketDateWeekend(isTodayWeekend);
      } catch (error) {
        // console.error('Error checking if date is weekend:', error);
        setIsTicketDateWeekend(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [djtDate]);
  
  useEffect(() => {
    if (isNew && !isEstimate) {
      const todaysDate = todaysDateYMD()
      handleDjtDateChanged(todaysDate)
    }
    if (isEstimate && isNew) {
      const todaysDate = todaysDateYMD()
      const tomorrowsDate = nextDayYMD(todaysDate)
      handleDjtDateChanged(tomorrowsDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[srcAction])

  useEffect(() => {
    const fetchVerifiedDomains = async (clientId) => {
      if (!clientId) return [];
      try {
        const verifiedDomains = await fetchVerifiedEmailDomainsByClientId(clientId);
        return verifiedDomains?.map(domain => domain?.domain) || [];
      } catch (error) {
        console.error("Failed to fetch verified domains for clientId:", clientId, error);
        return [];
      }
    };
  
    const fetchAndSetVerifiedDomains = async () => {
      if (divisionAllowsEmailCC && clientCompanies?.length > 0) {
        const fetchPromises = clientCompanies.map(client =>
          fetchVerifiedDomains(client?.value)
        );
  
        const domains = await Promise.all(fetchPromises);
        const verifiedDomains = domains.flat(); // Flatten the array of arrays
        setVerifiedEmailDomains(verifiedDomains);
      }
    };
  
    if (clientCompanies?.length>0) { fetchAndSetVerifiedDomains(); } 
  }, [divisionAllowsEmailCC, clientCompanies]);

  useEffect(() => {
    // console.log('billableRatesJobClass: ', billableRatesJobClass)
    if (billableRatesJobClass?.length>0) {
      setbillableRatesJobClass(billableRatesJobClass)
      const match = billableRatesJobClass?.filter((jobClass) => jobClass?.className==='3rd Party Labor')[0]
      setThirdPartyJobClass({label: match?.appDisplayName, value: match?.divisionPosition})
    }
  },[billableRatesJobClass])

  // useEffect(() => {
  //   if (ticketHasConvertedLabor) {
  //     getRemainingTeamMembers()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[ticketHasConvertedLabor])

  useEffect(() => {
    getRemainingTeamMembers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (unitDriversStatus) {
      // console.log('unitDriversStatus: ', unitDriversStatus)
      const hasMissing = unitDriversStatus.filter(item => item.missing === true)
      if (hasMissing?.length>0) {
        setCurrentlyMissingDriver(true)
      } else {
        setCurrentlyMissingDriver(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(unitDriversStatus)])

  useEffect(() => {
    if (unitOperatorsStatus) {
      const hasMissing = unitOperatorsStatus.filter(item => item.missing === true)
      if (hasMissing?.length>0) {
        setCurrentlyMissingOperator(true)
      } else {
        setCurrentlyMissingOperator(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(unitOperatorsStatus)])

  useEffect(() => {
    if (isDuplicateLaborLoaded && isDuplicateEquipmentLoaded && isDuplicateMaterialLoaded) {
      updateLoadingDuplicateModal("", 100)
      setTimeout(() => {  setLoadDuplicatetModalCloseDisabled(false) }, 1000);
    }
  },[isDuplicateLaborLoaded, isDuplicateEquipmentLoaded, isDuplicateMaterialLoaded])

  // useEffect(() => {
  //   let totals = []
  //   laborFields.forEach((labor, laborIndex) => {

  //     // update job class
  //     // Why was I trying to update the job class here? This is breaking the ability to mutate the RowTotal

  //     // If manually changing row total, do not auto calculate the row data
  //     if (rowTotalChange[laborIndex]) {
  //       // console.log(' >>>>>> rowTotalChange[laborIndex]: ', rowTotalChange[laborIndex])
  //     } else {
  //       // if this is needed it probably needs to be moved
  //       // handleLaborJobClassChange(labor.JobClass, laborIndex)
  //     }

  //     totals.push(convertCurrencyToDouble(labor.RowTotal))
  //   })

  //   const delayFocusHack = async () => {
  //     // setFocus(`labor[${index}].Lunch`)
  //     await delay(100)
  //     document.activeElement.blur();

  //     // setFocus(`labor[${index}].CustomLaborName`)
  //   }

  //   const laborFieldsLength = laborFields?.length
  //   if (laborFieldsLength>0) {
  //     const lastLaborItemIndex = laborFieldsLength-1
  //     if (thirdPartyJobClass!==laborFields[lastLaborItemIndex].JobClass) {
  //       delayFocusHack(lastLaborItemIndex)
  //     }
  //   }
    
  //   const lowestTotal = Math.min(...totals)
  //   setHasZeroDollarLabor((lowestTotal===0) ? true : false)
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[JSON.stringify(laborFields)])

  useEffect(() => {
    if (!laborFields) return;

    const calculateTotals = () => {
      let totals = laborFields.map((labor, laborIndex) => {
        if (!rowTotalChange[laborIndex]) {
          // If you need to update job class, you can do it here
          // handleLaborJobClassChange(labor.JobClass, laborIndex);
        }
        return convertCurrencyToDouble(labor.RowTotal);
      });

      const lowestTotal = Math.min(...totals);
      setHasZeroDollarLabor(lowestTotal === 0);
    };

    const handleLastLaborItem = async () => {
      const laborFieldsLength = laborFields.length;
      if (laborFieldsLength > 0) {
        const lastLaborItemIndex = laborFieldsLength - 1;
        if (thirdPartyJobClass !== laborFields[lastLaborItemIndex].JobClass) {
          await delay(100);
          document.activeElement.blur();
        }
      }
    };

    calculateTotals();
    handleLastLaborItem();
  }, [laborFields, thirdPartyJobClass, rowTotalChange]);

  useEffect(() => {

    const adjustedIndex = unitFields?.length-1
    const thisId = getValues(`unit[${adjustedIndex}].Id`)
    setLastUnitItemAddedId(thisId)
    const focusToAddedElement = (thisId===lastUnitItemAddedId) ? false : true

    // >>>>> CLEAN ALL OF THIS UP AFTER TESTING <<<<< 

    const RowTotal = getValues(`unit[${adjustedIndex}].RowTotal`)
    const currentAmount = convertCurrencyToDouble(RowTotal)

    const unitId = getValues(`unit[${adjustedIndex}].UnitId`) || null
    const isRental = getValues(`unit[${adjustedIndex}].isCustomEquipment`) || false  // now basically a rental
    // console.log('isRental value: ', isRental)

    // const isAuxChildValue = getValues(`unit[${adjustedIndex}]`)
    // const isAuxChild = (isAuxChildValue===true) ? true : false
    // console.log('isAuxChildValue: ', isAuxChildValue)
    // console.log('isAuxChild: ', isAuxChild)
    const auxilliaryUnit = getValues(`unit[${adjustedIndex}].isAuxillaryUnit`) || false
    // console.log('isAuxilliary value: ', auxilliaryUnit)
    const customAuxilliary = getValues(`unit[${adjustedIndex}].isCustomAuxillary`) || false
    // console.log('customAuxilliary value: ', customAuxilliary)

    const isPrimary = (!isRental && !customAuxilliary && !auxilliaryUnit) ? true : false
    // console.log(' --- isPrimary: ', isPrimary)

    const isAuxilliary = (!isRental && !isPrimary && !customAuxilliary && auxilliaryUnit) ? true : false
    // console.log(' --- isAuxilliary: ', isAuxilliary)

    const isCustomAuxilliary = (customAuxilliary && auxilliaryUnit) ? true : false
    // console.log(' --- isCustomAuxilliary: ', isCustomAuxilliary)

    if (isRental && currentAmount===0) {
      setHasZeroDollarRental(true)
      if (indexOfFirstZeroDollarRental===null) {
        setIndexOfFirstZeroDollarRental(adjustedIndex)
      }
    } else {
      setHasZeroDollarRental(false)
    }
    if (isPrimary && currentAmount===0 && unitId) {
      setHasZeroDollarPrimary(true)
    } else {
      setHasZeroDollarPrimary(false)
    }
    if (isAuxilliary && currentAmount===0) {
      setHasZeroDollarAuxilliary(true)
      // if (indexOfFirstZeroDollarAuxilliary===null) {
      //   setIndexOfFirstZeroDollarAuxilliary(adjustedIndex)
      // }
    } else {
      setHasZeroDollarAuxilliary(false)
    }
    if (isCustomAuxilliary && currentAmount===0) {
      setHasZeroDollarCustomAuxilliary(true)
    } else {
      setHasZeroDollarCustomAuxilliary(false)
    }

    const delayFocusHack = async () => {
      // setFocus(`unit[${adjustedIndex}].FocusableHiddenSelect`)
      // await delay(1000)
      setFocus(`unit[${adjustedIndex}].Description`)
    }

    if ((isRental || isAuxilliary || isCustomAuxilliary) && focusToAddedElement===true) {
      // WHEN ADDING AN ATTACHED AUX UNIT TO A PRIMARY UNIT THIS IS TRIGGERING 
      // BECAUSE WE AREN'T TECHNICALLY AT THE LAST ITEM SINCE WE INSERTED IT
      // maybe track the last item added and each time we run this, compare the current last tiem with the last, last item and if they are the same, do not focus
      // setFocus(`unit[${adjustedIndex}].Description`)
      delayFocusHack(adjustedIndex)
      //
    }
    let totals = []
    unitFields.forEach(total => {
      totals.push(convertCurrencyToDouble(total.RowTotal))
    })
    
    // const lowestTotal = Math.min(...totals)
    // setHasZeroDollarUnit((lowestTotal===0) ? true : false)

    // console.table(unitFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(unitFields)])

  useEffect(() => {
    const isCustomMaterialDoFocus = getValues(`material[${materialFields?.length-1}].isCustomMaterial`) || false
    const currentCustomMaterialDescription = getValues(`material[${materialFields?.length-1}].Description`)
    const currentCustomMaterialPrice = getValues(`material[${materialFields?.length-1}].Price`)
    if (isCustomMaterialDoFocus && currentCustomMaterialDescription==='' && currentCustomMaterialPrice==='$0.00') {
      // THIS NEEDS TO ONLY EXECUTE WHEN IT IS ADDED
      // console.log('GOT A CUSTOM MATERIAL, DOING FOCUS NOW')
      setFocus(`material[${materialFields?.length-1}].Description`)
    }
    let totals = []
    materialFields.forEach(total => {
      totals.push(convertCurrencyToDouble(total.RowTotal))
    })
    const lowestTotal = Math.min(...totals)
    setHasZeroDollarMaterial((lowestTotal===0) ? true : false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(materialFields)])

  useEffect(() => {
    (updatedLabor?.length>0) && console.log('UPDATED LABOR HAS CHANGED: ', updatedLabor)
  },[updatedLabor])

  useEffect(() => {
    // (updatedLabor) && console.log('updatedLabor in useEffect: ', updatedLabor)
    if (updatedLabor) {
      if (hasDuplicates(updatedLabor)) {
        const uniqueUpdatedLabor = updatedLabor.reduce((a, b) => {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
        setUpdatedLabor(uniqueUpdatedLabor)
        // console.warn('WHY ARE WE HERE: ', uniqueUpdatedLabor)
      }
    }
  },[updatedLabor])
  
  // useEffect(() => {
  //   (addedLabor?.length>0) && console.log(' ##### CURRENT ADDED LABOR: ', addedLabor)
  // },[addedLabor])

  useEffect(() => {
    (clientContractSelectedOption) && console.log('clientContractSelectedOption: ', clientContractSelectedOption)

  },[clientContractSelectedOption])

  useEffect(() => {
    if (unitSummary) {
      // console.info('  ---------->  unitSummary HAS CHANGED: ', unitSummary)
      let runningUnitSummaryAmount = 0
      unitSummary.forEach(unit => {
        const currentRowTotalStr = unit.RowTotal
        const currentRowTotalFloat = convertCurrencyToDouble(currentRowTotalStr)
        runningUnitSummaryAmount += currentRowTotalFloat
      })
      // setCalculatedUnitSummaryRunningTotal(runningUnitSummaryAmount)
      // console.info('  ---------->  unitSummaryTotal HAS CHANGED: ', runningUnitSummaryAmount)
      setUnitSummaryTotal(runningUnitSummaryAmount)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(unitSummary)])

  useEffect(() => {
    (materialFields?.length>0) && updateCurrentMaterialRowTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[materialFields])

  useEffect(() => {
    (materialFields) && setMaterialSummary(materialFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(materialFields)])

  useEffect(() => {
    // console.warn('materialsForDay', materialsForDay)
    if (
      billableMaterialsForDay?.length===0 
      && billableDefaultMaterials?.length>=0 
      && materialsForDay?.length===0  
    ) {
      // console.warn('SETTING MATERIALS FOR DAY', billableDefaultMaterials)
      // loadDailyJobTicketMaterial(billableMaterialsForDay)
      setMaterialsForDay(billableDefaultMaterials)
      updateDJTRunningTotal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billableDefaultMaterials, billableMaterialsForDay])

  useEffect(() => {
    if (billableMaterialsForDay?.length>0) {
      updateMaterialItemsTotal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billableMaterialsForDay])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // 👈️ return early if initial render
    }
    updateDJTRunningTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsTotalCost])

  useEffect(() => {
    // console.warn('clientContactInfoChanged changed: ', clientContactInfoChanged)
  },[clientContactInfoChanged])

  useEffect(() => {
    const timeOutId = setTimeout(() => handleDjtDateChanged(typedDjtDate), 750);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[typedDjtDate])

  useEffect(() => {
    const materialFieldsLength = materialFields.length
    const currentLastMaterialLength = lastMaterialLength.current
    // let materialIndex = null

    if (materialFieldsLength>0 && materialFieldsLength>currentLastMaterialLength) {
      
      // materialIndex = materialFieldsLength-1
      lastMaterialLength.current = currentLastMaterialLength + 1

      // updateMaterialState('append', materialFields[materialIndex], materialIndex)
    }
    
    if (materialFieldsLength<currentLastMaterialLength) {
      lastMaterialLength.current = currentLastMaterialLength - 1
    }

  }, [materialFields])

  useEffect(() => {
    if (materialFields?.length>0 && materialsForDay?.length>0 && isInitialMaterialLoad && !isNew) {
      // for each item in materialFields, match and toggle the button for the material
      materialFields.forEach(material => {
        // get the index of the button that matches this item
        const matchedButtonIndex = materialsForDay.findIndex((item) => material.Description===item.description)
        // console.warn(' <<<<< RUNNING TOGGLE MATERIAL BUTTON STATE')
        toggleMaterialsButtonState(matchedButtonIndex, true)
        materialFields.OriginalIndex = matchedButtonIndex
      })
      setIsInitialMaterialLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[materialsForDay, materialFields, isInitialMaterialLoad, isNew])

  useEffect(() => {
    // console.log('RUNNING TOTALS: ', laborTotalAmount+' '+unitTotalAmount+' '+materialTotalAmount)
    updateDJTRunningTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborSummaryTotal, unitSummaryTotal, materialSummaryTotal])

  useEffect(() => {
    if (laborForDay && laborForDay?.length>0) {
      getRemainingTeamMembers()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[laborForDay])

  useEffect(() => {
    const fetch = async () => {
      setIsUnitsDataLoading(true)
      try {
        if (showAllUnits || loadedAllUnits) {
          // setIsAllUnitsLoading(true)
          // setIsAllUnitsLoaded(false)
          
          // if the date has changed, reset this?
          // console.info(' -----> CURRENT PRELOADED UNITS: ', loadedAllUnits)
          // we might have some unavailable already loaded
          // const loadedUnavailable = loadedAllUnits
          // let allPrimaryUnits = await fetchAllUnits(nextUnitsToken, queryLimit)
          //changing to new method of showing all units, then reducing it by searching
          let allPrimaryUnits = await fetchAllUnits(nextUnitsToken, 500)

          // console.info('allPrimaryUnits: ', allPrimaryUnits)
          let isAvailableUnits = {
            items: [],
            nextToken: allPrimaryUnits?.nextToken,
          }
          isAvailableUnits.items = allPrimaryUnits?.items?.filter((unit) => { return unit?.unitStatus?.status?.isAvailable })
    
          // console.info('isAvailableUnits.items: ', isAvailableUnits.items)
          let allFetchedUnits = isAvailableUnits

          // --- UNCOMMENT THIS BETWEEN THE COMMENTS TO START TOMORROW ---

          // this is a two pronged approach
          // 1. remove the unavailable selected from the search results so we don't get them twice
          // 2. insert the unavailable selected into the first page of results

          // Remove any selected/loaded unavailable units form the search results so we don't show them twice.
          // console.log('allFetchedUnits: ', allFetchedUnits)
          // For each of the pre-loaded unavailable units
          // console.info('loadedAllUnits FOREACH: ', loadedAllUnits)
          loadedAllUnits?.forEach(unit => {
            // console.log('PREVIOUSLY SELECTED UNIT CODE: ', unit?.unitCode)
            // look in the search results and get the index of any units that match our previously selected units
            let foundIndex = allFetchedUnits?.items?.findIndex(fetchedUnit => {
              // console.log('SEARCH RESULTS POSSIBLE MATCH: ', fetchedUnit.code)
              return fetchedUnit?.code === unit?.unitCode
            })
            // console.log('FOUND INDEX OF MATCHE DUNIT: ', foundIndex)
            // If we have a match, remove this unit from the search results so we don't show it twice
            if (foundIndex>=0) allFetchedUnits?.items?.splice(foundIndex, 1)
          })

          // console.log('allFetchedUnits NOW: ', allFetchedUnits?.items)

          // Now that we have removed any possible duplicates from showing, we 
          // need to insert the selected unavailable units into the beginning
          // of the search results on the first page
          // if (currentUnitsPage===0 && previousUnitsPage===0) {
          if (currentUnitsPage===0) {
              // For each of the selected units, splice them into the beginning of the search results
            loadedAllUnits?.forEach((unit, unitIndex) => {
              // console.info('SPLICING UNAVAILABLE INTO THE UNAVAILABLE LIST ALREADY SELECTED: ', unit)
              allFetchedUnits?.items?.splice(unitIndex, 0, unit)
            })
          }
          // --- UNCOMMENT THIS BETWEEN THE COMMENTS TO START TOMORROW ---
          const allUnits = allFetchedUnits||[];

          // We don't want to show the same unit twice. 
          // need to get the ids of the current listed units for the day
          const availableUnits = unitsForDay?.map(item => item.unitId);
          // console.log('availableUnits: ', availableUnits)
          // console.log('allUnits: ', allUnits)
          const filteredAll = allUnits?.items?.filter(item => !availableUnits?.includes(item.id));

          // max page reached - if currentLaborPage greater than max pages reached then update max reached
          if (currentUnitsPage===0 && maxUnitsPageReached===0) {
            // runs first time all labor is loaded
            const currentUnitsPageRecordCount = filteredAll?.length
            setCurrentAllUnitsLength(currentUnitsPageRecordCount)
            // initialize first row
            unitsPageNumberRecordCount.push({page: 0, count: currentUnitsPageRecordCount})
            setUnitsPageNumberRecordCount(unitsPageNumberRecordCount)
          }

          if (currentUnitsPage > maxUnitsPageReached) {
            // we are on a new page for the first time
            // current page number of records
            const currentUnitsPageRecordCount = filteredAll?.length
            // add this record count to the total records we have
            const totalRecordCount = currentAllUnitsLength + filteredAll?.length
            setCurrentAllUnitsLength(totalRecordCount)
            // this should only happen when each new page is loaded, not when page goes back
            let tmpArray = unitsPageNumberRecordCount
            // console.log('---SHOULD BE ADDING TO ARRAY: ', {page: currentUnitsPage, count: currentUnitsPageRecordCount})
            
            tmpArray.push({page: currentUnitsPage, count: currentUnitsPageRecordCount})
            setUnitsPageNumberRecordCount(tmpArray)

            // update max pages reached
            setMaxUnitsPageReached(currentUnitsPage)

          }

          // setIsAllUnitsLoading(false)
          // setIsAllUnitsLoaded(true)
          // console.log('filteredAll: ', filteredAll)
          setOriginalAllUnits(filteredAll)
          setAllUnits(filteredAll)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsUnitsDataLoading(false)
      }
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUnitsToken, queryLimit, refetchUnitsCount, showAllUnits, prevDjtDate, loadedAllUnits])

  useEffect(() => {
    const fetch = async () => {
      setIsLaborDataLoading(true)
      try {
        if (showAllTeamMembers) {
          // setIsAllLaborLoading(true)
          // setIsAllLaborLoaded(false)
          
          // fetch unavailable team member
          const allFetchedTeamMembers = await listAllTeamMembers(nextLaborToken, queryLimit)
          // console.info('ALL FETCHED: ', allFetchedTeamMembers)
          // console.log('LOADED ALL UNAVAILABLE TEAM MEMBERS: ', loadedAllTeamMembers)
          // We don't want to show the same team member twice. Filter
          // out any clocked in team members from the "All Labor" array.
          const clockedinIds = laborForDay?.map(item => item.teamMember.teamMember.id);
          // console.info('CLOCKED IN: ', clockedinIds)
          const allUnavailableTeamMembers = allFetchedTeamMembers?.items.filter(item => !clockedinIds?.includes(item.id));
          // console.log('ALL UNAVAILABLE FILTERED: ', allUnavailableTeamMembers)
          allUnavailableTeamMembers.nextToken = allFetchedTeamMembers.nextToken

          // console.log('GOT ALL UNAVAILABLE TEAM MEMBERS: ', allUnavailableTeamMembers)
          

          // remove the selected unavailable labor from the search results
          loadedAllTeamMembers?.forEach(labor => {
            // console.log('THIS loadedAllTeamMembers TEAM MEMBER: ', labor?.teamMember?.id)
            // look in the search results and get the index of any labor that match our previously selected labor
            let foundIndex = allUnavailableTeamMembers?.findIndex(fetchedLabor => {
              // console.log('MATCHING THIS: ', fetchedLabor)
              // console.log('TO THIS: ', labor)
              return fetchedLabor?.id === labor?.teamMember?.id
            })
            // console.log('FOUND INDEX OF MATCHED LABOR: ', foundIndex)
            // If we have a match, remove this unit from the search results so we don't show it twice
            if (foundIndex>=0) {
              // console.info('REMOVING THIS LABOR FROM THE LIST: ', allUnavailableTeamMembers[foundIndex])
              allUnavailableTeamMembers?.splice(foundIndex, 1)
            }
          })

          // now prepend it to the search results on page 1
          if (currentLaborPage===0) {
            // For each of the selected units, splice them into the beginning of the search results
            loadedAllTeamMembers?.forEach((labor, laborIndex) => {

              // if this is an unavailable team member, prepend it to the list

              // console.log('THIS TEAMMEMBER PROPS: ', labor)
              labor.goesBy = labor?.teamMember?.goesBy||''
              labor.firstName = labor?.teamMember?.firstName
              labor.middleName = labor?.teamMember?.middleName||''
              labor.lastName = labor?.teamMember?.lastName
              // console.info('SPLICING THIS ITEM INTO THE UNAVAILABLE LIST: ', labor)
              allUnavailableTeamMembers?.splice(laborIndex, 0, labor)
            })
          }
          // --- UNCOMMENT THIS BETWEEN THE COMMENTS TO START TOMORROW ---
          const allTeamMembers = allUnavailableTeamMembers

          // setPreviousLaborPage(currentLaborPage)
          // setNextNextLaborToken(allTeamMembers.nextToken)
          setNextNextLaborToken(allTeamMembers.nextToken)

          // max page reached - if currentLaborPage greater than max pages reached then update max reached
          if (currentLaborPage===0 && maxPageReached===0) {
            // runs first time all labor is loaded
            const currentPageRecordCount = allTeamMembers?.length
            setCurrentAllLaborLength(currentPageRecordCount)
            // initialize first row
            pageNumberRecordCount.push({page: 0, count: currentPageRecordCount})
            setPageNumberRecordCount(pageNumberRecordCount)
          }

          if (currentLaborPage > maxPageReached) {
            // we are on a new page for the first time
            // current page number of records
            const currentPageRecordCount = allTeamMembers?.length
            // add this record count to the total records we have
            const totalRecordCount = currentAllLaborLength + allTeamMembers?.length
            setCurrentAllLaborLength(totalRecordCount)
            // this should only happen when each new page is loaded, not when page goes back
            let tmpArray = pageNumberRecordCount
            
            tmpArray.push({page: currentLaborPage, count: currentPageRecordCount})
            setPageNumberRecordCount(tmpArray)

            // update max pages reached
            setMaxPageReached(currentLaborPage)

          }
          
          // setIsAllLaborLoading(false)
          // setIsAllLaborLoaded(true)
          setAllTeamMembers(allTeamMembers)
        }
        
      } catch (err) {
        console.log(err)
      } finally {
        setIsLaborDataLoading(false)
      }
    }
  
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLaborToken, queryLimit, refetchLaborCount, showAllTeamMembers, prevDjtDate])

  useEffect(() => {
    if (unitsForDay) {
      // console.log(' ************** AVAILABLE UNITS FOR DAY HAS CAHNGED, UPDATING BUTTONS: ', unitsForDay)
      let tmpArray = []
      for (let i = 0; i < unitsForDay.length; i++) {
        tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
      }
      // console.log('   -X-X-X-X- SETTING UNITBUTTON VARIANTS TO THIS MANY BUTTONS: ', tmpArray.length)
      setUnitButtonVariants(tmpArray)
    }
  },[unitsForDay])
  
  useEffect(() => {
    if (materialsForDay) {
      let tmpArray = []
      for (let i = 0; i < materialsForDay.length; i++) {
        tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
      }
      setMaterialButtonVariants(tmpArray)
    }
  },[materialsForDay])

  useEffect(() => {
    if (laborForDay) {
      let tmpArray = []
      for (let i = 0; i < laborForDay.length; i++) {
        tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
      }
      setLaborButtonVariants(tmpArray)
    }
  },[laborForDay])

  useEffect(() => {
    if (allTeamMembers) {
      let tmpArray = []      
      if (currentLaborPage!==0 && currentLaborPage>highestLaborPageLoaded  ) {
        setHighestLaborPageLoaded(currentLaborPage)
        // console.log('NEW PAGE OF BUTTONS')
        tmpArray = allLaborButtonVariants
        // new page of buttons, append to current
        for (let i = 0; i < allTeamMembers.length; i++) {
          tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
        }
        setAllLaborButtonVariants([...allLaborButtonVariants], tmpArray)
      } else {
        if (currentLaborPage===0 && maxPageReached===0) {

          allTeamMembers?.forEach((labor, laborIndex) => {
            if (allLaborButtonVariants[laborIndex]?.isDisabled===true) {
              tmpArray.splice(laborIndex, 1, {variant: 'withIconQuinary', icon: 'check', isDisabled: true})
            } else {
              tmpArray.splice(laborIndex, 1, {variant: 'withIconSenary', icon: 'plus', isDisabled: false})
            }
          })

          // console.log('SETTING BUTTON VARIANTS: ', tmpArray)
          setAllLaborButtonVariants(tmpArray)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allTeamMembers])

  useEffect(() => {
    if (allUnits) {
      let tmpArray = []      
      if (currentUnitsPage!==0 && currentUnitsPage>highestUnitsPageLoaded  ) {
        setHighestUnitsPageLoaded(currentLaborPage)
        // console.warn('NEW PAGE OF BUTTONS')
        tmpArray = allUnitButtonVariants
        // new page of buttons, append to current
        // for (let i = 0; i < allUnits.length; i++) {
        //   tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
        // }
        
        allUnits?.forEach(() => 
          tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
        )

        
        setAllUnitButtonVariants([...allUnitButtonVariants], tmpArray)
      } else {
        if (currentUnitsPage===0 && maxUnitsPageReached===0) {
          // console.warn('INITIAL PAGE OF BUTTONS: ')
          // for (let i = 0; i < allUnits.length; i++) {
          //   tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
          // }
          if (allUnitButtonVariants?.length>0) {
            // we have some preloaded unavailable units, skip over these
            allUnitButtonVariants?.forEach(variant => {
              tmpArray.push(variant)
            })
          }
          allUnits?.forEach(() => {
            tmpArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false, x: 10})
          })
            
          setAllUnitButtonVariants(tmpArray)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allUnits])

  useEffect(() => {
    if (accordianClientSummaryIndex===0) {
      setHideModuleFromClientView(true)
     } else {
      setHideModuleFromClientView(false)
      // clientSummaryRef?.current?.scrollIntoView({ behavior: "smooth" })
     }
  },[accordianClientSummaryIndex])

  useEffect(() => {
    // and auto expand client summary accordian
    if (accordianClientAuthorizationIndex===0) {
      // expand accordian and hide modules form client view
      setHideModuleFromClientView(true)
      setAccordianClientSummaryIndex(0)
    } else {
      setHideModuleFromClientView(false)
      // collapse accordian and re-enable modules
      // when closing the accordian, we have now shown the entire page which keeps focus at the top
      // need to return focus down to the accordian
      // delay(1000)
      // clientSummaryRef.current?.focus()

    }
  },[accordianClientAuthorizationIndex])

  useEffect(() => {
    // console.log('materialsForDay: ', materialsForDay)
    // if (materialsForDay?.length === materialButtonVariants?.length && isMaterialFirstRender && isNew) {
    if (materialsForDay?.length === materialButtonVariants?.length && isNew) {
        const materialButtonsArray = async () => {
          const newArray = await Promise.all(materialsForDay?.map(async (item, index) => {
            if (item?.doShowOnLoad===true && !isDuplicate) {
              return {item: item, index: index}
            } else {
              return null
            }
          }
        ))
        newArray.forEach((material, index) => {
          if (material) {
            let materialObj = {
              Id: material.item.id,
              Description: material.item.description,
              Notes: material.item.notes,
              Price: '$'+material.item.price,
              Quantity: material.item.quantity,
              RowTotal: '$'+material.item.price,
              isDefaultMaterial: true,
              OriginalIndex: index
            }
            // console.log(' >>>>> RUNNING DEFAULT ADDED MATERIALS, ONLY ON NEW: ', isNew)
            updateMaterialSummary('append', materialObj, index)
          }
        })
        // setIsMaterialFirstRender(false)
      }
      materialButtonsArray()
      // setIsMaterialFirstRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[materialsForDay, materialButtonVariants, isNew])

  let djtIsLoaded = false
  useEffect(() => {
    // console.log('1st useeffect: ', (changedDate===null))
    // console.log('1st useeffect: ', (isDuplicate))
    // console.log('1st useeffect: ', (djtId))

    if (isDuplicate && djtId && changedDate===null && djtIsLoaded === false) {
      djtIsLoaded = true
      // console.log('LOADING DAILY JOB TICKET DUPLICATE')
      loadDailyJobTicket(djtId)
    }
    // console.log('2nd useeffect: ', (isDuplicate && djtId && djtDate && changedDate!==null && (changedDate!==djtDate)))
    if (isDuplicate && djtId && djtDate && changedDate!==null && (changedDate!==djtDate)) {
      djtIsLoaded = true
      // console.info(' -----> LOADING DAILY JOB TICKET DUPLICATE WITH CHANGED DATE')
      loadDailyJobTicket(djtId, djtDate)
    }

    // edit load
    if (isEdit && djtId && changedDate===null && djtIsLoaded === false) {
      djtIsLoaded = true
      // console.log('LOADING DAILY JOB TICKET FOR EDITING')
      loadDailyJobTicket(djtId)
    }
    // console.log('2nd useeffect: ', (isDuplicate && djtId && djtDate && changedDate!==null && (changedDate!==djtDate)))
    if (isEdit && djtId && djtDate && changedDate!==null && (changedDate!==djtDate)) {
      djtIsLoaded = true
      // console.log('LOADING DAILY JOB TICKET EDIT WITH ORIGINAL DATE', changedDate)
      loadDailyJobTicket(djtId, djtDate)        
    }
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isDuplicate, isEdit, djtId, djtDate, changedDate, djtIsLoaded])
  
  useEffect(() => {
    if ( 
      (isDuplicate || isEdit) && 
      djtId && 
      djtDuplicateLaborData && 
      !isDuplicateLaborLoaded 
    ) {
      // console.log(' --!!!!!!-- This needs to be revisted. loadDailyJobTicketLabor got called from useEffect with: ', djtDuplicateLaborData)
      loadDailyJobTicketLabor(djtDuplicateLaborData, isEstimate, loadedAllTeamMembers)
      setIsDuplicateLaborLoaded(true)
      // if (laborButtonVariants?.length>0) {
      //   console.log('x: ')
      //   loadDailyJobTicketLabor(djtDuplicateLaborData, isEstimate, loadedAllTeamMembers)
      // } else {
      //   console.log('y: ')
      //   // console.log(' &&&&&& THERE IS NO PUNCHED LABOR TO ERROR ON: ', djtDuplicateLaborData)
      //   loadDailyJobTicketLabor(djtDuplicateLaborData, isEstimate, loadedAllTeamMembers)
      // }
      
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ isDuplicate, isEdit,
      djtId, 
      laborForDay, 
      laborButtonVariants, 
      djtDuplicateLaborData,
      isDuplicateLaborLoaded,
    ]
  )

  useEffect(() => {
    if ( 
      (isDuplicate || isEdit)
      && djtId
      // unitButtonVariants?.length>0 && 
      && djtDuplicateEquipmentData 
      && !isDuplicateEquipmentLoaded
    ) {
      // THIS STILL NEEDS TO BE RESOLVED FOR BOTH DUPLICATE AND CHANGE DATE
      // THIS DOES NOT TRIGGER WHEN THE DATE HAS BEEN CHANGED MANUALLY
      // console.log(' $$$$$$  COMPARING AVAILABLE UNITS TO NUMBER OF BUTTONS CREATED FOR UNITS')
      console.log(' ----- unitsForDay.length: ', unitsForDay?.length)
      console.log(' ----- unitButtonVariants.length: ', unitButtonVariants?.length)
      // AT THIS POINT WE SHOULD HAVE THE TOTAL NUMBER OF unitsForDay count, check if we have them all
      if (unitsForDay?.length===unitButtonVariants?.length) {
        // console.log(' @@@@@   ALL BUTTONS SHOULD BE LOADED BY NOW, LOADING DUPLICATED UNIT DATA')
        // setIsDuplicateEquipmentLoaded(true)
        setIsDuplicateEquipmentLoaded(false)
        loadDailyJobTicketEquipment(djtDuplicateEquipmentData)
        setIsDuplicateEquipmentLoaded(true)
      } else {
        console.warn('unitsForDay.length !== unitbuttonVariants.length')
        console.warn('unitsForDay: ', unitsForDay)
        console.warn('unitButtonVariants: ', unitButtonVariants)
      }
      // if (unitButtonVariants?.length>0) {
      //   loadDailyJobTicketEquipment(djtDuplicateEquipmentData)
      // } else {
      //   loadDailyJobTicketEquipment(djtDuplicateEquipmentData)
      // }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ isDuplicate, isEdit,
      djtId, 
      unitsForDay,
      unitButtonVariants,
      djtDuplicateEquipmentData,
      isDuplicateEquipmentLoaded    ]
  )



  const handleEmailSummaryClick = async () => {

    setDoShowSentEmailResults(true)
    setIsDisabledWhileSending(true)
    let source = 'djt'
    if (isEstimate) {
      source = 'estimate'
    }

    const djtPoNum = clientPoSelectedOption?.label||''
    const laborTotal = USDollar.format(laborSummaryTotal)
    const unitTotal = USDollar.format(unitSummaryTotal)
    const materialTotal = USDollar.format(materialSummaryTotal)
    const total = USDollar.format(djtRunningTotal)
    const today = todaysDateYMD()
    const sentToCC = ccRecipientEmailsSelected.map(option => option.label).join(",");
    let customMessage = getValues('emailSummaryMessage')

    const props = {
      teamMemberId: currentTeamMemberId,
      supervisorName: spikeRepresentativeName,
      supervisorEmail: spikeRepresentativeEmail,
      sentToEmail: 'webadmin@spikeenterprise.com',  // hardcoded for testing. don't send emails to clients yet.
      // sentToEmail: clientContactEmailAddress,
      sentToName: clientContactName,
      sentToCC: sentToCC,
      sentToClient: companySelectedOption.label,
      djtId: djtId,
      dateSent: today,
      djtDate: djtDate,
      djtDescription: djtDescription,
      djtPO: djtPoNum,
      message: customMessage,
      summaryType: source,
      laborCost: laborTotal,
      equipmentCost:unitTotal,
      materialCost: materialTotal,
      totalCost: total,
    }
    const results = await addClientEmailSummarySent(props)
    // console.info('results: ', results)
    if ( results && results?.id) {
      // success message
      setEmailSentResults('Email was succesfully sent.')
      await delay(3500)
      // we should probably clear the cc dropdown and the object
      setCcRecipientSelected('')
      setCcRecipientEmailsSelected([])
      setValue('emailSummaryMessage', '')
      setDoShowSentEmailResults(false)
    } else {
      // failure message
      setEmailSentResults('Error: '+results)
    }
    setIsDisabledWhileSending(false)
  }

  function handleSubmitModalClosed() {
    onClose()
  }

  const handleConfirmContactDataChangeClosed = async () => {
    onConfirmContactDataChangeClose()

    // this is kind of hacky. This unfocuses the cursor so the user can't mutate the field
    // all because of Safari. 
    setClientContactDisabled(true)
    await delay(1000)
    setClientContactDisabled(false)

  }

  function handleLoadDuplicateModalClosed() {
    onClose()
  }

  const loadDailyJobTicketLabor = async (data, isEstimate, loadedAllTeamMembers) => {
    console.warn(' -----> LOADING LABOR DATA: ', data)

    let buttonIndex=0

    // sort the incoming data by start time
    data.sort(function( a , b ){
      return a.startTime > b.startTime ? 1 : -1;
    })
    
    try {

      (data?.length>0) && data?.forEach((item, index) => {
        console.log('item!!!:', item)

        let isConvertedLabor = false
        console.warn('1: ', 1)
        // console.info('item: ', item)
        // WE COULD STILL BE JUST EDITING THIS ESTIMATE
        if (/^labor-estimate/.test(item?.teamMemberId) && !isEstimate) {
          console.warn('2: ', 2)
          // console.log("Bingo! converted estiamte, need to set labor");
          // console.warn('isEstimate: ', isEstimate)
          // isConvertedLabor = (!isEstimate) ? true : false
          isConvertedLabor = true
          console.warn('3: ', 3)
          setLaborIsLocked(true)
          console.warn('4: ', 4)
          setTicketHasConvertedLabor(isConvertedLabor)
          console.warn('5: ', 5)
        } 

        if (index===0) setLoadedAllTeamMembers([])
          console.warn('isEstimate6: ', isEstimate)
          console.warn('item?.teamMember?.id: ', item?.teamMember?.id)
          
          console.warn('6: ', 6)

          // we need to see if this labor is clocked in
        let isLaborAvailable = true;
        if (isEstimate === false && item?.teamMember?.id) {
          try {
            // Ensure laborForDay is an array before using .some()
            let isAvailableResults = Array.isArray(laborForDay)
              ? laborForDay.some((entry) => {
                  // Check if entry is an array and has at least two elements
                  if (Array.isArray(entry) && entry.length > 1) {
                    const [, labor] = entry; // Safely destructure the second element as labor
                    return labor?.teamMember?.teamMember?.id === item?.teamMember?.id;
                  }
                  return false; // If entry isn't in the expected format, return false
                })
              : false;
            isLaborAvailable = isAvailableResults;
          } catch (err) {
            console.log('ERROR LOADING AVAILABLR LABOR: ', err);
          }
        }

        console.warn('7: ', 7)
        item.ButtonIndex = null
        if (isLaborAvailable===false && isConvertedLabor===false) {
          // add a button to the all button arr
          setAllLaborButtonVariants(prevState => [...prevState, {variant: 'withIconQuinary', icon: 'check', isDisabled: true}])

          item.ButtonIndex = buttonIndex
          buttonIndex++

          let tmpLoadedAllTeamMembersArr = loadedAllTeamMembers
          tmpLoadedAllTeamMembersArr.push(item)
          setLoadedAllTeamMembers(tmpLoadedAllTeamMembersArr)
        }
        console.warn('isConvertedLabor: ', isConvertedLabor)
        
        // create a laborObject and append it to laborFields
        // const isTempLabor = item?.isTempLabor

        let teamMemberId = null
        let firstName = null
        let goesBy = item?.teamMember?.goesBy
        let lastName = null

        if (isEstimate || !item?.teamMember) {
          teamMemberId = item?.teamMemberId
          firstName = 'Team'
          lastName = 'Member'
        } else {
          teamMemberId = item?.teamMember?.id
          firstName = item?.teamMember?.firstName
          lastName = item?.teamMember?.lastName
        }

        const teamMemberName = (goesBy) ? `${firstName} "${goesBy}" ${lastName}` : `${firstName} ${lastName}`
        const jobClass = item?.jobClassId?.split("#")
        const jobClassValue = `${jobClass[0]}#${jobClass[1]}`
        const hourlyRate = item?.jobClass?.hourlyRate
        const overtimeRate = item?.jobClass?.overtimeRate
        const customLaborName = item?.CustomLaborName||item?.customLaborName
        
        const laborObj = {
          Id: item?.id,
          TeamMemberId: teamMemberId,
          TeamMemberName: teamMemberName,
          // IsTempLabor: isTempLabor||false,
          IsConvertedLabor: isConvertedLabor,
          StartTime: item?.startTime,
          FinishTime: item?.finishTime,
          Lunch: item?.lunchDuration,
          PerDiemAmount: item?.perDiemAmount,  // pull this on a division or client basis?
          HourlyRate: hourlyRate,
          OvertimeRate: overtimeRate,
          JobClass: {
            label: jobClass[1],
            value: jobClassValue,
          },
          RowTotal: item?.totalPrice,
          DepartmentEmployee: item?.departmentEmployee||'',
          OriginalIndex: index,
          ButtonIndex: item.ButtonIndex,
          CustomLaborName: customLaborName||'',
          TeamMember: {},
        }

        updateLaborSummary('append-saved', laborObj, index)

      })
    } catch(err) {
      console.log('ERROR LOADING LABOR: ', err)
    }
    return true
  }

  async function loadDailyJobTicketEquipment(data) {

    let dataWithUnavailableRemoved = data
    let buttonIndex=0
    setLoadedAllUnits([])

    // for now, test with NOT removing unavailable units
    for (const unit of data) {
      if (unit?.isPrimary===true) {
        
        // we need to see if this primary unit is in the available units
        let isUnitAvailable = checkUnitAvailability(unit.unitId)

        // if it isn't available, let the user know
        if (!isUnitAvailable && !isEstimate) {
          // this unit doesn't exist in the available units for today

          // console.info('ADDING UNAVAILABLE UNIT')
          // add a function for appendDuplicatedUnitError
          // cleaning this up a bit
          let tmpArr = duplicatedUnitError
          tmpArr.push(unit?.unitCode)
          setDuplicatedUnitError(tmpArr)
          // setDuplicatedUnitError(prevState => [...prevState, unit?.unitCode])

          // cleaing this up too
          let currentAllUnitButtonVariants = allUnitButtonVariants
          currentAllUnitButtonVariants.splice(buttonIndex, 1, {variant: 'withIconQuinary', icon: 'check', isDisabled: true})
          setAllUnitButtonVariants(currentAllUnitButtonVariants)
          // setAllUnitButtonVariants(prevState => prevState.splice(0, 1, {variant: 'withIconQuinary', icon: 'check', isDisabled: true}))

          // add it to the allunits and buttons
          // code, subType, type
          // probably need to query for type and subType, but for now just extract from description
          const unitTypeDescription = unit?.billableDescription?.split(' / ')[0]
          const unitSubTypeDescription = unit?.billableDescription?.split(' / ')[1]
          unit.code = unit?.unitCode
          unit.type = {name: unitTypeDescription}
          unit.subType = {name: unitSubTypeDescription}
          unit.ButtonIndex = buttonIndex

          setLoadedAllUnits(prevState => [...prevState, unit])
          buttonIndex++
        }
      }
    }    

    let newData = []
    let existingData = dataWithUnavailableRemoved
    
    // get isAuxChild into arr
    const auxChildrenArr = existingData?.filter(item => (item.isAuxChild===true))
    const auxChildren = auxChildrenArr?.map(child => {return child.auxChildOfParentId})
    const uniqueAuxChildren = new Set(auxChildren)

    // which units have at least one child
    const uniqueAuxChildrenArr = [...uniqueAuxChildren]

    // get the parent of the first one
    uniqueAuxChildrenArr?.forEach(pId => {
      // for each parent UnitId
      
      // isolate the parents with their children
      // we have a child with a parentId, get its parent
      const parentId = pId
      // get the parent from the esisting for this child
      const parent = existingData?.filter(item => (item.id===parentId))[0]
      // push this parent to the newData
      newData.push(parent)
      // and remove them from existing data temp arr
      existingData = existingData?.filter(item => (item.id!==parentId))
      // now we have a parent, get its children aux units
      const auxChildrenOfParent = existingData?.filter(item => (item.auxChildOfParentId===pId))
      // now we have some kids, put them in the data
      auxChildrenOfParent?.forEach(child => newData.push(child))
      // and remove these children from the existing data temp arr
      let tmpExistingData = existingData?.filter(item => (item.auxChildOfParentId!==parent.id))
      existingData = tmpExistingData
      // now we have an existing with parent and children removed for an item in uniqueAuxChildrenArr
      // this should loop until all children are accounted for and we reunited them with their parent
      // what will be left in the existingData arr will be childless individuals that can now be added to new data
    })

    // at this point we should have JUST units that are parents of aux xhildren and their children
    // console.info('THIS DATA SHOULD BE ONLY PARENTS WITH CHILDREN, AND THEIR CHILDREN: ', newData)
    // now add the individuals
    existingData?.forEach(individual => {
      newData.push(individual)
    })
    // at this point we should have everythign else
    // we now SHOULD have an ordered arry of objects with children directly under their parents, then 
    // individual that can all get looped through for adding to the unitSummary, in order and all pretty.

    let tmpOrderedUnitFields = []
    setIsDuplicateEquipmentLoaded(false)
    let currentHighAuxUnitCodeNumber = 0
    try {
      (newData?.length>0) && newData?.forEach((item, index) => {
        
        const unavailableButtonIndex = (item?.ButtonIndex!==undefined) ? item.ButtonIndex : null
        
        // check if unit requires driver and if it has any
        let currentDriverStatus = null
        if (item?.requiresDriver===true && item?.drivers?.items?.length===0) {
          currentDriverStatus = {missing: true}
        } else {
          currentDriverStatus = {missing: false}
        }
        setUnitDriversStatus(prevState => [...prevState, currentDriverStatus])

        // check if unit requires operator and if it has any
        let currentOperatorStatus = null
        if (item?.requiresOperator===true && item?.operators?.items?.length===0) {
          currentOperatorStatus = {missing: true}
        } else {
          currentOperatorStatus = {missing: false}
        }
        setUnitOperatorsStatus(prevState => [...prevState, currentOperatorStatus])

        // if there is a 7XX series UNIT ID then we need to change the 
        // starting position of the custom unit ids so that when we add
        // another custom unit to this duplciated ticket, we get the correct
        // starting poisiton.
        const currentCustomUnitCode = String(item?.unitCode)
        if (currentCustomUnitCode.charAt(0)==="7" && currentCustomUnitCode?.length===3) {
          // we have a 7 series unit code
          const currentCustomUnitCodeNumber = Number(currentCustomUnitCode)
          // if this number is bigger than what we have set, set stored to this number + 1
          // this should keep the stored number 1 ahead of all incoming/loaded 7 series numbers
          if (currentCustomUnitCodeNumber>currentHighAuxUnitCodeNumber) {
            currentHighAuxUnitCodeNumber = currentCustomUnitCodeNumber
            // we increment this before use everywhere else
            setCurrentAuxUnitCode(currentCustomUnitCodeNumber)
          }
        }

        const unitDrivers = item?.drivers?.items
        let tmpSelectedDrivers = selectedDrivers

        let driversArr = []
        // let hasDrivers = false
        // let requiresDrivers = (item?.requiresDriver===true) ? true : false
        if (unitDrivers?.length>0) {
          // hasDrivers=true
          unitDrivers?.forEach(driver => {
            const driverId = driver?.drivenById
            const driverName = `${driver?.drivenBy?.firstName} ${driver?.drivenBy?.middleName||''} ${driver?.drivenBy?.lastName}`
            const driverObj = {
              label: driverName,
              value: driverId
            }
            driversArr.push(driverObj)
          })
        }
        tmpSelectedDrivers.splice(index, 1, driversArr)
        
        setSelectedDrivers(tmpSelectedDrivers)

        // if (requiresDrivers && !hasDrivers) {
        //   setHasEmptyDriver(true)
        // }

        const unitOperators = item?.operators?.items
        let tmpSelectedOperators = selectedOperators
        // {operatedById: "dfb8efb0-c7df-4ab3-a68a-5e074e7b94ff", operatedBy: Object}
        // for each item in operators array, extract the operatedBy name and operatedById
        
        let operatorsArr = []
        if (unitOperators?.length>0) {
          unitOperators?.forEach(operator => {
            const operatorId = operator?.operatedById
            const operatorName = `${operator?.operatedBy?.firstName} ${operator?.operatedBy?.middleName||''} ${operator?.operatedBy?.lastName}`
            const operatorObj = {
              label: operatorName,
              value: operatorId
            }
            operatorsArr.push(operatorObj)
          })
        }
        tmpSelectedOperators.splice(index, 1, operatorsArr)
        
        setSelectedOperators(tmpSelectedOperators)

        // if this is an unavailable unit, append-unavailable below

        // this should only run on primary units that are 
        const isUnitAvailable = checkUnitAvailability(item.unitId)
        let objUnitCategory = (isUnitAvailable===true) ? 'available' : 'unavailable'

        // console.log('item: ', item)
        // const unitBaseRate = (item?.isPrimary===true) ? item.totalBilled : null
        const unitBaseRate = item.totalBilled
        const unitObj = {
          UnitCode: item.unitCode,
          Id: item.id,
          UnitId: item.unitId,
          Description: item.billableDescription,
          OrigDescription: item.billableDescription,
          
          // UnitRate: '$'+billableRate+billableUnit,
          UnitRate: unitBaseRate,
          // UnitRate: '',
          Quantity: item?.quantity,
          RowTotal: item.totalBilled,
          // UnitCategory: 'available', // this is available unit group
          UnitCategory: objUnitCategory,
          OriginalIndex: index,
          ButtonIndex: unavailableButtonIndex,
          isAuxillaryUnit: item.isAuxillary||false,
          isCustomEquipment: item.isCustom||false,
          isPrimaryUnit: item.isPrimary||false,
          requiresOperator: item?.requiresOperator,
          requiresDriver: item?.requiresDriver,
          isAuxChild: item?.isAuxChild||false,
          auxChildOfParentId: item?.auxChildOfParentId||null

        }

        
        tmpOrderedUnitFields.push(unitObj)
      })
      
    } catch(err) {
      console.log('NO UNITS TO LOAD: ', err)
    }
    
    

    // console.log(' -----> tmpOrderedUnitFields: ', tmpOrderedUnitFields)
    // now we have an array of objects to insert
    for (const [unitIndex, unit] of tmpOrderedUnitFields.entries()) {
      // console.warn(' ----- unitObj: ', unit)
      await updateUnitSummary('append-saved', unit, unitIndex)
    }
  } 

  async function loadDailyJobTicketMaterial(data) {
    // console.warn(' !!!!! loadDailyJobTicketMaterial: ', data)
    // console.warn('materialFields: ', materialFields)
    try {
      data?.forEach((item, index) => {
        const total = convertCurrencyToDouble(item.totalPrice)
        const qty = item.quantity
        const price = convertToCurrency(total/qty)
        const materialObj = {
          Id: item.id,
          Description: item.description,
          Quantity: item.quantity,
          Price: price,
          RowTotal: item.totalPrice,
          isDefaultMaterial: item.isDefault,
          isCustomMaterial: item.isCustom,
        }
        updateMaterialSummary('append-saved', materialObj, index)
      })
      setIsDuplicateMaterialLoaded(true)
    } catch(err) {
      console.log('ERROR LOADING MATERIAL: ', err)
    }
  }

  async function updateLoadingDuplicateModal(status, progress) {
    setLoadDuplicateProgress(progress)
    setLoadDuplicateModalContent(status||'')
  }

  const updateSubmittingModal = (status, progress) => {
    setSubmissionProgress(progress)
    setSubmitModalContent(status)
  }

  async function openLoadingModalUI() {
    setLoadDuplicatetModalCloseDisabled(true);
    onLoadDuplicateModalOpen();
  }

  async function fetchDjt(id) {
    // console.log('djt id: ', id)
    const djt = await fetchDailyJobTicketById(id);
    return djt;
  }

  async function fetchContact(djtClientContactId) {
    const contact = await fetchClientContactByClientContactId(djtClientContactId);
    if (contact.phone !== '' && contact.phone !== null) {
        contact.phone = formatToPhoneData(contact.phone);
    }
    return contact
  }

  async function checkBouncedStatus(statusId) {
    const statusCode = await getStatusCodeByStatusId(statusId)
    if (statusCode === 'queued-unapproved-with-issue') {
      return true
    }
    return false
  }

  function setLaborEquipmentAndMaterial(djt) {
    setExistingLabor(djt?.djtLabor?.items);
    setLaborLength(djt?.djtLabor?.items?.length || 0);
    setExistingEquipment(djt?.djtEquipment?.items);
    setUnitsLength(djt?.djtEquipment?.items?.length || 0);
    setExistingMaterial(djt?.djtMaterial?.items);
    // setMaterialsLength(djt?.djtMaterial?.items?.length || 0);
  }

  function handleDateLogic(djtDate, optionalDate) {

    // console.warn(' !!!!! optional date: ', optionalDate)
    // console.warn(' !!!!! djtDate: ', djtDate)

    let nextDay = nextDayYMD(djtDate);
    // let nextDay = nextDayAsYMD(djtDate)
    let loadedDate = djtDate;
    // let earliestDate = "1980-01-01";
    let earliestDate = "2023-01-01";

    // changed on 01-19-2024 fixing duplicate ticket and set date to previous date
    // set the date to tomorrow by default
    // if they sent a date, set it to that date
    // make sure the date is not before the earliest date

    let customDay = (optionalDate) ? optionalDate : earliestDate;
    // let ticketDate = (isEdit) ? loadedDate : nextDay;
    // set the ticket date to the djtDate by default
    let ticketDate = loadedDate
    // console.warn('nextDay: ', nextDay)
    if (isDuplicate) {
      // if it's a duplicate default to next day, else  if they changed it - let them mutate the date
      if (!optionalDate) {
        ticketDate = nextDay;
      } else {
        if (optionalDate>earliestDate) {
          ticketDate = optionalDate;
        }
      }
    }
    
    // if (customDay > nextDay) {
    //   ticketDate = customDay;
    // }
    
    // console.warn(' !!!!! ticketDate: ', ticketDate)

    setChangedDate(ticketDate);
    setValue('djtDate', ticketDate);
    setDjtDateLong(spellOutDate(ticketDate));
    setPrevDjtDate(ticketDate);
    setDjtDate(ticketDate);
    const returnValues = {
      nextDay: nextDay,
      earliestDate: earliestDate,
      customDay: customDay,
      ticketDate: ticketDate,
    }
    return returnValues;
  }

  function handleShowComponents(ticketDate) {
    const year = ticketDate.substring(0,4)

    if (isValidDate(ticketDate) && Number(year)>=2023) {
        // setShowLaborComponent(true)
        // setShowUnitsComponent(true)
        // setShowMaterialsComponent(true)
    }
  }

  async function updateJobOverview(jobOverview) {
    setValue('djtNonContractPO', jobOverview.NonContractPO);
    setDjtPo(jobOverview.NonContractPO);
    setValue('djtNotes', jobOverview.notes);
    setDjtDescription(jobOverview.notes);
    setValue('contactLocation', jobOverview.location);

    if (jobOverview.poId.length>0) {
      const selectedPo = await fetchClientPoById(jobOverview.poId)
      // get the po of this poId
      setClientPoSelectedOption({label: selectedPo.po, value: selectedPo.id})
      handleDjtPoChanged({label: selectedPo.po, value: selectedPo.id}, {action: "select-option"})
    }
  }

  async function handleClientData(client, contact) {
    handleClientChanged({label: client?.contactCompany, value: client?.clientId}, {action: "select-option"})
    setCompanyName(client?.contactCompany)
    // handleClientContactChanged({label: contact?.name, value: contact?.id})
    handleClientContactChanged({label: contact?.name, value: contact?.id}, {action: "select-option"})

    setClientContactName(contact?.name)
    setValue('selectContact', {label: contact?.name, value: contact?.id})
    setValue('contactEmail', contact?.email)
    setValue('contactPhone', contact?.phone)
  }

  async function handleLoadLaborEquipmentMaterial(ticketData) {
    // console.warn(' ----- handleLoadLaborEquipmentMaterial -----', ticketData)
        
    const hasMaterial = (materialsForDay?.length>0) ? true : false

    if (ticketData?.labor?.length>0) {
      setDjtDuplicateLaborData(ticketData.labor)
    }

    setDjtDuplicateEquipmentData(null)
    if (ticketData?.equipment?.length>0) {
      setDjtDuplicateEquipmentData(ticketData.equipment)
    }
    // if (ticketData?.material?.length>0 && hasMaterial===false) setDjtDuplicateMaterialData(ticketData.material)

    if (ticketData?.material?.length>0 && hasMaterial===false) {
      // console.warn('!!!!! RUNNING LOAD MATERIALS: ', ticketData?.material?.length)
      if (ticketData?.material?.length>0) loadDailyJobTicketMaterial(ticketData.material)
    } else {
      // console.warn('!!!!! SKIPPING LOAD MATERIALS')
    }

    // If the date is changed manually, load the DJT labor
    if (ticketData.customDay>ticketData.earliestDate && ticketData.customDay!==ticketData.nextDay && ticketData?.labor?.length>0) {
      await loadDailyJobTicketLabor(ticketData.labor, isEstimate, loadedAllTeamMembers)
    }

    // If the date is changed manually in a duplicated DJT, load the DJT equipment
    if (ticketData.customDay>ticketData.earliestDate && ticketData.customDay!==ticketData.nextDay && ticketData?.labor?.length>0 && isDuplicateEquipmentLoaded===true) {
      setAuxillaryUnitOptions([])
      setIsDuplicateEquipmentLoaded(false)
    }
  }

  async function loadDailyJobTicket(id, optionalDate) {
    if (!id) return
    
    // open the modal and set the message to "prefetching data"
    await openLoadingModalUI()
    
    updateLoadingDuplicateModal("Prefetching data...", 10);
    // await delay(100);
    // fetch the daily job ticket data from db and set the djt object
    const djt = await fetchDjt(id)

    console.info(' LOADED DJT: ', djt)

    setDoMergeMaterialEquipment(djt?.mergeMaterialEquipment)
    setDoShowHoursState(djt?.showLaborHours)
    setValue('doShowHours', djt?.showLaborHours)
    setValue('mergeMaterialEquipment', djt?.mergeMaterialEquipment)
    setValue('specialInstructions', djt?.specialInstructions)

    updateLoadingDuplicateModal("Fetching contact data...", 15);
    // await delay(100);
    // fetch and set the contact data
    djt.contact = await fetchContact(djt.djtClientContactId)
    // console.info('djt?.contact?: ', djt?.contact)
    setClientContactEmailVerified(djt?.contact?.emailVerified||false);
    setClientContactEmailAddress(djt?.contact?.email||null)

    updateLoadingDuplicateModal("Fetching status...", 20);
    // await delay(100);
    // if this is a bounced ticket, set the bounced reason
    const isBounced = await checkBouncedStatus(djt.currentStatusId)
    if (isBounced) setBouncedAdminReason(djt?.bouncedAdminReason)

    updateLoadingDuplicateModal("Fetching contract data...", 25);
    // await delay(100);
    // if this djt has an attached contract, set those.
    await handleAttachedContract(djt?.attachedContractId, djt?.attachedContract)

    updateLoadingDuplicateModal("Loading data...", 30);
    // await delay(100);
    setLaborEquipmentAndMaterial(djt)

    updateLoadingDuplicateModal("Checking dates...", 40);
    // await delay(100);
    const {nextDay, earliestDate, customDay, ticketDate} = handleDateLogic(djt.djtDate, optionalDate)

    updateLoadingDuplicateModal("Populating punched labor data...", 50);
    // await delay(100);
    if (!optionalDate) await getLaborByDepartmentByDate({divisionId: currentDivisionId, workDayHuman: ticketDate})
    updateLoadingDuplicateModal("Populating equipment data...", 60);
    // await delay(100);
    if (!optionalDate) await getUnitsByDivisionByDate({divisionId: currentDivisionId, workDayHuman: ticketDate})

    updateLoadingDuplicateModal("Loading components...", 70);
    // await delay(100);
    handleShowComponents(ticketDate)
    
    updateLoadingDuplicateModal("Loading Job Overview...", 80);
    // await delay(100);
    const jobOverviewObj = {
      nonContractPo: djt?.djtNonContractPO, 
      notes: djt?.djtNotes, 
      location: djt?.djtLocation,
      poId: djt?.djtPOid,
    }
    await updateJobOverview(jobOverviewObj)

    updateLoadingDuplicateModal("Setting client...", 85);
    // await delay(100);
    const djtClient = {...djt?.djtClient, clientId: djt?.djtClientId}
    // console.info('djt?.contact: ', djt?.contact)
    await handleClientData(djtClient, djt?.contact)

    updateLoadingDuplicateModal("Setting ticket data...", 90);
    // await delay(100);
    //items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const laborItems = djt?.djtLabor?.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const ticketData = {
      labor: laborItems, 
      equipment: djt?.djtEquipment?.items, 
      material: djt?.djtMaterial?.items, 
      customDay: customDay, 
      nextDay: nextDay, 
      earliestDate: earliestDate,
    }

    // material data isn't controlled by dates. When we change the date and reload new data, we can just reload the material data
    handleLoadLaborEquipmentMaterial(ticketData)
    // setIsDuplicateLaborLoaded(true)
    // setIsDuplicateEquipmentLoaded(true)
    // setIsDuplicateMaterialLoaded(true)

    updateLoadingDuplicateModal("Wrapping up...", 95)
    // await delay(100)
    updateLoadingDuplicateModal("", 100)
    // await delay(100)
    setTimeout(() => {  setLoadDuplicatetModalCloseDisabled(false) }, 1000);
    
    return
  }
  
  const calculateTimeWorked = (start, finish, lunch)=> {
    const lunchMinutes = (lunch) ? Number(lunch) : 0;
    const timeStart = new Date("01/01/1970 " + start);
    const timeEnd = new Date("01/01/1970 " + finish);
    const timeDifference = timeEnd - timeStart

    const difference_as_date = new Date(timeDifference)
    const adjusted_difference = subtractLunchMinutes(difference_as_date, lunchMinutes)
    const dhours = adjusted_difference.getUTCHours()
    const dminutes = adjusted_difference.getUTCMinutes()

    // hardcoded at 8
    let maxStraightTime = 8
    // let returnTime = null
    let returnTime = []

    let totalHoursBilled = 0
    // if hours is greater than max OT hours is max and there are minutes extra
    if ((dhours>maxStraightTime)||(dhours===maxStraightTime&&dminutes>0)) {
      // Cap hours at 8 and move minutes to ot
      const otHours = dhours-maxStraightTime
      const otMinutes = dminutes
      const adjHours = dhours-otHours
      const adjMinutes = '00'

      // set the number of hours to use for suggested billable hours
      let totalHoursBilled = Number(dhours)
      const totalMinutesBilled = Number(dminutes)
      // set the hours, and then bill for an additional hour if time goes over the hour
      if (totalMinutesBilled>0) {
        totalHoursBilled = totalHoursBilled+1
      }

      returnTime = [adjHours+':'+adjMinutes,otHours+':'+otMinutes, totalHoursBilled]
    } else {
      if (dminutes>30) {
        totalHoursBilled = dhours + 1
      }
      returnTime = [dhours+':'+dminutes,'00:00',totalHoursBilled]
    }
    return returnTime
  }

  const calculateTotalAmount = (params) => {
    // To calculate the total dollars earned by the person, we need to follow these steps:
    // Determine the total hours worked excluding lunch. Since the person takes a 30-minute lunch break, the actual hours worked are 8.5 hours.
    // Identify the regular hours and the overtime hours. In this case, the regular hours are 8 hours, and the overtime hours are 0.5 hours (30 minutes).
    // Calculate the pay for the regular hours by multiplying the number of regular hours by the regular hourly rate.
    // Calculate the pay for the overtime hours by multiplying the number of overtime hours by the overtime hourly rate.
    // Sum the pay for the regular hours and the overtime hours to find the total dollars earned.
    // Do the calculations based on the given rates: $72 an hour for regular time and $108 an hour for overtime.
    // The total dollars earned by the person for the 8.5 hours of work, with a 30-minute lunch break, at the given rates ($72 an hour for an 8-hour day and $108 an hour for overtime) is $630. This includes pay for both the regular hours and the overtime hours.

    // console.warn(' ---- getTotalAmount params: ', params)
    //straightHours, straightRate, overtimeHours, overtimeRate
    // calse cost per 15 minutes
    // calc cost per minute
    let scale = Number(params.straightRate)/60;
    let otscale = Number(params.overtimeRate)/60;
    // then calc total minutes * scale
    scale = Number(scale.toFixed(5))
    otscale = Number(otscale.toFixed(5))

    const straightHoursSplit = params.straightHours.split(":")
    const hours = Number(straightHoursSplit[0])
    const minutes = Number(straightHoursSplit[1])

    const overtimeHoursSplit = params.overtimeHours.split(":")
    const othours = Number(overtimeHoursSplit[0])
    const otminutes = Number(overtimeHoursSplit[1])

    const totalStraightMinutes = (hours*60)+minutes
    const totalOTMinutes = (othours*60)+otminutes
    
    let totalLaborAmount = (totalStraightMinutes*scale) + (totalOTMinutes*otscale)
    totalLaborAmount = totalLaborAmount.toFixed(2)
    totalLaborAmount = parseFloat(totalLaborAmount)
    return totalLaborAmount
  }

  const calculateLaborTotal = (props) => {
    const {
      HourlyRate,
      OvertimeRate,
      StartTime,
      FinishTime,
      PerDiemAmount=0.00,
      Lunch
    } = props

    // console.warn(' ----- props: ', props)

    const perDiemTotal = convertCurrencyToDouble(PerDiemAmount)

    const lunchMinutesString = (Lunch?.includes(':')) ? Lunch?.split(':')[1] : Lunch
    const lunchMinutes = Number(lunchMinutesString)

    // StartTime has to be greater than FinishTime
    // If not, you'll get the total hours for one day to the next day
    const timeWorked = calculateTimeWorked(StartTime, FinishTime, lunchMinutes)

    const straightTimeWorked = timeWorked[0]
    const overTimeWorked = timeWorked[1]
    // const workedBillableHours = timeWorked[2]

    // set hourly rate
    let hourlyRate = (HourlyRate===undefined) ? '0.00' : HourlyRate+''

    // set overtime rate
    let overtimeRate = (OvertimeRate===undefined) ? '0.00' : OvertimeRate+''
    
    // set the total amount for this labor row
    const totalInput = {
      straightHours: straightTimeWorked,
      straightRate: hourlyRate,
      overtimeHours: overTimeWorked,
      overtimeRate: overtimeRate
    }

    // console.info('totalInput: ', totalInput)

    let totalAmount = calculateTotalAmount(totalInput)

    // per diem gets added on top of everything else
    totalAmount += perDiemTotal

    return totalAmount
    
  }

  const calculateCurrentLaborRowTotal = () => {
    const grandTotal = laborFields.reduce((acc, labor) => (acc += convertCurrencyToDouble(labor.RowTotal)), 0)
    console.log('grandTotal: ', grandTotal)
    return grandTotal
  }

  function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }
  
  const updateLaborSummary = async (action, props, index) => {
    console.log('updateLaborSummary props: ', props)
    console.log('updateLaborSummary index: ', index)
    console.log('updateLaborSummary action: ', action)

    // const isCustom = (props?.JobClass==='custom') ? true : false
    // console.warn('updateLaborSummary action: ', action)
    setIsLaborDisabled(true)

    // an "all labor" item was added to laborFields, update summary state
    if (action==='append-unavailable') {
      props.Id = uuidv4()
      setAddedLabor(prevState => [...prevState, props.Id])
      // console.log('appending from all labor: ', props)
      // update the row total based on job class, hours, perdiem, lunch
      const updatedRowTotal = await updateLaborRowTotal(props, index)

      // update the prop in the object with the updated row total
      props.RowTotal = convertToCurrency(updatedRowTotal)

      // update the labor grand total
      setLaborSummaryTotal(laborSummaryTotal+updatedRowTotal)

      // update the laborSummary array, add new object to end of labor summary array
      setLaborSummary(prevState => [...prevState, props])

      // toggle the button state in the allLabor group
      toggleAllLaborButtonState(props?.OriginalIndex);

      // append the row to laborFields
      laborAppend(props)
    }
    // an item was added to laborFieds, update summary state
    if (action==='append') {
      props.Id = uuidv4()
      setAddedLabor(prevState => [...prevState, props.Id])
      // update the row total based on job class, hours, perdiem, lunch
      const updatedRowTotal = await updateLaborRowTotal(props, index)

      // update the prop in the object with the updated row total
      props.RowTotal = convertToCurrency(updatedRowTotal)

      // update the labor grand total
      setLaborSummaryTotal(laborSummaryTotal+updatedRowTotal)

      // update the laborSummary array, add new object to end of labor summary array
      setLaborSummary(prevState => [...prevState, props])

      // update the button state id this is a team member
      if (props?.JobClass!==thirdPartyJobClass) {
        toggleLaborButtonState(index)
      }

      // append the row to laborFields
      laborAppend(props)
        
    }
    // update the summary state item values accordingly
    if (action==='update') {
      // track which labor items need to be updated
      // it's only an update if it isn't being added new

      // for converted estimates, update the team member id and name
      // console.warn('props?.TeamMemberName: ', props?.TeamMemberName)
      if (props?.IsConvertedLabor===true) {
        setValue(`labor[${index}].TeamMemberId`, props?.TeamMemberId||'')
        setValue(`labor[${index}].TeamMemberName`, props?.TeamMemberName||'')
      }

      let existingMatch = existingLabor?.filter(item => item.id === props.Id)
      // if it didn't already exist AND we haven't already updated this id, add it to the updated ids list
      let alreadyUpdated = updatedLabor?.includes(props.Id)

      if (existingMatch?.length>0 && !alreadyUpdated) {
        setUpdatedLabor(prevState => [...prevState, props.Id])
      }
      // this is just triggering all existing to update on load - need to come back and revisit this later.
      // which is basically going to tell the formSubmit to update these items even if they weren't technically updated

      // only if the existing jobclassid is different than what is coming in
      // const originalJobClassId = (existingMatch?.length>0) ? existingMatch[0]?.jobClassId : null
      // const prevJobClassId = laborSummary[index]?.JobClass?.value
      // const propsJobClassId = props.JobClass.value

      const updatedRowTotal = await updateLaborRowTotal(props, index)
      // console.info('updatedRowTotal: ', updatedRowTotal)
      // update the form field of the row in the summary
      setValue(`labor[${index}].RowTotal`, convertToCurrency(updatedRowTotal))
      // update the prop in the object with the updated row total
      props.RowTotal = convertToCurrency(updatedRowTotal)
      

      // update the laborSummary array, mutate the object at index with new data
      laborSummary.splice(index, 1, props)
      setLaborSummary(laborSummary)

      // update the running total
      const laborGrandTotal = calculateCurrentLaborRowTotal()
      setLaborSummaryTotal(laborGrandTotal)

    }
    // remove this item from the summary state
    if (action==='remove') {
      // tag this for removal

      // if it was in the existing batch of tickets, add it to the items to remove - make sure if it gets added back...
      let matchedExisting = existingLabor?.filter(item => item.id === props.Id)
      if (matchedExisting?.length>0) {
        // add this item to the labor to delete when submitted
        setRemovedLabor(prevState => [...prevState, props.Id])

        // now remove it from the incoming, existing labor, we can add it back if we want to
        let tmpArr = existingLabor?.filter(item => item.id !== props.Id)
        setExistingLabor(tmpArr)
      }

      // remove it form the addedLabor
      let tmpAddedLabor = addedLabor?.filter(item => item !== props.Id)
      setAddedLabor(tmpAddedLabor)

      // and possibly remove it from updated, which would be a modified, existing record that is now being deleted
      let isInUpdated = updatedLabor?.includes(props.Id)
      if (isInUpdated) {
        let tmpUpdatedLabor = updatedLabor?.filter(item => item !== props.Id)
        setUpdatedLabor(tmpUpdatedLabor)
      }
      
      
      // remove item from job class array
      jobClassSelected.splice(index, 1)
      setJobClassSelected(jobClassSelected)
      // console.log('laborFields: ', laborFields)

      // console.log('jobClassSelected: ', jobClassSelected)
      // update jobclass for each row below the one deleted
      
      // update the labor summary grand total
      setLaborSummaryTotal(laborSummaryTotal-convertCurrencyToDouble(props.RowTotal))

      // remove from laborSummary array delete the object at index
      laborSummary.splice(index, 1)
      setLaborSummary(laborSummary)

      // remove labor row
      laborRemove(index)


    }

    if (action==='append-saved') {

      // console.info('isDuplicate: ', isDuplicate===true ? 'true' : 'false')
      // console.info('isEdit: ', isEdit===true ? 'true' : 'false')
      // console.info('isReviewing: ', isReviewing===true ? 'true' : 'false')
      
      // MAKE SURE THIS DOESN'T DUPLICATE ON VALID LABOR LOADED
      setAddedLabor(prevState => [...prevState, props.Id])

      let updatedRowTotal = 0.00

      // isDuplicate
      // isEdit

      // isEstimate
      

      if (isTicketDateWeekend && isDuplicate) {
        // This will recalculate the labor on duplicate tickets so weekend hours can be applied as needed.
        // update the row total based on job class, hours, perdiem, lunch
        updatedRowTotal = await updateLaborRowTotal(props, index)

      } else {
        // RowTotal is not calculated on row values, it could have been manually mutated
        updatedRowTotal = convertCurrencyToDouble(props.RowTotal)
        console.log('updatedRowTotal: ', updatedRowTotal)
      }


      
      




      // update the prop in the object with the updated row total
      props.RowTotal = convertToCurrency(updatedRowTotal)

      updateLaborSummaryTotal(updatedRowTotal)
      
      // update the laborSummary array, add new object to end of labor summary array
      setLaborSummary(prevState => [...prevState, props])

      // update the button state
      // CANNOT RELY ON INDEX FOR THIS UPDATE, NEED TO MATCH INCOMING PERSON TO BUTTON IN EITHER AVAILABLE ON UNAVAILABLE LABOR
      // VERIFY THAT PERSON IS IN AVAILABLE LABOR

      // console.log('ABOUT TO APPEND LABOR PROPS: ', props)

      // console.log('laborForDay for matching: ', laborForDay)

      // we could be loading a DJT without any labor. Handle for that.
      let foundAvailableMatch = false

      // console.info(' ----- laborForDay: ', laborForDay)
      // if (laborForDay?.length>0) {
      laborForDay?.forEach((labor, laborIndex) => {
        // console.warn('DUPLICATING THIS LABOR: ', labor)
        // console.warn('THESE PROPS: ', props)
        // console.log('THIS TEAM MEMBER: ', labor?.teamMember?.teamMember)
        if (props?.TeamMemberId===labor?.teamMember?.teamMember?.id) {

          // only update the running labor total if we are adding this person
          // update the labor grand total
          // updateLaborSummaryTotal(updatedRowTotal)

          props.OriginalIndex = laborIndex
          props.LaborCategory = 'available'

          toggleLaborButtonState(laborIndex, 'on')
          foundAvailableMatch = true

          // append the row to laborFields
          laborAppend(props)

        }
      })
      // } else {
      //   console.log('GOT NOTHING')
      // }
      
      // if (foundAvailableMatch===false && props?.TeamMemberName && !isEstimate) {
      if (foundAvailableMatch===false && props?.TeamMemberName) {
        
        if (!isEstimate) {
          let teamMemberName = props?.TeamMemberName
          // console.log(' ++++++++ SETTING ERRORS FOR TM: ', teamMemberName)
          let tmpArr = duplicatedLaborError
          tmpArr.push(teamMemberName)
          setDuplicatedLaborError(tmpArr)
        }

        // THIS WILL ADD THE UNAVAILABLE WHEN WE LOAD, BUT WE AREN'T HANDLING THE BUTTON STATE YET
        // WE STILL NEED TO NOT LOAD THIS TEAM MEMBER WHEN ADDING UNAVAILABLE SEARCH RESULTS AND PREPEND 
        // THIS TEAM MEMBER TO THE RESULTS WITH TOGGLED BUTTON STATE (LIKE UNITS)
        laborAppend(props)

        // populate the allLabor here?
        // setLoadedAllTeamMembers()
        // add it to the allunits and buttons
          // code, subType, type
          // probably need to query for type and subType, but for now just extract from description

        // setDuplicatedLaborError(prevState => [...prevState, teamMemberName])
        
        // console.log(' ++++++++ new tmpArr state: ', tmpArr)

        
      }
      
    }

    setIsLaborDisabled(false)
  }

  const updateLaborRowTotal = async (props, index) => {
    const {
      HourlyRate,
      OvertimeRate,
      StartTime,
      FinishTime,
      PerDiemAmount=0.00,
      Lunch
    } = props

    // console.info('updateLaborRowTotal props: ', props)

    const perDiemTotal = convertCurrencyToDouble(PerDiemAmount)

    const lunchMinutesString = (Lunch.includes(':')) ? Lunch.split(':')[1] : Lunch
    const lunchMinutes = Number(lunchMinutesString)

    // console.info('lunchMinutes: ', lunchMinutes)

    // StartTime has to be greater than FinishTime
    // If not, you'll get the total hours for one day to the next day
    const timeWorked = await getTimeWorked(StartTime, FinishTime, lunchMinutes)
    // console.info('timeWorked: ', timeWorked)
    const straightTimeWorked = timeWorked[0]
    const overTimeWorked = timeWorked[1]
    const workedBillableHours = timeWorked[2]

    billableHours[index] = workedBillableHours
    // let currentMaxBillableHours = Math.max(...billableHours)
    // turn this off for now. Not updating the billable hours in the unit buttons that are already generated.
    // would need to update the unit buttons each time we change maxbillablehours

    // set hourly rate
    let hourlyRate = (HourlyRate===undefined) ? '0.00' : HourlyRate+''

    // set overtime rate
    let overtimeRate = (OvertimeRate===undefined) ? '0.00' : OvertimeRate+''
    
    // set the total amount for this labor row
    const totalInput = {
      straightHours: straightTimeWorked,
      straightRate: hourlyRate,
      overtimeHours: overTimeWorked,
      overtimeRate: overtimeRate
    }

    // console.info('totalInput: ', totalInput)

    let totalAmount = await getTotalAmount(totalInput)
    // console.warn(' labor row totalAmount: ', totalAmount)
    // per diem gets added on top of everything else
    totalAmount += perDiemTotal

    return totalAmount
  }

  const fetchAvailableContracts = async (clientId) => {
    if(!clientId) return
    const clientContracts = await fetchClientContractsByClientId(clientId)
    clientContracts?.forEach((contract, index) => {
      const dateTime = contract.createDateTime
      const utcDate = new Date(dateTime)
      // const date = dateTime.split('T')[0]
      // const time = dateTime.split('T')[1]
      contract.utcDateTime = utcDate.toString()
      contract.version = `v${index+1}`
    })

    clientContracts.reverse()
    clientContracts.splice(0, 1)
    setAvailableClientContracts(clientContracts)

    // filter out the current attachedContractId n ame from clientContracts and set Selected
    // const selectedContract = clientContracts.filter(contract => {return contract.id === attachedContractId})
    // console.log('selectedContract: ', selectedContract)
    return
  }

  const fetchClientPOs = async (clientId, divisionId) => {
    if(!clientId || !divisionId) return
    const props = {
      client: clientId,
      division: divisionId,
    }
    const clientPOs = await fetchClientPOsByDivisionClient(props)
    setAvailableClientPOs(clientPOs)
    return 
  }

  const handleClientChanged = async (event, action) => {

    // if this division allows sending cc emails, get the cc recipient data from the database
    // divisionAllowsEmailCC

    // console.log('event: ', event)
    // console.log('action: ', action)

    if (action?.action === "select-option") {
      setClientContactDisabled(false)
      setIsClientNew(false)
      setValue('isClientNew', false)
      const prevSelectedClient = companySelectedOption?.value||null

      const clientId = event.value
      const clientName = event.label

      if (divisionAllowsEmailCC) {
        try {
          const ccRecipients = await fetchCCEmailsByClientId(clientId);
          // Transform directly with map, avoiding the temp array
          const ccEmailOptions = ccRecipients?.map(recipient => ({
            label: recipient.email,
            value: recipient.clientId,
          })) ?? []; // Fallback to an empty array if ccRecipients is nullish
      
          setCcEmailOptions(ccEmailOptions);
        } catch (error) {
          console.error("Failed to fetch CC emails:", error);
          // Handle the error, maybe set an error state here
        }
      }

      await fetchAvailableContracts(clientId)

      setCompanySelectedOption({label: clientName, value: clientId})
      const client = await fetchContactCompanyByCompanyId(clientId)
      setValue('selectCompany', {label: clientName, value: clientId})
      setClientId(clientId)
      await fetchClientPOs(clientId, currentDivisionId)

      // THESE ARE NO LONGER USED IN THE SAME WAY, REFERENCE DEFAULT CLIENT SELECTED
      // setContactId(client.id)
      setValue('contactId',client.id)
      setCompanyName(event.label)
      // setValue('contactCompanyName',client.contactCompany)

      // setIsCompanyNameReadOnly(true)
      setValue('contactName',client.contactName)
      setValue('djtClientAuthName',client.contactName)

      // this only needs to happen if the current selected client dropdown 
      // is different than the incoming client selected. Otherwise we are 
      // loading a duplicate and don't want to clear out client contact selected.
      if (prevSelectedClient && prevSelectedClient!==clientId) {

        // clear the previously selected contact, if any.
        setValue('contactEmail', null)
        setValue('contactPhone', null)

        const action = {action: "clear", removedValues: [clientContactSelected], name: "selectContact"};
        handleClientContactChanged(null, action);
      }
      
      // setValue('contactBillingEmail',client.contactBillingEmail)
      setValue('djtSpikeAuthName', spikeRepresentativeName)
      
    }

    if (action?.action === "create-option") {
      clearClientContactData()
      setClientContactDisabled(false)
      const id = uuidv4()
      setNewClientId(id)
      setClientId(id)
      setIsClientNew(true)
      setValue('isClientNew', true)
      setClientContactEmailVerified(false);
      const clientId = id
      const clientName = event.label
      setCompanySelectedOption({label: clientName, value: clientId})
      setValue('selectCompany', {label: clientName, value: clientId})
    }

    

    clearErrors(['selectCompany'])

  }

  const handleDoNotBillStateChange = (event) => {
    setValue('doNotBill', event)
    setDoNoBillState(event)
  }

  const handleLaborConversion = async (event, index) => {
    // console.info('event: ', event)
    // console.info('labor[index]: ', laborFields[index])
    
    // setValue(`labor[${index}].IsTempLabor`, event?.isTempLabor)
    setValue(`labor[${index}].TeamMemberId`, event.value)
    setValue(`labor[${index}].TeamMemberName`, event.label)

    // console.info('labor[index]: ', laborFields[index])
    laborFields[index].update = uuidv4()
    // laborFields[index].IsTempLabor = event?.isTempLabor||false
    laborFields[index].TeamMemberId = event.value
    laborFields[index].TeamMemberName = event.label

    // console.info('labor[index]: ', laborFields[index])
    // console.info('labor: ', laborFields)
    // do the remove, then fix the id on the team member, then do the append

    //nope, need to set the dropdowns and save before even being able to add more labor

    // just need to update the labor object at index with the team member id
    updateLaborSummary('update', laborFields[index], index)
  }

  const handleLaborCustomNameChange = async (value, index) => {
    if (!value) return
    if (!index) return
    // laborFields[index].CustomName = event.target.value
    laborFields[index].CustomName = value
    // console.info('sending to update: ', laborFields[index])
    updateLaborSummary('update', laborFields[index], index)
  }

  const handleLaborJobClassChange = (event, index) => {

    console.log('updating job class: ', event)
    console.log('updating job class index: ', index)
    // get the rates for the selected job class
    // const [ rateClassName, rateDivisionPosition ] = 
    const rates = billableRatesJobClass.filter(rate =>
      rate.divisionPosition === event.value
    )

    // update the job class selected
    // jobClassSelected[index] = {label: rates[0]?.className, value: rates[0]?.divisionPosition}
    jobClassSelected[index] = {label: rates[0]?.appDisplayName, value: rates[0]?.divisionPosition}

    // update the form field so we have this data at submit
    setValue(`labor[${index}].HourlyRate`, rates[0]?.hourlyRate)
    setValue(`labor[${index}].OvertimeRate`, rates[0]?.overtimeRate)
    // setValue(`labor[${index}].JobClass`, {label: rates[0]?.className, value: rates[0]?.divisionPosition})
    setValue(`labor[${index}].JobClass`, {label: rates[0]?.appDisplayName, value: rates[0]?.divisionPosition})
    setJobClassSelected(jobClassSelected)

    // update the fieldarray values
    laborFields[index].HourlyRate = rates[0]?.hourlyRate
    laborFields[index].OvertimeRate = rates[0]?.overtimeRate
    // laborFields[index].JobClass = {label: rates[0]?.className, value: rates[0]?.divisionPosition}
    laborFields[index].JobClass = {label: rates[0]?.appDisplayName, value: rates[0]?.divisionPosition}
    
    // this is now being handled in updateLaborSummary
    // const laborId = getValues(`labor[${index}].Id`)
    // if (!addedLabor.includes(laborId)) {
    //   setUpdatedLabor(prevState => [...prevState, laborId])
    // }
    
    // update the labor summary array with the updated job class
    // console.log('CALLING UPDATE LABOR')
    updateLaborSummary('update', laborFields[index], index)

  }

  const handleLaborStartTimeChange = (index, value) => {

    // ios/safari funkiness
    // if the reset button is clicked or hour/minute is
    // deleted, this catches it and resets it. Without it
    // all kinds of funkiness happening.
    if (value==="") {
      setValue(`labor[${index}].StartTime`, defaultStartTime)
      return
    }

    if (laborFields?.length >= (index+1)) {
      
      laborFields.forEach((laborRow, laborIndex) => {
        // if the incoming index is equal to or greater than the current laborIndex
        if (laborIndex>=index) {
          // update the prop for start time
          laborFields[laborIndex].StartTime = value

          if (!addedLabor.includes(laborRow?.Id)) {
            setUpdatedLabor(prevState => [...prevState, laborRow?.Id])
          }

          // update the labor summary
          updateLaborSummary('update', laborFields[laborIndex], laborIndex)
          // change the value of the form field
          setValue(`labor[${laborIndex}].StartTime`, value)
        }
      })
    }
  }

  const handleLaborFinishTimeChange = (index, value) => {
    // ios/safari funkiness
    // if the reset button is clicked or hour/minute is
    // deleted, this catches it and resets it. Without it, 
    // all kinds of funkiness happen.
    if (value==="") {
      setValue(`labor[${index}].FinishTime`, "17:00")
      return
    }
    
    if (laborFields?.length >= (index+1)) {
      laborFields.forEach((laborRow, laborIndex) => {
        // if the incoming index is equal to or greater than the current laborIndex
        if (laborIndex>=index) {
          // update the prop for start time
          laborFields[laborIndex].FinishTime = value

          if (!addedLabor.includes(laborRow?.Id)) {
            setUpdatedLabor(prevState => [...prevState, laborRow?.Id])
          }

          // update the labor summary
          updateLaborSummary('update', laborFields[laborIndex], laborIndex)
          // change the value of the form field
          setValue(`labor[${laborIndex}].FinishTime`, value)
          // setFocus(`labor[${laborIndex}].FinishTime`)
        }
      })
    }
  }

  const handleLaborLunchChange = (event, index) => {
    // lunchMinutesSelected[index] = {label: event.label, value: event.value}
    setValue(`labor[${index}].Lunch`, {label: event.label, value: event.value})
    // setLunchMinutesSelected(lunchMinutesSelected)
    laborFields[index].Lunch = event.label
    // console.log('CALLING UPDATE LABOR')
    updateLaborSummary('update', laborFields[index], index)

    if (laborFields?.length >= (index+1)) {
      laborFields.forEach((laborRow, laborIndex) => {
        // if the incoming index is equal to or greater than the current laborIndex
        if (laborIndex>=index) {
          // update the prop for start time
          laborFields[laborIndex].Lunch = event.label
          // console.log('should be updating labor at index: ', laborRow)
          setValue(`labor[${laborIndex}].Lunch`, {label: event.label, value: event.value})

          if (!addedLabor.includes(laborRow?.Id)) {
            setUpdatedLabor(prevState => [...prevState, laborRow?.Id])
          }

          // console.log('CALLING UPDATE LABOR')
          updateLaborSummary('update', laborFields[laborIndex], laborIndex)
        }
      })
    }
  }

  const handleValidateEmailAddress = async () => {
    // const results = await validateEmailAddress(email)
    // console.log('validation results: ', results)
  }

  const handleClientBillingEmailChanged = async () => {
    // not much to do here. It's a new client and an admiin will 
    // need to validate the information and add to QB before this 
    // client can be billed.
  }

  const handleSaveClientContactChanges = async (field, data) => {
    if (!field || !data) return

    setClientContactInfoChanged(true)

    if (field==='email') {
      setValue('contactEmail', data)
    } else {
      setValue('contactPhone', data.substring(0,16))
    }

    onConfirmContactDataChangeClose()

    // this is kind of hacky. This unfocuses the cursor so the user can't mutate the field
    // all because of Safari. 
    setClientContactDisabled(true)
    await delay(1000)
    setClientContactDisabled(false)
  }

  const handleClientContactEmailChanged = async (event) => {
    // validate that the email address the user entered has the 
    // same domain as the parent company domain. if that part 
    // matches, we can allow the supervisor to email a client summary

    if (!event) return

    // get the email from the event
    const email = event.target.value

    // deactivate valid email boolean for client summary
    setClientContactEmailVerified(false)

    const clientId = companySelectedOption.value

    // get the clientBillingEmail from the clients table
    const clientBillingEmail = await getClientBillingEmailByClientId(clientId)

    // console.log('clientBillingEmail: ', clientBillingEmail)
    // compare the domains and return match boolean
    const isEmailValid = (clientBillingEmail!==false) ? doEmailAddressDomainsMatch(email, clientBillingEmail) : false

    // if matches then activate client summary
    setClientContactEmailVerified(isEmailValid)

  }

  const handleLaborPerDiemChange = (index, value) => {
    
    // clean up and convert the incoming value to a double
    const perdiemAmount = convertCurrencyToDouble(value)

    // update row total
    laborFields[index].PerDiemAmount = USDollar.format(perdiemAmount.toFixed(2))
    setValue(`labor[${index}].PerDiemAmount`, USDollar.format(perdiemAmount.toFixed(2)))

    // update this row in labor summary array
    // console.log('CALLING UPDATE LABOR')
    updateLaborSummary('update', laborFields[index], index)

    // if there are rows beneath this row, cascade the per diem down
    if (laborFields?.length >= (index+1)) {

      // scan the rows and start the cascade after the current row
      laborFields.forEach((laborRow, laborIndex) => {
        // only cascade after the current row
        if (laborIndex>=index) {
          // update the rows below
          laborFields[laborIndex].PerDiemAmount = USDollar.format(perdiemAmount.toFixed(2))
          setValue(`labor[${laborIndex}].PerDiemAmount`, USDollar.format(perdiemAmount.toFixed(2)))

          if (!addedLabor.includes(laborRow?.Id)) {
            setUpdatedLabor(prevState => [...prevState, laborRow?.Id])
          }

          // console.log('CALLING UPDATE LABOR')
          updateLaborSummary('update', laborFields[index], index)
        }
      })
    }

  }

  const updateUnitSummaryManually = async (index) => {
    let tmpArr = unitSummary
    tmpArr[index].trigger = uuidv4()
    setUnitSummary(tmpArr)
  }

  const updateUnitSummary = async (action, props, index) => {
    console.warn('updateUnitSummary action: ', action)
    console.warn('updateUnitSummary props: ', props)
    console.warn('updateUnitSummary index: ', index)

    setIsUnitsDisabled(true)
    const newEquipmentId = uuidv4()
    const currentId = props.Id

    // this may be a holdover from a previous hack with job description that is fixed now
    if (index!==undefined) props['unit'+index+'Description'] = props.Description
    if (index===undefined) {
      // NOPE - WE NEED TO KNOW WHICH POSITION THIS EXACT UNIT IS
      // ALSO DO THIS FOR append-custom
      // MOVE THIS TO THE TOP AND MUTATE THE INDEX THERE

      // this is a standalone custom aux unit, that was added after loading, there 
      // is no index yet, we need to find the index and then force the index to be the last item in unitSummary

      // console.log('CURRENT UNIT FIELDS LENGTH:'+unitFields?.length+':')
      // if unitSummary.length 0 then nothing is here yet
      if (unitFields?.length>0) {
        // find the index of this item
        // Id: "11216cac-c1f6-4747-9d96-c15fee749d69"
        // Id: "11216cac-c1f6-4747-9d96-c15fee749d69"
        let foundIndex = 99
        foundIndex = unitFields.findIndex(unit => String(unit.UnitCode) === String(props.UnitCode))
        if (!foundIndex>=0) {
          // we didn't find it, it's new, add it to the end
          index = unitFields?.length
        } else {
          // we hound it
          index = foundIndex
        
        }
        
        
      } else {
        // this is the first item
        index = 0
      }
      // console.log('INDEX NOW: ', index)
      // just set the index to 0

      // if the unitSummary length is greater than 0
      // there are items here and if this index is undefined we need to find it

      // console.log('unitSummary?.length: ', unitSummary?.length)
      // index = (unitSummary?.length>=0) ? unitSummary?.length : 0
      // console.log('placing this unit at index : ', index)
    }

    // create an object to hold all of the unit properties for drivers, operators, aux units available, aux units selected

    if (action==='append-auxillary') {

      // if (item?.requiresDriver===true && item?.drivers?.items?.length===0) {
      //   currentDriverStatus = {missing: true}
      // } else {
      //   currentDriverStatus = {missing: false}
      // }
      // setUnitDriversStatus(prevState => [...prevState, {missing: false}])


      // insert a blank element into the arrays for driver/operator
      // setSelectedDrivers(prevState => [...prevState, ''])
      // setSelectedOperators(prevState => [...prevState, ''])
      // this empty driver/operator insertion needs to happen where this unit is getting added
      // if there are >0 items currently
      // insert at index+1?
      let isChildAux = (props?.isAuxChild===true) ? true : false
      if (isChildAux) {
        // console.info('THIS IS AN AUX CHILD')
        // this aux unit is getting inserted right below the primary unit.
        // add a blank element to the matching drivers/operators array at the right place
        // so we can keep track of the units and the drivers/operators
        let existingDrivers = selectedDrivers
        existingDrivers.splice(index+1, 0, [])
        setSelectedDrivers(existingDrivers)

        let existingDriverStatus = unitDriversStatus
        existingDriverStatus.splice(index+1, 0, {missing: false})
        setUnitDriversStatus(existingDriverStatus)

        let existingOperators = selectedOperators
        existingOperators.splice(index+1, 0, [])
        setSelectedOperators(existingOperators)

        let existingOperatorStatus = unitOperatorsStatus
        existingOperatorStatus.splice(index+1, 0, {missing: false})
        setUnitOperatorsStatus(existingOperatorStatus)

      } else {
        // it gets inserted at the end
        setSelectedDrivers(prevState => [...prevState, []])
        setUnitDriversStatus(prevState => [...prevState, {missing: false}])
        setSelectedOperators(prevState => [...prevState, []])
        setUnitOperatorsStatus(prevState => [...prevState, {missing: false}])
      }

      props.requiresDriver=false
      props.requiresOperator=false
      setAddedEquipment(prevState => [...prevState, newEquipmentId])
      props.Id = newEquipmentId

      // total
      // console.info('props: ', props)
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display
      // props.RowTotal = '$999.99'  // set the props as currency for display

      // calculated summary total
      // const updatedUnitSummaryTotal = convertCurrencyToDouble(unitSummaryTotal) + convertCurrencyToDouble(updatedRowTotal)
      
      let tmpArr = unitSummary
      tmpArr.splice(index+1, 0, props)
      setUnitSummary(tmpArr)

      // console.log('check for undefined in these: ', props)

      // ALSO NEED TO MAKE SURE WE MUTATE THE ARRAY HOLDING WHICH ITEMS HAVE AUX UNITS
      // insert the row at index+1
      if (index===undefined) {
        // console.info('APPENDING')
        // This is an aux unit, add null to the aux units array
        setAuxillaryUnitOptions([...auxillaryUnitOptions, []])
        // append the row to the end
        unitAppend(props)
      } else {
        // console.info('INSERTING')
        let tmpArr = [...auxillaryUnitOptions]
        tmpArr.splice(index+1, 0, [])
        setAuxillaryUnitOptions(tmpArr)
        // insert at index
        unitInsert(index+1, props)
        // force the new quantity field to 1.

        // if this is an aux attached to a unit, add + 1 to index, else index stays the same
        let actualIndex = index
        if (isChildAux) actualIndex++
        setValue(`unit[${actualIndex}].Quantity`, '1')
      }
      
    }
    // an "all labor" item was added to laborFields, update summary state
    // rental
    if (action==='append-custom') {
      // insert a blank element into the arrays for driver/operator
      setSelectedDrivers(prevState => [...prevState, []])
      setSelectedOperators(prevState => [...prevState, []])
      setUnitDriversStatus(prevState => [...prevState, {missing: false}])
      setUnitOperatorsStatus(prevState => [...prevState, {missing: false}])

      // when adding, the unit is still unselected so we need to gen an id
      // let customUnitId = uuidv4()
      setAddedEquipment(prevState => [...prevState, newEquipmentId])
      props.UnitId = newEquipmentId
      props.Id = newEquipmentId

      // console.log('custom props: ', props)
      
      // update the row total based on job class, hours, perdiem, lunch
      // const updatedRowTotal = await updateUnitRowTotal(props, index)

      // update the prop in the object with the updated row total
      // props.RowTotal = convertToCurrency(updatedRowTotal)

      // update the labor grand total
      // setUnitSummaryTotal(unitSummaryTotal+updatedRowTotal)

      // update the unitSummary array, add new object to end of labor summary array
      setUnitSummary(prevState => [...prevState, props])

      // This is a custom unit, add null to the aux units array
      setAuxillaryUnitOptions([...auxillaryUnitOptions, []])
      // setAuxillaryUnitOptions([...auxillaryUnitOptions, null])

      // toggle the button state in the allLabor group
      // toggleAllUnitsButtonState(props?.OriginalIndex);

      // append the row 
      unitAppend(props)
    }
    //unavailable/all units
    // uninspected
    if (action==="append-unavailable") {

      // ----- update driver operators
      // check if unit requires driver
      let currentDriverStatus = null
      if (props?.requiresDriver===true) {
        currentDriverStatus = {missing: true}
      } else {
        currentDriverStatus = {missing: false}
      }
      setUnitDriversStatus(prevState => [...prevState, currentDriverStatus])

      // check if unit requires operator
      let currentOperatorStatus = null
      if (props?.requiresOperator===true) {
        // console.log('REQUIRES OPERATOR')
        currentOperatorStatus = {missing: true}
      } else {
        // console.log('NO OPERATOR REQUIRED')
        currentOperatorStatus = {missing: false}
      }
      setUnitOperatorsStatus(prevState => [...prevState, currentOperatorStatus])

      // insert a blank element into the arrays for driver/operator
      setSelectedDrivers(prevState => [...prevState, []])
      setSelectedOperators(prevState => [...prevState, []])
      // ----- update driver operators

      // setAddedEquipment(prevState => [...prevState, props.UnitId])
      // setAddedEquipment(prevState => [...prevState, trackingId])
      setAddedEquipment(prevState => [...prevState, newEquipmentId])
      // props.trackingId = trackingId
      props.Id = newEquipmentId
      
      // currency
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display

      // calculated summary total
      // const updatedUnitSummaryTotal = convertCurrencyToDouble(unitSummaryTotal) + convertCurrencyToDouble(updatedRowTotal)

      // update the unit grand total

      // update the unitSummary array, add new object to end of unit summary array
      setUnitSummary(prevState => [...prevState, props])

      // update the button state
      // this button may not be loaded because we are appending an unavailable on initial load.
      // add this button to the allUnits and the allUnitsButtonVariants
      // console.log(' ***** SENDING THIS INDEX TO TOGGLE BUTTON STATE: ', index)
      toggleAllUnitsButtonState(index)

      // get auxillary unit options for this unit
      const auxUnitOptions = await getAuxillaryUnitOptionsForUnit(props.UnitId, '')
      // console.log('auxUnitOptions: ', auxUnitOptions)
      setAuxillaryUnitOptions([...auxillaryUnitOptions, auxUnitOptions])

      // append the row
      // unitAppend(props)
      // actually, insert the row at the correct spot for a loaded ticket
      // console.warn('SHOULD BE INSERTING THIS UNIT AT UNITFIELDS INDEX: ', index)
      // unitInsert(index, props)
      unitAppend(props)

    }
    // an item was added to uniutFieds, update summary state
    // primary
    if (action==='append') {

      // ----- update driver operators
      // check if unit requires driver
      let currentDriverStatus = null
      if (props?.requiresDriver===true) {
        currentDriverStatus = {missing: true}
      } else {
        currentDriverStatus = {missing: false}
      }
      setUnitDriversStatus(prevState => [...prevState, currentDriverStatus])

      // check if unit requires operator
      let currentOperatorStatus = null
      if (props?.requiresOperator===true) {
        // console.log('REQUIRES OPERATOR')
        currentOperatorStatus = {missing: true}
      } else {
        // console.log('NO OPERATOR REQUIRED')
        currentOperatorStatus = {missing: false}
      }
      setUnitOperatorsStatus(prevState => [...prevState, currentOperatorStatus])

      // insert a blank element into the arrays for driver/operator
      setSelectedDrivers(prevState => [...prevState, []])
      setSelectedOperators(prevState => [...prevState, []])
      // ----- update driver operators

      // setAddedEquipment(prevState => [...prevState, props.UnitId])
      // setAddedEquipment(prevState => [...prevState, trackingId])
      setAddedEquipment(prevState => [...prevState, newEquipmentId])
      props.Id = newEquipmentId
      // props.trackingId = trackingId

      // console.log('unit props: ', props)
      // auxillaryUnitOptions[index] = await getAuxillaryUnitOptionsForUnit(props.UnitId)
      // setAuxillaryUnitOptions([...auxillaryUnitOptions, auxillaryUnitOptions[index]])

      // currency
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display

      // calculated summary total
      // const updatedUnitSummaryTotal = convertCurrencyToDouble(unitSummaryTotal) + convertCurrencyToDouble(updatedRowTotal)

      // update the unit grand total

      // update the unitSummary array, add new object to end of unit summary array
      setUnitSummary(prevState => [...prevState, props])

      // get auxillary unit options for this unit
      // if this unit is a primary unit, get possible auxiliary units
      const auxUnits = await getAuxillaryUnitOptionsForUnit(props.UnitId, props.Id)
      // console.log('auxUnitOptions: ', auxUnits)
      // console.log(' ---- SETTING AUXOPTIONS IN UNIT APOPEND')
      setAuxillaryUnitOptions([...auxillaryUnitOptions, auxUnits])

      // update the button state
      // console.log('ON APPEND NOW RUNNING TOGGLE WITH INDEX OF: ', index)
      toggleUnitButtonState(index)

      // append the row
      unitAppend(props)
        
    }
    // update the summary state item values accordingly
    if (action==='update') {

      console.log('update unit summary props - originalId?: ', props)
      // handle for an existing unit changing, it should now be in the updatedUnits arr

      // --- NOT MATCHING ID'S ON AUX - MAYBE TRY ON UNIT CODE? 701, 166
      // console.log('incoming props: ', props)
      // console.log('existingEquipment: ', existingEquipment)
      // let matchedExisting = existingEquipment?.filter(item => item.id === props.Id)?.length
      let matchedExisting = existingEquipment?.filter(item => item.id === props.originalId)?.length
      // let matchedExisting = existingEquipment?.filter(item => item.unitId === props.UnitId)?.length
      // console.log('MATCHED EXISTING: ', matchedExisting)

      // updating
      // if it has a trackingId - it is newly added
      // if it doesn't have trackingId, it was existing

      // const isExisting = (props?.trackingId?.length>0) ? false : true
      // const isExisting = (props?.id?.length>0) ? true : false
      const isExisting = (matchedExisting>0) ? true : false
      // console.log('IS EXISTING RECORD?: ', isExisting)


      // let matchedExisting = existingEquipment?.filter(item => item.unitId === props.UnitId)
      // if (matchedExisting?.length>0) {
      if (isExisting) {
        // this unit was existing, has now been changed and needs to be 
        // included in the updatedEquipment arr - if it isn't already there
        // check to see if this unit is in the updatedEquipment arr
        // let filteredUpdatedEquipment = updatedEquipment?.filter(item => item === props.UnitId)

        // add it to updated - do we have anything in updatedEquipemnt that matches this existing Id
        // let filteredUpdatedEquipment = updatedEquipment?.filter(item => item === props.Id)
        console.log('!!!updatedEquipment: ', updatedEquipment)
        let filteredUpdatedEquipment = updatedEquipment?.filter(item => item === props.originalId)

        // if we don't , add it to updatedEquipemnt
        // console.log('filtered udpateEquipment: ', filteredUpdatedEquipment)
        if (filteredUpdatedEquipment?.length===0) {
          console.log('UPDATING UPDATED EQUIPMENT HERE: ', props)
          // setUpdatedEquipment(prevState => [...prevState, props.Id])

          // 2024-12-06 chaning originalId to just id because adding a driver to a unit doesn't mean there is an originalId
          // setUpdatedEquipment(prevState => [...prevState, props.originalId])
          setUpdatedEquipment(prevState => [...prevState, props.Id])
        }

        // and remove it from existing equipment
        // this is wrong, either id or Id
        // let unmatchedExisting = existingEquipment?.filter(item => (item?.id !== props.id && item?.id !== props.Id && item?.unitId !== props?.UnitId))
        let unmatchedExisting = existingEquipment?.filter(item => (item?.id !== props?.originalId))
        // console.log(unmatchedExisting)
        setExistingEquipment(unmatchedExisting)

      } else {
        // console.warn('CAUGHT AN INSTANCE OF NEEDING TO UPDATE AND NOT DOING ANYTHING')
      }

      // update the Description field if thi is an aux unit
      if (props?.UnitCategory==="auxillary" && props?.isAuxillaryUnit===true) {
        unitFields[index].Description = props?.Description
      }
      
      // row total
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display
      setValue(`unit[${index}].RowTotal`, updatedRowTotal)
      unitFields[index].RowTotal = updatedRowTotal

      setValue(`unit[${index}].Quantity`, props?.Quantity||1)
      unitFields[index].Quantity = props?.Quantity||1

      // update the running total
      const unitGrandTotal = calculateCurrentUnitRowTotal()
      // console.warn('unitGrandTotal: ', unitGrandTotal)
      setUnitSummaryTotal(unitGrandTotal)

      // if this is a custom aux unit, set the 
      // trigger(`unit[${index}].Description`)

      // update the unitSummary array, mutate the object at index with new data
      // unitSummary.splice(index, index, props)
      let tmpArr = unitSummary
      tmpArr.splice(index, 1, props)
      // unitSummary.splice(index, 1, props)
      // setUnitSummary(unitSummary)
      setUnitSummary(tmpArr)

      updateUnitSummaryManually(index)
      // console.info('updated unitSummary so totals should update.', unitSummary)
    }

    // remove this item from the summary state
    if (action==='remove') {

      // --- THIS WHERE WE NEED TO START DEBUGGING THE ISSUES WITH DELETE GETTING OUT OF SYNC === 
      // Determine unit type
      // determine what needs to be deleted
      // delete and confirm each step


      // // DELETE THE POSSIBLE ROW FROM slectedOperators and selectedDrivers
      // if (props?.requiresDriver===true) {
      //   let existingDrivers = selectedDrivers
      //   existingDrivers.splice(index, 1)
      //   setSelectedDrivers(existingDrivers)
      // }
      //  // and remove the status
      //  let currentSelectedUnitDriversStatus = unitDriversStatus
      //  currentSelectedUnitDriversStatus.splice(index, 1)
      //  setUnitDriversStatus(currentSelectedUnitDriversStatus)

      // if (props?.requiresOperator===true) {
      //   let existingOperators = selectedOperators
      //   existingOperators.splice(index,1)
      //   setSelectedOperators(existingOperators)
      // }
      // // remove this status
      // let currentSelectedUnitOperatorsStatus = unitOperatorsStatus
      // currentSelectedUnitOperatorsStatus.splice(index, 1)
      // setUnitOperatorsStatus(currentSelectedUnitOperatorsStatus)

      // handle removing aux unit from addedEquipment
      // if (props.UnitCategory==='auxillary' && props.isAuxillaryUnit === true) {
      //   // remove this unit from the addedEquipment
      //   // get everything currently in addedEquipemnt that isn't the unit we are changing
      //   let addedEquipmentToKeep = addedEquipment.filter(item => item[1] !== props.UnitCode)
      //   // reset the addedEquipment with the filtered equipment
      //   setAddedEquipment(addedEquipmentToKeep)
      // }

      // need to handle for any aux units that have been attached to this unit
      // delete the attached aux units first

      // console.log('PROPS OF ITEM BEING REMOVED: ', props)
      // /(item?.id !== props.id && item?.id !== props.Id)
      // let matchedExisting = existingEquipment?.filter(item => (item?.id === props.id || item?.id === props.Id))
      // let matchedExisting = existingEquipment?.filter(item => item?.id === props.Id)
      let matchedExisting = existingEquipment?.filter(item => item?.id === props.originalId)
      // console.log('matchedExisting: ', matchedExisting)
      if (matchedExisting?.length>0) {
        // console.log('RUNNING REMOVE EQUIPMENT FROM ADDED EQUIPMENT')
        // add this item to the labor to delete when submitted
        // setRemovedEquipment(prevState => [...prevState, props.Id])
        setRemovedEquipment(prevState => [...prevState, props.originalId])

        // now remove it from the incoming, existing equipment, we can add it back if we want to
        // let tmpArr = existingEquipment?.filter(item => item?.Id !== props.Id)
        let tmpArr = existingEquipment?.filter(item => item?.Id !== props.originalId)
        setExistingEquipment(tmpArr)
      }

      // remove it form the addedEquipment
      // const isInAdded = addedEquipment?.includes(props.Id)
      const isInAdded = addedEquipment?.includes(props.originalId)
      if (isInAdded) {
        // let tmpAddedEquipment = addedEquipment?.filter(item => item !== props.Id)
        let tmpAddedEquipment = addedEquipment?.filter(item => item !== props.originalId)
        // console.log('tmpAddedEquipment: ', tmpAddedEquipment)
        setAddedEquipment(tmpAddedEquipment)
      }

      // and possibly remove it from updated, which would be a modified, existing record that is now being deleted
      // let isInUpdated = updatedEquipment?.includes(props.Id)
      let isInUpdated = updatedEquipment?.includes(props.originalId)
      if (isInUpdated) {
        // let tmpUpdatedEquipment = updatedEquipment?.filter(item => item !== props.Id)
        let tmpUpdatedEquipment = updatedEquipment?.filter(item => item !== props.originalId)
        console.log('UPDATING UPDATED EQUIPMENT HERE 1: ', props)
        setUpdatedEquipment(tmpUpdatedEquipment)
      }

      // now we need to handle when an aux unit was added from the unit aux dropdown and the parent unit was deleted


      // -- previous code

      // now check for added aux units to throw off result

      // const rowTotal = (isCurrency(props.RowTotal)) ? convertCurrencyToDouble(props.RowTotal) : props.RowTotal;
      // const summaryTotal = (isCurrency(unitSummaryTotal)) ? convertCurrencyToDouble(unitSummaryTotal) : unitSummaryTotal;
    
      // update the unit summary grand total

      // remove the auxUnit array item so when the items move up, we keep aux unit state
      // this is what was causing the previous problems with these aux options getting out of order
      setAuxillaryUnitOptions(auxillaryUnitOptions.filter((item, itemIndex) => itemIndex !== index))

      // WE NEED TO REMOVE THIS UNITS ADDED AUX CHILDREN FIRST
      // foreach item in unitSummary, check if parentPrimaryIndex = index and delete as well
      let tmpUnitSummaryArr = unitSummary
      // let tmpUnitLoopArr = unitFields

      // REALLY NEED TO CHECK IF THIS ISN'T ALREADY A CHILD WE ARE ONE OFF DELETING
      let toRemoveItems = []
      let selectedDriversToRemove = []
      let selectedOperatorsToRemove = []
      // tjhis will remove all of the attached aux children of the parent and their corresponding drivers/operators placeholders
      unitFields.forEach((unit, unitIndex) => {
        // console.log('THIS IS THE UNIT WE ARE DEALING WITH: ', unit)

        let workingId = (props.originalId) ? props.originalId : props.Id
        // if (unit?.parentPrimaryIndex===index) {
          // if (unit?.auxChildOfParentId===props.Id) {
          if (unit?.auxChildOfParentId===workingId) {

          // console.info('THESE TWO SHOULD BE THE SAME: ', unit?.auxChildOfParentId)
          // console.info('THESE TWO SHOULD BE THE SAME: ', props.Id)
          // console.info('THESE TWO SHOULD BE THE SAME: ', workingId)
          // console.info('THESE TWO SHOULD BE THE SAME UNIT: ', unit)
          // console.info('THESE TWO SHOULD BE THE SAME PROPS: ', props)

          // console.info('removing from existingOperators  this data: ', selectedOperators[unitIndex])
          // console.info('removing from existingDrivers this data: ', selectedDrivers[unitIndex])
          // console.info('AT OVERALL UNIT FIELDS INDEX: ', unitIndex)
          // console.info('THESE PROPS SHOULD NOT BE A PRIMARY, ONLY AUX: ', props)

          // DELETE THE ROW FROM slectedOperators and selectedDrivers
          // let existingDrivers = selectedDrivers
          // existingDrivers.splice(unitIndex, 1)
          // setSelectedDrivers(existingDrivers)
          // set the overall index of the unitfield to remove
          selectedDriversToRemove.push(unitIndex)
          

          // and remove the status
          let currentSelectedUnitDriversStatus = unitDriversStatus
          currentSelectedUnitDriversStatus.splice(unitIndex, 1)
          setUnitDriversStatus(currentSelectedUnitDriversStatus)

          // let existingOperators = selectedOperators
          // existingOperators.splice(unitIndex,1)
          // setSelectedOperators(existingOperators)
          selectedOperatorsToRemove.push(unitIndex)

          // remove this status
          let currentSelectedUnitOperatorsStatus = unitOperatorsStatus
          currentSelectedUnitOperatorsStatus.splice(unitIndex, 1)
          setUnitOperatorsStatus(currentSelectedUnitOperatorsStatus)



          // console.log('WE FOUND A CHILD AUX UNIT THAT NEEDS TO BE REMOVED: ', unit)
            // we need to remove from existing
            // const matchedExisting = existingEquipment?.filter(item => item.Id === unit.Id)
            const matchedExisting = existingEquipment?.filter(item => item.Id === workingId)
            // console.log('matchedExisting: ', matchedExisting)
          if (matchedExisting?.length>0) {
            // console.log('RUNNING REMOVE EQUIPMENT FROM ADDED EQUIPMENT')
            // add this item to the labor to delete when submitted
            // setRemovedEquipment(prevState => [...prevState, unit.Id])
            setRemovedEquipment(prevState => [...prevState, workingId])

            // now remove it from the incoming, existing equipment, we can add it back if we want to
            // let tmpArr = existingEquipment?.filter(item => item?.Id !== unit.Id)
            let tmpArr = existingEquipment?.filter(item => item?.Id !== workingId)
            setExistingEquipment(tmpArr)
          }

          // remove it form the addedEquipment
          // const isInAdded = addedEquipment?.includes(unit.Id)
          const isInAdded = addedEquipment?.includes(unit.originalId)
          if (isInAdded) {
            // let tmpAddedEquipment = addedEquipment?.filter(item => item !== unit.Id)
            let tmpAddedEquipment = addedEquipment?.filter(item => item !== workingId)
            // console.log('tmpAddedEquipment: ', tmpAddedEquipment)
            setAddedEquipment(tmpAddedEquipment)
          }

          // and possibly remove it from updated, which would be a modified, existing record that is now being deleted
          // let isInUpdated = updatedEquipment?.includes(unit.Id)
          let isInUpdated = updatedEquipment?.includes(unit.originalId)
          if (isInUpdated) {
            // let tmpUpdatedEquipment = updatedEquipment?.filter(item => item !== unit.Id)
            let tmpUpdatedEquipment = updatedEquipment?.filter(item => item !== workingId)
            console.log('UPDATING UPDATED EQUIPMENT HERE 2: ', tmpUpdatedEquipment)
            setUpdatedEquipment(tmpUpdatedEquipment)
          }

          // console.log('IS THIS THE AUX UNIT TO GET DELETED?: ', unit)
          // this one gets removed
          tmpUnitSummaryArr.splice(unitIndex, 1)
          // console.log('REMOVING THIS ONE: ', unit)
          // unitRemove(unitIndex)
          toRemoveItems.push(unitIndex)
          // console.log('THIS IS NOW THE UNITFIELDS ARR: ', unitFields)
        } else {
          // console.log('NOT REMOVING THIS ONE: ', unit)
        }
      })

      // console.info('THESE SHOULD MATCH UP WITH THE INDEX OF THE ORIGINAL ARR')
      // console.log('selectedDriversToRemove?: ', selectedDriversToRemove)
      // console.log('selectedOperatorsToRemove?: ', selectedOperatorsToRemove)

      // now loop through the toRemoveItems backwards and start removing
      toRemoveItems?.reverse().forEach(item => {
        // console.log(' <<<<< this is the item index we are removing: ', item)
        // console.log(' <<<<< from unitFields: ', unitFields)
        unitRemove(item)

        // and the auxiliary options at that index
        let tmpAuxUnitOptionsArr = auxillaryUnitOptions
        tmpAuxUnitOptionsArr.splice(item, 1)
        setAuxillaryUnitOptions(tmpAuxUnitOptionsArr)
      })

      // now delete the parent
      tmpUnitSummaryArr.splice(index, 1)
      setUnitSummary(tmpUnitSummaryArr)

      // and the auxiliary options at that index
      let tmpAuxUnitOptionsArr = auxillaryUnitOptions
      tmpAuxUnitOptionsArr.splice(index, 1)
      setAuxillaryUnitOptions(tmpAuxUnitOptionsArr)

      // clear the special description field since it is no longer in the unitArray
      setValue(`unit${index}Description`, '')


      // we might have a mutated array that is no longer the same length
      // make sure this index is the correct index to remove
      // let tmpDriversArr = selectedDrivers
      // tmpDriversArr.splice(index, 1)
      // setSelectedDrivers(tmpDriversArr)
      selectedDriversToRemove.push(index)
      // console.info('removing from tmpDriversArr at index: ', index)
      // await delay(3000)

      // let tmpOperatorsArr = selectedOperators
      // tmpOperatorsArr.splice(index, 1)
      // setSelectedOperators(tmpOperatorsArr)
      selectedOperatorsToRemove.push(index)

      let existingDrivers = selectedDrivers
      selectedDriversToRemove.sort((a, b) => b-a)
      selectedDriversToRemove?.forEach(toRemoveIndex => {
        // console.log('REMOVING THIS DRIVER FROM THE ARRAY AT INDEX: ', toRemoveIndex)
        existingDrivers.splice(toRemoveIndex, 1)
      })
      setSelectedDrivers(existingDrivers)

      let existingOperators = selectedOperators
      selectedOperatorsToRemove.sort((a, b) => b-a)
      selectedOperatorsToRemove?.forEach(toRemoveIndex => {
        // console.log('REMOVING THIS OPERATOR FROM THE ARRAY AT INDEX: ', toRemoveIndex)
        existingOperators.splice(toRemoveIndex, 1)
      })
      setSelectedOperators(existingOperators)

      let currentSelectedUnitDriversStatus = unitDriversStatus
      currentSelectedUnitDriversStatus.splice(index, 1)
      setUnitDriversStatus(currentSelectedUnitDriversStatus)

      let currentSelectedUnitOperatorsStatus = unitOperatorsStatus
      currentSelectedUnitOperatorsStatus.splice(index, 1)
      setUnitOperatorsStatus(currentSelectedUnitOperatorsStatus)

      unitRemove(index)

    }

    if (action==='append-saved') {

      const updatedRowTotal = convertCurrencyToDouble(props.RowTotal)
      // update the prop in the object with the updated row total
      props.RowTotal = convertToCurrency(updatedRowTotal)

      if (props?.isPrimaryUnit===true) {
        previousParentId = currentId
        previousNewParentId = newEquipmentId
      }
      
      if (props?.isAuxillaryUnit===true) {
        // console.info('THIS IS AN AUX UNIT', props)
        // potential child of previous parent
        // if this auxChildOfParentId is the last primary units id
        // console.info('DOES IT MATCH A PREVIOUS PRIMARY UNIT: ', props.auxChildOfParentId)
        // console.info('DOES IT MATCH A PREVIOUS PRIMARY UNIT: ', previousParentId)
        if (props?.auxChildOfParentId===previousParentId) {
          
          // we found a child of a primary that we assigned a new id to
          props.auxChildOfParentId = previousNewParentId
        }
      }

      
      props.originalId = props.Id
      props.Id = newEquipmentId

      // console.info('NEW UNIT ID AFTER MUTATION: ', props.Id)
      // console.warn(' =====> MUTATED PROPS SHOULD BE NEW AUX PARENT ID IF CHILD: ', props)

      // if this unit has child aux units we have to update those as well to attach them to this unit
      // get this current unit record id      
      setUnitSummary(prevState => [...prevState, props])

      // if this is a primary, check to see if it is unavailable
      //
      if (props?.isPrimaryUnit) {

        // we need to see if this primary unit is in the available units
        let isUnitAvailable = checkUnitAvailability(props.UnitId)
        // console.warn(' ----- IS THIS UNIT AVAILABLE: ', isUnitAvailable)
        // console.warn(' ----- IS THIS isEstimate: ', isEstimate)
        // console.warn(' ----- unitsForDay: ', unitsForDay)
        // let isUnitAvailable = true

        // if it isn't available, let the user know
        // if (!isUnitAvailable && !isEstimate) {
        if (!isUnitAvailable) {
          
          // this unit doesn't exist in the available units for today
          // let UnitDescription = props?.UnitCode+' - '+props?.Description
          let UnitDescription = props?.UnitCode
          
          // add a function for appendDuplicatedUnitError
          let tmpArr = duplicatedUnitError
          // if this unit isn't already in the errorarray, add it
          const errorAlreadyExists = tmpArr.includes(UnitDescription)
          // console.log(duplicatedUnitError)
          if (!errorAlreadyExists && !isEstimate) {
            tmpArr.push(UnitDescription)
            setDuplicatedUnitError(tmpArr)
          }
          // currency
          const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
          props.RowTotal = updatedRowTotal  // set the props as currency for display

          // calculated summary total
          // const updatedUnitSummaryTotal = convertCurrencyToDouble(unitSummaryTotal) + convertCurrencyToDouble(updatedRowTotal)

          
          const auxUnits = await getAuxillaryUnitOptionsForUnit(props.UnitId)
          let tmpAuxArr = auxillaryUnitOptions
          // tmpAuxArr.splice(index, 0, auxUnits)
          tmpAuxArr.push(auxUnits)
          setAuxillaryUnitOptions(tmpAuxArr)

          unitInsert(index, props)
          // ----- ADDED FROM APPEND-UNAVAILABLE

        } else {
          // console.info('IN UPDATE SUMMARY AVAILABLE PRIMARY: ', props)
          // loop through buttons to toggle the button as checked for this unit 

          // now that we are allowing unavailable, we need to toggle the button for the unavailable unit too.

          // we also need to loop through for units that aren't in unitsForDay - unavailable Units
          // prepend unavailableUnits buttons array with these incoming sued unavailable?

          for (const unit of unitsForDay.entries()) {
            // console.warn(' ----- unit: ', unit)
            if (unit[1].unitId === props.UnitId) {
              

              //updateUnitSummaryTotal(updatedRowTotal)
              
              props.OriginalIndex = unit[0]
              props.UnitCategory = 'available'
              
              toggleUnitButtonState(unit[0], 'on')

              // add the auxOptions
              // this is where we are attaching aux units to a primary unit if it has any
              const auxUnits = await getAuxillaryUnitOptionsForUnit(props.UnitId)
              let tmpArr = auxillaryUnitOptions
              tmpArr.splice(index, 0, auxUnits)
              setAuxillaryUnitOptions(tmpArr)
              // setAuxillaryUnitOptions(prevState => [...prevState, auxUnits])

              // we are loading previous units in a specific order
              // unitInsert(0, props)
              // unitAppend(props)
              // this is an available primary unit
              props.orderedIndex=index
              // console.warn('SHOULD BE INSERTING THIS PRIMARY AVAILABLE UNIT AT UNITFIELDS INDEX: ', index)
              unitInsert(index, props)
  
              break
            }
          }
        }
      } else {
        // console.info('IN UPDATE SUMMARY NON-PRIMARY: ', props)
        // this isn't a primary unit but we are adding it to the unitFileds so we need to 
        // insert a null placeholder in aux unit options so we can keep everything in order
        let tmpArr = auxillaryUnitOptions
        tmpArr.splice(index, 0, null)
        setAuxillaryUnitOptions(tmpArr)
        // setAuxillaryUnitOptions(prevState => [...prevState, null])
        
        // this isn't a primary unit. go ahead and add it.
        // console.log('ADDING NON PRIMARY UNIT: ', props)
// updateUnitSummaryTotal(updatedRowTotal)
        // unitInsert(0, props)
        // unitAppend(props)
        props.orderedIndex=index
        // console.warn('SHOULD BE INSERTING THIS NON-PRIMARY UNIT AT UNITFIELDS INDEX: ', index)
        unitInsert(index, props)
      }      
    }
    setIsUnitsDisabled(false)
    return index
  }

  // const checkUnitAvailability = (unitId) => {
  //   console.info('CHECKING UNIT AVAILABILITY FOR UNIT ID: ', unitId)
  //   console.info('unitsForDay: ', unitsForDay)
  //   unitsForDay?.some(([, unit]) => unit.unitId === unitId) ?? false;
  // }
  const checkUnitAvailability = (unitId) => {
    console.info('CHECKING UNIT AVAILABILITY FOR UNIT ID: ', unitId);
    console.info('unitsForDay: ', unitsForDay);

    return unitsForDay?.some(unit => unit.unitId === unitId) ?? false;
};

  // const checkLaborAvailability = (laborId) => 
  //   laborForDay?.some(([, labor]) => labor?.teamMember?.teamMember?.id === laborId) ?? false;
  
  const calculateCurrentUnitRowTotal = () => {
    const grandTotal = unitFields.reduce((acc, unit) => (acc += convertCurrencyToDouble(unit.RowTotal)), 0)
    return grandTotal
  }

  const handleCustomEquipmentChanged = async () => {
    // if one is selected
    // console.log('EXECUTING HANDLE EQUIPMENT CHANGE WITH EVENT: ', event)
    // setMaxAuxillaryOptionsSelected(true)
  }
  
  const calculateCurrentMaterialRowTotal = async () => {
    
    console.log('materialFields: ', materialFields)
    const grandTotal = materialFields.reduce((accumulator, material) => accumulator += convertCurrencyToDouble(material.RowTotal), 0)
    return grandTotal.toFixed(2)
  }

  const updateCurrentMaterialRowTotal = () => {
    let materialGrandTotal = 0.00
    materialFields.forEach(item => {
      let materialRowTotal = convertCurrencyToDouble(item.RowTotal)
      materialGrandTotal += materialRowTotal
    })
    setMaterialSummaryTotal(materialGrandTotal)
    return materialGrandTotal
  }

  const updateMaterialSummary = async (action, props, index) => {
 
    // console.info(' ----- updateMaterialSummary ACTION: ', action)
    // console.info(' ----- updateMaterialSummary PROPS: ', props)

    const newMaterialId = uuidv4()

    // an "all labor" item was added to laborFields, update summary state
    if (action==='append-custom') {
      setAddedMaterial(prevState => [...prevState, newMaterialId])
      props.Id = newMaterialId

      // update the unitSummary array, add new object to end of labor summary array
      setMaterialSummary(prevState => [...prevState, props])

      // append the row to laborFields
      materialAppend(props)
    }

    // an item was added, update summary state
    if (action==='append') {
      setAddedMaterial(prevState => [...prevState, newMaterialId])
      props.Id = newMaterialId

      // row total
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display

      // update the materialSummary array, add new object to end of unit summary array
      setMaterialSummary(prevState => [...prevState, props])

      // console.log(' >>>>> SHOULD BE TOGGLING BUTTON STATE: ', index)
      // console.log(' >>>>> OF materialsForDay: ', materialsForDay)
      // update the button state
      // console.warn(' >>>>> RUNNING TOGGLE STATE')
      toggleMaterialsButtonState(index, true)

      // append the row
      materialAppend(props)
    }
    // update the summary state item values accordingly
    if (action==='update') {

      // console.log('existingMaterial ARR: ', existingMaterial)
      let matchedExisting = existingMaterial?.filter(item => item.id === props.Id)?.length
      // console.log('MATCHED EXISTING: ', matchedExisting)
      const isExisting = (matchedExisting>0) ? true : false
      console.log('IS EXISTING: ', isExisting)
      if (isExisting) {
        let filteredUpdatedMaterial = updatedMaterial?.filter(item => item === props.Id)

        if (filteredUpdatedMaterial?.length===0) {
          // console.log('UPDATING UPDATED MATERIAL HERE')
          setUpdatedMaterial(prevState => [...prevState, props.Id])
        }

        // and remove it from existing equipment
        // this is wrong, either id or Id
        let unmatchedExisting = existingMaterial?.filter(item => (item?.id !== props.id && item?.id !== props.Id))
        setExistingMaterial(unmatchedExisting)
      }

      // row total
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display
      setValue(`material[${index}].RowTotal`, updatedRowTotal)
      setValue(`material${index}Description`, props.Description)

      // // update the running total
      const materialGrandTotal = await calculateCurrentMaterialRowTotal()
      // console.info('materialGrandTotal: ', Number(materialGrandTotal))
      
      setMaterialSummaryTotal(Number(materialGrandTotal))
      // setMaterialSummaryTotal(479.9)
      
      let tmpMaterialSummary = materialSummary
      tmpMaterialSummary.splice(index, 1, props)
      setMaterialSummary(tmpMaterialSummary)

    }

    // remove this item from the summary state
    if (action==='remove') {

      let matchedExisting = existingMaterial?.filter(item => item?.id === props.Id)
      console.log('matchedExisting: ', matchedExisting)
      if (matchedExisting?.length>0) {
        console.log('RUNNING REMOVE MATERIAL FROM ADDED MATERIAL')
        // add this item to the labor to delete when submitted
        setRemovedMaterial(prevState => [...prevState, props.Id])

        // now remove it from the incoming, existing equipment, we can add it back if we want to
        let tmpArr = existingMaterial?.filter(item => item?.Id !== props.Id)
        setExistingMaterial(tmpArr)
      }

      // remove it form the addedMaterial
      const isInAdded = addedMaterial?.includes(props.Id)
      if (isInAdded) {
        let tmpAddedMaterial = addedMaterial?.filter(item => item !== props.Id)
        console.log('tmpAddedMaterial: ', tmpAddedMaterial)
        setAddedMaterial(tmpAddedMaterial)
      }

      // and possibly remove it from updated, which would be a modified, existing record that is now being deleted
      let isInUpdated = updatedMaterial?.includes(props.Id)
      if (isInUpdated) {
        let tmpUpdatedMaterial = updatedMaterial?.filter(item => item !== props.Id)
        console.log('UPDATING UPDATED MATERIAL HERE 1: ', tmpUpdatedMaterial)
        setUpdatedMaterial(tmpUpdatedMaterial)
      }

      const rowTotal = (isCurrency(props.RowTotal)) ? convertCurrencyToDouble(props.RowTotal) : props.RowTotal;
      
      const summaryTotal = (isCurrency(materialSummaryTotal)) ? convertCurrencyToDouble(materialSummaryTotal) : materialSummaryTotal;

      // update the summary grand total
      setMaterialSummaryTotal(summaryTotal - rowTotal)

      // toggle the button state
      // console.log('DELETED PROPS: ', props)
      const description = props.Description
      materialsForDay?.forEach((item, index) => {
        if (item.description === description) {
          // console.warn(' <<<<< RUNNING TOGGLE MATERIAL BUTTON STATE')
          toggleMaterialsButtonState(index, true)
        }
      })

      // remove unit row
      materialRemove(index)
    }

    if (action==='append-saved') {

      // check the isDefault value and toggle the button that matches this item
      if (props.isDefaultMaterial) {
        const description = props.Description
        // get the button that matches this item
        materialsForDay?.forEach((item, index) => {
          if (item.description === description) {
            // console.warn(' <<<<< RUNNING TOGGLE MATERIAL BUTTON STATE')
            toggleMaterialsButtonState(index, true)
          }
        })
      }

      // row total
      const updatedRowTotal = (isCurrency(props.RowTotal)) ? props.RowTotal : convertToCurrency(props.RowTotal)
      props.RowTotal = updatedRowTotal  // set the props as currency for display

      // update the materialSummary array, add new object to end of unit summary array
      setMaterialSummary(prevState => [...prevState, props])

      // update the button state
      // toggleMaterialsButtonState(index, true)

      // append the row
      materialAppend(props)
    }
  }

  const addCustomContact = async (props) => {
    const newContactId = await addClientContact(props)
    return newContactId
  }

  const addCustomClient = async (props) => {
    // if (props.isNewClient!==true) return null
    // isNewClient
    // divisionId
    // contactCompany
    // contactName
    // contactEmail
    // clientBillingEmail
    // contactPhone
    // isActive
    const clientId = await addClient(props)
    return clientId

  }

  const formSubmit = async (values, event) => {

    if (event.key === 'Enter') {
      event.preventDefault();
      return
    }
    
    // console.log(' >>> EVENT: ', event.key)
    // console.log(' >>> EVENT: ', event.target.name)
    console.log(' >>> VALUES: ', values)
  
    // console.info(' updatedLabor: ', updatedLabor)

    // refactor most of these down to async functions and put 
    // in try/catch with a backout and messgae that something failed.

    // console.log('hasConfirmedFormSubmission: ', hasConfirmedFormSubmission)
    // if (!hasConfirmedFormSubmission) {
    //   // formConfirmation(values, event)
    //   setFormValues(values)
    //   setFormEvent(event)
    //   onConfirmSubmitAlertOpen()
    //   return
    // }

    // showLaborHours doShowHours

    event.preventDefault();
    onSubmitModalOpen();

    // if the client contact phone or email has been changed, update the client contact record
    if (clientContactInfoChanged===true && clientContactId!==null) {
      // console.info('UPDATING CLIENT INFO: ', clientContactInfoChanged)
      // console.info('UPDATING CLIENT INFO: ', clientContactId)
      const updatedEmail = values.contactEmail
      const updatedPhone = values.contactPhone
      // console.info('UPDATING CLIENT INFO: ', updatedPhone)
      // console.info('UPDATING CLIENT INFO: ', updatedEmail)

      const updatedClientProps = {
        id: clientContactId, 
        email: updatedEmail, 
        emailVerified: false,
        phone: updatedPhone
      }
      await updateClientContact(updatedClientProps)
      // console.info('updatedClientContact: ', updatedClientContact)
    }
    
    // if the client is new, create the new client and return the id
    // then add the new client contact to that client
    if (values.isClientNew===true) {
      const client = {
        // isNewClient: values.isClientNew,
        divisionId: currentDivisionId,
        contactCompany: values.selectCompany.label,
        contactName: values.selectContact.label,
        contactEmail: values.contactEmail,
        clientBillingEmail: values.contactEmail,
        contactPhone: values.contactPhone,
        isActive: true,
        staleAfterDate: todaysDatePlusNDaysYMD(365, '-'),
      }
      const newClientId = await addCustomClient(client)
      values.selectCompany.value = newClientId
    }
  
    // let submissionHasErrors = false;
    updateSubmittingModal(null, 0)
    updateSubmittingModal("Prefetching data...", 10);

    // Process contact data
    updateSubmittingModal("Processing contact data...", 20)
    const djtClientContactId = await processContactData(values);
    
    // Prepare djtFormData
    const djtFormData = await prepareDjtFormData(values, djtClientContactId);
    
    // handle for if this is a selected client po or just a new po #
    let clientPO = values?.clientPO
    // console.log(' >>>>> STARTING WITH PO VALUE OF: ', clientPO)
    // if it's an object but the label===value, treat as typed because they typed in a new value
    if (clientPO && clientPO?.label?.length>0 && clientPO?.value === clientPO?.label) {
      // console.log(' >>>>> SETTINGH SELECTED CLIENT PO TO clientPO.label: ', clientPO)
      clientPO = clientPO.label
    }
    if (
      typeof clientPO === 'object' &&
      !Array.isArray(clientPO) &&
      clientPO !== null
    ) {
      // console.log(' >>>>> PO IS NOW : ', clientPO)
        // we have a selected po
        // console.log(' >>>> WE HAVE A SELECTED PO: ', clientPO)
        // console.log(' >>>> WITH AN ID OF: ', clientPO.value)
        // probably values.djtPOid = clientPO.value
      djtFormData.djtPOid = clientPO.value

    } else {
      // we don't have a selected po, we might have a new typed po
      // console.log(' >>>>> WE HAVE A TYPED OR EMPTY PO: ', clientPO)
      if (clientPO?.length>0) {
        // console.log(' >>>>> WE HAVE A NON-EMPTY PO: ', clientPO)
        // we need to add this po to the po tbl, get the id, then set the djtFormData.djtPOid to this value
        // {divisionId: "", djtClientId: "", isActive: false, isClosed: false, po: "", poDivisionClient: ""}
        const poClientId = values.selectCompany.value
        // console.log(' >>>>> WE HAVE A CLIENT ID: ', poClientId)
        const newPoParams = {
          divisionId: currentDivisionId,
          djtClientId: poClientId,
          isActive: true,
          isClosed: false,
          po: clientPO,
          poDivisionClient: `${currentDivisionId}#${poClientId}`,
        }
        // console.log(' >>>>> SENDING PARAMS: ', newPoParams)
        const newPOId = await addClientPO(newPoParams)
        console.log(' >>>>> GOT NEW PO ID: ', newPOId)
        djtFormData.djtPOid = newPOId
      } else {
        // it's empty, we don't really need to do anything except maybe remove it?
        // values.djtPOid = ''
        // console.log(' >>>>> WELL, WE GOT HERE FOR THE PO: ', clientPO)
        djtFormData.djtPOid = ''
      }
    }
    
    // Save general djt info - move this up to the processing function to generate the object
    updateSubmittingModal("Adding general info...", 30)
    // we want the parent id to be the top-level parent
    // console.log(' <<<< IF WE DONT HAVE PARENT ID SOMETHING IS BROKEN ABOVE: ', parentId)
    // if the parentId is blank, this is the parent, set the srcId

    // console.info('parentId: ', parentId)
    // console.info('srcId: ', srcId)
    if (parentId) {
      // console.info('WE HAVE A PARENT ID')
      djtFormData.parentId = parentId
    } else {
      // console.info('WE DONT HAVE A PARENT ID')
      djtFormData.parentId = srcId
    }
    // djtFormData.parentId = parentId||srcId
    djtFormData.isDuplicate = isDuplicate
    if (isDuplicate) {
      djtFormData.duplicateOfId = duplicateOfId
    }
    djtFormData.djtDivisionTeamMember = `${currentDivisionId}#${currentTeamMemberId}`
    djtFormData.djtDivisionTeamMemberClient = `${currentDivisionId}#${currentTeamMemberId}#${values.selectCompany.value}`
    djtFormData.djtDivisionClient = `${currentDivisionId}#${values?.selectCompany?.value}`
    if (attachedContractId) {
      djtFormData.attachedContractId = attachedContractId
    }
    djtFormData.verifiedComplete = values?.isVerifiedReady
    djtFormData.verifiedById = values.verifiedById
    djtFormData.mergeMaterialEquipment = values.mergeMaterialEquipment||false
    djtFormData.doNotBill = values.doNotBill
    djtFormData.doNotBillDescription = values.doNotBillDescription||''
    djtFormData.showLaborHours = values.doShowHours||false
    
    const submissionType = event.target.name
    let statusId = ''
    // sending straight to invoicing
    if (submissionType === 'submit-invoicing') {
      if (values?.doNotBill === true || values?.doNotBill === "true") {
        statusId = await getStatusIdByStatusCode('uninvoiced-donotbill')
        // console.warn('statusId = uninvoiced-donotbill')
      } else {
        statusId = await getStatusIdByStatusCode('queued-pending-approval')
        // console.warn('statusId = queued-pending-approval')
      }
    }      
    // saving for collaboration
    if (submissionType === 'submit-save') {
      statusId = await getStatusIdByStatusCode('queued-pending-submission')
    }

    if (submissionType === 'submit-estimate') {
      statusId = await getStatusIdByStatusCode('created-as-estimate')
    }

    // console.info('SETTING statusId to for invoice 7fd6f20e-9698-4646-ac68-beebab1d0d5b: ', statusId)

    djtFormData.currentStatusId = statusId
    djtFormData.djtDivisionStatus = `${currentDivisionId}#${statusId}`
    djtFormData.djtDivisionTeamMemberStatus = `${currentDivisionId}#${currentTeamMemberId}#${statusId}`
    djtFormData.djtDivisionTeamMemberClientStatus = `${currentDivisionId}#${currentTeamMemberId}#${values.selectCompany.value}#${statusId}`
    djtFormData.djtDivisionClientStatus = `${currentDivisionId}#${values.selectCompany.value}#${statusId}`
    if (values?.responseFromSupervisor) {
      djtFormData.bouncedSupervisorRespone = values?.responseFromSupervisor
    }

    // djtFormData.isParent is only a parent if there are duplicates of this djt
    // this gets updated later when we save a duplicated djt
    // so set this as false by default
    // djtFormData.isParent = false
    // console.log(' ------ isParent: ', isParent)
    if (isParent && (isParent===true || isParent===false)) {
      djtFormData.isParent = isParent
    } else {
      djtFormData.isParent = true
    }
    // djtFormData.isParent = !isChild
    
    // update parent djt isParent to true
    // we no longer have to do this because now all djts are parents unless otherwise defined
    await updateParentDJTAsParent(duplicateOfId, isDuplicate)

    // console.log('djtFormData: ', djtFormData)
    // return 

    //{djtId: "", createdDateTime: "", createdBy: "", statusId: ""}
    const journalProps = {
      createdDateTime: awsDateTimeNow(),
      createdBy: currentTeamMemberId,
      statusId: statusId,
    }
    // console.warn('djtFormData 1: ', djtFormData)
    // THIS SHOULD BE UPDATING THE JOB TICKET, NOT CREATING A NEW ONE
    let dailyJobTicketId = ''
    if (submissionType === 'submit-invoicing') {
      
      if (isNew || isDuplicate) {
        dailyJobTicketId = await addDailyJobTicket(djtFormData);
        journalProps.djtId = dailyJobTicketId
        // console.log(' >>>>> WE ARE NEW OR DUPLICATE SO WE ARE ADDING A NEW DJT: ', djtFormData)
        await addDJTJournal(journalProps)
      } else {
        dailyJobTicketId = djtId
        djtFormData.id = djtId
        journalProps.djtId = djtId
        await updateDailyJobTicket(djtFormData)
        await addDJTJournal(journalProps)
      }

    }

    // saving for collaboration
    if (submissionType === 'submit-save' || submissionType === 'submit-estimate') {
      // console.warn('SUBMISSION TYPE: ', submissionType)
      // console.warn('isNew: ', isNew)
      // console.warn('isDuplicate: ', isDuplicate)
      
      // save
      if (isNew || isDuplicate) {
        dailyJobTicketId = await addDailyJobTicket(djtFormData);
        journalProps.djtId = dailyJobTicketId
        // console.log(' >>>>> WE ARE NEW OR DUPLICATE SO WE ARE ADDING A NEW DJT: ', djtFormData)
        await addDJTJournal(journalProps)
      } else {
        dailyJobTicketId = djtId
        // console.log(' >>>>> WE ARE EDIT SO WE ARE UPDATING AN EXISTING DJT')
        await updateDailyJobTicket({
          id: djtId, 
          djtDate: values.djtDate,
          djtNotes: values.djtNotes, 
          djtLocation: values.contactLocation,
          djtPOid: djtFormData.djtPOid,
          mergeMaterialEquipment: djtFormData?.mergeMaterialEquipment||false,
          doNotBill: djtFormData?.djtDoNotBill||false,
          doNotBillDescription: djtFormData.doNotBillDescription||'',
          // showLaborHours: djtFormData?.showLaborHours,
          showLaborHours: values.doShowHours||false,
          djtEquipmentTotal: djtFormData?.djtEquipmentTotal,
          djtMaterialTotal: djtFormData?.djtMaterialTotal,
          djtLaborTotal: djtFormData?.djtLaborTotal,
          djtTotalBilled: djtFormData?.djtTotalBilled,
        })
        journalProps.djtId = dailyJobTicketId
        await addDJTJournal(journalProps)

        // get the djts with this djt id as the parentId
        const djtChildren = await fetchDJTsByParentId(djtId)

        // console.warn(' ===== djtChildren: ', djtChildren)

        for (const child of djtChildren) {
          // get the djt id
          const id = child.id
          // create the update object
          const params = { id: id, djtPOid: djtFormData.djtPOid }
          // update the po id of the child djt
          await updateDailyJobTicket(params)
          // console.error(' >>>>> UPDATING PO ON CHILDREN result: ', result)
        }
      }
      
    }


    // THESE NOW NEED TO BE HANDLED BASED ON IF THIS IS AN EDIT OR NEW/DUPLCIATE
    // IF THESE ARE AN EDIT WE NEED TO UPDATE THESE RECORDS
    // Process labor data
    // if isDuplicate or isNew are either true, then send to process as a new ticket and just save everything.
    const isNewOrDuplicate = (isNew===true || isDuplicate===true) ? true : false
    updateSubmittingModal("Adding labor data...", 40)
    await processLaborData(values?.labor, values?.djtDate, dailyJobTicketId, statusId, isNewOrDuplicate);
    
    // Process equipment, drivers and operators data
    updateSubmittingModal("Adding equipment data...", 50)
    await processEquipmentData(values?.unit, values?.djtDate, dailyJobTicketId, statusId, selectedOperators, selectedDrivers, isNewOrDuplicate);
    
    // Process materials data
    updateSubmittingModal("Adding materials data...", 80)
    // handle when updated or added new
    await processMaterialsData(values?.material, dailyJobTicketId, statusId, isNewOrDuplicate);
    
    // Process signatures data
    updateSubmittingModal("Processing signatures data...", 85)
    // await processSignaturesData(values, dailyJobTicketId);

    //{djtId: "", isSettled: false, reasonId: ""}
    // if switch is off, skip this section
    // Process doNotBill data
    // updateSubmittingModal("Processing do not bill data...", 90)
    // await processDoNotBillData(values, dailyJobTicketId, currentDivisionId);
    
    updateSubmittingModal("Form submitted...", 100);
    setSubmitModalCloseDisabled(false);
    
    return;
  };

  const processContactData = async (values) => {
    // console.log('   -------    Contact Data: ', values)
    let djtClientContactId = null;
    const isNewClientContact = values.isClientNew||values?.selectContact?.value === 0;
  
    if (isNewClientContact) {
      const newContact = {
        clientId: values?.selectCompany?.value,
        name: values?.selectContact?.label,
        email: values?.contactEmail||null,
        emailVerified: false,
        phone: values?.contactPhone||null,
        isActive: true,
      };
  
      djtClientContactId = await addCustomContact(newContact);
    } else {
      djtClientContactId = values?.selectContact?.value;
    }
  
    return djtClientContactId;
  };

  const prepareDjtFormData = async (values, djtClientContactId) => {
    const djtFormData = {
      createDateTime: awsDateTimeNow(),
      djtClientId: values?.selectCompany?.value,
      djtClientContactId: djtClientContactId,
      djtDate: values?.djtDate,
      djtDivisionId: currentDivisionId,
      // djtDivisionClient: `${currentDivisionId}#${values?.selectCompany?.value}`,
      djtEquipmentTotal: `${unitSummaryTotal}`,
      djtLaborTotal: `${laborSummaryTotal}`,
      djtLocation: values.contactLocation,
      djtMaterialTotal: `${materialSummaryTotal}`,
      // djtNonContractPO: values?.djtNonContractPO,
      djtNotes: values?.djtNotes,
      djtRequiresClientAuth: false,
      djtTotalBilled: `${djtRunningTotal}`,
      doNotBill: values?.doNotBill||false,
      doNotBillDescription: values?.doNotBillDescription||'',
      isActive: true,
      isAuthorized: false,
      isClosed: false,      
      isEstimate: false,
      isExported: false,
      isPaid: false,
      isSubmitted: false,
      isSubmitValid: false,
      lastStep: "is working",
      showLaborHours: values?.doShowHours||false, 
      specialInstructions: values?.specialInstructions||'',
      teamMemberId: currentTeamMemberId,
      // djtSupervisorAuthName: '',
    };
    return djtFormData;
  };

  const processLaborData = async (formLabor, djtDate, dailyJobTicketId, statusId, isNewTicket) => {
    
    const hasDeletedLabor = (removedLabor?.length>0) ? true : false
    const hasAddedLabor = (addedLabor?.length>0) ? true : false
    const hasUpdatedLabor = (updatedLabor?.length>0) ? true : false
    const hasFormLabor = (formLabor.length>0) ? true : false

    console.log(' _____ FORM LABOR: ', formLabor)
    console.log(' _____ REMOVED LABOR: ', removedLabor)
    console.log(' _____ ADDED LABOR: ', addedLabor)
    console.log(' _____ UPDATED LABOR: ', updatedLabor)
    console.log(' _____ isNewTicket: ', isNewTicket)
    console.log('dailyJobTicketId: ', dailyJobTicketId)

    if (isNewTicket===true) {
      // console.log('is a new ticket')
      // this labor is all new. verify that we have something to add and if so, add the labor records
      // if (hasFormLabor && hasAddedLabor) {
      if (hasFormLabor) {
        console.log('hasFormLabor')
        for (const laborItem of formLabor) {
        // handle for if this is temp labor
          // let tempEmployeeId = '0'
          // if (laborItem?.IsTempLabor) {
          //   tempEmployeeId = laborItem?.TeamMemberId
          //   const extendedDate = todaysDatePlusNDaysYMD(templEmployeeEndDateGracePeriod)
          //   const tempEmployee = {id: tempEmployeeId, extendedEndDate: extendedDate}
          //   await updateNoTimeCoTempEmployee(tempEmployee)
          // }

          console.log('----- LABORITEM: ', laborItem)
          let laborItemLunch = (laborItem?.Lunch?.value) ? `00:${laborItem?.Lunch?.value}` : laborItem?.Lunch||'00'
          // create the labor object for this labor item
          const djtLaborObj = {
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            teamMemberId: laborItem?.TeamMemberId,
            // tempEmployeeId: tempEmployeeId,
            // isTempLabor: laborItem?.IsTempLabor||false,
            jobClassId: laborItem?.JobClass.value,
            startTime: laborItem?.StartTime,
            finishTime: laborItem?.FinishTime,
            perDiemAmount: laborItem?.PerDiemAmount,
            // lunchDuration: laborItem?.Lunch||"00",
            // lunchDuration: laborItem?.Lunch?.value||"00",
            lunchDuration: laborItemLunch,
            departmentEmployee: laborItem?.DepartmentEmployee,
            // are these still being used?
            straightTime: "0:00",
            overTime: "0:00",
            statusId: statusId,
            totalPrice: laborItem?.RowTotal,
            customLaborName: laborItem?.CustomLaborName||'',
          };

          // add the labor
          console.log('ADDING THIS LABOR OBJ: ', djtLaborObj)
          await addDJTLabor(djtLaborObj);
          // if this is a team member we are adding, update their popularity
          ( !isEstimate && 
            laborItem.departmentEmployee && 
            laborItem.departmentEmployee!=="" && 
            !laborItem?.IsTempLabor
          ) && await updateTMPopularity(laborItem.departmentEmployee)
        }
      }
    } else {
      // ticket is editied, check for deleted labor, added labor and changed labor

      // first, check if we have any records to remove and remove them.
      if (hasDeletedLabor) {
        for (const removeId of removedLabor) {
          // This may need to have labor record status updated
          await updateDJTLaborUsed({ id: removeId, isActive: false})
          // await updateDJTLaborUsed({ id: removed, isActive: false, statusId: statusId})
          // and journaled?
        }
      }

      // handle for any existing labor that was updated
      // console.log(' ***** UPDATED EXISTING LABOR: ', updatedLabor)
      if (hasUpdatedLabor) {
        // if we have updated labor, loop through each one and update
        for (const updateId of updatedLabor) {
          // this is the id of the labor item we need to update
          // get the props from the formLabor
          // console.log('UPDATE THIS ID: ', updateId)
          const updatedLaborObj = formLabor?.filter(item => item.Id === updateId)[0]
          // console.log(' -----> updatedLaborObj: ', updatedLaborObj)
          // let tempEmployeeId = '0'
          // if (updatedLaborObj?.IsTempLabor) {
          //   tempEmployeeId = updatedLaborObj?.TeamMemberId
          //   const extendedDate = todaysDatePlusNDaysYMD(templEmployeeEndDateGracePeriod)
          //   const tempEmployee = {id: tempEmployeeId, extendedEndDate: extendedDate}
          //   await updateNoTimeCoTempEmployee(tempEmployee)
          // }

          let laborItemLunch = (updatedLaborObj?.Lunch?.value) ? `00:${updatedLaborObj?.Lunch?.value}` : updatedLaborObj?.Lunch||'00'
          // create the labor object for this labor item
          const djtLaborObj = {
            id: updateId,
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            teamMemberId: updatedLaborObj?.TeamMemberId||'0',
            // tempEmployeeId: tempEmployeeId||'0',
            // isTempLabor: updatedLaborObj?.IsTempLabor||false,
            jobClassId: updatedLaborObj?.JobClass.value,
            startTime: updatedLaborObj?.StartTime,
            finishTime: updatedLaborObj?.FinishTime,
            perDiemAmount: updatedLaborObj?.PerDiemAmount,
            // lunchDuration: updatedLaborObj?.Lunch||'00',
            // lunchDuration: updatedLaborObj?.Lunch?.value||'00',
            lunchDuration: laborItemLunch,
            departmentEmployee: updatedLaborObj?.DepartmentEmployee||'0',
            // are these still being used?
            straightTime: "0:00",
            overTime: "0:00",
            statusId: statusId,
            totalPrice: updatedLaborObj?.RowTotal,
            customLaborName: updatedLaborObj?.CustomLaborName||'',
          };
          // console.log('UPDATING THIS LABOR RECORD BECAUSE IT ALREADY EXISTS: ', djtLaborObj)
          await updateDJTLaborUsed(djtLaborObj)
        }
      }

      // console.log(' ***** ADDED LABOR: ', addedLabor)
      // console.log(' ***** EXISTING LABOR: ', existingLabor)
      if (hasAddedLabor) {
        // REMOVE EXISTING LABOR FROM ADDED LABOR, THEY ALREADY EXIST, THEY DON'T GET ADDED AGAIN
        // for each item in addedLabor, if it matches an existingLabor item, remove it
        let tmpAddedLabor = []

        addedLabor.forEach(addedItem => {
          // console.log('ADDED ITEM ID: ', addedItem)
          // check if this added labor exists in the existedLabor arr
          const laborAlreadyExisted = existingLabor.filter(existingItem => existingItem.id === addedItem)
          // console.log('DOES IT EXIST?: ', laborAlreadyExisted)
          if (laborAlreadyExisted?.length===0) {
            // console.log(' ***** FOUND AN ITEM NOT PREVIOUSLY EXISTING: ', addedItem)
            tmpAddedLabor.push(addedItem)
          }
        })
        console.log('addedLabor: ', addedLabor)

        // console.log(' ***** THIS IS WHAT IS LEFT OVER AND WHAT WE ARE GOING TO PROCESS NOW: ', tmpAddedLabor)
        // if we have new, added labor to this ticket, loop through each one
        console.log('tmpAddedLabor: ', tmpAddedLabor)
        for (const addedId of tmpAddedLabor) {
          
          // get the props for this labor item
          const addedLaborObj = formLabor?.filter(item => item.Id === addedId)[0]

          // console.log('ADDED LABOR OBJECT MATCH: ', addedLaborObj)


          // handle if this labor is by a temp labor
          // let tempEmployeeId = '0'
          // if (addedLaborObj?.IsTempLabor) {
          //   tempEmployeeId = addedLaborObj?.TeamMemberId
          //   const extendedDate = todaysDatePlusNDaysYMD(templEmployeeEndDateGracePeriod)
          //   const tempEmployee = {id: tempEmployeeId, extendedEndDate: extendedDate}
          //   await updateNoTimeCoTempEmployee(tempEmployee)
          // }

          // TECHNICALLY WE NEED TO REMOVE ADDED BECAISE THEY GET ADDED AT LOAD

          // console.log('ABOUT TO ADD THIS SUBMITTED LABOR OBJ: ', addedLaborObj)
          // console.log('ABOUT TO ADD THIS SUBMITTED LABOR OBJ JOB CLASS VALUE: ', addedLaborObj?.JobClass?.value||'MISSING THIS AND NEED THIS ID')
          // create the labor object
          let laborItemLunch = (addedLaborObj?.Lunch?.value) ? `00:${addedLaborObj?.Lunch?.value}` : addedLaborObj?.Lunch||'00'
          const laborObj = {
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            teamMemberId: addedLaborObj?.TeamMemberId,
            // tempEmployeeId: tempEmployeeId,
            // isTempLabor: addedLaborObj?.IsTempLabor||false,
            jobClassId: addedLaborObj?.JobClass.value,
            startTime: addedLaborObj?.StartTime,
            finishTime: addedLaborObj?.FinishTime,
            perDiemAmount: addedLaborObj?.PerDiemAmount,
            // lunchDuration: addedLaborObj?.Lunch||"00",
            // lunchDuration: addedLaborObj?.Lunch?.value||"00",
            lunchDuration: laborItemLunch,
            departmentEmployee: addedLaborObj?.DepartmentEmployee,
            // are these still being used?
            straightTime: "0:00",
            overTime: "0:00",
            statusId: statusId,
            totalPrice: addedLaborObj?.RowTotal,
            customLaborName: addedLaborObj?.CustomLaborName||'',
          };
          console.log('ADDING THIS DJT LABOR: ', laborObj)
          // add this labor item
          await addDJTLabor(laborObj);
          // if this is a team member, update their popularity
          (
            !isEstimate && 
            addedLaborObj.departmentEmployee && 
            addedLaborObj.departmentEmployee!=="" && 
            !addedLaborObj?.IsTempLabor
          ) && await updateTMPopularity(addedLaborObj.departmentEmployee)
        }
      }
    }

  };

  const processEquipmentData = async (formEquipment, djtDate, dailyJobTicketId, statusId, selectedOperators, selectedDrivers, isNewTicket) => {

    const hasDeletedEquipment = (removedEquipment?.length>0) ? true : false
    const hasAddedEquipment = (addedEquipment?.length>0) ? true : false
    // const hasUpdatedEquipment = (updatedEquipment?.length>0) ? true : false
    const hasFormEquipment = (formEquipment.length>0) ? true : false
    // const hasDrivers = (selectedDrivers?.length>0) ? true : false
    // const hasOperators = (selectedOperators?.length>0) ? true : false

    // console.log(' _____ REMOVED EQUIPMENT: ', removedEquipment)
    // console.log(' _____ ADDED EQUIPMENT: ', addedEquipment)
    // console.log(' _____ UPDATED EQUIPMENT: ', updatedEquipment)
    // console.log(' _____ FORM EQUIPMENT: ', formEquipment)
    // console.log(' _____ DRIVERS: ', selectedDrivers)
    // console.log(' _____ OPERATORS: ', selectedOperators)

    if (isNewTicket) {
      // this ticket new, everything in it will be created. verify that we have something to add and if so, add the unit records
      // if (hasFormEquipment && hasAddedEquipment) {
      if (hasFormEquipment) {
          for (const [unitIndex, unitItem] of formEquipment.entries()) {

          const isPrimaryUnit = (unitItem?.isCustomEquipment===false && unitItem?.isAuxillaryUnit===false) ? true : false
          const billableDescription = (unitItem.Description.label) ? unitItem.Description.label : unitItem.Description;
          const doesRequireOperator = (unitItem?.requiresOperator===true || unitItem?.requiresOperator===false) ? unitItem?.requiresOperator : false
          const doesRequireDriver = (unitItem?.requiresDriver===true || unitItem?.requiresDriver===false) ? unitItem?.requiresDriver : false

          // create the unit object for this unit item
          const djtUnitObj = {
            id: unitItem?.Id,
            isActive: true,
            billableDescription: billableDescription,
            date: djtDate,
            djtId: dailyJobTicketId,
            isAuxillary: unitItem?.isAuxillaryUnit||false, 
            isCustom: unitItem?.isCustomEquipment||false, 
            isPrimary: isPrimaryUnit||false,
            statusId: statusId,
            totalBilled: unitItem?.RowTotal,
            unitCode: unitItem?.UnitCode,
            unitId: unitItem?.UnitId,
            requiresOperator: doesRequireOperator,
            requiresDriver: doesRequireDriver,
            isAuxChild: unitItem?.isAuxChild||false,
            auxChildOfParentId: unitItem?.auxChildOfParentId||null,
            quantity: unitItem?.Quantity||1,
          };

          // add the equipment
          // if (addedEquipment.includes(unitItem?.UnitId) || addedEquipment.includes(unitItem?.trackingId)) {
          // if (addedEquipment.includes(unitItem?.Id)) {

          console.log(' ----- ----- ADDING THIS DJT EQUIPMENT: ', djtUnitObj)
          const djtEquipmentId = await addDJTEquipment(djtUnitObj)
          const unitId = unitItem?.UnitId;
          await updateUnitPopularity(currentDivisionId, unitId)

          // console.log('ADDING DRIVERS & OPERATORS', unitIndex)
          // console.log('CHECK FOR LENGTH OF selectedOperators: ', selectedOperators)
          // console.log('CHECK FOR LENGTH OF selectedDrivers: ', selectedDrivers)
          await processUnitOperators(unitItem, djtEquipmentId, djtDate, dailyJobTicketId, selectedOperators, unitIndex);
          await processUnitDrivers(unitItem, djtEquipmentId, djtDate, dailyJobTicketId, selectedDrivers, unitIndex);
        }
      }
    } else {
      // this is not a new ticket
      // first, check if we have any existing records to remove and remove them.
      if (hasDeletedEquipment) {
        for (const removeId of removedEquipment) {
          await updateDJTEquipmentUsed({ id: removeId, isActive: false, statusId: statusId})
        }
      }

      if (updatedEquipment) {
        // really need to fix the duplicates in the first place.
        const uniqueUpdatedEquipment = new Set(updatedEquipment)
        // which units have at least one child
        const uniqueUpdatedEquipmentArr = [...uniqueUpdatedEquipment]
        console.log('uniqueUpdatedEquipmentArr: ', uniqueUpdatedEquipmentArr)
        // if we have updated labor, loop through each one and update
        //[index, updateId]
        // for (const [updateId] of uniqueUpdatedEquipmentArr.entries()) {
        for (const [index, updateId] of uniqueUpdatedEquipmentArr.entries()) {
          console.log('updateId: ', updateId);
          console.warn('formEquipment: ', formEquipment);

          // Find the updated equipment object
          const updatedEquipmentObj = formEquipment?.find(
            item => item.originalId === updateId || item.Id === updateId
          );

          if (!updatedEquipmentObj) {
            console.error(`No matching equipment found for updateId: ${updateId}`);
            continue; // Skip to the next iteration if no match is found
          }

          console.log('updatedEquipmentObj: ', updatedEquipmentObj);

          // Derive the equipmentId
          const equipmentId = updatedEquipmentObj.originalId || updatedEquipmentObj.Id;
          console.log('equipmentId: ', equipmentId);

          // Find the index of the updated unit
          const updatedUnitIndex = formEquipment?.findIndex(
            item => item.originalId === equipmentId || item.Id === equipmentId
          );

          if (updatedUnitIndex === -1) {
            console.error(`Could not find unit index for equipmentId: ${equipmentId}`);
            continue; // Skip to the next iteration if index is not found
          }

          console.info('THIS IS THE INDEX OF THE UNIT WE ARE ADDING DRIVERS TO: ', updatedUnitIndex);

          // Proceed with operations
          const currentUnitOperators = await fetchUnitOperatorsByEquipmentId(equipmentId);
          for (const operator of currentUnitOperators) {
            const removedOperatorId = await removeUnitOperator(operator.id);
            console.log('REMOVED THIS OPERATOR: ', removedOperatorId);
          }

          // Process unit operators
          await processUnitOperators(
            updatedEquipmentObj,
            equipmentId,
            djtDate,
            dailyJobTicketId,
            selectedOperators,
            updatedUnitIndex
          );
          // console.log('updateId: ',  updateId)
          // console.warn('formEquipment: ', formEquipment)
          // // this is the id of the unit item we need to update
          // // get the props from the formEquipment
          // // const updatedEquipmentObj = formEquipment?.filter(item => item.Id === updateId)[0]

          // const updatedEquipmentObj = formEquipment?.filter(item => item.originalId === updateId)[0]||formEquipment?.filter(item => item.Id === updateId)[0]
          // // const equipmentId = updatedEquipmentObj.Id
          // console.log('updatedEquipmentObj: ', updatedEquipmentObj)
          // const equipmentId = updatedEquipmentObj.originalId||updatedEquipmentObj.Id
          // console.log('equipmentId: ', equipmentId)
          // // const updatedUnitIndex = formEquipment?.findIndex(match => match.Id === equipmentId)
          // const updatedUnitIndex = formEquipment?.findIndex(match => match.originalId === equipmentId)||formEquipment?.findIndex(match => match.Id === equipmentId)
          // console.info('THIS IS THE INDEX OF THE UNIT WE ARE ADDING DRIVERS TO: ', updatedUnitIndex)
          // // THIS IS DUPLCIATED CODE, REFACTOR
          // const currentUnitOperators = await fetchUnitOperatorsByEquipmentId(equipmentId)
          // // for each of these unit operators, delete them before we re-add them
          // for (const operator of currentUnitOperators) {
          //   const removedOperatorId = await removeUnitOperator(operator.id)
          //   console.log('REMOVED THIS OPERATOR: ', removedOperatorId)
          // }
          // // now we need to know what the index if of the unit we are adding operators for is

          
          // // await processUnitOperators(updatedEquipmentObj, equipmentId, djtDate, dailyJobTicketId, selectedOperators, updateIndex);
          // await processUnitOperators(updatedEquipmentObj, equipmentId, djtDate, dailyJobTicketId, selectedOperators, updatedUnitIndex);
          
          const currentUnitDrivers = await fetchUnitDriversByEquipmentId(equipmentId)
          console.info('currentUnitDrivers: ', currentUnitDrivers)
          // remove the drivers
          for (const driver of currentUnitDrivers) {
            const removedDriverId = await removeUnitDriver(driver.id)
            console.log('REMOVED THIS DRIVER: ', removedDriverId)
          }
          // and add the drivers
          // console.info('SENDING THIS DATA TO UPDATE DRIVERS: ', )

          // now process the operators as if they were new.

          await processUnitDrivers(updatedEquipmentObj, equipmentId, djtDate, dailyJobTicketId, selectedDrivers, updatedUnitIndex);

          const isPrimaryUnit = (updatedEquipmentObj?.isCustomEquipment===false && updatedEquipmentObj?.isAuxillaryUnit===false) ? true : false
          const billableDescription = (updatedEquipmentObj.Description.label) ? updatedEquipmentObj.Description.label : updatedEquipmentObj.Description;
          
          // create the unit object for this unit item
          const djtUnitObj = {
            id: updateId,
            isActive: true,
            billableDescription: billableDescription,
            date: djtDate,
            djtId: dailyJobTicketId,
            isAuxillary: updatedEquipmentObj?.isAuxillaryUnit||false, 
            isCustom: updatedEquipmentObj?.isCustomEquipment||false, 
            isPrimary: isPrimaryUnit||false,
            statusId: statusId,
            totalBilled: updatedEquipmentObj?.RowTotal,
            unitCode: updatedEquipmentObj?.UnitCode,
            unitId: updatedEquipmentObj?.UnitId,
            requiresOperator: updatedEquipmentObj?.requiresOperator||false,
            requiresDriver: updatedEquipmentObj?.requiresDriver||false,
            isAuxChild: updatedEquipmentObj?.isAuxChild||false,
            auxChildOfParentId: updatedEquipmentObj?.auxChildOfParentId||null,
            quantity: updatedEquipmentObj?.Quantity||1,
          };
          console.log(' ----> UPDATING THIS UNIT RECORD BECAUSE IT ALREADY EXISTS: ', djtUnitObj)
          const result = await updateDJTEquipmentUsed(djtUnitObj)
          console.log('result: ', result)
        }
      }

      if (hasAddedEquipment) {
        // REMOVE EXISTING FROM ADDED, THEY ALREADY EXIST, THEY DON'T GET ADDED AGAIN
        // for each item in addedEquipment, if it matches an existingEquipment item, remove it
        let tmpAddedEquipment = []

        // THIS IS MATCHING ON AN EXISTING FIRE PUMP BECAUSE OF THE SAME UNIT ID
        // WE HAVE TO USE THE RECORD ID HERE INSTEAD BECAUSE OF POSSIBILITY OF MULTIPLE FIRE PUMPS (WHICH HAVE SAME UNIT ID)
        addedEquipment.forEach(addedItem => {
          // console.log('ADDED ITEM ID: ', addedItem)
          // check if this added equipment exists in the existedLabor arr
          const equipmentAlreadyExisted = existingEquipment.filter(existingItem => existingItem.id === addedItem)
          // console.log('DOES IT EXIST?: ', equipmentAlreadyExisted)
          if (equipmentAlreadyExisted?.length===0) {
            // console.log(' ***** FOUND AN ITEM NOT PREVIOUSLY EXISTING: ', addedItem)
            tmpAddedEquipment.push(addedItem)
          }
        })

        // if we have new, added equipment to this ticket, loop through each one
        for (const addedId of tmpAddedEquipment) {
          
          // this could be a primary unit added or an aux unit with a tracking id
          // get the props for this unit item
          const addedEquipmentObj = formEquipment?.filter(item => item.Id === addedId)[0]

          const addedUnitIndex = formEquipment?.findIndex(match => match.Id === addedEquipmentObj?.Id)

          // TECHNICALLY WE NEED TO REMOVE ADDED BECAUSE THEY GET ADDED AT LOAD

          const isPrimaryUnit = (addedEquipmentObj?.isCustomEquipment===false && addedEquipmentObj?.isAuxillaryUnit===false) ? true : false
          const billableDescription = (addedEquipmentObj.Description.label) ? addedEquipmentObj.Description.label : addedEquipmentObj.Description;

          // create the equipment object
          const djtUnitObj = {
            id: addedEquipmentObj?.Id,
            isActive: true,
            billableDescription: billableDescription,
            date: djtDate,
            djtId: dailyJobTicketId,
            isAuxillary: addedEquipmentObj?.isAuxillaryUnit||false, 
            isCustom: addedEquipmentObj?.isCustomEquipment||false, 
            isPrimary: isPrimaryUnit||false,
            statusId: statusId,
            totalBilled: addedEquipmentObj?.RowTotal,
            unitCode: addedEquipmentObj?.UnitCode,
            unitId: addedEquipmentObj?.UnitId,
            requiresOperator: addedEquipmentObj?.requiresOperator||false,
            requiresDriver: addedEquipmentObj?.requiresDriver||false,
            isAuxChild: addedEquipmentObj?.isAuxChild||false,
            auxChildOfParentId: addedEquipmentObj?.auxChildOfParentId||null,
            quantity: addedEquipmentObj?.Quantity||1,
          };

          // add this labor item
          console.log(' ___---___ ADDING DJT EQUIPMENRT: ', djtUnitObj)
          await addDJTEquipment(djtUnitObj);

          console.info('NOW RUNNING ADD OPS DRVS FOR ADDED UNIT')
          await processUnitOperators(djtUnitObj, addedEquipmentObj?.Id, djtDate, dailyJobTicketId, selectedOperators, addedUnitIndex);
          await processUnitDrivers(djtUnitObj, addedEquipmentObj?.Id, djtDate, dailyJobTicketId, selectedDrivers, addedUnitIndex);
          
        }
      }
    }
  };

  const processUnitOperators = async (unitItem, djtEquipmentId, djtJobDate, dailyJobTicketId, selectedOperators, unitIndex) => {
    updateSubmittingModal("Adding operator data...", 70)
    console.log('PROCESSING OPERATORS AT OPERATOR INDEX: ', unitIndex)
    const unitOperators = selectedOperators?.[unitIndex];
    console.log('PROCESSING UNIT OPERATORS: ', unitItem)
    if (unitOperators) {
      for (const operator of unitOperators) {
        const djtUnitOperator = {
          djtId: dailyJobTicketId,
          unitId: unitItem?.unitId||unitItem?.UnitId,
          djtEquipmentId: djtEquipmentId,
          date: djtJobDate,
          operatedById: operator.value,
        };
        
        await addDJTUnitOperator(djtUnitOperator);
      }
    }
  };
  
  const processUnitDrivers = async (unitItem, djtEquipmentId, djtJobDate, dailyJobTicketId, selectedDrivers, unitIndex) => {
    updateSubmittingModal("Adding driver data...", 60)
    console.log('PROCESSING DRIVERS AT DRIVER INDEX: ', unitIndex)
    // get the unit drivers for the unit at index
    const unitDrivers = selectedDrivers?.[unitIndex];

    console.log('PROCESSING UNIT DRIVERS: ', unitItem)
  
    if (unitDrivers) {
      for (const driver of unitDrivers) {
        const djtUnitDriver = {
          djtId: dailyJobTicketId,
          unitId: unitItem?.unitId||unitItem?.UnitId,
          djtEquipmentId: djtEquipmentId,
          date: djtJobDate,
          drivenById: driver.value,
        };

        console.info('SENDING THIS WHERE S UNTID: ', djtUnitDriver)
  
        await addDJTUnitDriver(djtUnitDriver);
      }
    }
  };
  
  const processMaterialsData = async (formMaterial, dailyJobTicketId, statusId, isNewTicket) => {
    const hasDeletedMaterial = (removedMaterial?.length>0) ? true : false
    const hasAddedMaterial = (addedMaterial?.length>0) ? true : false
    // const hasUpdatedMaterial = (updatedMaterial?.length>0) ? true : false
    const hasFormMaterial = (formMaterial.length>0) ? true : false

    console.log(' _____ REMOVED MATERIAL: ', removedMaterial)
    console.log(' _____ ADDED MATERIAL: ', addedMaterial)
    console.log(' _____ UPDATED MATERIAL: ', updatedMaterial)
    console.log(' _____ FORM MATERIAL: ', formMaterial)

    
    if (isNewTicket) {
      // if (hasFormMaterial && hasAddedMaterial) {
      if (hasFormMaterial) {
        for (const materialItem of formMaterial) {
        // for (const [materialItem] of formMaterial.entries()) {
          console.log('PROCESSING MATERIAL: ', materialItem)
          console.log('PROCESSING MATERIAL DESCRIPTION: ', materialItem?.Description)
          // const isPrimaryUnit = (materialItem?.isCustomEquipment===false && unitItem?.isAuxillaryUnit===false) ? true : false
          const djtMaterialObj = {
            // id: materialItem?.Id,
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            description: materialItem?.Description,
            price: materialItem?.Price,
            quantity: materialItem?.Quantity,
            totalPrice: materialItem?.RowTotal,
            isCustom: materialItem?.isCustomMaterial||false,
            isDefault: materialItem?.isDefaultMaterial||false,
            statusId: statusId,
          };

          // if (addedMaterial.includes(materialItem?.Id)) {
            console.log('WE HAVE AN ADDED MATERIAL: ',djtMaterialObj )
            const djtMaterialId = await addDJTMaterial(djtMaterialObj)
            console.log('djtMaterialId: ', djtMaterialId)
          // }
        }
      }
    } else {
      if (hasDeletedMaterial) {
        for (const removeId of removedMaterial) {
          await updateDJTMaterialUsed({ id: removeId, isActive: false, statusId: statusId})
        }
      }

      if (updatedMaterial) {
        const uniqueUpdatedMaterial = new Set(updatedMaterial)
        // which units have at least one child
        const uniqueUpdatedMaterialArr = [...uniqueUpdatedMaterial]
        for (const [updateId] of uniqueUpdatedMaterialArr.entries()) {

          const updatedMaterialObj = formMaterial?.filter(item => item.Id === updateId)[0]
          // const materialId = updatedMaterialObj.Id
          // const updatedMaterialIndex = formMaterial?.findIndex(match => match.Id === materialId)

          const djtMaterialObj = {
            id: updateId,
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            description: updatedMaterialObj?.Description,
            price: updatedMaterialObj?.Price,
            quantity: updatedMaterialObj?.Quantity,
            totalPrice: updatedMaterialObj?.RowTotal,
            isCustom: updatedMaterialObj?.isCustomMaterial||false,
            isDefault: updatedMaterialObj?.isDefaultMaterial||false,
            statusId: statusId,
          };
          const result = await updateDJTMaterialUsed(djtMaterialObj)
          console.log('result: ', result)

        }
      }

      if (hasAddedMaterial) {

        let tmpAddedMaterial = []

        // THIS IS MATCHING ON AN EXISTING FIRE PUMP BECAUSE OF THE SAME UNIT ID
        // WE HAVE TO USE THE RECORD ID HERE INSTEAD BECAUSE OF POSSIBILITY OF MULTIPLE FIRE PUMPS (WHICH HAVE SAME UNIT ID)
        addedMaterial.forEach(addedItem => {
          // console.log('ADDED ITEM ID: ', addedItem)
          // check if this added equipment exists in the existedLabor arr
          const materialAlreadyExisted = existingMaterial.filter(existingItem => existingItem.id === addedItem)
          // console.log('DOES IT EXIST?: ', equipmentAlreadyExisted)
          if (materialAlreadyExisted?.length===0) {
            // console.log(' ***** FOUND AN ITEM NOT PREVIOUSLY EXISTING: ', addedItem)
            tmpAddedMaterial.push(addedItem)
          }
        })

        // if we have new, added equipment to this ticket, loop through each one
        for (const addedId of tmpAddedMaterial) {
          
          // this could be a primary unit added or an aux unit with a tracking id
          // get the props for this unit item
          const addedMaterialObj = formMaterial?.filter(item => item.Id === addedId)[0]

          const djtMaterialObj = {
            id: addedMaterialObj?.Id,
            djtId: dailyJobTicketId,
            isActive: true,
            date: djtDate,
            description: addedMaterialObj?.Description,
            price: addedMaterialObj?.Price,
            quantity: addedMaterialObj?.Quantity,
            totalPrice: addedMaterialObj?.RowTotal,
            isCustom: addedMaterialObj?.isCustomMaterial||false,
            isDefault: addedMaterialObj?.isDefaultMaterial||false,
            statusId: statusId,
          };

          // add this labor item
          await addDJTMaterial(djtMaterialObj);
          
        }
      }

    }
  };
  
  const clearClientContactData = () => {
    setClientContactSelected(null);
    setValue('selectContact', null);
    setClientContactName(null);
    setValue('contactEmail', null);
    setClientContactEmailAddress(null)
    setValue('contactPhone', null);
  };
  
  const selectClientContactOptionAction = (event) => {
    const clientId = event?.value;
    setClientContactSelected({ label: event.label, value: event.value });
    setValue('selectContact', { label: event.label, value: event.value });
  
    // console.info('clientContacts: ', clientContacts)
    const matchedContact = clientContacts?.find((contact) => contact.id === clientId);
  
    console.info(' !!! matchedContact: ', matchedContact)
    if (matchedContact) {
      // set the client contact id for updating changes
      setClientContactId(matchedContact.id)
      setClientContactEmailVerified(matchedContact.emailVerified);
      // console.log('emailVerified: ', matchedContact.emailVerified);

      setClientContactEmailAddress(matchedContact.email)
  
      setClientContactName(matchedContact.name);
      setValue('contactEmail', matchedContact.email);
      
      // format the phone data
      setValue('contactPhone', formatToPhoneData(matchedContact.phone));
      setValue('originalContactEmail', matchedContact.email);
      
      setValue('originalContactPhone', formatToPhoneData(matchedContact.phone));
      setValue('djtClientAuthName', matchedContact.name);
    }
  };
  
  const createClientContactOptionAction = (event) => {
    setClientContactEmailVerified(false);
    const newId = 0;
    if (companySelectedOption) {
      clientContacts?.push({
        clientId: companySelectedOption.value,
        email: null,
        emailVerified: false,
        id: newId,
        name: event.label,
        phone: null,
      });
    } else {
      console.log('newClientId: ', newClientId)
      const newClientContact = {data: {
        clientId: newClientId,
        email: null,
        emailVerified: false,
        id: newId,
        name: event.label,
        phone: null,
      }}
      // setNewClientContacts(newClientContact)
      console.log('newClientContact: ', newClientContact)
    }
    
    setClientContactSelected({ label: event.label, value: newId });
    setValue('selectContact', { label: event.label, value: newId });
    setClientContactName(event.label);
    setValue('contactName', event.label);
    setValue('djtClientAuthName', event.label);
  };

  const handleClientContactChanged = async (event, action) => {
    // console.log(' <<<<< RUNNING handleClientContactChanged action: ', action);
    // console.log(' <<<<< RUNNING handleClientContactChanged event: ', event);

    // Clear any stagnating errors
    clearErrors(['selectContact']);
  
    if (!event) {
      clearClientContactData();
    } else {
      // Clear out the previously selected value
      if (clientContactSelected) clearClientContactData();
      if (action?.action === "create-option") {
        createClientContactOptionAction(event);
      }

      if (action?.action === "select-option") {
        selectClientContactOptionAction(event);
      }
    }
  
    clearErrors(['selectContact', 'contactPhone']);
    return;
  };

  async function handleAttachedContract(attachedContractId, attachedContract) {
    if (!attachedContractId && !attachedContract) return
    console.log(' -----> THESE VALUES: ', attachedContractId)
    console.log(' -----> THESE VALUES: ', attachedContract)
    setAttachedContractId(attachedContractId)
    setClientContractSelectedOption({label: attachedContract?.name, value: attachedContract?.id})
    setValue('selectClientContract', {label: attachedContract?.name, value: attachedContract?.id})
  }

  const handleClientContractChanged = async (event) => {
    // if (!event || !action) return
    // console.log('Client contract changed: ', event)
    // console.log('Client contract changed: ', action)
    if (event===null) {
      setAttachedContractId(null)
      setClientContractSelectedOption(null)
      // setValue('selectClientContract', null)
    } else {
      setAttachedContractId(event?.value)
      setClientContractSelectedOption({label: event?.label, value: event?.value})
      setValue('selectClientContract', {label: event?.label, value: event?.value})
    }
    
  }

  const onContractUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setShowFileUploadSpinner(true)
      // open modal with progress bar - or just set dropdown to new contract

      // at least one file has been selected so do something
      
      // need to create a table of all of the available buckets to upload to
      const params = {file: e.target.files[0], bucket: 'djt-client-contracts'}
      const result = await uploadFileToS3(params);
      // console.log(' <<<<< file upload params: ', params)
      // console.log(' <<<<< upload file result: ', result)

      // now create the record in SpkClientContractTbl with this contract
      const contractParams = {
        name: result.fileName,
        createDateTime: awsDateTimeNow(),
        clientId: clientId,
        documentId: result.documentId,
        createdById: currentTeamMemberId,
      }
      const clientContract = await addClientContract(contractParams)
      console.info(' -----> clientContract: ', clientContract)

      // setAttachedContractId(attachedContractId)
      // setClientContractSelectedOption({label: attachedContract?.name, value: attachedContract?.id})
      //name id
      // const clientContractObj = {name: clientContract.name, id: clientContract.id}
      handleAttachedContract(clientContract.id, clientContract)

      // update the dropdown and set the selcted as the new contract
      // setAvailableClientContracts(prevState => [...prevState, {label: result.fileName, value: contractId}])
      // setClientContractSelectedOption({label: result.fileName, value: contractId})  
      

      // now set attachedContractId to this id
      // setAttachedContractId(clientContract.id)
      setShowFileUploadSpinner(false)
    }
  }

  const handleCompanyDetailsChanged = async () => {
    // set contact phone, email, location

    // if (contactId!==null) {
    //   // company has been selected
    //   // going to add a new contact under this company
    //   // console.log('we have an existing company contact changing')
    //   setDidExistingContactChange(true)
    // } else {
    //   // new company entirely
    //   setIsNewContactCompany(true)
    //   // console.log('this is a new contact entirely, get company name')
    // }

  }

  const handlePhoneEmailChanged = async (event) => {      
    const fieldClicked = (event.target.id==='contactPhone') ? 'phone' : 'email'
    // console.log('eenntt: ', event.target.value)
    if (fieldClicked==='phone') {
      // console.log('in phone')
      if (event.target.value.length===16) {
        // console.log('hit 16')
        clearErrors(['contactPhone'])
      }
    }
    setContactChangedTypeClicked(fieldClicked)
    onConfirmContactDataChangeModalOpen()
  }

  async function resetAllState() {
    laborRemove()
    unitRemove()
    // materialRemove()

    setAccordianLaborIndex(-1)
    setAccordianUnitIndex(-1)

    setUnitSummaryTotal(0)
    setLaborSummaryTotal(0)
    // setMaterialSummaryTotal(0)

    //setAllLaborButtonVariants
    //setLoadedAllTeamMembers
    
    // setAllLaborForDay()
    setLoadedAllTeamMembers([])
    setAllTeamMembers([])
    setLaborSummary([])
    setAllLaborButtonVariants([])
    
    setAllUnits([])
    setAllUnitButtonVariants([])
    setUnitSummary([])
    setUnitDriversStatus([])
    setUnitOperatorsStatus([])

    // setMaterialsForDay([])
    // setMaterialButtonVariants([])
    // setMaterialSummary([])
    // materialRemove()
    
    setDuplicatedUnitError([])
    setDuplicatedLaborError([])

  }

  const handleDjtPoChanged = async (event, action) => {

    if (action?.action === "clear") {
      setValue('clientPO', null)
      setClientPoSelectedOption(null)
    }

    if (action?.action === "select-option") {
      setValue('clientPO', event)
      setClientPoSelectedOption(event)
    }

    if (action?.action === "create-option") {

      console.log(' <><><><>< CREATED NEW OPTION IN PO')

      // this needs to be the same as the new typed djt
      setValue('clientPO', event)
      setClientPoSelectedOption(event)

    }
  } 

  const handleJobDescriptionChange = (event) => {
    setDjtDescription(event.target.value)
  }

  const handleDjtDateChanged = async (searchDate) => {
    // console.log('SETTING DJTDATE TO: ', searchDate)
    // before anything, wait until the data is the right length
    if (searchDate.length===10) {
      await resetAllState()      
  
      const djtDate = searchDate

      // check and set weekend OT status
      const isDateWeekend = isWeekend(djtDate)
      setIsTicketDateWeekend(isDateWeekend)
      // console.error('is today a weekend: ', isDateWeekend)

      const dateSpelledOut = spellOutDate(djtDate)
      setDjtDateLong(dateSpelledOut)
      let year = djtDate.substring(0,4)
      if (isValidDate(djtDate) && Number(year)>=2023) {
        
          // setPrevDjtDate(djtDate)

        if (currentDivisionId!==null) {
          
          // await loadDailyJobTicketEquipment
          // get labor
          const laborData = {divisionId: currentDivisionId, workDayHuman: djtDate}
          await getLaborByDepartmentByDate(laborData)

          // get units
          const unitData = {divisionId: currentDivisionId, workDayHuman: djtDate}
          await getUnitsByDivisionByDate(unitData)

          // // update material data <- NONE OF THIS HAS TO BE DONE IF WE AREN'T DELETING MATERIALS ON DATE CHANGE
          // loadDailyJobTicketMaterial(billableMaterialsForDay)
          // setMaterialsForDay(billableDefaultMaterials.data)
          // updateDJTRunningTotal()

          // setShowLaborComponent(true)
          // setShowUnitsComponent(true)
          // setShowMaterialsComponent(true)
          
          // console.log('djtDate is set to: ', djtDate)

          setDjtDate(djtDate)
          
        }
      } else {
        console.log('Date string not long enough. Needs to be 10 characters.')
      }
      
    }
      
  }

  const getLaborByDepartmentByDate = async (params) => {
    // if (isEstimate) return  // no need to load labor if this is an estimate

    // console.warn('getLaborByDepartmentByDate: ', params)
    setLaborForDay(null)

    // const punchedLabor = await fetchLaborByDivisionByDate(params)
    const punchedLabor = await fetchPunchedLaborByDateDepartment(params)

    console.warn('punchedLabor: ', punchedLabor)
    // filter out hideByDefault
    const filteredLabor = punchedLabor.filter(labor => 
      labor?.teamMember?.teamMember?.djtHideByDefault !== true
    )
    
    console.log('filtered labor: ', filteredLabor)
    setLaborForDay(filteredLabor)
  }

  const getUnitsByDivisionByDate = async (params) => {
    if (!params) return
    // console.log('params for units: ', params)
    setUnitsForDay(null)
    const dirtyUnits = await fetchInspectedUnitsByDateByStatus(params)
    // the same unit can be inspected multiple times in a day
    const units = removeDuplicateCodes(dirtyUnits)
    // console.warn('got returned units for this day: ', dirtyUnits)
    // if units = 0 set units alert
    setUnitsForDay(units)
  }

  const updateMaterialsRunningTotal = async () => {
    // console.log('updateMaterialsRunningTotal: ', materialCostItem)
    let accumulatedMaterialTotalCost = 0
    materialCostItem.forEach((materialCost) => {
      accumulatedMaterialTotalCost = accumulatedMaterialTotalCost + Number(materialCost)
    })
    accumulatedMaterialTotalCost = converNumberToDouble(accumulatedMaterialTotalCost)

    // setMaterialsTotalCost(accumulatedMaterialTotalCost, console.log('set now?', accumulatedMaterialTotalCost))
    setMaterialsTotalCost(accumulatedMaterialTotalCost)

    // updateDJTRunningTotal()

  }

  function removeDuplicateCodes(array) {
    const seenCodes = new Set();
    const uniqueArray = array.filter(item => {
      if (seenCodes.has(item?.unit?.code)) {
        return false; // Skip this item, it's a duplicate code
      } else {
        seenCodes.add(item?.unit?.code);
        return true; // Keep this item, its code is unique so far
      }
    });
  
    return uniqueArray;
  }

  const getAuxillaryUnitOptionsForUnit = async (unitId, Id) => {
    // const auxillaryUnits =  await fetchAuxillaryUnitsByUnitId({unitId: unitId})
    const auxillaryUnitOptions =  await fetchAuxillaryUnitOptionsByUnitId({unitId: unitId, id: Id})
    // console.log('src auxillaryUnitOptions: ', auxillaryUnitOptions)

    return auxillaryUnitOptions
  }

  const handleUnitRowTotalChange = async (index, xvalue ) => {

    // console.info('UNIT ROW TOTAL CHANGE: ', index)
    // console.info('UNIT ROW TOTAL CHANGE: ', xvalue)

    const calculationRegexPattern = /[@*]/;
    let isCalculation = calculationRegexPattern.test(xvalue)
    let value = (isCalculation) ? multiplyIfEaster(xvalue) : xvalue;

    const rowTotal = (isCurrency(value)) ? Number(convertCurrencyToDouble(value)) : Number(value)

    unitFields[index].RowTotal = rowTotal
    setValue(`unit[${index}].RowTotal`, convertToCurrency(rowTotal))

    setIsUnitsDisabled(true)
    await updateUnitSummary('update', unitFields[index], index)
    setIsUnitsDisabled(false)

    // console.info('unitFields[index]: ', unitFields[index])
    // if (isEdit) {
    //   let currentUnitRate = unitFields[index]?.UnitRate
    //   let currentUnitQuantity = unitFields[index]?.Quantity
    //   if (currentUnitQuantity>1) {
    //     currentUnitRate = convertCurrencyToDouble(currentUnitRate)/currentUnitQuantity
    //     setValue(`unit[${index}].UnitRate`, convertToCurrency(currentUnitRate))
    //     // unitFields[index]?.UnitRate = currentUnitRate
    //   }
    // }

    // -----
    // IF WE ARE IN EDIT MODE
    // FOR EACH ITEM, IF THE QTYU IS NOT ONE OR ZERO
    // WE NEED TO MANUALLY UPDATE THE UNIT PRICE/RATE
    // -----

    // If you load up an edit that has an item at 2 @ $50 each for a total of $100
    // we have the quamntity and the row total, but no longer have what the unit price was
    // therefore, there can be no calculations done

    // WE DON'T CARE ABOUT QUANTITY AT THIS POINT

    // If the user just deleted the row total and tabbed/clicked out, reset to original value
    // this is dependant on if we are in edit mode or new
    // if edit, we need to know the original incoming row total

    // THIS NEEDS TO BE TESTED
    // WE SHOULDN'T BE CHANGING QUANTITY IN THE ROW TOTAL CHANGE FUNCTION
    const unitQuantityAmount = (isEdit) ? 1 : getValues(`unit[${index}].Quantity`)
    console.info(' ----------> unitQuantityAmount: ', unitQuantityAmount)
    // if (xvalue==='') handleUnitQuantityChange(index, unitQuantityAmount||1)
    // unitSummary[0].trigger= uuidv4()

    return true
  }

  const handleUnitQuantityChange = async (index, value) => {
    // console.log('index: ', index)
    // console.log('value: ', value)
    const unitQuantity = (Number(value) > 0) ? value : 1

    let unitRate = (unitFields[index]?.UnitRate==="$0.00") ? getValues(`unit[${index}].UnitRate`) : unitFields[index]?.UnitRate
    // let unitPrice = (isCurrency(unitFields[index]?.UnitRate)) ? Number(convertCurrencyToDouble(unitFields[index]?.UnitRate)) : Number(unitFields[index]?.UnitRate)
    

    // NOW MAKE SURE WHEN THE QTY IS BUMPED UP OR DOWN AFTER LOADING, IT REFLECTS IN SUMMARY TOTAL

    // if (isEdit) {
      let currentUnitRowTotal = unitFields[index]?.RowTotal
      let currentUnitRate = unitFields[index]?.UnitRate
      let currentUnitQuantity = unitFields[index]?.Quantity
      let calculatedCurrentRate = (convertCurrencyToDouble(currentUnitRowTotal)/currentUnitQuantity)
      // console.info('calculatedCurrentRate: ', calculatedCurrentRate)
      // console.info('currentUnitRate: ', currentUnitRate)
      if (calculatedCurrentRate!==currentUnitRate) {
        // console.info('Custom unit rate, setting rate to custom rate')
        setValue(`unit[${index}].UnitRate`, convertToCurrency(calculatedCurrentRate))
        unitRate = calculatedCurrentRate
      }
    // }

    // let unitPrice = (isCurrency(unitRate)) ? Number(convertCurrencyToDouble(unitRate)) : Number(unitRate)
    
    // console.info(' >>>>> unitRate: ', unitRate)
    // console.log('unitPrice: ', unitPrice)
    // const unitTotal = unitPrice*unitQuantity
    const calculatedUnitTotal = unitQuantity*unitRate
    
    // console.info(' >>>>> calculatedUnitTotal: ', calculatedUnitTotal)

    unitFields[index].Quantity = unitQuantity
    setValue(`unit[${index}].Quantity`, unitQuantity)

    unitFields[index].RowTotal = calculatedUnitTotal
    setValue(`unit[${index}].RowTotal`, convertToCurrency(calculatedUnitTotal))

    handleUnitRowTotalChange(index, calculatedUnitTotal)
  }

  const handleLaborRowTotalChange = async (index, xvalue) => {
    let tmpArr = rowTotalChange
    tmpArr[index] = xvalue
    setRowTotalChange(tmpArr)

    const calculationRegexPattern = /[@*]/;
    let isCalculation = calculationRegexPattern.test(xvalue)
    let value = (isCalculation) ? multiplyIfEaster(xvalue) : xvalue;

    const rowTotal = (isCurrency(value)) ? Number(convertCurrencyToDouble(value)) : Number(value)

    laborFields[index].RowTotal = rowTotal
    setValue(`labor[${index}].RowTotal`, convertToCurrency(rowTotal))

    const laborId = getValues(`labor[${index}].Id`)
    if (!addedLabor.includes(laborId)) {
      setUpdatedLabor(prevState => [...prevState, laborId])
    } else {
      // update the labor array
      if (!updatedLabor.includes(laborId)) {
        updatedLabor.push(laborId);  // Add laborId to the array if it's not present
      } else {
          console.log('laborId already exists in updatedLabor the array.');
      }
    }

    // update the running total
    const laborGrandTotal = calculateCurrentLaborRowTotal()
    setLaborSummaryTotal(laborGrandTotal)
  }

  const handleMaterialCustomDescriptionChange = async (index, value) => {
    materialFields[index].Description  = value
    setValue(`material${index}Description`, value)
    setValue(`material[${index}].Description`, value)
    updateMaterialSummary('update', materialFields[index], index)
  }

  const handleMaterialRowTotalChange = async (index, xvalue) => {
    const value = multiplyIfEaster(xvalue)
    const rowTotal = (isCurrency(value)) ? Number(convertCurrencyToDouble(value)) : Number(value)

    materialFields[index].RowTotal = rowTotal
    setValue(`material[${index}].RowTotal`, convertToCurrency(rowTotal))

    updateMaterialSummary('update', materialFields[index], index)

  }

  const handleMaterialPriceChange = async (index, value) => {

    const quantity = materialFields[index]?.Quantity
    const qty = (quantity && quantity>=1) ? quantity : 1
    const price = (isCurrency(value)) ? Number(convertCurrencyToDouble(value)) : Number(value)
    const materialTotal = price*qty

    materialFields[index].Price = price
    setValue(`material[${index}].Price`, convertToCurrency(price))

    materialFields[index].RowTotal = materialTotal
    setValue(`material[${index}].RowTotal`, convertToCurrency(materialTotal))

    handleMaterialRowTotalChange(index, materialTotal)

  }

  const handleMaterialQuantityChange = async (index, value) => {

    const materialQuantity = (Number(value) > 0) ? value : 1
    const materialPrice = (isCurrency(materialFields[index]?.Price)) ? Number(convertCurrencyToDouble(materialFields[index]?.Price)) : Number(materialFields[index]?.Price)
    const materialTotal = materialPrice*materialQuantity

    materialFields[index].Quantity = materialQuantity
    setValue(`material[${index}].Quantity`, materialQuantity)

    // materialFields[index].RwoTotal = materialTotal
    materialFields[index].RowTotal = materialTotal
    setValue(`material[${index}].RowTotal`, convertToCurrency(materialTotal))

    handleMaterialRowTotalChange(index, materialTotal)
  }

  const subtractLunchMinutes = (date, minutes) => {
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  }

  const getTimeWorked = async (start, finish, lunch)=> {
    const lunchMinutes = (lunch) ? Number(lunch) : 0;
    const timeStart = new Date("01/01/1970 " + start);
    const timeEnd = new Date("01/01/1970 " + finish);
    const timeDifference = timeEnd - timeStart

    const difference_as_date = new Date(timeDifference)
    const adjusted_difference = subtractLunchMinutes(difference_as_date, lunchMinutes)
    const dhours = adjusted_difference.getUTCHours()
    const dminutes = adjusted_difference.getUTCMinutes()

    // hardcoded at 8
    let maxStraightTime = 8
    if (isTicketDateWeekend===true) {
      maxStraightTime = 0
    }
    // let returnTime = null
    let returnTime = []

    let totalHoursBilled = 0
    // if hours is greater than max OT hours is max and there are minutes extra
    if ((dhours>maxStraightTime)||(dhours===maxStraightTime&&dminutes>0)) {
      // Cap hours at 8 and move minutes to ot
      const otHours = dhours-maxStraightTime
      const otMinutes = dminutes
      const adjHours = dhours-otHours
      const adjMinutes = '00'

      // set the number of hours to use for suggested billable hours
      let totalHoursBilled = Number(dhours)
      const totalMinutesBilled = Number(dminutes)
      // set the hours, and then bill for an additional hour if time goes over the hour
      if (totalMinutesBilled>0) {
        totalHoursBilled = totalHoursBilled+1
      }

      returnTime = [adjHours+':'+adjMinutes,otHours+':'+otMinutes, totalHoursBilled]
    } else {
      if (dminutes>30) {
        totalHoursBilled = dhours + 1
      }
      returnTime = [dhours+':'+dminutes,'00:00',totalHoursBilled]
    }
    return returnTime
  }

  const getTotalAmount = async (params) => {
    // To calculate the total dollars earned by the person, we need to follow these steps:
    // Determine the total hours worked excluding lunch. Since the person takes a 30-minute lunch break, the actual hours worked are 8.5 hours.
    // Identify the regular hours and the overtime hours. In this case, the regular hours are 8 hours, and the overtime hours are 0.5 hours (30 minutes).
    // Calculate the pay for the regular hours by multiplying the number of regular hours by the regular hourly rate.
    // Calculate the pay for the overtime hours by multiplying the number of overtime hours by the overtime hourly rate.
    // Sum the pay for the regular hours and the overtime hours to find the total dollars earned.
    // Do the calculations based on the given rates: $72 an hour for regular time and $108 an hour for overtime.
    // The total dollars earned by the person for the 8.5 hours of work, with a 30-minute lunch break, at the given rates ($72 an hour for an 8-hour day and $108 an hour for overtime) is $630. This includes pay for both the regular hours and the overtime hours. 

    // console.warn(' ---- getTotalAmount params: ', params)
    //straightHours, straightRate, overtimeHours, overtimeRate
    // calse cost per 15 minutes
    // calc cost per minute
    let scale = Number(params.straightRate)/60;
    let otscale = Number(params.overtimeRate)/60;
    // then calc total minutes * scale
    scale = Number(scale.toFixed(5))
    otscale = Number(otscale.toFixed(5))

    const straightHoursSplit = params.straightHours.split(":")
    const hours = Number(straightHoursSplit[0])
    const minutes = Number(straightHoursSplit[1])

    const overtimeHoursSplit = params.overtimeHours.split(":")
    const othours = Number(overtimeHoursSplit[0])
    const otminutes = Number(overtimeHoursSplit[1])

    const totalStraightMinutes = (hours*60)+minutes
    const totalOTMinutes = (othours*60)+otminutes
    
    let totalLaborAmount = (totalStraightMinutes*scale) + (totalOTMinutes*otscale)
    totalLaborAmount = totalLaborAmount.toFixed(2)
    totalLaborAmount = parseFloat(totalLaborAmount)
    return totalLaborAmount
  }

  const updateMaterialItemsTotal = async () => {
    // console.log('running material item totals: ', billableMaterialsForDay)
    // for each material item, calculate price * quantity and update materialItemCost[index]
    //materialCostItem[]
    let materialItemPrice = 0
    let materialItemCost = 0
    let materialItemQuantity = 0
    
    billableMaterialsForDay.forEach((material, index) => {
      // console.log('material: ', index+' - '+material.price + ' * '+material.quantity+' = '+ Number(material.price)*Number(material.quantity))

      materialItemPrice = material.price

      // console.log('starting with: ', materialItemPrice)

      materialItemPrice = (isCurrency(materialItemPrice)) ? convertCurrencyToDouble(materialItemPrice) : materialItemPrice

      // console.log('after 1 check: ', materialItemPrice)

      materialItemPrice = (isWholeNumber(materialItemPrice)) ? converNumberToDouble(materialItemPrice) : materialItemPrice

      // console.log('after 2 checks: ', materialItemPrice)

      // materialItemPrice = (!Number.isNaN(Number.parseFloat(materialItemPrice))) ? Number.parseFloat(materialItemPrice).toFixed(2) : materialItemPrice

      // console.log('after 3 checks: ', materialItemPrice)

      materialItemCost = materialItemPrice*Number(material.quantity)

      // console.log('calculated cost: ', materialItemCost)
      materialItemCost = converNumberToDouble(materialItemCost)
      materialCostItem[index] = materialItemCost
      
      setValue(index+'_materialRowTotal', materialCostItem[index])


      materialPriceItem[index] = materialItemPrice
      
      setValue(index+'_materialPrice', materialPriceItem[index])

      if (isNaN(Number(material.quantity))) {
        // console.log(material.quantity+' is not a number')
      } else {
        // console.log(Number(material.quantity)+' is a number')
      }
      materialItemQuantity = Number(material.quantity)
      materialQuantityItem[index] = materialItemQuantity
      setValue(index+'_materialQuantity', materialQuantityItem[index])
    })

    updateMaterialsRunningTotal()
    
    
  }

  const updateDJTRunningTotal = async () => {
    // get total labor, total equipment, total materials and update totalDJT field
    let currentLaborTotalCost = 0
    let currentUnitsTotalCost = 0
    let currentMaterialsTotalCost = 0
    let currentDJTTotalCost = 0

    currentLaborTotalCost = laborSummaryTotal
    currentLaborTotalCost = (isCurrency(currentLaborTotalCost)) ? convertCurrencyToDouble(currentLaborTotalCost) : currentLaborTotalCost
    currentLaborTotalCost = (isWholeNumber(currentLaborTotalCost)) ? converNumberToDouble(currentLaborTotalCost) : currentLaborTotalCost

    currentUnitsTotalCost = unitSummaryTotal
    currentUnitsTotalCost = (isCurrency(currentUnitsTotalCost)) ? convertCurrencyToDouble(currentUnitsTotalCost) : currentUnitsTotalCost
    currentUnitsTotalCost = (isWholeNumber(currentUnitsTotalCost)) ? converNumberToDouble(currentUnitsTotalCost) : currentUnitsTotalCost

    currentMaterialsTotalCost = materialSummaryTotal
    currentMaterialsTotalCost = (isCurrency(currentMaterialsTotalCost)) ? convertCurrencyToDouble(currentMaterialsTotalCost) : currentMaterialsTotalCost
    currentMaterialsTotalCost = (isWholeNumber(currentMaterialsTotalCost)) ? converNumberToDouble(currentMaterialsTotalCost) : currentMaterialsTotalCost

    currentDJTTotalCost = Number(currentLaborTotalCost) + Number(currentUnitsTotalCost) + Number(currentMaterialsTotalCost)
    currentDJTTotalCost = converNumberToDouble(currentDJTTotalCost)
    setDjtRunningTotal(currentDJTTotalCost)
    currentDJTTotalCost = USDollar.format(currentDJTTotalCost)

    setValue('djtTotalCost', currentDJTTotalCost)
    return currentDJTTotalCost
  }

  const handleCustomAuxillaryUnitChange = async (event, index) => {
    // get aux unit info and add to units section or materials section
    let auxillaryUnitId = event.value
    let auxillaryUnit = await fetchAuxillaryUnitByUnitId({unitId: auxillaryUnitId})
    // console.log('auxillaryUnit: ', auxillaryUnit?.name)
    const rateDescription = auxillaryUnit.rateDescription
    let billableTotal = 0.00
    const rate = rateDescription.split('/')
    let rateTotal = 0
    if (rate[1]==='Shift') {
      rateTotal = convertCurrencyToDouble(rate[0])
      billableTotal = Number(rateTotal)
    }
    if (rate[1]==='Hour') {
      rateTotal =  convertCurrencyToDouble(rate[0])*Number(maxBillableHours)
      billableTotal=rateTotal.toFixed(2)
    }
    if (rate[1]==='Each') {
      rateTotal = convertCurrencyToDouble(rate[0])
      billableTotal = rateTotal
    }

    // update the formfield for Rate
    setValue(`unit[${index}].UnitRate`, auxillaryUnit.rateDescription)

    const auxUnitCode = currentAuxUnitCode
    let unitObj = {
      UnitId: auxillaryUnit.id,
      // OrigDescription: auxillaryUnit.name,
      // Description: auxillaryUnit.name,
      OrigDescription: auxillaryUnit.name,
      Description: event,
      UnitCode: auxUnitCode,
      UnitRate: auxillaryUnit.rateDescription,
      UnitCategory: 'auxillary', 
      RowTotal: billableTotal,
      isAuxillaryUnit: true

    }
    // setValue(`unit${index}Description`, event)
    setValue(`unit[${index}].Description`, event)
    
    // setValue(`unit[${index}].Description`, auxillaryUnit.name)
    // clearErrors([`unit${index}.Description`])
    clearErrors([`unit[${index}].Description`])

    // setAddedEquipment(prevState => [...prevState, auxillaryUnit.id])
    // addedEquipment needs ot be mutated by removing this selects previous seletion id
    // console.log('unitSummary onChange: ', unitSummary)
    // console.log('this unitObj: ', unitObj)
    
    setIsUnitsDisabled(true)
    await updateUnitSummary('update', unitObj, index)
    setIsUnitsDisabled(false)

  }

  const handleDriverChange = async (event, action, index) => {

    //splice the driver array
    let tmpArray = selectedDrivers
    tmpArray.splice(index, 1, event)
    setSelectedDrivers(tmpArray)

    let tmpDriverStatus = unitDriversStatus
    // check if there are any drivers for this unit
    let requiredDriverLength = selectedDrivers[index]?.length||0

    const driverIsMissing = (requiredDriverLength===0) ? true : false
    tmpDriverStatus.splice(index, 1, {missing: driverIsMissing})
    setUnitDriversStatus(tmpDriverStatus)
    
    // for some reason, once in a while, select multi add/clear doesn't update right away after selection. This is fixing that for now
    let rowTotal = getValues(`unit[${index}].RowTotal`)
    await handleUnitRowTotalChange(index, 0)
    await handleUnitRowTotalChange(index, rowTotal)

  }

  const handleOperatorChange = async (event, action, index) => {

    // splice the operator array
    let tmpArray = selectedOperators
    tmpArray.splice(index, 1, event)
    setSelectedOperators(tmpArray)

    let tmpOperatorStatus = unitOperatorsStatus
    let requiredOperatorLength = tmpArray[index]?.length||0

    const operatorIsMissing = (requiredOperatorLength===0) ? true : false
    tmpOperatorStatus.splice(index, 1, {missing: operatorIsMissing})
    setUnitOperatorsStatus(tmpOperatorStatus)

    // for some reason, once in a while, select multi add/clear doesn't update right away after selection. This is fixing that for now
    let rowTotal = getValues(`unit[${index}].RowTotal`)
    await handleUnitRowTotalChange(index, 0)
    await handleUnitRowTotalChange(index, rowTotal)

  }

  const handleCustomUnitChange = async (index, event) => {

    const existingFieldValue = unitFields[index].Description

    // console.info(' -----> handleCustomUnitChange index: ', index)
    // console.info(' -----> handleCustomUnitChange event: ', event)
    // console.info(' -----> handleCustomUnitChange existingFieldValue: ', existingFieldValue)
    // custom equipment/rentals get added with a generated id
    // make sure we don't need to set and form field data when they change the description
    // console.log('custom unit change: ', event)

    if (existingFieldValue !== event) {
      unitFields[index].Description = event
      setValue(`unit[${index}].Description`, event)
      trigger(`unit[${index}].Description`)
      // clearErrors(`unit[${index}].Description`)
  
      updateUnitSummary('update', unitFields[index], index)
    }
  
  }

  const handleAuxillaryUnitChange = async (event, index) => {

    // This is the function that is causing the controleed to uncontrolled error

    // Now adding aux unit to directly under primary.
    console.log(' >>> aux unit event: ', event)
    console.log(' >>> aux unit index: ', index)
    // CLEAN THIS UP
    // get aux unit info and add to units section or materials section
    let auxillaryUnitId = event.value

    let auxillaryUnit = await fetchAuxillaryUnitByUnitId({unitId: auxillaryUnitId})
    console.log('aux unit: ', auxillaryUnit)
    // use new price data here
    console.log('auxillaryUnit: ', auxillaryUnit)

    // priceCode.price, priceCode.rateType.description
    const rateDescription = '$'+auxillaryUnit.priceCode.price+auxillaryUnit.priceCode.rateType.description
    // const rateDescription = auxillaryUnit.rateDescription
    // console.log('rateDescription new: ', rateDescription)

    let billableTotal = 0.00
    let rateTotal = 0

    const auxUnitRate = auxillaryUnit.priceCode.price
    const auxUnitRateType = auxillaryUnit.priceCode.rateType.rateType

    // if (auxUnitRateType==='shift' || auxUnitRateType==='each') {
    //   console.log('auxUnitRateType: ', auxUnitRateType)
    //   console.log('auxUnitRate: ', auxUnitRate)
    //   billableTotal=auxUnitRate
    // }
    if (auxUnitRateType==='hour') {
      console.log('auxUnitRateType: ', auxUnitRateType)
      console.log('auxUnitRate: ', auxUnitRate)
      rateTotal = auxUnitRate*Number(maxBillableHours)
      billableTotal=rateTotal
    } else {
      billableTotal=auxUnitRate
    }

    // console.info('billableTotal: ', billableTotal)
    
    // const rate = rateDescription.split('/')
    
    // if (rate[1]==='Shift') {
    //   rateTotal = convertCurrencyToDouble(rate[0])
    //   billableTotal = Number(rateTotal)
    //   // console.log('1', rateTotal)
    // }
    // if (rate[1]==='Hour') {
    //   rateTotal =  convertCurrencyToDouble(rate[0])*Number(maxBillableHours)
    //   //rateTotal = USDollar.format(rateTotal.toFixed(2))
    //   billableTotal=rateTotal.toFixed(2)
    //   // console.log('2', billableTotal)
    // }
    // if (rate[1]==='Each') {
    //   rateTotal = convertCurrencyToDouble(rate[0])
    //   billableTotal = rateTotal
    // }

    // const isChildOfParent = (event?.parentId && event.parentId!=='') ? true : false
    
    // const auxParentId = (event?.parentId && event.parentId!=='') ? event?.parentId : null
    // const auxParentId = getValues(`unit[${index}].Id`)

    // console.log('x: ', getValues(`unit[${index}]`))
    // const auxParentId = getValues(`unit[${index}].originalId`)
    const auxParentId = getValues(`unit[${index}].Id`)
    // console.log('auxParentId: ', auxParentId)

    // is this a child of a preant unit
    const isChildOfParent = (auxParentId?.length>0) ? true : false
    // console.log('isChildOfParent: ', isChildOfParent)
    // add this to the units section
    // make sure we don't go over 799
    const auxUnitCode = (currentAuxUnitCode>798) ? 700 : currentAuxUnitCode+1
    // console.log('auxUnitCode: ', auxUnitCode)

    setCurrentAuxUnitCode(auxUnitCode)

    let unitObj = {
      UnitId: auxillaryUnit.id,
      OrigDescription: auxillaryUnit.name,
      Description: auxillaryUnit.name,
      UnitCode: auxUnitCode,
      // UnitRate: auxillaryUnit.rateDescription,
      UnitRate: rateDescription,
      UnitCategory: 'auxillary', 
      RowTotal: billableTotal,
      isAuxillaryUnit: true,
      parentPrimaryIndex: index,
      isAuxChild: isChildOfParent,
      auxChildOfParentId: auxParentId,
      Quantiy: '1',
    }

    console.log(' ~~~~~ ABOUT TO SEND THIS TO UPDATESUMMARY: ', unitObj)
    updateUnitSummary('append-auxillary', unitObj, index)
  }

  // async function getRemainingTeamMembers() {

  //   // console.warn(' ----- laborForDay: ', laborForDay)
  //   // get the options for our punched labor
  //   let punchedLaborOptions = [];
  //   punchedLaborOptions = laborForDay?.map((labor) => ({
  //     label: prettifyName(labor?.teamMember?.teamMember?.firstName, labor?.teamMember?.teamMember?.goesBy, labor?.teamMember?.teamMember?.middleName, labor?.teamMember?.teamMember?.lastName),
  //     value: `${labor?.teamMember?.teamMember?.id}`,
  //     // isTempLabor: `${labor?.teamMember?.teamMember?.isTempLabor}`,
  //   }))

  //   const punchedLaborIds = laborForDay?.map(labor => labor?.teamMember?.teamMember?.id);

  //   // get all of the team members
  //   const allTeamMembers = await listAllTeamMembers('', 500)

  //   // filter out todays labor from all labor
  //   let remainingLabor = [];
  //   remainingLabor = allTeamMembers?.items.filter(item => !punchedLaborIds?.includes(item.id));

  //   // construct remaining labor options
  //   const remainingLaborOptions = remainingLabor?.map((labor) => ({
  //     label: prettifyName(labor?.firstName, labor?.goesBy, labor?.middleName, labor?.lastName),
  //     value: labor?.id
  //   }))

  //   // console.log('groupedOptions: ', groupedOptions)
  //   // group the options
  //   const groupedOptions = [
  //     {
  //       label: " --- Available Labor ---",
  //       options: punchedLaborOptions
  //     },
  //     {
  //       label: " --- Unavailable Labor ---",
  //       options: remainingLaborOptions
  //     }
  //   ]

  //   // console.log('groupedOptions: ', groupedOptions)

  //   // set the object for the drivers/operators dropdown
  //   setOperatorsDriversSelectOptions(groupedOptions)
  // }
  
  async function getRemainingTeamMembers() {
    // Get the punched labor options
    const punchedLaborOptions = laborForDay?.map((labor) => ({
      label: prettifyName(
        labor?.teamMember?.teamMember?.firstName,
        labor?.teamMember?.teamMember?.goesBy,
        labor?.teamMember?.teamMember?.middleName,
        labor?.teamMember?.teamMember?.lastName
      ),
      value: `${labor?.teamMember?.teamMember?.id}`,
    })) || []; // Default to empty array if laborForDay is null/undefined
  
    const punchedLaborIds = laborForDay?.map(
      (labor) => labor?.teamMember?.teamMember?.id
    ) || [];
  
    // Get all team members
    const allTeamMembers = await listAllTeamMembers("", 500);
  
    // Filter out today's labor
    const remainingLabor = allTeamMembers?.items.filter(
      (item) => !punchedLaborIds?.includes(item.id)
    ) || [];
  
    // Construct remaining labor options
    const remainingLaborOptions = remainingLabor?.map((labor) => ({
      label: prettifyName(
        labor?.firstName,
        labor?.goesBy,
        labor?.middleName,
        labor?.lastName
      ),
      value: labor?.id,
    })) || [];
  
    // Group the options
    const groupedOptions = [
      {
        label: "--- Available Labor ---",
        options: remainingLaborOptions, // Remaining labor is "available"
      },
      {
        label: "--- Unavailable Labor ---",
        options: punchedLaborOptions, // Punched labor is "unavailable"
      },
    ];
  
    // Debugging
    console.log("Grouped Options:", JSON.stringify(groupedOptions, null, 2));
  
    // Set the object for the dropdown
    setOperatorsDriversSelectOptions(groupedOptions);
  }

  const nextLabor = () => {
    setCurrentLaborPage(currentLaborPage+1)
    setPreviousLaborTokens((prev) => [...prev, nextLaborToken])
    setNextLaborToken(nextNextLaborToken)
    setNextNextLaborToken(null)
  }

  const prevLabor = () => {
    setCurrentLaborPage(currentLaborPage-1)
    setNextLaborToken(previousLaborTokens.pop())
    setPreviousLaborTokens([...previousLaborTokens])
    setNextNextLaborToken(null)
  }

  const toggleUnitButtonState = async (index, optAssign) => {

    if (unitButtonVariants[index]) {

      const currentUnitButtonVariant = unitButtonVariants[index]?.variant;
  
      // console.log('   *********     : ', unitButtonVariants[index])
      // console.log('IN TOGGLE, currentUnitButtonVariant: ', unitButtonVariants)
      const updateButtonVariant = (variant, icon, isDisabled) => {
        unitButtonVariants[index].variant = variant;
        unitButtonVariants[index].icon = icon;
        unitButtonVariants[index].isDisabled = isDisabled;
      };
    
      if (optAssign) {
        if (optAssign === 'on') {
          // console.log('optassign is on, setting to check w/index: ', index)
          updateButtonVariant('withIconQuinary', 'check', true);
        } else {
          // console.log('optassign is on, setting to plus w/index: ', index)
          updateButtonVariant('withIconSenary', 'plus', false);
        }
      } else {
        if (currentUnitButtonVariant === 'withIconSenary') {
          // console.log('optassign is off, setting to check w/index: ', index)
          updateButtonVariant('withIconQuinary', 'check', true);
        } else {
          // console.log('optassign is off, setting to plus w/index: ', index)
          updateButtonVariant('withIconSenary', 'plus', false);
        }
      }

      // console.log('   -W-W-W-W-W SETTING UNITBUTTON VARIANTS TO THIS MANY BUTTONS: ', unitButtonVariants.length)
    
      setUnitButtonVariants([...unitButtonVariants]);
      return true
    } else {
      return false
    }
    
  };

  const toggleMaterialsButtonState = async (index, isDefaultMaterial) => {

    // only change button state if this is not a custom material
    if (isDefaultMaterial && materialButtonVariants[index]) {

      // console.log(' >>>>> BEING SENT THIS INDEX TO TOGGLE: ', index)
      // console.log(' >>>>> materialButtonVariants[index]: ', materialButtonVariants[index])

      const currentMaterialButtonVariant = materialButtonVariants[index].variant
      // console.log(' >>>>> currentMaterialButtonVariant: ', currentMaterialButtonVariant)
      let tmpArray = materialButtonVariants

      if (currentMaterialButtonVariant==='withIconSenary') {
        tmpArray[index].variant = 'withIconQuinary'
        tmpArray[index].icon = 'check'
        tmpArray[index].isDisabled = true
        // console.info(' >>>>> SETTING BUTTON TO CHECK AT INDEX: ', index)
        // console.log(' >>>>> SETTING BUTTON TO: ', tmpArray[index])
      } else {
        tmpArray[index].variant = 'withIconSenary'
        tmpArray[index].icon = 'plus'
        tmpArray[index].isDisabled = false
        // console.info(' >>>>> SETTING BUTTON TO PLUS AT INDEX: ', index)
        // console.log(' >>>>> SETTING BUTTON TO: ', tmpArray[index])
      }
      // console.log(' >>>>> SHOULD BE THE ARRAY WITH NEW BUTTON SETTING VARIANTS: ', tmpArray)
      setMaterialButtonVariants(tmpArray)
    }
  }

  const toggleLaborButtonState = async (index, optAssign) => {
    
    if (laborButtonVariants[index] ) {
      const currentLaborButtonVariant = laborButtonVariants[index].variant
      
      const updateButtonVariant = (variant, icon, isDisabled) => {
        laborButtonVariants[index].variant = variant;
        laborButtonVariants[index].icon = icon;
        laborButtonVariants[index].isDisabled = isDisabled;
      };
    
      if (optAssign) {
        if (optAssign === 'on') {
          updateButtonVariant('withIconQuinary', 'check', true);
        } else {
          updateButtonVariant('withIconSenary', 'plus', false);
        }
      } else {
        if (currentLaborButtonVariant === 'withIconSenary') {
          updateButtonVariant('withIconQuinary', 'check', true);
        } else {
          updateButtonVariant('withIconSenary', 'plus', false);
        }
      }
    
      setLaborButtonVariants([...laborButtonVariants]);
      return true
    }
    return false
  }

  const toggleAllUnitsButtonState = async (index, buttonIndex) => {
    // console.log(' ***** ABOUT TO TOGGLE THE ALL BUTTONS VARIANT INDEX: ', index)
    // console.log(' ***** ABOUT TO TOGGLE THE ALL BUTTONS VARIANT BUTTONINDEX: ', buttonIndex)
    // console.log('INCOMING INDEX: ', index)
    // console.log('INCOMING BUTTONINDEX: ', buttonIndex)
    if (Number(buttonIndex)>=0) index=buttonIndex
    // console.log(' ----- toggleAllUnitsButtonState INCOMING INDEX: ', index)
    if (!allUnitButtonVariants?.length>0) return
    // console.log('INCOMING: ', allUnitButtonVariants)
    
    // if (!index || allUnitButtonVariants?.length<1) return
    // if (!index) return

    // console.info('allUnitButtonVariants: ', allUnitButtonVariants)
    const currentAllUnitButtonVariant = allUnitButtonVariants[index]?.variant
    const updateButtonVariant = (variant, icon, isDisabled) => {
      allUnitButtonVariants[index].variant = variant;
      allUnitButtonVariants[index].icon = icon;
      allUnitButtonVariants[index].isDisabled = isDisabled;
    };

    if (currentAllUnitButtonVariant==='withIconSenary') {
      updateButtonVariant('withIconQuinary', 'check', true);
    } else {
      updateButtonVariant('withIconSenary', 'plus', false);
    }

    setAllUnitButtonVariants([...allUnitButtonVariants]);
  }

  const toggleAllLaborButtonState = async (index, buttonIndex) => {
    if (Number(buttonIndex)>=0) index=buttonIndex
    // console.log(' ----- toggleAllUnitsButtonState INCOMING INDEX: ', index)
    if (!allLaborButtonVariants?.length>0) return

    const currentAllLaborButtonVariant = allLaborButtonVariants[index].variant
    const updateButtonVariant = (variant, icon, isDisabled) => {
      allLaborButtonVariants[index].variant = variant;
      allLaborButtonVariants[index].icon = icon;
      allLaborButtonVariants[index].isDisabled = isDisabled;
    };

    if (currentAllLaborButtonVariant==='withIconSenary') {
      updateButtonVariant('withIconQuinary', 'check', true);
    } else {
      updateButtonVariant('withIconSenary', 'plus', false);
    }

    setAllLaborButtonVariants([...allLaborButtonVariants])
  }

  function AllTeamMembersNavigate({ isLaborDataLoading, hasLaborNext, hasLaborPrev, nextLabor, prevLabor }) {
    const disabledPrev = !hasLaborPrev || isLaborDataLoading
    const disabledNext = !hasLaborNext || isLaborDataLoading
    return (
      <HStack>
        <ButtonQuaternaryWithIcon 
          name='prevAllLabor'
          iconsize='26px'
          leftIcon='previous'
          value='Previous'
          onClick={prevLabor}
          isDisabled={disabledPrev}
        />
        <Spacer/>
        <ButtonQuaternaryWithIcon 
          name='nextAllLabor'
          iconsize='26px'
          rightIcon='next'
          value='Next'
          onClick={nextLabor}
          isDisabled={disabledNext}
        />
      </HStack>
    )
  }

  const componentWidth = useBreakpointValue({
    sm: '25%',
    md: '40%',
    lg: '45%',
    xl: '45%',
    '2xl': '45%'
  })

  const handleAllLaborAccordianClick = (index) => {
    if (index===-1 && hasShownAllLaborModal===false) {
      setHasShownAllLaborModal(true)
      onLaborModalOpen()
    }
  }

  const handleAllUnitsAccordianClick = (index) => {
    if (index===-1 && hasShownAllUnitsModal===false) {
      setHasShownAllUnitsModal(true)
      onUnitsModalOpen()
    }
  }

  const availableLaborButtons = (punchedLabor, laborButtonArr, divisionId) => {
    if (!punchedLabor || !laborButtonArr || !divisionId) return <Box></Box>
    return(
      <Box>
        {punchedLabor?.map((item, index) => {
          // console.warn('available labor: ', item)
          // buttons
          const laborButton = laborButtonArr[index]
          const buttonVariant = laborButton?.variant||'withIconQuinary'
          const rightIcon = laborButton?.icon||'plus'
          const isButtonDisabled = laborButton?.isDisabled||false

          // button label
          const teammember = item?.teamMember?.teamMember
          const goesBy = (teammember?.goesBy) ? `"${teammember?.goesBy}"` : ''
          // const nickname = (goesBy!==null) ? '"'+goesBy+'" ' : ''
          const firstName = teammember?.firstName+' '
          const lastName = teammember?.lastName
          const formattedName = `${firstName} ${goesBy} ${lastName}` 
          const laborButtonText = formattedName

          // labor obj
          const teamMemberName = formattedName
          const divisionJobClass = item?.teamMember?.teamMember?.billableDivisionJobClass
          // console.warn('divisionJobClass: ', item)
          const hourlyRate = divisionJobClass?.hourlyRate
          const overtimeRate = divisionJobClass?.overtimeRate
          const jobClass = {
            // label: divisionJobClass?.className, 
            label: divisionJobClass?.appDisplayName, 
            value: `${divisionId}#${divisionJobClass?.className}`
          }

          // employee department
          let departmentEmployee = "0#0"
          if (item?.dateDepartmentEmployeeTime) {
            const departmentEmployeeArr = item?.dateDepartmentEmployeeTime?.split('#')
            departmentEmployee = departmentEmployeeArr.slice(1,2).join('#')
          }

          // const isTempLabor = (teammember?.isTempLabor) ? true : false
          const teamMemberId = teammember?.id
          const laborObj = {
            TeamMemberId: teamMemberId,
            TeamMemberName: teamMemberName,
            // IsTempLabor: isTempLabor,
            StartTime: defaultStartTime,
            FinishTime: defaultEndTime,
            Lunch: defaultLunchDuration,
            PerDiemAmount: '0.00',  // pull this on a division or client basis?
            HourlyRate: hourlyRate,
            OvertimeRate: overtimeRate,
            JobClass: jobClass,
            RowTotal: '$0.00',
            DepartmentEmployee: departmentEmployee,
            LaborCategory: 'available', // this is being added from the clocked-in/available labor group
            OriginalIndex: index,
          }
          
          return(
            <ButtonSeptenaryWithIcon
              variant={buttonVariant}
              key={`add_labor_button_${index}`}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={rightIcon}
              isDisabled={isButtonDisabled}
              iconsize='22px'
              value={laborButtonText||''}
              onClick={() => updateLaborSummary('append', laborObj, index) }
            />
          )
        })}
      </Box>
    )
  }
  
  const unavailableLaborButtons = (unavailableLabor, unavailableLaborButtonArr, divisionId) => {
    if (!unavailableLabor || !unavailableLaborButtonArr || !divisionId) return <Box></Box>
    return(
      <Box>
        {/* {(allLaborLoadingStatus) && <Box><Center><Text textStyle='heading-1'>{allLaborLoadingStatus}</Text></Center></Box>} */}
        {/* if allTeamMembers has loaded, show all team members. If is loading, show data */}
        {unavailableLabor?.map((item, index) => {
          // console.log('unavailableLabor: ', item)
          // buttons
          const teamMemberId = item?.id
          const goesBy = (item?.goesBy) ? `"${item?.goesBy}"` : ''
          // const nickname = (hasnickname!==null) ? '"'+hasnickname+'" ' : ''
          const firstName = item.firstName
          const lastName = item.lastName
          const formattedName = `${firstName} ${goesBy} ${lastName}`
          const buttonText = formattedName
          let previousRecordCount = 0
          for (let i=0; i<currentLaborPage; i++) {
            previousRecordCount = (Number(previousRecordCount) + Number(pageNumberRecordCount[i]?.count))
          }

          const currentButtonIndex = (index+previousRecordCount)
          const unavailableLaborButton = unavailableLaborButtonArr[currentButtonIndex]
          const divisionJobClass = item?.billableDivisionJobClass
          // console.warn('divisionJobClass: ', divisionJobClass)
          const laborObj = {
            TeamMemberId: teamMemberId,
            TeamMemberName: formattedName,
            StartTime: defaultStartTime,
            FinishTime: defaultEndTime,
            Lunch: defaultLunchDuration,
            PerDiemAmount: '0.00',
            HourlyRate: divisionJobClass?.hourlyRate,
            OvertimeRate: divisionJobClass?.overtimeRate,
            JobClass: {
              // label: divisionJobClass?.className, 
              label: divisionJobClass?.appDisplayName, 
              value: `${currentDivisionId}#${divisionJobClass?.className}`
            },
            RowTotal: '0.00',
            LaborCategory: 'all', // this is the "all" labor group
            OriginalIndex: (currentButtonIndex),
            // DepartmentEmployee: departmentEmployee - not pulled for allLabor
          }
          return(
            <ButtonSeptenaryWithIcon
              variant={unavailableLaborButton?.variant||'withIconSenary'}
              key={`add_all_labor_button_${currentButtonIndex}_${uuidv4}`}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={unavailableLaborButton?.icon||'plus'}
              isDisabled={unavailableLaborButton?.isDisabled||(isLaborDataLoading||false)}
              iconsize='22px'
              value={buttonText||''}
              onClick={() => {
                updateLaborSummary('append-unavailable', laborObj, index)
              }}
            />
          )
        })}

        <Box mt='25px'><AllTeamMembersNavigate {...{ hasLaborNext, hasLaborPrev, prevLabor, nextLabor, isLaborDataLoading }} /></Box>
      </Box>
    )
  }

  const inspectedUnitButtons = (inspectedUnits, inspectedUnitButtonArr, billableHours) => {
    if (!inspectedUnits) return <Box></Box>
    return(
      <Box>
        {inspectedUnits?.map((item, index) => {
          const unitButton = inspectedUnitButtonArr[index]
          const unit = item?.unit
          // console.log('ITEM: ', item)

          // price data is now in subType priceCode price
          // subtype priceCode rateType rateType
          // subType priceCode rateType description

          const rateType = unit?.subType?.priceCode?.rateType?.rateType           // 'hour'
          const rateDescription = unit?.subType?.priceCode?.rateType?.description // '/Hour'
          const ratePrice = unit?.subType?.priceCode?.price?.replaceAll(',', ''); // '100.00' 
          // const hourlyRate = (unit?.rates?.hourlyRate) ? unit.rates?.hourlyRate : '0.00'
          // const shiftRate = (unit?.rates?.shiftRate) ? unit.rates?.shiftRate : '0.00'
          // const billableRatex = (hourlyRate!=='0.00') 
          //   ? {rate: hourlyRate, unit: '/Hour', total: Number(billableHours)*Number(hourlyRate)} 
          //   : {rate: shiftRate, unit: '/Shift', total: Number(shiftRate)}

          const billableTotal = (rateType==='hour') ? Number(billableHours)*Number(ratePrice) : Number(ratePrice)
          const billableRate = {rate: ratePrice, unit: rateDescription, total: billableTotal}

          const unitSubType = (unit?.subType?.name) ? `/ ${unit.subType.name}` : ''
          const unitButtonText = `${unit?.code} - ${unit?.type?.name} ${unitSubType}`
          const unitDescrition = `${unit?.type?.name} ${unitSubType}`
          const requiresOperator = unit?.requiresOperator
          const requiresDriver = unit?.requiresDriver

          // console.log(' ########  unitButton: ', unitButton)
          // console.log(' ########  unitButtonText: ', unitButtonText)

          const unitObj = {
            Id: item.id,
            UnitId: item.unitId,
            Description: unitDescrition,
            OrigDescription: unitDescrition,
            UnitCode: unit?.code,
            UnitRate: `$${billableRate?.rate}${billableRate?.unit}`,
            RowTotal: `${billableRate?.total}`,
            UnitCategory: 'available',
            OriginalIndex: index,
            isAuxillaryUnit: false,
            isCustomEquipment: false,
            requiresOperator: requiresOperator,
            requiresDriver: requiresDriver,
          }

          // console.log(' ~~~~~ ADDING UNT BUTTON: ', unitButtonText)

          return(
            <ButtonSeptenaryWithIcon
              variant={unitButton?.variant||'withIconQuinary'}
              key={`add_unit_button_${index}`}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={unitButton?.icon||'plus'}
              isDisabled={unitButton?.isDisabled||false}
              iconsize='22px'
              value={unitButtonText||''}
              onClick={() => updateUnitSummary('append', unitObj, index) }
            />
          )
        })}
        </Box>
    )
  }

  const uninspectedUnitButtons = (uninspectedUnits, uninspectedUnitButtonArr, billableHours) => {
    if (!uninspectedUnits || !uninspectedUnitButtonArr || !billableHours) return <Box></Box>
    // console.log('uninspectedUnits unit: ', uninspectedUnits)
    return(
      <Box>
        {uninspectedUnits?.map((unit, index) => {
          if (unit?.visible===false) return 
          // console.log('uninspected unit: ', unit)
          // let previousRecordCount = 0
          // for (let i=0; i<currentUnitsPage; i++) {
          //   previousRecordCount = (Number(previousRecordCount) + Number(unitsPageNumberRecordCount[i]?.count))
          // }

          // const currentButtonIndex = (index+previousRecordCount)
          const currentButtonIndex = (index)
          const uninspectedUnitButton = uninspectedUnitButtonArr[currentButtonIndex]
          const unitSubType = (unit?.subType?.name) ? `/ ${unit.subType.name}` : ''
          const unitButtonText = `${unit?.code} - ${unit?.type?.name} ${unitSubType}`
          const unitDescrition = `${unit?.type?.name} ${unitSubType}`
          const requiresOperator = unit?.requiresOperator
          const requiresDriver = unit?.requiresDriver

          const rateType = unit?.subType?.priceCode?.rateType?.rateType           // 'hour'
          const rateDescription = unit?.subType?.priceCode?.rateType?.description // '/Hour'
          const ratePrice = unit?.subType?.priceCode?.price?.replaceAll(',', ''); // '100.00' 
          const billableTotal = (rateType==='hour') ? Number(billableHours)*Number(ratePrice) : Number(ratePrice)
          const billableRate = {rate: ratePrice, unit: rateDescription, total: billableTotal}

          // console.warn('unit: ', unit)

          let isVisible = true
          if (showAllUnitsInSearch===true) {
            isVisible = true
          } else {
            isVisible = unit?.visible||true
          }
          
          // const hourlyRate = (unit?.rates?.hourlyRate) ? unit.rates?.hourlyRate : '0.00'
          // const shiftRate = (unit?.rates?.shiftRate) ? unit.rates?.shiftRate : '0.00'
          // const billableRate = (hourlyRate!=='0.00') 
          //   ? {rate: hourlyRate, unit: '/Hour', total: Number(billableHours)*Number(hourlyRate)} 
          //   : {rate: shiftRate, unit: '/Shift', total: Number(shiftRate)}

          const unitObj = {
            UnitId: unit.id,
            Description: unitDescrition,
            OrigDescription: unitDescrition,
            UnitCode: unit?.code,
            // UnitRate: `${billableRate.unit}`,
            UnitRate: `$${billableRate.rate}${billableRate.unit}`,
            RowTotal: `${billableRate.total}`,
            SubType: unit?.subType?.name,
            Type: unit?.type?.name,
            UnitCategory: 'unavailable', // this is all unit group
            OriginalIndex: (currentButtonIndex),
            isAuxillaryUnit: false,
            isCustomEquipment: false,
            requiresOperator: requiresOperator,
            requiresDriver: requiresDriver,
            isVisible: isVisible,
          }

          if (isVisible===true) {
            return(
              <ButtonSeptenaryWithIcon
                variant={uninspectedUnitButton?.variant||'withIconSenary'}
                key={'add_all_units_button_'+currentButtonIndex}
                mr='25px'
                mt='25px'
                name='plus'
                rightIcon={uninspectedUnitButton?.icon||'plus'}
                isDisabled={uninspectedUnitButton?.isDisabled||(isUnitsDataLoading||false)}
                iconsize='22px'
                value={unitButtonText||''}
                onClick={() => { updateUnitSummary('append-unavailable', unitObj, currentButtonIndex) }}
              />
            )
          }
          
        })}

        {/* <Box mt='25px'>
          <AllUnitsNavigate {...{ hasUnitsNext, hasUnitsPrev, prevUnits, nextUnits, isUnitsDataLoading }} />
        </Box> */}
      </Box>
    )
  }

  const searchUnits = (searchStr) => {
    let allUnitsToSearch = originalAllUnits
    console.info('allUnitsToSearch: ', allUnitsToSearch)
    const currentSearchStringLength = searchStr.length
    
    if (searchStr.length<currentEquipmentSearchStringLength) {
      // they deleted something from the string
      // console.info('THEY DELETED SOMETHING FROM THE STRING')
      setAllUnits(originalAllUnits)
    }
    // need to see if this string length is shorter than the previous one. If they are deleting something they typed, we need to search
    if (currentSearchStringLength===0) {
      // console.info('got empty search string, restting', originalAllUnits)
      setAllUnits(originalAllUnits)
      setShowAllUnitsInSearch(true)
      return
    } else {
      setShowAllUnitsInSearch(false)
    }

    let searchString = searchStr.toLowerCase()
    allUnitsToSearch?.forEach(unit => {
      unit.searchable = unit?.code?.toLowerCase()+' '+unit?.type?.name?.toLowerCase()+' '+unit?.subType?.name?.toLowerCase()
      unit.visible = true
    })

    // filter out the items we want to hide and set a "visible" property to false
    // const notMatchedResults = allUnitsToSearch.filter(obj => {
    //   return !obj.searchable.includes(searchString) 
    // });
    const searchResults = allUnitsToSearch.map(obj => {
      return {
        ...obj,
        visible: obj.searchable.includes(searchString),
      }
    });
    setAllUnits(searchResults);
    setCurrentEquipmentSearchStringLength(searchStr.length)
    console.info('searchResults: ', searchResults)
  }

  const materialButtons = (availableMaterials, availableMaterialButtonArr) => {
    // (availableMaterials?.length>0) && console.warn('HERE', availableMaterials)
    if (!availableMaterials || !availableMaterialButtonArr ) return <Box></Box>
    return(
      <Box> 
        {availableMaterials?.map((material, index) => {
          const materialButtonText = material?.description
          const materialButton = availableMaterialButtonArr[index]
          const materialObj = {
            Id: material.id,
            Description: material.description,
            Notes: material.notes,
            Price: '$'+material.price,
            Quantity: material.quantity,
            RowTotal: '$'+material.price,
            isDefaultMaterial: true,
            isCustomMaterial: false,
            OriginalIndex: index
          }
          return(
            <ButtonSeptenaryWithIcon
              variant={materialButton?.variant||'withIconQuinary'}
              key={'add_material_button_'+index}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={materialButton?.icon||'plus'}
              isDisabled={materialButton?.isDisabled||false}
              iconsize='22px'
              value={materialButtonText||''}
              onClick={() => { updateMaterialSummary('append', materialObj, index) }}
            />
          )
        })
        }
      </Box> 
    )
  }

  const handleEstimatedLaborConverted = async () => {
    // setAllEstimatedLaborConverted(allConverted)
  }

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };

  const handleCCRecipientDelete = async (index) => {

    // console.info('ccRecipientEmailsSelected: ',ccRecipientEmailsSelected)
    // const deletedOption = ccRecipientEmailsSelected[index]
    setCcEmailOptions(prevEmails => [...prevEmails, ccRecipientEmailsSelected[index]]);

    setCcRecipientEmailsSelected(ccRecipientEmailsSelected.filter((_, ccRecipientEmailsSelectedIndex) => ccRecipientEmailsSelectedIndex !== index));

  }

  const handleCCRecipientsChange = async (event, action) => {
    if (!action) return
    // verifiedEmailDomains holds the verified domains for ccRecipients
    // if the user adds a new email address, we need to check if the domain is verified

    console.info('verifiedEmailDomains: ', verifiedEmailDomains)
    console.info('action: ', action)
    //console.info('email: ', action?.value)
    console.info('event: ', event)
    
    // split the email address on @
    // check if it is a valid domain

    // {action: "create-option", name: "ccRecipients", option: {label: "Test", value: "Test", __isNew__: true}}
    // {action: "remove-value", removedValue: {label: "Test", value: "Test", __isNew__: true}, name: "ccRecipients"}

    // if @domain.com is verified, continue
    // otherwise we need to show an error message
    // email addresses that end in @domain.com can be added without needing to be verified first.
    // if the email address does not end in @domain.com, we need to show a message that the email address needs to be verified first.

    

    if (action?.action === "remove-value") {
      // remove from database
      console.info('email: ', action?.removedValue?.value)

    }

    let isValidEmail;
    if (action?.action === "create-option") {
      // add to database
      const emailAddress = action?.option?.value
      console.info('email: ', emailAddress)
      isValidEmail = await validateEmail(emailAddress)
      // console.info('isValidEmail: ', isValidEmail)
      if (isValidEmail===false) {
        setError('ccRecipients', { type: "manual", message: `Email address "${emailAddress}" is not formatted correctly.` })
      } else {
        // add the email to the database
        // clientId is the current client selected

        // check if this @domain is in the valid domains list
        // const domain = '@'+emailAddress.split('@').pop()
        const domainPart = emailAddress.substring(emailAddress.lastIndexOf('@') + 1);
        const domain = '@' + domainPart;
        console.info('domain: ', domain)
        console.info('verifiedEmailDomains: ', verifiedEmailDomains)
        const isVerified = verifiedEmailDomains.includes(domain)
        
        // need to let the user know if the domain wasn't in the list of verified domains, it will have to be verified first.
        if (isVerified===false) {
          alert('This email address is not verified. It will need to be verified by an admin before it can be used as a recipient for emails.')
        }
        // generate the props to pass to addCLientCCEmail
        // const props = {
        //   clientId: clientId,
        //   addedById: currentTeamMemberId,
        //   email: emailAddress,
        //   isActive: true,
        //   isVerified: isVerified
        // }
        // const addedCCEmailId = await addClientCCEmail(props)
        // let tmpArray = ccEmailOptions
        // tmpArray.push({label: emailAddress, value: clientId})
        // setCcEmailOptions(tmpArray)
        setCcEmailOptions(prevEmails => [...prevEmails, {label: emailAddress, value: clientId}]);

        // tmpArray = ccRecipientEmailsSelected
        // tmpArray.push(emailAddress)
        // setCcRecipientEmailsSelected(tmpArray)
        setCcRecipientEmailsSelected(prevEmails => [...prevEmails, emailAddress]);

        
      }
      
    }

    if (action?.action === "select-option") {

      // add this email to the cc list
      const emailAddress = event?.label
      // setCcRecipientEmailsSelected(prevEmails => [...prevEmails, emailAddress]);
      setCcRecipientEmailsSelected(prevEmails => [...prevEmails, {label: emailAddress, value: clientId}]);

      // and remove it from the dropdown
      // Use setState with a functional update to ensure you're working with the most current state
      setCcEmailOptions(currentEmails => currentEmails.filter(email => email.label !== emailAddress));
    }

  }

  return(

    <Container 
      pb='25px' 
      as="form" 
      ref={formRef}>
      
      <CenteredAllLaborModal
        isModalOpen={isLaborModalOpen}
        onModalClose={onLaborModalClose}
      />

      <CenteredAllUnitsModal
        isModalOpen={isUnitsModalOpen}
        onModalClose={onUnitsModalClose}
      />

      <CenteredSubmitDJTModal
        isModalOpen={isSubmitModalOpen}
        onModalClose={onSubmitModalClose}
        content={submitModalContent}
        closeButtonDisabled={submitModalCloseDisabled}
        handleSubmitModalClosed={handleSubmitModalClosed}
        progress={submissionProgress}
      />
      
      <CenteredConfirmContactDataChange
        isAlertOpen={isConfirmContactDataChangeModalOpen}
        onAlertClose={onConfirmContactDataChangeClose}
        cancelRef={confirmContactDataChangeCancelRef}
        triggerType={contactChangedTypeClicked}
        closeButtonDisabled={confirmContactDataChangeCloseDisabled}
        handleConfirmContactDataChangeClosed={handleConfirmContactDataChangeClosed}
        register={register}
        errors={errors}
        handleClientContactEmailChanged={handleClientContactEmailChanged}
        handleSaveChanges={handleSaveClientContactChanges}
        setValue={setValue}
        getValues={getValues}
        setFocus={setFocus}
        clearErrors={clearErrors}
        trigger={trigger}
        isUserMobile={isUserMobile}
        scrollBehavior={scrollBehavior}
      />

      <CenteredLoadDuplicateEditDJTModal
        loadType={srcAction}
        isModalOpen={isLoadDuplicateModalOpen}
        onModalClose={onLoadDuplicateModalClose}
        content={loadDuplicateModalContent}
        laborLength={laborLength}
        duplicateEditLaborLoaded={isDuplicateLaborLoaded}
        duplicateEditLaborError={duplicatedLaborError}
        unitsLength={unitsLength}
        duplicateEditUnitsLoaded={isDuplicateEquipmentLoaded}
        duplicateEditUnitError={duplicatedUnitError}
        closeButtonDisabled={loadDuplicatetModalCloseDisabled}
        handleLoadDuplicateModalClosed={handleLoadDuplicateModalClosed}
        progress={loadDuplicateProgress}
      />

      {(laborIsLocked && !isEstimate) && (
        <>
          <ModuleBoxDrawerAttention>
            <Box>
              <Text as="span" textStyle="heading-1">Ticket Submission is Locked</Text>
            </Box>
            <Box mt={'10px'}>
              <Text>Assign all labor entries and save as a draft to unlock ticket.</Text>
            </Box>
          </ModuleBoxDrawerAttention>
          <Box mb='25px'></Box>
        </>
      )}

      {(isReviewing) && (
        <>
          <ModuleBoxDrawerAttention>
            <Box>
              <Text as="span" textStyle="heading-1">Approval Required Issues</Text>
          </Box>
          <Box mt={'10px'}>
            <Text>{bouncedAdminReason}</Text>
          </Box>
          </ModuleBoxDrawerAttention>
          <Box mb='25px'></Box>
        </>
      )}

      <JobOverview
        register={register}
        errors={errors}
        control={control}
        componentWidth={componentWidth}
        onClose={onClose}
        isDuplicate={isDuplicate}
        isEdit={isEdit}
        isChild={isChild}
        isClientNew={isClientNew}
        isEstimate={isEstimate}
        clientContactDisabled={clientContactDisabled}
        handleValidateEmailAddress={handleValidateEmailAddress}
        handleClientBillingEmailChanged={handleClientBillingEmailChanged}
        handleClientContactEmailChanged={handleClientContactEmailChanged}
        handleDjtPoChanged={handleDjtPoChanged}
        handleDjtDateChanged={handleDjtDateChanged}
        setTypedDjtDate={setTypedDjtDate}
        handleJobDescriptionChange={handleJobDescriptionChange}
        handleCompanyDetailsChanged={handleCompanyDetailsChanged}
        handlePhoneEmailChanged={handlePhoneEmailChanged}
        companySelectedOption={companySelectedOption}
        clientCompanies={clientCompanies}
        divisionHasOneClient={divisionHasOneClient}
        handleClientChanged={handleClientChanged}
        clientPOs={availableClientPOs}
        clientPoSelectedOption={clientPoSelectedOption}
        clientContractSelectedOption={clientContractSelectedOption}
        availableClientContracts={availableClientContracts}
        contactClientSelectRef={contactClientSelectRef}
        clientContacts={clientContacts||[]}
        handleClientContactChanged={handleClientContactChanged}
        clientContactSelected={clientContactSelected}
        fileInputRef={fileInputRef}
        handleFileInputChange={handleFileInputChange}
        onContractUploadButtonClick={onContractUploadButtonClick}
        handleClientContractChanged={handleClientContractChanged}
        showFileUploadSpinner={showFileUploadSpinner}
        isUserMobile={isUserMobile}
      />

      {/* Labor Start */}
      {(!hideModuleFromClientView) && <ModuleBoxDrawer mt='25px'>
        {/* header */}
        <Flex>
          <Box>
            <Box>
              <Text as="span" mb={'10px'} textStyle='heading-1'>Labor</Text>
            </Box>
            <Box>
              <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Add available labor for: {djtDateLong}.</Text>
            </Box>
            {(isTicketDateWeekend) && 
              <Box paddingTop={'5px'} display="flex" alignItems="flex-end">
                <AvailableIcons boxSize="22px" color="var(--caution-yellow)" iconRef="alerttriangle" />
                <Text as="span" color="var(--dark-text-grey-2)" textStyle="text-2" ml={2} lineHeight="1" position="relative" top="2px">
                  Overtime rates will be applied to this job ticket because the date falls on a weekend day.
                </Text>
              </Box>
            }
          </Box>
        <Spacer />
        {(laborIsLocked && !isEstimate) ? <></> : <ButtonOctonaryWithIcon
            key={'add_3rd_party_button'}
            mr='25px'
            name='add_3rd_party_labor'
            rightIcon={'plus'}
            iconsize='22px'
            value={'Add 3rd Party Labor'}
            onClick={() => {
              const laborObj = {
                TeamMemberId: uuidv4(),
                TeamMemberName: '3rd Party Labor',
                IsTempLabor: false,
                StartTime: defaultStartTime,
                FinishTime: defaultEndTime,
                Lunch: defaultLunchDuration,
                PerDiemAmount: '0.00',  // pull this on a division or client basis?
                HourlyRate: '50',
                OvertimeRate: '75',
                // JobClass: 'custom',
                JobClass: thirdPartyJobClass,
                RowTotal: '$0.00',
                DepartmentEmployee: '',
                LaborCategory: 'available', // this is being added from the clocked-in/available labor group
                OriginalIndex: laborFields?.length,
              }
              updateLaborSummary('append', laborObj, laborFields?.length)
            }}
          />}
        </Flex>
        

        {/* labor buttons */}
        <fieldset disabled={isLaborDisabled}>
        {(!isEstimate && laborIsLocked!==true) && availableLaborButtons(laborForDay, laborButtonVariants, currentDivisionId )}

        {/* accordian for all labor */}
        {(!isEstimate && laborIsLocked!==true) && <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          mt='25px'
          p='25px'>
          <Accordion 
            allowToggle 
            index={accordianLaborIndex} 
            onChange={setAccordianLaborIndex}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton 
                onClick={() => {
                  setShowAllTeamMembers(true)
                  handleAllLaborAccordianClick(accordianLaborIndex)
                }} 
                _expanded={{ bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text as="span" textStyle='text-2'>Add Unrecorded Labor</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
              <AccordionPanel>
                {unavailableLaborButtons(allTeamMembers, allLaborButtonVariants, currentDivisionId)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>}

        {/* let matched = billableRatesJobClass.data.filter(item => item.className === "Operator Class III") */}
        {(isEstimate) && <Box>
          <ButtonOctonaryWithIcon
              key={'add_estimated_labor_button'}
              mr='25px'
              mt='25px'
              name='add_estimated_labor'
              rightIcon={'plus'}
              iconsize='22px'
              value={'Add Labor Job Class'}
              onClick={() => {
                // const matchProp = (laborFields?.length>0) ? "Recovery Operator/Operator Class III" : "Division Manager"
                const matchProp = (laborFields?.length>0) ? "III" : "Division Manager"
                // console.info('matchProp: ', matchProp)
                // const match = billableRatesJobClass.data.filter(item => item.className === matchProp)
                // const match = billableRatesJobClass.data.filter(item => item?.className?.toLowerCase().includes(matchProp.toLowerCase()))
                // const match = billableRatesJobClass.data.filter(item => item?.appDisplayName?.toLowerCase().includes(matchProp.toLowerCase()))
                const match = billableRatesJobClass.filter(item => item?.appDisplayName?.toLowerCase().includes(matchProp.toLowerCase()))
                //item?.className?.toLowerCase().includes(matchProp.toLowerCase())
                // console.info('match: ', match)
                const divisionPositionSplit = match[0]?.divisionPosition.split('#')
                const appDisplayName = match[0].appDisplayName
                // console.info('divisionPositionSplit: ', divisionPositionSplit)
                const hourlyRate = match[0]?.hourlyRate
                const overtimeRate = match[0]?.overtimeRate
                let laborObj = {
                  TeamMemberId: 'labor-estimate-'+uuidv4(),
                  TeamMemberName: 'Team Member',
                  IsTempLabor: false,
                  StartTime: defaultStartTime,
                  FinishTime: defaultEndTime,
                  Lunch: defaultLunchDuration,
                  PerDiemAmount: '0.00',
                  HourlyRate: hourlyRate, //hourlyRate,
                  OvertimeRate: overtimeRate, //overtimeRate,
                  // JobClass: {label: divisionPositionSplit[1], value: `${divisionPositionSplit[0]}#${divisionPositionSplit[1]}`},
                  JobClass: {label: appDisplayName, value: `${divisionPositionSplit[0]}#${divisionPositionSplit[1]}`},
                  RowTotal: '$0.00',
                  DepartmentEmployee: '', // we can keep this blank, just like a temp employee '01-0013', //departmentEmployee,
                  LaborCategory: 'available', // this is being added from the clocked-in/available labor group
                  OriginalIndex: laborFields?.length||0,
                }
                updateLaborSummary('append', laborObj, laborFields?.length||0)
              }}
            />
        </Box>}
        </fieldset>

        <Divider mt='30px' mb='25px'/>

        {/* Labor summary header */}
        <div id="laborscrollto"></div>
        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Labor Summary</Text>
          {/* <ButtonDenaryPlain ml={'10px'} value={'log laborSummary'} onClick={(e) => {console.log(' _____ LABOR SUMMARY: ', laborSummary)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log laborFields'} onClick={(e) => {console.log(' _____ LABOR FIELDS: ', laborFields)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log allLaborButtonVariants'} onClick={(e) => {console.log(' _____ ALL LABBOR BTN VAR: ', allLaborButtonVariants)}}></ButtonDenaryPlain> */}
          
        </Box>
        <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove labor entries.</Text>
          {/* <Button onClick={() => {
            handleValidateEmailAddress()
            console.log('--- jobClassSelected:', jobClassSelected)
          }}>DEBUG</Button>  */}
        </Box>
        {/* labor summary */}
        <LaborSummaryTable 
          tableData={laborFields}
          jobClasses={billableRatesJobClass||[]}
          laborSelectOptions={operatorsDriversSelectOptions||[]}
          ticketHasConvertedLabor={ticketHasConvertedLabor}
          updateLaborSummary={updateLaborSummary}
          handleLaborConversion={handleLaborConversion}
          handleLaborCustomNameChange={handleLaborCustomNameChange}
          handleLaborJobClassChange={handleLaborJobClassChange}
          jobClassSelected={jobClassSelected}
          handleLaborStartTimeChange={handleLaborStartTimeChange}
          handleLaborFinishTimeChange={handleLaborFinishTimeChange}
          handleLaborPerDiemChange={handleLaborPerDiemChange}
          handleLaborLunchChange={handleLaborLunchChange}
          handleLaborRowTotalChange={handleLaborRowTotalChange}
          toggleLaborButtonState={toggleLaborButtonState}
          toggleAllLaborButtonState={toggleAllLaborButtonState}
          laborTotalAmount={laborSummaryTotal}
          calculateLaborTotal={calculateLaborTotal}
          djtTotalAmount={djtRunningTotal||0}
          control={control}
          register={register}
          errors={errors}
          useDebounce={useDebounce}
          handleEstimatedLaborConverted={handleEstimatedLaborConverted}
          isEstimate={isEstimate}
          doBillOvertime={isTicketDateWeekend}
          // handleLaborTotalInputClick={handleLaborTotalInputClick}
        />
      </ModuleBoxDrawer>}

      {(!hideModuleFromClientView) && <ModuleBoxDrawer mt='25px'>
        {/* header */}
        <Flex>
        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Equipment</Text><br/>

          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Add available equipment for: {djtDateLong}.</Text>
        </Box>
        <Spacer />
        <Box>
        {/* <Button onClick={() => console.log('--- auxUnitOptions:', auxillaryUnitOptions)}>DEBUG</Button>  */}
          {/* Add a blank row to units for "rental" equipment */}
          <ButtonOctonaryWithIcon
            key={'add_auxillary_equipment_button'}
            mr='25px'
            name='add_auxillary_equipment'
            rightIcon={'plus'}
            iconsize='22px'
            value={'Add Stand-Alone Auxiliary Unit'}
            onClick={() => {
              const nextUnitCode = (currentAuxUnitCode>=798) ? 700 : currentAuxUnitCode+1
              // console.log(' ~~~~~ CHANGING AUX UNIT CODE: ', nextUnitCode)
              setCurrentAuxUnitCode(nextUnitCode)
              let unitObj = {
                UnitId: nextUnitCode,
                Description: '',
                OrigDescription: '',
                UnitCode: nextUnitCode,
                UnitRate: '$0.00',
                RowTotal: '$0.00',
                UnitCategory: 'auxillary', // this is available unit group
                isAuxillaryUnit: true,
                isCustomEquipment: false,
                isCustomAuxillary: true,
                // Quantity: '1',
              }
              updateUnitSummary('append-auxillary', unitObj)
            }}
          />

          <ButtonOctonaryWithIcon
            key={'add_custom_equipment_button'}
            mr='25px'
            name='add_custom_equipment'
            rightIcon={'plus'}
            iconsize='22px'
            // value={'Add Custom Equipment'}
            value={'Add Rental'}
            onClick={() => {
              const nextUnitCode = (currentAuxUnitCode>=798) ? 700 : currentAuxUnitCode+1
              // console.log(' ~~~~~ CHANGING AUX UNIT CODE: ', nextUnitCode)
              setCurrentAuxUnitCode(nextUnitCode)
              let unitObj = {
                UnitId: nextUnitCode,
                Description: '',
                OrigDescription: '',
                UnitCode: nextUnitCode,
                UnitRate: '$0.00',
                RowTotal: '$0.00',
                UnitCategory: 'custom', // this is available unit group
                isAuxillaryUnit: false,
                isCustomEquipment: true,
                Quantity: '1',
              }
              updateUnitSummary('append-custom', unitObj)
            }}
          />
        </Box>
        </Flex>

        <fieldset disabled={isUnitsDisabled}>
        {inspectedUnitButtons(unitsForDay, unitButtonVariants, maxBillableHours)}

        {/* accordian for all units */}
        <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          mt='25px'
          p='25px'
          >
          <Accordion 
            allowToggle 
            index={(isEstimate) ? 0 : accordianUnitIndex} 
            onChange={setAccordianUnitIndex}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton 
                onClick={() => {
                  setShowAllUnits(true)
                  handleAllUnitsAccordianClick(accordianUnitIndex)
                }} 
                _expanded={{ bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box 
                  as="span" 
                  flex='1' 
                  textAlign='left'>
                  <Text as="span" 
                    textStyle='text-2'
                    >{(isEstimate) ? 'Add Primary Unit' : 'Add Un-inspected Primary Unit'}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
              <AccordionPanel>
                {/* Search */}
                <Box w='350px'>
                  <TextInput
                    register={register}
                    errors={errors}
                    w='350px'
                    fieldname="djtEquipmentSearch"
                    onChange={(e) => { searchUnits(e.target.value) }}
                    prettyname="Equipment Search"
                    placeholder="Equipment Search"
                    isRequired={false} 
                    isReadOnly={false}
                  />
                </Box>
                {uninspectedUnitButtons(allUnits, allUnitButtonVariants, maxBillableHours)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        </fieldset>

        {/* divider */}
        <Divider mt='30px' mb='25px'/>

        {/* Equipment summary header */}
        <div id="unitscrollto"></div>
        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Equipment Summary</Text>
          {/* <ButtonDenaryPlain ml={'10px'} value={'log all button var'} onClick={(e) => {console.log(' _____ ALL UNIT BTN VAR: ', allUnitButtonVariants)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log deleted'} onClick={(e) => {console.log(' _____ REMOVED EQUIPMENT: ', removedEquipment)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log added'} onClick={(e) => {console.log(' _____ ADDED EQUIPMENT: ', addedEquipment)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log updated'} onClick={(e) => {console.log(' _____ UPDATED EQUIPMENT: ', updatedEquipment)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log existing'} onClick={(e) => {console.log(' _____ EXISTING EQUIPMENT: ', existingEquipment)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log unit form'} onClick={(e) => {console.log(' _____ UNIT FORM: ', unitFields)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log all units'} onClick={(e) => {console.log(' _____ ALL UNITS: ', allUnits)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log aux opt arr'} onClick={(e) => {console.log(' _____ AUX OPT ARR: ', auxillaryUnitOptions)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log unitSummary arr'} onClick={(e) => {console.log(' _____ UNITSUMMARY ARR: ', unitSummary)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log unitFields arr'} onClick={(e) => {console.log(' _____ UNITFILEDS ARR: ', unitFields)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log operators'} onClick={(e) => {console.log(' _____ OPERATORS ARR: ', selectedOperators)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log drivers'} onClick={(e) => {console.log(' _____ DRIVERS ARR: ', selectedDrivers)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log unitsforday'} onClick={(e) => {console.log(' _____ UNITSFORDAY: ', unitsForDay)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log drivers stat'} onClick={(e) => {console.log(' _____ UNITDRVRSTAT: ', unitDriversStatus)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log operators stat'} onClick={(e) => {console.log(' _____ OPERATORSSTAT: ', unitOperatorsStatus)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log ismissing driver'} onClick={(e) => {console.log(' _____ MISSDRVR: ', currentlyMissingDriver)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log ismissing operator'} onClick={(e) => {console.log(' _____ MISSOPER: ', currentlyMissingOperator)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log current custom aux code'} onClick={(e) => {console.log(' _____ AUX CODE: ', currentAuxUnitCode)}}></ButtonDenaryPlain> */}
        </Box>
        <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove equipment entries.</Text>
        </Box>

        <EquipmentSummaryTable
          tableData={unitFields}
          equipmentFinishedLoading={isDuplicateEquipmentLoaded}
          unitOperators={unitOperators}
          operatorsDriversSelectOptions={operatorsDriversSelectOptions}
          handleDriverChange={handleDriverChange}
          selectedDrivers={selectedDrivers}
          handleOperatorChange={handleOperatorChange}
          selectedOperators={selectedOperators}
          updateUnitSummary={updateUnitSummary}
          toggleUnitsButtonState={toggleUnitButtonState}
          toggleAllUnitsButtonState={toggleAllUnitsButtonState}
          handleAuxillaryUnitChange={handleAuxillaryUnitChange}
          handleCustomUnitChange={handleCustomUnitChange}
          auxillaryUnitOptions={auxillaryUnitOptions}
          allAuxillaryUnitOptions={allAuxillaryUnitOptions}
          handleCustomAuxillaryUnitChange={handleCustomAuxillaryUnitChange}
          handleUnitRowTotalChange={handleUnitRowTotalChange}
          handleCustomEquipmentChanged={handleCustomEquipmentChanged}
          maxAuxillaryOptionsSelected={maxAuxillaryOptionsSelected}
          unitTotalAmount={unitSummaryTotal}
          djtTotalAmount={djtRunningTotal}
          costItem={costItem}
          control={control}
          register={register}
          errors={errors}
          setError
          getValues={getValues}
          handleUnitQuantityChange={handleUnitQuantityChange}
          isEdit={isEdit}
          isEstimate={isEstimate}
         />
      </ModuleBoxDrawer>}

      {/* Materials Start */}
      {(!hideModuleFromClientView) && <fieldset disabled={isMaterialDisabled}><ModuleBoxDrawer mt='25px'>
        {/* header */}
        <Flex>
        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Materials </Text><br/>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Add materials.</Text>
        </Box>
        <Spacer />
        <Box>
          {/* <Button onClick={() => console.log(materialSummary)}>DEBUG</Button> */}
          <ButtonOctonaryWithIcon
            key={'add_custom_materials_button'}
            mr='25px'
            name='add_custom_materials'
            rightIcon={'plus'}
            iconsize='22px'
            value={'Add Custom Materials'}
            onClick={() => {
              let materialObj = {
                Id: uuidv4(),
                Description: "",
                Notes: "",
                Price: '$0.00',
                Quantity: '1',
                RowTotal: '$0.00',
                isAuxillaryUnit: false,
                isDefaultMaterial: false,
                isCustomMaterial: true,
              }
              // materialAppend(materialObj)
              updateMaterialSummary('append-custom', materialObj, null)
            }}
          />
        </Box>
        </Flex>

        {materialButtons(materialsForDay, materialButtonVariants )}

        {/* divider */}
        <Divider mt='30px' mb='25px'/>

        {/* Material summary header */}
        <div id="materialscrollto"></div>
        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Materials Summary</Text>
          {/* <ButtonDenaryPlain ml={'10px'} value={'log deleted'} onClick={(e) => {console.log(' _____ REMOVED MATERIAL: ', removedMaterial)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log added'} onClick={(e) => {console.log(' _____ ADDED MATERIAL: ', addedMaterial)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log updated'} onClick={(e) => {console.log(' _____ UPDATED MATERIAL: ', updatedMaterial)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log existing'} onClick={(e) => {console.log(' _____ EXISTING MATERIAL: ', existingMaterial)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log material form'} onClick={(e) => {console.log(' _____ MATERIAL FORM: ', materialFields)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log materialSummary arr'} onClick={(e) => {console.log(' _____ MATERIALSUMMARY ARR: ', materialSummary)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log materialFields arr'} onClick={(e) => {console.log(' _____ MATERIALFILEDS ARR: ', materialFields)}}></ButtonDenaryPlain>
          <ButtonDenaryPlain ml={'10px'} value={'log materialsforday'} onClick={(e) => {console.log(' _____ MATERIALSFORDAY: ', materialsForDay)}}></ButtonDenaryPlain> */}

        </Box>
        <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove entries.</Text>
        </Box>

        <MaterialSummaryTable
          tableData={materialFields}
          materialFields={materialFields}
          materialRemove={materialRemove}
          updateMaterialSummary={updateMaterialSummary}
          toggleMaterialsButtonState={toggleMaterialsButtonState}
          handleMaterialRowTotalChange={handleMaterialRowTotalChange}
          handleMaterialPriceChange={handleMaterialPriceChange}
          handleMaterialQuantityChange={handleMaterialQuantityChange}
          handleMaterialCustomDescriptionChange={handleMaterialCustomDescriptionChange}
          materialTotalAmount={materialSummaryTotal}
          djtTotalAmount={djtRunningTotal||0}
          costItem={costItem}
          control={control}
          register={register}
          errors={errors}
        />
      </ModuleBoxDrawer></fieldset>}

      {(showToggleMergeMaterialEquipment && !isEstimate) && <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Merge Material and Equipment on Ticket</Text><br/>
            <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>PDF and invoice will show material listed with equipment.</Text>
          </Box>
          <Spacer />
          <Box>
            <FormSwitch
              register={register}
              control={control}
              fieldname="mergeMaterialEquipment"
              isChecked={doMergeMaterialEquipment}
              onChange={() => {
                setValue('mergeMaterialEquipment', !doMergeMaterialEquipment)
                setDoMergeMaterialEquipment(!doMergeMaterialEquipment)
              }}
            />
          </Box>
        </Flex>
      </ModuleBoxDrawer>}

      {/* Client Summary> Start */}
      <ModuleBoxDrawer mt='25px'>

        {/* Client Summary Accordian */}
        {/* accordian for client summary */}
        <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          m='25px'
          p='25px'
          >
          <Accordion 
            allowToggle 
            index={accordianClientSummaryIndex} 
            onChange={setAccordianClientSummaryIndex}
            // onClick={setRefocusClientSumamry(true)}
            // onClick={clientSummaryRef?.current?.scrollIntoView({ behavior: "smooth" })}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton  
                my='15px'
                _expanded={{ py: '30px', bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box 
                  as="span" 
                  flex='1' 
                  textAlign='left'>
                  {(isEstimate) ?
                    <Text as="span" textStyle='heading-1'>Daily Job Estimate Summary</Text>
                    :
                    <Text as="span" textStyle='heading-1'>Daily Job Summary</Text>
                  }
                </Box>
                {(accordianClientSummaryIndex===-1) ? 
                  <AvailableIcons boxSize={'22px'} iconRef={'accordianexpandlg'} /> : 
                  <AvailableIcons boxSize={'22px'} iconRef={'accordiancollapselg'} />
                }
              </AccordionButton>
              </h2>
              <AccordionPanel>

                {(doShowEmailSentResults) ?
                  <>
                    <Box w={'100%'} pt={'25px'}>
                      <Center>
                        {(emailSentResults) ?
                          <Text as="span" textStyle={'heading-2'}>{emailSentResults}</Text>
                          :
                          <Spinner color='var(--progress-bar-primary)' />
                        }
                      </Center>
                    </Box>
                  </>
                :
                  <>
                    {(divisionAllowsEmailCC) && 
                      <HStack>
                        <Box width='400px' paddingBottom={'25px'}>
                          <FormSelectCreatableSingle
                            register={register}
                            errors={errors}
                            control={control}
                            optionsArray={ ccEmailOptions||[] }
                            selectedoption=''
                            value={ccRecipientSelected||''}
                            onChange={(event, action) => handleCCRecipientsChange(event, action)}
                            fieldname="ccRecipients"
                            fieldlabel="CC Recipients"
                            placeholder="Select or start typing to search or create new"
                            isClearable
                            isDisabled={!clientId || !clientContactSelected}
                          />
                        </Box>
                        <Spacer />
                        <Box w='396px' mt='10px'>
                          {/* <AvailableIcons boxSize={'22px'} iconRef={'accordianexpandlg'} /> */}
                          <Text as="span" textStyle='text-6'>CC: 
                          {ccRecipientEmailsSelected.map((email, index) => (
                            <Box pl='5px' key={email?.label} as="span" d="flex" >
                              {email?.label}<ButtonSenaryWithIconOnly
                                ml='5px'
                                w='22px'
                                h='22px'
                                name='delete'
                                icon='delete'
                                iconsize='16px'
                                onClick={() => handleCCRecipientDelete(index)}
                              />
                              {index < ccRecipientEmailsSelected.length - 1 && ','} {/* Conditional comma */}
                            </Box>
                          ))}
                          </Text>
                        </Box>
                      </HStack>
                    }
                    
                    <Box>
                      <Box pb={'25px'}>
                        <TextareaInput
                          register={register}
                          errors={errors}
                          fieldname="emailSummaryMessage"
                          fieldlabel="Message"
                          placeholder="Enter email message here."
                        />
                      </Box>
                      
                      <HStack>
                        {/* Left Container */}
                        <Box h='240px'>
                          <Box w='396px' flex='1'>
                            <Box w='396px' h='34px' borderBottom='1px solid var(--dark-component-border)'>
                              <Text as="span" mt='18px' textStyle='text-6'>Job Date: {djtDateLong}</Text>
                            </Box>
                            <Box w='396px' mt='9px' h='34px' borderBottom='1px solid var(--dark-component-border)'>
                              <Text as="span" textStyle='text-6'>PO: {djtPo||'No PO'}</Text>
                            </Box>
                            <Box w='396px' mt='9px' h='34px' borderBottom='1px solid var(--dark-component-border)'>
                              <Text as="span" textStyle='text-6'>Client: {companyName}</Text>
                            </Box>
                            <Box w='396px' mt='9px' h='34px' borderBottom='1px solid var(--dark-component-border)'>
                              <Text as="span" textStyle='text-6'>Contact Name: {clientContactName}</Text>
                            </Box>
                          </Box>
                        </Box>
                        <Spacer />

                        {/* Right Container */}
                        <Box h='240px' w='396px'>
                          <Box 
                            w='396px' 
                            h='51px' 
                            borderBottom='1px solid var(--dark-component-border)'>
                            <HStack pt='18px'>
                              <Text as="span" textStyle='text-6'>Labor</Text>
                              <Spacer/>
                              <Text as="span" textStyle='text-6'>{USDollar.format(laborSummaryTotal)}</Text>
                            </HStack>
                          </Box>
                          <Box 
                            w='396px' 
                            h='51px' 
                            borderBottom='1px solid var(--dark-component-border)'>
                            <HStack pt='18px'>
                              <Text as="span" textStyle='text-6'>Equipment</Text>
                              <Spacer/>
                              <Text as="span" textStyle='text-6'>{USDollar.format(unitSummaryTotal)}</Text>
                            </HStack>
                          </Box>
                          <Box 
                            w='396px' 
                            h='51px' 
                            borderBottom='1px solid var(--dark-component-border)'>
                            <HStack pt='18px'>
                              <Text as="span" textStyle='text-6'>Materials</Text>
                              <Spacer/>
                              <Text as="span" textStyle='text-6'>{USDollar.format(materialSummaryTotal)}</Text>
                            </HStack>
                          </Box>
                          <Box 
                            w='396px' 
                            h='60px' 
                            borderBottom='1px solid var(--dark-component-border)'>
                            <HStack pt='18px'>
                              <Text as="span" textStyle='heading-2'>Total Amount Due:</Text>
                              <Spacer/>
                              <Text as="span" textStyle='heading-2'>{USDollar.format(djtRunningTotal)}</Text>
                            </HStack>
                          </Box>
                          <Box py='10px'>
                            <ButtonQuaternaryWithIcon 
                              name='email'
                              iconsize='26px'
                              leftIcon='email'
                              value='Email summary to client contact'
                              onClick={() => handleEmailSummaryClick()}
                              // onClick={() => alert('EMAIL COMPONENT IN INTERNAL TESTING, AVAILABLE SOON!')}
                              isDisabled={(!clientContactEmailVerified||!clientContactEmailAddress||isDisabledWHileSending)}
                            />
                          </Box>
                        </Box>
                      </HStack>
                    </Box>
                  </>
                }
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </ModuleBoxDrawer>
      {/* <div ref={clientSummaryRef}></div> */}
      {/* Client Authorization */}
      {/* is_touch_enabled() */}
      {/* {(is_touch_enabled()) ? <Text>Touch Enabled</Text> : <Text>Not Touch Enabled</Text>} */}
      {(!isEstimate) && <ModuleBoxDrawer mt='25px'>
        {/* Client Authorization Accordian */}
        <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          m='25px'
          p='25px'
          >
          <Accordion 
            allowToggle 
            index={accordianClientAuthorizationIndex} 
            onChange={setAccordianClientAuthorizationIndex}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton  
                my='15px'
                onClick={() => {
                  // setShowAllUnits(true)
                  // handleAccordianClientSummaryClick(accordianUnitIndex)
                }} 
                _expanded={{ py: '30px', bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text as="span" textStyle='heading-1'>Client Authorization</Text>
                </Box>
                {/* <AccordionIcon /> */}
                {(accordianClientAuthorizationIndex===-1) ? 
                    <AvailableIcons boxSize={'22px'} iconRef={'accordianexpandlg'} /> : 
                    <AvailableIcons boxSize={'22px'} iconRef={'accordiancollapselg'} />}
              </AccordionButton>
              </h2>
              <AccordionPanel>
                <Box>
                    {/* Left Container */}
                    <Box h='300px' w='850px'>
                      <Box mb='25px'>
                        <TextInput
                          register={register}
                          errors={errors}
                          w='850px'
                          fieldname="djtClientAuthName"
                          fieldlabel="Client Name"
                          prettyname="Client Name"
                          placeholder="Client Name"
                          isRequired={false} 
                          isReadOnly={false}
                        />
                      </Box>
                      <Box><TextInput
                        register={register}
                        errors={errors}
                        fieldtype="hidden"
                        fieldname="clientsignature"
                        fieldlabel="Client Signature"
                      /></Box>
                      <Box w='850px'>
                        <SignaturePad
                          ref={sigCanvasClient}
                          canvasProps={{
                            width: '850px',
                            height: '100px',
                            style: { marginBottom: "25px", borderRadius: "6px", border: "1px solid green", background: "#fff" },
                          }}
                        />
                        <ButtonSecondaryPlain 
                          width={'120px'}
                          name='cancel'
                          value='Clear'
                          onClick={() => {
                            sigCanvasClient.current.clear()
                          }}
                        />
                      </Box>
                    </Box>

                    {/* Right Container */}
                    <Box h='300px' w='850px' mt='25px'>
                      <Box mb='25px'>
                        <TextInput
                          register={register}
                          errors={errors}
                          w='850px'
                          fieldname="djtSpikeAuthName"
                          fieldlabel="Spike Representative"
                          prettyname="Spike Representative"
                          placeholder="Spike Representative"
                          isRequired={false} 
                          isReadOnly={false}
                        />
                      </Box>
                      <Box><TextInput
                        register={register}
                        errors={errors}
                        fieldtype="hidden"
                        fieldname="representativesignature"
                        fieldlabel="Spike Representative Signature"
                      /></Box>
                      <Box>
                        <SignaturePad
                          ref={sigCanvasSpike}
                          canvasProps={{
                            width: '850px',
                            height: '100px',
                            style: { marginBottom: "25px", borderRadius: "6px", border: "1px solid green", background: "#fff" },
                          }}
                        />
                        <ButtonSecondaryPlain 
                          width={'120px'}
                          name='cancel'
                          value='Clear'
                          onClick={() => {
                            sigCanvasSpike.current.clear()
                          }}
                        />
                      </Box>
                    </Box>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

      </ModuleBoxDrawer>}

      {/* Special Billing Instructions */}
      {(!hideModuleFromClientView && !isEstimate) && <ModuleBoxDrawer mt='25px'>
        <Box mb='25px'>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Special Billing Instructions</Text>
        </Box>
        <Box>
          {/* Left Container */}
          <Box 
            // h='180px' 
            w='440px'
            // border='1px solid green'
            >
            <Box >
              <TextareaInput
                register={register}
                errors={errors}
                fieldname="specialInstructions"
                fieldlabel="Special Instructions for Spike Administrator"
                prettyname="Special Instructions"
                placeholder="Enter special instructions as needed, for split PO's or other special circumstances."
                isRequired={false} 
                isReadOnly={false}
              />
            </Box>
          </Box>
        </Box>
      </ModuleBoxDrawer>}

      {/* Show Hours */}
      {/* isEstimate */}
      {(!hideModuleFromClientView) && <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Show Hours on Bill and Client PDF</Text><br/>
            <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Show labor names for billing and client PDF.</Text>
          </Box>
          <Spacer />
          <Box>
            <FormSwitch
              register={register}
              control={control}
              fieldname="doShowHours"
              isChecked={doShowHoursState}
              onChange={() => {
                setValue('doShowHours', !doShowHoursState)
                setDoShowHoursState(!doShowHoursState)
              }}
            />
          </Box>
        </Flex>
      </ModuleBoxDrawer>}

      {/* Do Not Invoice */}
      {(!hideModuleFromClientView && !isEstimate) && <ModuleBoxDrawer mt='25px'>
        <Flex mb='25px'>
          <Box>
            <Text as="span" textStyle='heading-1'>Do Not Bill</Text>
          </Box>
          <Spacer />
          <Box>
            <FormSwitch
              register={register}
              control={control}
              errors={errors}
              fieldname="doNotBill"
              isChecked={doNotBillState}
              onChange={(e) => handleDoNotBillStateChange(e)}
            />
          </Box>
        </Flex>

        <Flex>
          <Box w='440px'>
          <TextareaInput
            register={register}
            errors={errors}
            fieldname="doNotBillDescription"
            fieldlabel="Reason for Do Not Bill"
            prettyname="Reason for Do Not Bill"
            placeholder="Enter reason for Do Not Bill"
            isRequired={false} 
            isReadOnly={false}
            isDisabled={(!doNotBillState)}
          />
          </Box>
        </Flex>
      </ModuleBoxDrawer>}

      {/* Issues Detected */}
      {(
        !hideModuleFromClientView && 
        (
          hasZeroDollarRental
          || hasZeroDollarCustomAuxilliary
          || hasZeroDollarAuxilliary
          || hasZeroDollarPrimary
          || hasZeroDollarLabor 
          || hasZeroDollarMaterial 
          || currentlyMissingDriver===true 
          || currentlyMissingOperator===true 
        ) && !isEstimate
        ) && <ModuleBoxDrawer mt='25px'>
        <Box mb='20px'>
          <HStack>
            <Box>
            <AvailableIcons color={'var(--error-red)'} boxSize={'28px'} iconRef={'alerttriangle'} />
            </Box>
            <Box>
              <Text as="span" ml={'5px'} mb={'5px'} textStyle='heading-1'>Issues Detected</Text>
            </Box>
          </HStack>
        </Box>
        <Box
          px={'45px'}
          mb={'15px'}
          >
          <Text as="span" 
            textStyle='text-2'>Please edit these entries, if required, before submitting this daily job ticket.</Text>
        </Box>
        <Box px={'65px'} mb={'10px'}>
        <ul>
          {(hasZeroDollarRental) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>You have added a $0 Rental.</Text></Link></li>}
          {(hasZeroDollarCustomAuxilliary) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>$0 Custom Auxilliary entry amount detected.</Text></Link></li>}
          {(hasZeroDollarAuxilliary) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>$0 Auxilliary entry amount detected.</Text></Link></li>}
          {(hasZeroDollarPrimary) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>$0 Primary Unit entry amount detected.</Text></Link></li>}
          {(hasZeroDollarLabor) && <li><Link href="#laborscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>$0 Labor entry amount detected.</Text></Link></li>}
          {(hasZeroDollarMaterial) && <li><Link href="#materialscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>$0 Material entry amount detected.</Text></Link></li>}
          {(currentlyMissingDriver===true) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>Driver not added for Equipment.</Text></Link></li>}
          {(currentlyMissingOperator===true) && <li><Link href="#unitscrollto"><Text textDecoration={"underline"} color='var(--dark-gold-primary)'>Operator not added for Equipment.</Text></Link></li>}
        </ul>
        </Box>
      </ModuleBoxDrawer>}

      {/* Verified Ready to Submit for Invoicing */}
      {(!hideModuleFromClientView && !isEstimate) && <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Verified Ready to Submit</Text><br/>
            <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>I have checked this daily job ticket and verified that everything is correct and complete. It is ready to be sent to billing.</Text>
          </Box>
          <Spacer />
          <Box>
            <FormSwitch
              register={register}
              control={control}
              fieldname="isVerifiedReady"
              isChecked={isVerifiedReadyState}
              onChange={() => {
                // setValue('isVerifiedReady', e)
                setIsVerifiedReadyState(!isVerifiedReadyState)
              }}
            />
            <TextInput
              register={register}
              errors={errors}
              // width='102px'
              fieldname={'verifiedById'}
              fieldvalue={currentTeamMemberId}
              fieldtype={'hidden'}
            />
          </Box>
        </Flex>
      </ModuleBoxDrawer>}

      {/* hasSelectedDoNotBillRental, hasZeroDollarRental, hasZeroDollarCustomAuxilliary, hasZeroDollarAuxilliary, hasZeroDollarPrimary */}

      <TextInput
        register={register}
        errors={errors}
        fieldtype="hidden"
        fieldname="isClientNew"
      />

      {/* Submit */}
      {/* Add textarea for reviewing */}

      {/* add the submit-estimate button that only shows up, and is the only button available, if this is an estimate. */}
      <ModuleBoxDrawer mt='25px' mb='50px'>
        <Box mb='50px' pt='45px'>
          {(isReviewing) ? 
            <><Box mb='25px'>
              <Text as="span" mb={'10px'} textStyle='heading-1'>Resubmit to Billing</Text>
            </Box>
            <Box>
              {/* Left Container */}
              <HStack>
              <Box h='115px' w='440px'>
                <Box >
                  <TextareaInput
                    register={register}
                    errors={errors}
                    fieldname="responseFromSupervisor"
                    fieldlabel="Reason for Edit or No Edit Required"
                    prettyname="Reason for edit or no edit"
                    placeholder="Enter reason for edit or no edit required."
                    isRequired={true} 
                    isReadOnly={false}
                  />
                </Box>
              </Box>
              <Spacer/>
              <Box h='115px'>
                <ButtonPrimaryPlain
                  mt='67px'
                  mr='25px'
                  type="submit"
                  onClick={handleSubmit(formSubmit, onError)}
                  // onClick={formConfirmation}
                  width={'210px'}
                  name='submit-invoicing'
                  value='Resubmit to Billing'
                  isDisabled={!isVerifiedReadyState}
                />
              </Box>
              </HStack>
            </Box></>
          : 
            <><Center>
            {(isEstimate) ? <>
              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                // onClick={formConfirmation}
                width={'210px'}
                name='submit-estimate'
                value='Save as Estimate'
              /></>
            : <>
              <ButtonDenaryPlain
                mr='25px'
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                // onClick={formConfirmation}
                width={'210px'}
                name='submit-invoicing'
                value='Submit to Billing'
                isDisabled={(laborIsLocked || isVerifiedReadyState===false)}
              />
              {/* <>laborIsLocked: {laborIsLocked} </>
              <>djtDate > dateToday: {djtDate+' '+dateToday} </>
              <>isVerifiedReadyState===false): {isVerifiedReadyState}</> */}

              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                // onClick={formConfirmation}
                width={'210px'}
                name='submit-save'
                value='Save as Draft'
              />

            </>}
            </Center></>
          }
        </Box>
      </ModuleBoxDrawer>
    </Container>
  )
}