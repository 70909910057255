import { useEffect, useContext, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';

// https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx%3A131%2C11-131%2C17
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useForm } from 'react-hook-form';
import {
  Box,
  Text,
  Flex,
  Spacer,
  VStack,
} from "@chakra-ui/react";

// From
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonDenaryPlain } from '../Form/Button/ButtonDenaryPlain.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// AWS
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';

// Generic
import { awsDateTimeNow } from '../functions/dateTime.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// graphql
import { addUnitDocument } from '../graphqlCompnents/Unit/addUnitDocument.jsx';
import { updateUnit } from '../graphqlCompnents/Unit/updateUnit.jsx';

import { v4 as uuid } from 'uuid';

export const UploadUnitImages = (props) => {
  const {
    unitid,
    unitname=null,
    refresh,
  } = props

  console.log('unitname:', unitname);

  // grab global context values
  const { store } = useContext(AppContext);
  // console.log('store:', store);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  const unitDocumentTypes = store?.unitDocumentTypes
  // console.log('unitDocumentTypes:', unitDocumentTypes);

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    setValue, 
  } = useForm({ 
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {},
  });

  
  const [src, setSrc] = useState('');
  const [crop, setCrop] = useState({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  useEffect(() => {
    completedCrop && console.log('completedCrop:', completedCrop);
  },[completedCrop])

  const [previewUrl, setPreviewUrl] = useState(null);
  // const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const imgRef = useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setSrc(reader.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // smoother execution but rectangle crop
  // const onImageLoad = () => {
  //   if (imgRef.current) {
  //     // Calculate the smaller dimension to ensure a square
  //     const width = Math.min(50, (imgRef.current.width * 50) / imgRef.current.height);
  //     const height = width;
  //     const x = (100 - width) / 2;
  //     const y = (100 - height) / 2;

  //     const initialCrop = {
  //       unit: '%',
  //       x,
  //       y,
  //       width,
  //       height,
  //       aspect: 1
  //     };
      
  //     setCrop(initialCrop);
  //     setCompletedCrop(initialCrop);
  //     handleCropComplete(initialCrop);
  //   }
  // };

  const onImageLoad = () => {
    if (imgRef.current) {
      const { width: displayedWidth, height: displayedHeight } = imgRef.current;
      // Use the smaller dimension for the crop size
      const cropSize = Math.min(displayedWidth, displayedHeight);
      // Center the crop
      const initialCrop = {
        unit: 'px',
        x: (displayedWidth - cropSize) / 2,
        y: (displayedHeight - cropSize) / 2,
        width: cropSize,
        height: cropSize,
        aspect: 1,
      };
      setCrop(initialCrop);
      setCompletedCrop(initialCrop);
      handleCropComplete(initialCrop);
    }
  };
  
  const [result, setResult] = useState(null);
  const imageRef = useRef(null);
  // const [isGeneratingCrop, setIsGeneratingCrop] = useState(false);
  
  // const handleCropComplete = async (crop) => {
  //   if (!imgRef.current || !crop.width || !crop.height) return;

  //   // Get original image dimensions
  //   const imageWidth = imgRef.current.naturalWidth;
  //   const imageHeight = imgRef.current.naturalHeight;
    
  //   // Get display dimensions
  //   const displayWidth = imgRef.current.width;
  //   const displayHeight = imgRef.current.height;
    
  //   // Calculate scaling factors
  //   const scaleX = imageWidth / displayWidth;
  //   const scaleY = imageHeight / displayHeight;
    
  //   // Get device pixel ratio
  //   const pixelRatio = window.devicePixelRatio || 1;
    
  //   // Create canvas with proper scaling
  //   const canvas = document.createElement('canvas');
  //   canvas.width = Math.round(crop.width * scaleX);
  //   canvas.height = Math.round(crop.height * scaleY);
    
  //   const ctx = canvas.getContext('2d');
  //   if (!ctx) return;
    
  //   // Disable smoothing for sharper results
  //   ctx.imageSmoothingEnabled = false;
    
  //   // Draw the cropped image
  //   ctx.drawImage(
  //     imgRef.current,
  //     Math.round(crop.x * scaleX),
  //     Math.round(crop.y * scaleY),
  //     Math.round(crop.width * scaleX),
  //     Math.round(crop.height * scaleY),
  //     0,
  //     0,
  //     canvas.width,
  //     canvas.height
  //   );
    
  //   // Create preview canvas with device pixel ratio consideration
  //   const previewCanvas = document.createElement('canvas');
  //   const previewCtx = previewCanvas.getContext('2d');
  //   if (!previewCtx) return;
    
  //   // Set preview dimensions
  //   const previewSize = 400; // Max preview size
  //   const scale = Math.min(previewSize / canvas.width, previewSize / canvas.height);
    
  //   previewCanvas.width = Math.round(canvas.width * scale * pixelRatio);
  //   previewCanvas.height = Math.round(canvas.height * scale * pixelRatio);
    
  //   // Scale for device pixel ratio
  //   previewCtx.scale(pixelRatio, pixelRatio);
    
  //   // Draw with proper scaling
  //   previewCtx.drawImage(
  //     canvas,
  //     0,
  //     0,
  //     previewCanvas.width / pixelRatio,
  //     previewCanvas.height / pixelRatio
  //   );
    
  //   // Generate blob with proper quality
  //   previewCanvas.toBlob(
  //     (blob) => {
  //       if (blob) {
  //         const croppedImageUrl = URL.createObjectURL(blob);
  //         setPreviewUrl(croppedImageUrl);
  //         setResult(croppedImageUrl);
  //       }
  //     },
  //     'image/jpeg',
  //     1
  //   );
  // };
  
  const handleCropComplete = async (crop) => {
    if (!imgRef.current || !crop.width || !crop.height) return;
  
    const imageWidth = imgRef.current.naturalWidth;
    const imageHeight = imgRef.current.naturalHeight;
    const displayWidth = imgRef.current.width;
    const displayHeight = imgRef.current.height;
    
    // Calculate scaling factors
    const scaleX = imageWidth / displayWidth;
    const scaleY = imageHeight / displayHeight;
    const pixelRatio = window.devicePixelRatio || 1;
  
    // Create high-resolution canvas
    const canvas = document.createElement('canvas');
    canvas.width = Math.round(crop.width * scaleX * pixelRatio);
    canvas.height = Math.round(crop.height * scaleY * pixelRatio);
    
    const ctx = canvas.getContext('2d', {
      alpha: false,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high'
    });
    
    if (!ctx) return;
  
    // Scale context for high DPI displays
    ctx.scale(pixelRatio, pixelRatio);
    
    // Draw with better quality settings
    ctx.drawImage(
      imgRef.current,
      Math.round(crop.x * scaleX),
      Math.round(crop.y * scaleY),
      Math.round(crop.width * scaleX),
      Math.round(crop.height * scaleY),
      0,
      0,
      canvas.width / pixelRatio,
      canvas.height / pixelRatio
    );
  
    // Create a second canvas for downscaling
    const finalCanvas = document.createElement('canvas');
    const targetSize = 400;
    const scale = Math.min(targetSize / canvas.width, targetSize / canvas.height);
    
    finalCanvas.width = Math.round(canvas.width * scale);
    finalCanvas.height = Math.round(canvas.height * scale);
    
    const finalCtx = finalCanvas.getContext('2d', {
      alpha: false,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high'
    });
    
    if (!finalCtx) return;
    
    // Use better quality downscaling
    finalCtx.drawImage(
      canvas,
      0,
      0,
      finalCanvas.width,
      finalCanvas.height
    );
  
    // Generate high-quality JPEG
    finalCanvas.toBlob(
      (blob) => {
        if (blob) {
          const croppedImageUrl = URL.createObjectURL(blob);
          setPreviewUrl(croppedImageUrl);
          setResult(croppedImageUrl);
        }
      },
      'image/jpeg',
      0.95 // Higher quality setting
    );
  };
  
  const resizeImage = (imageUrl, width, height) => {
    return new Promise((resolve, reject) => {
      console.log(`Starting resizeImage for dimensions ${width}x${height} and image URL:`, imageUrl);
  
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
  
      if (!ctx) {
        console.error('Canvas context could not be created.');
        reject(new Error('Canvas context could not be created.'));
        return;
      }
  
      const image = new Image();
      const timestamp = Date.now();
      const fileName = `${timestamp}.jpg`;
      const imageType = 'image/jpeg';
  
      image.onload = () => {
        console.log('Image loaded successfully:', imageUrl);
        ctx.drawImage(image, 0, 0, width, height);
        console.log(`Image drawn on canvas with dimensions ${width}x${height}`);
  
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              console.error('Failed to create a blob from the canvas.');
              reject(new Error('Failed to create a blob from the canvas.'));
              return;
            }
            const file = new File([blob], fileName, { type: imageType });
            console.log('Blob successfully created and converted to File:', file);
            resolve(file);
          },
          imageType,
          1 // High-quality compression
        );
      };
  
      image.onerror = (error) => {
        console.error('Error loading image:', error);
        reject(new Error(`Failed to load image: ${imageUrl}`));
      };
  
      console.log('Setting image source to:', imageUrl);
      image.src = imageUrl;
    });
  };

  // fileinput ref for attached contracts
  const fileInputRef = useRef(null)

  const [ isDefaultImage, setIsDefaultImage ] = useState(false)

  let unitId = unitid||null

  const [smallImageTypeId, setSmallImageTypeId] = useState(null);
  const [mediumImageTypeId, setMediumImageTypeId] = useState(null);
  const [largeImageTypeId, setLargeImageTypeId] = useState(null);
  useEffect(() => {
    const findTypeIdByName = (name) => unitDocumentTypes?.find(type => type?.name === name)?.id;

    if (unitDocumentTypes) {
      const smallTypeId = findTypeIdByName('unit-photo-small');
      const mediumTypeId = findTypeIdByName('unit-photo-medium');
      const largeTypeId = findTypeIdByName('unit-photo-large');

      if (smallTypeId) {
        console.warn('Set Small Image Type ID: ', smallTypeId);
        setSmallImageTypeId(smallTypeId);
      }

      if (mediumTypeId) {
        console.warn('Set Medium Image Type ID: ', mediumTypeId);
        setMediumImageTypeId(mediumTypeId);
      }

      if (largeTypeId) {
        console.warn('Set Large Image Type ID: ', largeTypeId);
        setLargeImageTypeId(largeTypeId);
      }
    }
  }, [unitDocumentTypes, 
    // setSmallImageTypeId, setMediumImageTypeId, setLargeImageTypeId
  ]);
  
  const resetImageUploadForm = () => {
    console.log('Resetting the image upload form...');
    
    // Clear the image source and crop states
    setSrc('');
    setCrop(null);
    setCompletedCrop(null);
  
    // Clear the preview and result URLs
    setPreviewUrl(null);    
    setResult(null);
  
    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clears the file input and icon
    }
  
    // Reset form and toggle states
    setValue('isDefaultImage', false); // If using react-hook-form
    setIsDefaultImage(false);
    // setIsUploadDisabled(true); // Disable upload button until a new image is selected
  };

  const saveImages = async () => {
    try {
      console.log('Starting saveImages process...');

      const imageSetId = uuid();

      // Function to save image data to DB
      const saveImageToDB = async (documentId, unitDocumentTypeId, imageSetId) => {
        const documentParams = {
          unitId,
          createdById: currentTeamMemberId,
          uploadedDateTime: awsDateTimeNow(),
          unitDocumentTypeId,
          uploadedDocumentId: documentId,
          unitIdUnitDocumentTypeId: `${unitId}#${unitDocumentTypeId}`,
          active: 'active',
          isActive: true,
          imageSetId,
        };
        console.info(' -----> documentParams: ', documentParams);
  
        const newUnitDocument = await addUnitDocument(documentParams);
        console.info(' -----> newUnitDocument: ', newUnitDocument);
  
        const unitDocumentId = newUnitDocument.id;
  
        if (isDefaultImage) {
          let updatePayload;
        
          // Determine the payload based on the unitDocumentTypeId
          if (unitDocumentTypeId === smallImageTypeId) {
            updatePayload = { id: unitId, unitSmallImageId: unitDocumentId };
          } else if (unitDocumentTypeId === mediumImageTypeId) {
            updatePayload = { id: unitId, unitMediumImageId: unitDocumentId };
          } else if (unitDocumentTypeId === largeImageTypeId) {
            updatePayload = { id: unitId, unitLargeImageId: unitDocumentId };
          } else {
            throw new Error("Invalid unitDocumentTypeId: Unable to determine the image type");
          }
        
          await updateUnit(updatePayload);
        }

        // if (isDefaultImage) {
        //   const updatePayload =
        //     unitDocumentTypeId === smallImageTypeId
        //       ? { id: unitId, unitSmallImageId: unitDocumentId }
        //       : { id: unitId, unitMediumImageId: unitDocumentId };

  
        //   await updateUnit(updatePayload);
        // }
      };
  
      const sizes = [172, 364, 660];
      const keyNames = ['small', 'medium', 'large'];
      const bucket = 'prod-spike-unit-images';
  
      // Validate `result` before starting tasks
      if (!result) {
        console.error('No cropped image result available. Cannot proceed with cropped image tasks.');
        throw new Error('Cropped image result is null or undefined.');
      }
  
      

      // Process cropped images
      const croppedImagesTasks = sizes.map((size, index) => {
        // const key = index === 0 ? 'small' : 'medium';
        const key = keyNames[index];
        console.log(`Starting ${key} image processing for size ${size}x${size}...`);
  
        return resizeImage(result, size, size)
          .then((resizedImage) => {
            if (!resizedImage) {
              throw new Error(`${key} resizeImage failed`);
            }
            console.log(`${key} image resized successfully:`, resizedImage);
            return uploadFileToS3({ file: resizedImage, bucket });
          })
          .then((uploadData) => {
            if (!uploadData || !uploadData.documentId) {
              throw new Error(`${key} uploadFileToS3 failed`);
            }
            console.log(`${key} image uploaded successfully:`, uploadData);
            const typeIds = {
              small: smallImageTypeId,
              medium: mediumImageTypeId,
              large: largeImageTypeId,
            };
            return saveImageToDB(uploadData.documentId, typeIds[key], imageSetId);
          })
          .then(() => {
            console.log(`${key} image saved to DB`);
          })
          .catch((error) => {
            console.error(`Error processing ${key} image:`, error);
            throw error;
          });
      });
  
      console.log('Processing large uncropped image...');
      const largeUncropped = await resizeImageUncropped(src, 1024, 1024)
        .then((file) => {
          if (!file) throw new Error('resizeImageUncropped returned a null/undefined file');
          console.log('Large uncropped image resized successfully:', file);
          return file;
        })
        .catch((error) => {
          console.error('Error resizing large uncropped image:', error);
          throw error;
        });
  
      const originalImageTask = uploadFileToS3({ file: largeUncropped, bucket })
        .then((uploadData) => {
          if (!uploadData || !uploadData.documentId) {
            throw new Error('uploadFileToS3 returned invalid data for large image');
          }
          console.log('Large uncropped image uploaded successfully:', uploadData);
          return saveImageToDB(uploadData.documentId, largeImageTypeId, imageSetId).then(() => {
            console.log('Large uncropped image saved to DB');
          });
        })
        .catch((error) => {
          console.error('Error processing large uncropped image:', error);
          throw error;
        });
  
      console.log('Waiting for all tasks to complete...');
      const results = await Promise.allSettled([...croppedImagesTasks, originalImageTask]);
  
      results.forEach((result, index) => {
        // const taskType =
        //   index < sizes.length ? `Cropped ${index === 0 ? 'small' : 'medium'} Task` : 'Original Image Task';
        const taskType = keyNames[index];
        if (result.status === 'fulfilled') {
          console.log(`${taskType} succeeded`);
        } else {
          console.error(`${taskType} failed:`, result.reason);
        }
      });
  
      console.log('All images have been processed and data has been saved.');
  
      resetImageUploadForm();
      refresh();
    } catch (error) {
      console.error('Error in saveImages:', error);
    }
  };

  const resizeImageUncropped = (imageUrl, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const timestamp = Date.now();
        const fileName = `${timestamp}.jpg`;
        const imageType = 'image/jpeg';
        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, { type: imageType });
          resolve(file);
        }, imageType);

        // canvas.toBlob((blob) => {
        //   resolve(blob);
        // }, 'image/jpeg');
      };
      image.src = imageUrl;
    });
  };
  
  useEffect(() => {
    if (src && imageRef.current) {
      // Force crop update by slightly modifying it
      const newCrop = {
        ...crop
      };
      setCrop(newCrop);
      handleCropComplete(newCrop);
      console.log('Force crop update:', newCrop);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <Box>
      <VStack spacing={4} align="stretch" w="100%">
        <Box padding="20px">
          {/* Left-aligned section */}
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box marginBottom="25px">
              <Text as="span" textStyle="heading-1">
                Upload Unit Image
              </Text>
            </Box>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              style={{ marginBottom: '25px' }}
            />
            {src && (
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={(newCrop) => {
                  setCompletedCrop(newCrop);
                  handleCropComplete(newCrop); // Generate preview and result on crop complete
                }}
                aspect={1} // Keep aspect ratio square
              >
                <img
                  src={src}
                  ref={imgRef}
                  alt="Source"
                  onLoad={onImageLoad}
                  style={{
                    width: '100%', // Fill horizontally
                    height: 'auto', // Maintain aspect ratio
                    display: 'block',
                  }}
                />
              </ReactCrop>
            )}
          </Box>
  
          {/* Centered preview */}
          {previewUrl && (
            <Box
              marginTop="20px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="lg" fontWeight="medium" marginBottom="10px">
                Preview
              </Text>
              <img
                alt="Crop preview"
                src={previewUrl}
                style={{
                  maxWidth: '400px',
                  maxHeight: '400px',
                  objectFit: 'contain',
                  imageRendering: 'high-quality', // Modern browsers
                  // '-webkit-font-smoothing': 'antialiased',
                  WebkitFontSmoothing: 'antialiased',
                  width: '100%',
                  height: 'auto',
                  // imageRendering: 'auto',
                  backgroundColor: '#ffffff',
                  display: 'block',
                  margin: '0 auto', // Center the image
                }}
              />
            </Box>
          )}

          <Box w={'300px'} pt={'25px'}>
            <Flex>
              <Box>
                <Text as="span" textStyle='label-1'>Set as default unit image</Text><br/>
              </Box>
              <Spacer />
              <Box>
                <FormSwitch
                  register={register}
                  control={control}
                  fieldname="isDefaultImage"
                  isChecked={isDefaultImage}
                  onChange={() => {
                    setValue('isDefaultImage', !isDefaultImage)
                    setIsDefaultImage(!isDefaultImage)
                  }}
                />
              </Box>
            </Flex>
          </Box>
  
          {/* Action Buttons */}
          <Box
            marginTop="25px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonDenaryPlain
              width="250px"
              mr="25px"
              type="button"
              onClick={resetImageUploadForm} // Resets form and states
              name="cancel"
              value="Reset"
            />
            <ButtonPrimaryPlain
              width="250px"
              onClick={saveImages}
              type="button"
              name="upload-file"
              // value={isGeneratingCrop ? "Generating..." : "Upload Image"}
              value={"Upload Image"}
              isDisabled={!result} // Disable while generating or if result is null
            />
          </Box>
        </Box>
      </VStack>
    </Box>
  );

}

UploadUnitImages.propTypes = {
  unitid: PropTypes.string.isRequired,
  unitname: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};