import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
} from "@chakra-ui/react";

import { UnitSummaryTab } from "./TabPanels/UnitSummaryTab.jsx";
import { ModuleBox } from "../Structural/ModuleBox";

export const UnitInfoTabs = () => {
  return (
    <ModuleBox>
      <Box 
        backgroundColor={'var(--dark-menu-background)'}
        borderRadius="6px" 
        p={'25px'}>
        <Tabs colorScheme="yellow">
          <TabList>
            <Tab>Summary</Tab>
            <Tab>Images</Tab>
            <Tab>Documentation</Tab>
            <Tab>Receipts</Tab>
            <Tab>Inspections</Tab>
            <Tab>User Manual</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <UnitSummaryTab />
            </TabPanel>
            <TabPanel>
              <Text color="gray.200">Images go here...</Text>
            </TabPanel>
            <TabPanel>
              <Text color="gray.200">Documentation goes here...</Text>
            </TabPanel>
            <TabPanel>
              <Text color="gray.200">Receipts go here...</Text>
            </TabPanel>
            <TabPanel>
              <Text color="gray.200">Inspections go here...</Text>
            </TabPanel>
            <TabPanel>
              <Text color="gray.200">User manual goes here...</Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ModuleBox>
  );
};
