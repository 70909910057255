import { listPlaidCreditCardTransactionsByAccountNumberByDate } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchPlaidCreditCardTransactionByAccountNumberByDate = async (lastFour, startDate, limit = 300) => {
  try {

    // filter out pending_match after query
    const gqlParams = {
      accountNumber: lastFour, 
      date: {ge: startDate}, 
      sortDirection: "DESC", 
      limit,
    }

    // console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: listPlaidCreditCardTransactionsByAccountNumberByDate,
      variables: gqlParams,
    });

    // console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.plaidCreditCardTransactionByAccountNumberByDate) {
      console.warn(`No data returned for card ending in ${lastFour}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.plaidCreditCardTransactionByAccountNumberByDate.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for card ending in ${lastFour}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchPlaidCreditCardTransactionByAccountNumberByDate:', err);
    return null; // Return null on error
  }
};

fetchPlaidCreditCardTransactionByAccountNumberByDate.propTypes = {
  lastFour: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  limit: PropTypes.number,
};