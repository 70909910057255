import { allUnitDocumentTypes } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitDocumentTypes = async () => {
  try {
    const response = await client.graphql({
      query: allUnitDocumentTypes,
      variables: { allSort: "all" },
    });

    const results = response.data.allUnitDocumentTypes.items.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    // Optionally, you could use the other sorting logic by description here:
    // const results = response.data.allUnitDocumentTypes.items.sort((a, b) =>
    //   a.description > b.description ? 1 : -1
    // );

    return results;
  } catch (err) {
    return ('error fetchUnitDocumentTypes: ', [err]);
  }
}
